import React from 'react'
import useListProduct from '../listProductsHook';
import GVDItem from './GVDItem';
import ProductItem from './ProductItem';

const GVDDetailItem = ({ detail }) => {

  const [products, fetchProducts] = useListProduct(detail.ID)

  return (
    <GVDItem level={3} description={detail.DetailDesc } hasChildren={true} fetchData={fetchProducts} total={detail.Total}>
      {products?.length === 0 && <span></span>}
      {products?.map((product, key) => (
        <ProductItem key={key} product={product} />
      ))}
    </GVDItem>
  );
};
export default GVDDetailItem;