import React, { useContext } from 'react';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import { BarcodeSearchButton } from './ProductScanNewStyle';
import ReclassifyModal from './ReclassifyModal';

const ButtonShowReclassifyModal = (props) => {

  return (
    <>
      <button className='bg-primary rounded px-3 py-1.5 text-white' onClick={props.openClassifyModal}>Classify</button>
      <ReclassifyModal {...props} />
    </>
  );
};

export default ButtonShowReclassifyModal;