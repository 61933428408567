import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listRetailerIndustries, listServiceProviderIndustries, listSupplierIndustries, setAccountAsRetailer, setAccountAsServiceProvider, setAccountAsSupplier } from '../../api/SignUp';
import { HTTP_STATUS_CODES } from '../../api/API';

import './GetStartedAccountTypeSelection.scss';
import { useAuthContext } from '../../context/AuthContext';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import { useFormFieldOnChange } from '../../utils/FormHooks';

const GetStartedAccountTypeSelection = () => {

  const [industryOther, setIndustryOther, onChangeIndustryOther] = useFormFieldOnChange('');

  const userAuth = useAuthContext();
  const user = userAuth.user;

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [hasBeenAccountTypeSet, setHasBeenAccountTypeSet] = useState(false);
  const [secsToNextStep, setSecsToNextStep] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  const [loadingIndustry, setLoadingIndustry] = useState(false);
  const [industries, setIndustries] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const [formInvalidMsg, setFormInvalidMsg] = useState(null);

  const onChangeAccountType = (event) => {
    setFormInvalidMsg(null);
    setSelectedAccountType(event.currentTarget.value);
  };

  const onChangeIndustry = (event) => {
    setFormInvalidMsg('');
    setIndustryOther('');
    setSelectedIndustry(industries.find(
      (industry) => industry.SystemSelectID === event.currentTarget.value
    ));
  }

  const submitForm = (event) => {
    event.preventDefault();

    if (selectedAccountType === null) {
      setFormInvalidMsg('Please, choose an account type!');
      return;
    }

    if (selectedIndustry === null || selectedIndustry.SelectionSortby === 0) {
      setFormInvalidMsg('Please, choose an industry!');
      return;
    }

    if (selectedIndustry?.SelectionSortby === 9 && (industryOther === null || !industryOther.trim().length)) {
      setFormInvalidMsg('Please, type an industry!');
      return;
    }

    callSetAccountAsSuch();
  };

  const setAccountAsSuch = async () => {
    if (selectedAccountType === 'supplier') {
      return await setAccountAsSupplier();
    }
    if (selectedAccountType === 'retailer') {
      return await setAccountAsRetailer();
    }
    if (selectedAccountType === 'service-provider') {
      return await setAccountAsServiceProvider();
    }
    return false;
  };

  const callSetAccountAsSuch = async () => {
    setIsSubmitting(true);
    const res = await setAccountAsSuch();
    setIsSubmitting(false);

    setSubmissionError(res === false || res.status !== HTTP_STATUS_CODES.NO_CONTENT);

    if (res !== false && res.status === HTTP_STATUS_CODES.NO_CONTENT) {
      setHasBeenAccountTypeSet(true);
      setSecsToNextStep(3);
      userAuth.setUser({
        ...user,
        isSupplier: selectedAccountType === 'supplier',
        isRetailer: selectedAccountType === 'retailer',
        isServiceProvider: selectedAccountType === 'service-provider',
      });
    }
  };

  useEffect(() => {
    if (secsToNextStep === null) return;
    if (secsToNextStep <= 0) {
      const industry = !industryOther.trim().length ? selectedIndustry.SelectionName : industryOther;
      if (selectedAccountType === 'supplier') {
        navigate('/GetStarted/AccountTypeSupplier', {
          state: { industry: industry }
        });
      }
      else if (selectedAccountType === 'retailer') {
        navigate('/GetStarted/AccountTypeRetailer', {
          state: { industry: industry }
        });
      }
      else if (selectedAccountType === 'service-provider') {
        navigate('/GetStarted/AccountTypeServiceProvider', {
          state: { industry: industry }
        });
      }
      return;
    }

    setTimeout(() => {
      setSecsToNextStep(secsToNextStep - 1);
    }, 1000);
  }, [secsToNextStep]);

  useEffect(() => {
    fetchIndustries();
  }, [selectedAccountType]);

  const fetchIndustries = async () => {
    setIndustries(null);
    setLoadingIndustry(true);
    setSelectedIndustry(null);

    if (selectedAccountType === 'supplier') {
      var res = await listSupplierIndustries();
    }
    else if (selectedAccountType === 'retailer') {
      var res = await listRetailerIndustries();
    }
    else if (selectedAccountType === 'service-provider') {
      var res = await listServiceProviderIndustries();
    }
    else {
      setLoadingIndustry(false);
      return;
    }
    setLoadingIndustry(false);
    if (res === false || res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setSelectedIndustry(res.data[0]);
    setIndustries(res.data);
  };

  const resetForm = (event) => {
    event.preventDefault();
    setSelectedAccountType(null);
  };

  return (
    <DashboardContainer title={"Choose Account Type"}>
      <div className="dashboard-get-started">
        <form className="form get-started-account-type">
          <div className="account-type-container">
            <div className="account-type-title">
              <input type="radio" name="user-type" value="supplier" checked={selectedAccountType === 'supplier'} onChange={onChangeAccountType} />
              <span>Supplier</span>
            </div>
            <div className="account-type-description">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
            </div>
          </div>
          <div className="account-type-container">
            <div className="account-type-title">
              <input type="radio" name="user-type" value="retailer" checked={selectedAccountType === 'retailer'} onChange={onChangeAccountType} />
              <span>Retailer</span>
            </div>
            <div className="account-type-description">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
            </div>
          </div>
          <div className="account-type-container">
            <div className="account-type-title">
              <input type="radio" name="user-type" value="service-provider" checked={selectedAccountType === 'service-provider'} onChange={onChangeAccountType} />
              <span>Service Provider</span>
            </div>
            <div className="account-type-description">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
            </div>
          </div>
          {!loadingIndustry && industries !== null && (
            <div className="action-footer" style={{ marginBottom: '20px' }}>
              <table>
                <tbody>
                  <tr>
                    <th><span>Industry: </span></th>
                    <th>
                      <select value={selectedIndustry?.SystemSelectID} onChange={onChangeIndustry} style={{ width: '100%' }}>
                        {industries.map(((industry, index) => (
                          <option key={index} value={industry.SystemSelectID}>{industry.SelectionName}</option>
                        )))}
                      </select>
                    </th>
                  </tr>
                  {selectedIndustry?.SelectionSortby === 9 && (
                    <tr>
                      <td></td>
                      <td>
                        <input type="text" onChange={onChangeIndustryOther} value={industryOther} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {loadingIndustry &&
            <div className="message" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              Loading ...
            </div>
          }
          {formInvalidMsg?.trim().length > 0 && (
            <div className="message choose-account-type">
              {formInvalidMsg}
            </div>
          )}
          {isSubmitting &&
            <div className="message" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              We are processing your request ...
            </div>
          }
          {submissionError &&
            <div className="message" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
              Something went wrong, please contact our support!
            </div>
          }
          {hasBeenAccountTypeSet &&
            <div className="message" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              All set up! You will be taken to the next step in {secsToNextStep} second(s)
            </div>
          }
          {!hasBeenAccountTypeSet && !isSubmitting && (
            <div className="action-footer">
              <button onClick={submitForm}>Confirm</button>
              <button onClick={resetForm}>Reset</button>
            </div>
          )}
        </form>
      </div>
    </DashboardContainer>
  );
};

export default GetStartedAccountTypeSelection;
