import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './DatabaseAPI.css';

const DatabaseAPI = () => {
  return (
    <PageSkeletonComponent>

     <h1>DATABASE - API Page</h1>

      <hr />
      
      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" rel="noopener noreferrer" href="https://trickletreat.atlassian.net/wiki/spaces/BBA/pages/409010199/Buy+Back+API">API Documentation</a><br />

      <h2>Purpose of Page</h2>
      <b>Information about our Public API with instructions about how to use it</b>

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default DatabaseAPI;