import React from "react";

export default function PageNotFound(){
    return(
        <div className={`w-full h-full flex justify-center items-center px-3 rounded-xl overflow-y-auto`}>
            <div className="flex justify-center items-center flex-col gap-8">
                <p className="text-[50px] leading-7 primary">
                    404!
                </p>
                <p className="text-[40px] leading-7 text-black">
                    Page Not Found
                </p>
            </div>
        </div>
    );
}