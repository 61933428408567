import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './SupplierLocations.css';

const SupplierLocations = () => {
  return (
    <PageSkeletonComponent>

     <h1>SUPPLIER - Locations</h1>

      <hr />

      <h2>Purpose of Page</h2>
      Listing of locations associated with the supplier.<br /><br />
      Supplier locations include Head Office, State Offices, Distribution Centres... and maybe something else...

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default SupplierLocations;