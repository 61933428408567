import { useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import {
  SihSignupHistory,
  fetchAccountDetails,
  fetchAccountDetailsAfterLogin,
  fetchSIGG,
  orderSelector,
  saveSubscriptionData,
  submitOrder,
  submitSIGP,
  updateSignupInformation
} from '../store/slices/getStartedSlice';
import { useAppDispatch, useAppSelector } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function useRenewPayment({
  isFreeCoupon = false,
  stripe,
  elements
}) {
  const dispatch = useAppDispatch();
  const userAuth = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const user = userAuth.user;

  const {
    form,
    country
  } = useAppSelector(orderSelector);

  const selectedCountry =
    (country || []).filter((i) => i.CountryCode === form?.country_id)?.[0] || {};

  const [arrayData, setArrayData] = useState({
    1000129: '1000000003',
    1000130: '1000000004',
    1000131: '1000000005',
    1000132: '1000000006',
    1000133: '1000000007',
    1000134: '1000000008'
  });
  const [message, setMessage] = useState(null);
  const [turnOver, setTurnOver] = useState('');
  const [userSignUpData, setUserSignUpData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    dispatch(
      fetchAccountDetailsAfterLogin(userAuth?.parentCompanies?.ParentCompanyID)
    ).then((res) => {
      setTurnOver(res.data?.ParentCompanyInfo[0]?.FK_CompanyTurnover);
    });
  }, []);

  const logout = async () => userAuth.logoutUserAfterPayment();

  const handlePaymentCompletion = async (data) => {
    if (form?.coupon?.CouponID) {
      dispatch(
        updateSignupInformation({
          SignupID: form?.signup?.SignupID,
          FK_CouponUsed: form?.coupon?.CouponID
        })
      );
      // updateCouponCode({CouponID:form?.coupon?.CouponID, QuantityUsed:parseInt(form?.coupon?.QuantityUsed)+1});
    }
    const json = await dispatch(submitOrder(data));
    if (json.status === 204) {
      // SIH Signup History Record
      const SubscriptionId = arrayData[turnOver];
      const saveSubscription = {
        Action: 'RENEWAL',
        ParentCompanyID: userAuth?.parentCompanies?.ParentCompanyID,
        SubscriptionID: SubscriptionId
      };
      await dispatch(saveSubscriptionData(saveSubscription));
      const sigpData = {
        SignupID: userSignUpData.SignupID,
        AuditUserID: userAuth.user.UserID,
        Trial: 0
      };
      await dispatch(submitSIGP(sigpData));
      const siggData = {
        SignupID: userSignUpData.SignupID
      };
      await dispatch(fetchSIGG(sigpData));
      userAuth.setUser({
        ...user,
        hasSignedUp: true,
        isApprovalBeingProcessed: true,
        isSupplier: true
      });
      logout().then((res) => {
        navigate('/CompleteSubscriptionRenewal');
      });
      //display success message or redirect user
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    await dispatch(fetchAccountDetails()).then((res) => {
      setUserSignUpData(res.data.signup);
    });
    if (!isFreeCoupon && (!stripe || !elements)) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setMessage('');

    if (!form?.termCondition) {
      setMessage(t('common:accept_term_condition').toString());
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }
    setMessage('');
    setIsProcessing(true);

    if (isFreeCoupon) {
      handlePaymentCompletion(form);
    } else {
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: form?.company_name,
              phone:
                form?.direct_landline_number ??
                form?.mobile_number ??
                form?.company_phone_number,
              address: {
                country: selectedCountry?.CountryCodeAN2 || 'AU',
                postal_code: form?.postcode,
                state: form?.state || 'Australian Capital Territory',
                city: form?.city,
                line1: form?.company_address_1,
                line2: form?.company_address_2 ?? '~'
              }
            }
          }
        },
        redirect: 'if_required'
      });
      if (response?.error) {
        /*dispatch(setIndex(3));
            navigate("/GetStarted/AccountPayment");*/
        // setMessage(response.error.message);
      } else if (response?.paymentIntent?.id) {
        let data = form;
        data = {
          ...data,
          transaction_id: response.paymentIntent.id
        };
        handlePaymentCompletion(data);
      }
    }
    setIsProcessing(false);
  };

  return {
    isProcessing,
    setIsProcessing,
    handlePaymentSubmit,
    message,
  };
}
