import React, { useRef, useState } from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import { scanProduct } from '../../../api/BarcodeScan';
import './ProductScan.css';

import { isValid } from 'gtin';
import CompromisedModal from './CompromisedModal';

const ProductScan = () => {

  const [isValidBarcode, setIsValidBarcode] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [apiRes, setApiRes] = useState({});

  const [gtinInput, setGtinInput, onChangeGtinInput] = useFormFieldOnChange('');
  const gtinInputRef = useRef(null);

  const [showCompromisedModal, setShowCompromisedModal] = useState(false);

  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    fetchProductInfo(gtinInput);
  };

  const fetchProductInfo = async (GTIN) => {
    if (isLoading || GTIN.trim().length === 0) return;

    if (!isValidGTIN(GTIN)) {
      setIsValidBarcode(false);
      return;
    }
    setIsValidBarcode(true);

    setApiRes({});
    setIsLoading(true);
    const apiRes = await scanProduct(GTIN);
    setIsLoading(false);
    if (apiRes === false) return;
    setApiRes(apiRes.data);
    setGtinInput('');
  };

  const getTableInfo = () => {
    if (apiRes.ProductInfo === null) return null;
    return (
      <div className="ProductScanResult">
        <div className="Row">
          <div className="Column">
            <div className="Header">
              Product
            </div>
            <div className="Content">
              <span>{!apiRes.ProductInfo.isNew ? 'Existing Record' : '*NEW*'}</span>
              <span>Source: {apiRes.ProductInfo.Source ?? 'null'}</span>
              <span>Origin: {apiRes.ProductInfo.Origin ?? 'null'}</span>
            </div>
          </div>
          <div className="Column">
            <div className="Header">
              Company
            </div>
            <div className="Content">
              {apiRes.CompanyInfo && (
                <>
                  <span>{!apiRes.CompanyInfo.isNew ? 'Existing Record' : '*NEW*'}</span>
                  <span>{apiRes.CompanyInfo.CompanyName}</span>

                  <br />
                  {apiRes.CompanyInfo.Country}
                </>
              )}

            </div>
          </div>
          <div className="Column">
            <div className="Header">
              Prefixes
            </div>
            <div className="Content">
              {apiRes.PrefixesInfo.JustAdded.length > 0 && (
                <span>*NEW*</span>
              )}
              {apiRes.PrefixesInfo.JustAdded.map(prefix => (
                <span>{prefix}</span>
              ))}
              {apiRes.PrefixesInfo.NewCompanyPrefixesAdded.length > 0 && (
                <>
                  <span>New Prefixes</span>
                </>
              )}
              {apiRes.PrefixesInfo.NewCompanyPrefixesAdded.map(prefix => (
                <span>{prefix}</span>
              ))}
              {apiRes.PrefixesInfo.Existing.length > 0 && (
                <>
                  <span>Existing Prefixes</span>
                </>
              )}
              {apiRes.PrefixesInfo.Existing.map(prefix => (
                <span>{prefix}</span>
              ))}
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Column">
            BRICK: {apiRes.BrickInfo.ID ? (apiRes.BrickInfo.ID + ' - ' + apiRes.BrickInfo.Description) : <i>NOT DEFINED</i>}
          </div>
          <div className="Column">
            Group: {apiRes.GroupVariantDetail.Group ?? <i>NOT DEFINED</i>}
          </div>
          <div className="Column">
            Variant: {apiRes.GroupVariantDetail.Variant ?? <i>NOT DEFINED</i>}
          </div>
          <div className="Column">
            Detail: {apiRes.GroupVariantDetail.Detail ?? <i>NOT DEFINED</i>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageSkeletonComponent>
        <div className="ProductScanContainer">
          <p>Product Scan</p>
          <div style={{ display: 'flex' }}>
            <form onSubmit={submitForm}>
              <input
                autoFocus
                ref={gtinInputRef}
                onChange={onChangeGtinInput}
                value={gtinInput}
                className="GTIN" type="text"
                placeholder="Enter the barcode/GTIN" />
              &nbsp;
              <input type="button" value="Reset"
                onClick={() => {
                  setGtinInput('');
                  gtinInputRef.current.focus();
                }} />
            </form>
          </div>
          {!isValidBarcode && (
            <React.Fragment>
              <br />
              <span style={{ color: 'red' }}>Please, enter a valid barcode</span>
            </React.Fragment>
          )}
          {apiRes.ProductInfo && (
            <>
              <br />
              <span>GTIN: {apiRes.ProductInfo.GTIN}</span>
              <span>Description: {apiRes.ProductInfo.Name ?? 'null'}</span>
              <button
                onClick={() => setShowCompromisedModal(true)}
                style={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}>Compromised</button>
            </>
          )}
          {apiRes.ErrorMessages?.length > 0 && apiRes.ErrorMessages.map((error, index) => (
            <React.Fragment key={index}>
              <br />
              <span style={{ color: 'red' }}>ERROR: {error}</span>
            </React.Fragment>
          ))}
          <hr />
          {isLoading && (
            <p>Loading product</p>
          )}
          {getTableInfo()}
        </div>
      </PageSkeletonComponent>
      <CompromisedModal
        GTIN={apiRes?.ProductInfo?.GTIN}
        showModal={showCompromisedModal}
        onClose={() => setShowCompromisedModal(false)} />
    </>
  );
};

export default ProductScan;
