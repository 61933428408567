import React from 'react';
import { Navigate, Route, useLocation } from "react-router-dom"
import { useAuthContext } from '../context/AuthContext';

export default function PrivateRoute({ element }) {

  const loggedIn = useAuthContext().isUserLoggedIn;
  const location = useLocation();

  const redirect = <Navigate
    to={"/"}
    state={{ referer: location }}
  />;

  return loggedIn ? element : redirect;
}