const getToken = () => {
  const token = localStorage.getItem("token");
  if (token == null) return null;
  return JSON.parse(token).access_token;
};
const getUser = () => JSON.parse(localStorage.getItem("user"));
const getUserSignupInfo = () => JSON.parse(localStorage.getItem("signupInfo"));
const getParentCompanies = () =>
  JSON.parse(localStorage.getItem("parentCompanies"));

const setToken = (token) => {
  if (token === null) return;
  localStorage.setItem("token", JSON.stringify(token));
};

const setUser = (user) => {
  if (user === null) return;
  localStorage.setItem("user", JSON.stringify(user));
};

const setUserSignupInfo = (data) => {
  localStorage.setItem("signupInfo", JSON.stringify(data));
};

const setParentCompanies = (data) => {
  localStorage.setItem("parentCompanies", JSON.stringify(data));
};

const logoutUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("signupInfo");
  localStorage.removeItem("parentCompanies");
};

// Function to retrieve Subscription from parentCompanies
const getSubscription = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.Subscription || [];
};

// Function to retrieve Companies from parentCompanies
const getCompanies = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.Companies || [];
};

// Function to retrieve Groups from parentCompanies
const getGroups = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.Groups || [];
};

// Function to retrieve Stores from parentCompanies
const getStores = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.Stores || [];
};

// Function to retrieve CompromisedPrefixes from parentCompanies
const getCompromisedPrefixes = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.CompromisedPrefixes || [];
};

// Function to retrieve Accreditations from parentCompanies
const getAccreditation = () => {
  const parentCompanies = getParentCompanies();
  return parentCompanies?.Accreditation || [];
};

const isLoggedIn = () => getToken() != null;

export {
  getUser,
  setUser,
  getToken,
  setToken,
  isLoggedIn,
  logoutUser,
  setUserSignupInfo,
  getUserSignupInfo,
  setParentCompanies,
  getParentCompanies,
  getCompanies,
  getSubscription,
  getCompromisedPrefixes,
  getGroups,
  getStores,
  getAccreditation,
};
