import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
  orderSelector,
  submitOrder,
  setIndex,
  addForm,
  updateSignupInformation, SihSignupHistory, submitSIGP, fetchSIGG, saveSubscriptionData, fetchAccountDetails, fetchAccountDetailsAfterLogin
} from "../../store/slices/getStartedSlice";
import GetStartedStripePaymentElement from "./GetStartedStripePaymentElement";
import { useAuthContext } from '../../context/AuthContext';
import { updateCouponCode } from "../../api/SignUp";
import { SignupInfoInformation } from "../../store/slices/authSlice";

const Payment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAuthContext();
  const user = userAuth.user;
  const userSignupInfo = userAuth.signupInfo;

  const {
    form,
    wizardIndex,
    isContactValidate,
    isShippingValidate,
    isPaymentValidate,
    country,
  } = useAppSelector(orderSelector);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const logout = async () => userAuth.logoutUserAfterPayment();

  const { t } = useTranslation(["common"]);

  const selectedCountry = ((country || []).filter(i => i.CountryCode === form?.country_id)?.[0] || {});


  React.useEffect(() => {
    if (wizardIndex < 2) {
      navigate("/");
    }
    // openPopup();
    // window.scrollTo({
    //   top: 0,
    //   behavior: "instant",
    // });
    // if (!form?.termCondition) {
    //   setFormField("termCondition", true)
    // }
  }, [wizardIndex]);


  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setMessage("");

    if (!form?.termCondition) {
      setMessage(t("common:accept_term_condition").toString());
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    setMessage("")
    setIsProcessing(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: form?.company_name,
            phone: form?.direct_landline_number ?? form?.mobile_number ?? form?.company_phone_number,
            address: {
              country: selectedCountry?.CountryCodeAN2 || "AU",
              postal_code: form?.postcode,
              state: form?.state || "Australian Capital Territory",
              city: form?.city,
              line1: form?.company_address_1,
              line2: form?.company_address_2 ?? "~"
            }
          }
        }
      },
      redirect: "if_required",
    });
    if (
      (response?.error)
    ) {
      /*dispatch(setIndex(3));
      navigate("/GetStarted/AccountPayment");*/
      // setMessage(response.error.message);
    } else if (response?.paymentIntent?.id) {
      let data = form;
      data = {
        ...data,
        transaction_id: response.paymentIntent.id,
      };
      if (form?.coupon?.CouponID) {
        dispatch(updateSignupInformation({ SignupID: form?.signup?.SignupID, FK_CouponUsed: form?.coupon?.CouponID }));
        // updateCouponCode({CouponID:form?.coupon?.CouponID, QuantityUsed:parseInt(form?.coupon?.QuantityUsed)+1});
      }
      const json = await dispatch(submitOrder(data));
      if (json.status === 204) {
        // SIH Signup History Record
        const historyRecord = {
          FK_SignupID: userSignupInfo.SignupID,
          FK_UserID: userSignupInfo.FK_UserID,
          SignupCheckTitle: "User has completed Sign-up Wizard Step: PAYMENT",
          SignupCheckText: `${userAuth.user.NameFirst} has selected the Get Started process`
        }
        await Promise.all([
          dispatch(SihSignupHistory(historyRecord))
        ])
        const sigpData = {
          SignupID: userSignupInfo.SignupID,
          AuditUserID: userAuth.user.UserID,
          Trial: 0
        }
        await dispatch(submitSIGP(sigpData));
        const siggData = {
          SignupID : userSignupInfo.SignupID
        }
        await dispatch(fetchSIGG(sigpData));
        userAuth.setUser({
          ...user,
          hasSignedUp: true,
          isApprovalBeingProcessed: true,
          isSupplier: true,
        });
        logout().then(res => {
          navigate('/DownloadOurApp');
        });
      }

      //display success message or redirect user
    }

    setIsProcessing(false);
  };

  const back = () => {
    if(form.selectedField !== ''){
      window.location.reload();
      dispatch(setIndex(3));
      navigate("/GetStarted/AccountPayment");

    }else {
      setFormField("termCondition", false)
      dispatch(setIndex(2));
      navigate("/GetStarted/AccountReview");
    }
  }


  return (
    <>
      {Object.keys(form).length === 0 ? (
        <div className={`w-full max-w-7xl px-3 mx-auto py-10 bg-white mt-5 rounded-xl`}>
          <div className="w-80 h-80 mx-auto">
            <img src="https://members.bringback.app/BBA-Logo-TBl.png" alt="logo" className="w-full h-full object-contain object-centers" />
          </div>
          <div
            className="p-4 text-sm text-center text-green-800 rounded-lg bg-green-300"
          >
            <span className="font-semibold">Success:</span>{" "}
            {t("common:order_success_meessage").toString()}

          </div>
          <div className="flex flex-col items-center gap-3 mt-10">
            <span>Thank you for signing up. Please download the app and test on your products. And then test on your competitor products</span>
            <a
              href="https://bringbackaustralia.app/download-app/"
              className="px-8 py-2 w-max bg-primary text-white rounded-md border"
            >
              Download our APP
            </a>
          </div>
        </div>
      ) : (
        <div className={`w-full max-w-7xl mx-auto py-10`}>
          {!!message &&
            <div className="p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-300">
              {message}
            </div>
          }
          {
            !open &&
            <>
              <div className="flex gap-5 bg-white">
                <p className="w-[220px] text-end text-sm">

                </p>
                <div className="px-5 py-3 rounded-xl mt-4 gap-5 grid lg:grid-cols-2 grid-cols-1">
                  <div className={`block`}>
                    <GetStartedStripePaymentElement />
                    <img src={'../stripe-badge-transparent.png'} alt={"stripeBadge"} />
                  </div>
                </div>
              </div>


              <div className="flex justify-center items-center mt-3 gap-4">
                <button
                  onClick={back}
                  className="px-8 py-2 bg-gray-500 uppercase text-white rounded-md border"
                >
                  {t("common:back_button_text").toString()}
                </button>
                <button
                  disabled={
                    isProcessing
                    // !isContactValidate ||
                    // !isShippingValidate ||
                    // !isPaymentValidate
                  }
                  onClick={handleSubmit}
                  className="px-8 py-2 bg-primary uppercase text-white rounded-md border"
                >
                  {isProcessing
                    ? t("common:processing").toString()
                    : t("common:submit_text").toString()}
                </button>
              </div>
            </>
          }
        </div>
      )}
    </>
  );
};

export default Payment;
