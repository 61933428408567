import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './ProductHome.css';

const ProductHome = () => {
  return (
    <PageSkeletonComponent>
     <h1>PRODUCTS - Home Page (Dashboard)</h1>
      <br />
      <hr />
      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      What can Employees do in relation to products?  What should Employees be able to do in relation to products?<br />
      Bring Back Staff should be able to login as another user and see what they are seeing.

      <h3>Supplier Subscribers</h3>
      What can Suppliers do in relation to products?  What should Suppliers be able to do in relation to products?
      
      <h3>Retailer Subscribers</h3>
      What can Retailers do in relation to products?  What should Retailers be able to do in relation to products?

      <h3>Service Subscribers</h3>
      What can Service Providers do in relation to products?  What should Service Providers be able to do in relation to products?

      <h2>Purpose of Page</h2>
      This page is the welcome page for the Product section, what we will show here or what we can do from here will be determined...
      <br /><br />A summary page showing products and status...
      <li>Number of products not shared</li>
      <li>Number of products missing images</li>
      <li>Number of products scanned by day, week, month</li>
      <br /><br />
      Where these items are shown, there should be a link to enable the user to click to go and view these products or analytics.

    </PageSkeletonComponent>
  );
};

export default ProductHome;
