import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { Auth } from '../../api/Auth';
import {getAuthRequest} from "../../api/API";
import {getToken} from "../../provider/AuthProvider";
import UAParser from 'ua-parser-js'; 
import moment from 'moment';

// Define the state interface for authentication
export interface authState {
  processing: boolean;
  succeeded: boolean;
  failed: boolean;
  pwDontMatch: boolean;
  error: string;
  authUser:any
}

// Initialize the state with default values
export const initialState: authState = {
  processing: false,
  succeeded: false,
  failed: false,
  pwDontMatch: false,
  error: "",
  authUser:{}
};

// Create a slice for auth with reducers
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Set error message
    setError: (state: authState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    // Set success state
    setSucceeded: (state: authState, { payload }: PayloadAction<any>) => {
      state.succeeded = payload;
    },
    // Set failure state
    setFailed: (state: authState, { payload }: PayloadAction<any>) => {
      state.failed = payload;
    },
     // Set processing state
    setProcessing: (state: authState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    // Set password mismatch state
    setPwDontMatch: (state: authState, { payload }: PayloadAction<any>) => {
      state.pwDontMatch = payload;
    },
    // Set authenticated user
    setAuthUser: (state: authState, { payload }: PayloadAction<any>) => {
      state.authUser = payload;
    },
    // Reset state to initial values
    resetState: (state: authState) => {
      return initialState;
    },
  },
});

// Thunk for handling forgot password functionality
export const forgotPassword = (emailInput) => async (dispatch: any) => {
  dispatch(setProcessing(true))
  const req = await Auth().ForgotPassword(emailInput);
  dispatch(setProcessing(false))
  if (req.status === 422){
    dispatch(setError(req.data.message));
    dispatch(setFailed(true));
  } else if (req == false || req.status !== 200) {
      dispatch(setFailed(true));
      return;
    }else {
    dispatch(setSucceeded(true));
  }
};

// Thunk for handling new account registration
export const newAccount = (nameInput, emailInput, passwordInput, Source, State) => async (dispatch: any) => {
  dispatch(setProcessing(true))
  const req = await Auth().PreRegister(nameInput, emailInput, passwordInput,Source, State);
  dispatch(setProcessing(false))
    if (req == false || req.status !== 200) {
      dispatch(setFailed(true));
      dispatch(setError(
        req?.data?.error ||
            "An error has occurred during the registration process."
    ));
      return;
    }
    dispatch(setSucceeded(true));
};

// Thunk for handling user sign-in
export const signIn = (email, password) => async (dispatch: any) => {
  if (!email || !password) {
    dispatch(setError(!email ? "Email is required" : "Password is required"));
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.match(emailRegex)) {
    dispatch(setError("Invalid email format"));
    return;
  }

  dispatch(setProcessing(true));

  const parser = new UAParser();
  const result = parser.getResult();

  const req = await Auth().login(email, password);

  dispatch(setProcessing(false));
  if (req === false || req.status !== 200) {
    // console.log("req?.data?.error",req?.data?.error)
    dispatch(setFailed(true));
    dispatch(
        setError(req?.data?.error)
    );
    return;
  }

  const browserInfo = {
    UserID: req?.data?.user?.UserID,
    AuditUserID:req?.data?.user?.UserID,
    DateWebLastOpen: moment().format('YYYY-MM-DD HH:mm:ss'),
    WebBrowser: result.browser.name,
    WebBrowserVersion: result.browser.version
  };

  await Auth().signInPrompt(browserInfo);

  return req;
};

// Thunk to get the current date from the server
export const currentDate = () => async (dispatch: any) => {
  try {
    const res = await getAuthRequest(getToken()).get('system/ServerDetails');
    if(res?.status == 200){
      return res?.data;
    }
  } catch (error) {
    return error;
  }
}

// Thunk to get signup information
export const SignupInfoInformation = (query) => async (dispatch: any) => {
  return await Auth().signupInfo(query);
}

// Thunk to call the sign-in prompt endpoint
export const signInPromptEndpoint = (query) => async (dispatch: any) => {
  await Auth().signInPrompt(query);
}


export const {
  setFailed,
  setError,
  resetState,
  setProcessing,
  setSucceeded,
  setPwDontMatch,
  setAuthUser
} = authSlice.actions;

export const auth = (state: RootState) => state.auth;

export default authSlice.reducer;
