import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./i18n/config";
import "./assets/css/fontawesome-5.css";
// import "./assets/css/lite-purple.css";
import "./assets/css/color.css";
import "./assets/css/metisMenu.min.css";
import { createRoot } from 'react-dom/client';
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import store from "./store/store";

// Define your primary and secondary colors
const theme = createTheme({
  palette: {
    primary: {
      main: "#032158", // Navy Blue
      contrastText: "#ffffff", // White
    },
    disabled: {
      main: "#C0C0C0", // Navy Blue
      contrastText: "#000000", // White
    },
    secondary: {
      main: "#D9534F", // Red
      contrastText: "#333333", // Grey
    },
    grey: {
      500: "#888888",
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
