import React, {useEffect, useState} from "react";
import moment from "moment";
import {useAuthContext} from "../../context/AuthContext";
import {currentDate} from "../../store/slices/authSlice";
import {useAppDispatch} from "../../store/store";

const Footer = ({ login,toggle }) => {
    const userSubscription = useAuthContext().subscription;
    const userAuth = useAuthContext();
    const dispatch = useAppDispatch();

    if((userSubscription?.Trial === '1' && login)) {
        return (
            <>
                {
                    typeof userSubscription === 'object' && Object.keys(userSubscription).length > 0 ?
                        <div
                            className="px-4 py-3 text-sm bg-red-100 dark:bg-gray-800 dark:text-red-400"
                            role="alert">
                            <p>
                                {`Trial expires on ${moment(userSubscription?.DateTo).format('DD-MMM-YYYY')} `}
                            </p>
                        </div> : null

                }
                <div className={`p-5 bg-primary transition-all duration-200 ease-in float-right w-full`}>
                    <p className="text-white">Powered by ProductHub</p>
                </div>
            </>
        );
    }

    return (
        <div className="p-5 bg-primary transition-all duration-200 ease-in float-right w-full">
            <p className="text-white">Powered by ProductHub</p>
        </div>
    );

};

export default Footer;
