import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const scanProduct = async (GTIN) => {
  try {
    return await getAuthRequest(getToken())
      .get("barcodescan/scanProduct/" + GTIN)
      .then((res) => {
        console.log("res on the main page is ", res);
        return res;
      });
  } catch (error) {
    return false;
  }
};

const searchParentCompanyByName = async (CompanyName) => {
  try {
    return await getAuthRequest(getToken()).get(
      "barcodescan/searchParentCompanyByName/" + CompanyName
    );
  } catch (error) {
    return false;
  }
};

const createParentCompany = async (
  CompanyName,
  CompanyPhone,
  CompanyEmail,
  CompanyWebsite,
  CompanyCountry,
  AddressStreet1,
  AddressStreet2,
  AddressCity,
  AddressState,
  AddressPostCode
) => {
  try {
    return await getAuthRequest(getToken()).post(
      "barcodescan/createParentCompany",
      {
        CompanyName,
        CompanyPhone,
        CompanyEmail,
        CompanyWebsite,
        AddressStreet1,
        AddressStreet2,
        AddressCity,
        AddressState,
        AddressPostCode,
        CompanyCountry,
      }
    );
  } catch (error) {
    return false;
  }
};

const assignParentCompanyToPrefix = async (ParentCompanyID, GTIN) => {
  try {
    return await getAuthRequest(getToken()).post(
      "barcodescan/assignParentCompanyToPrefix",
      { ParentCompanyID, GTIN }
    );
  } catch (error) {
    return false;
  }
};

const assignParentCompanyToCompany = async (ParentCompanyID, CompanyID) => {
  try {
    return await getAuthRequest(getToken()).post(
      "barcodescan/assignParentCompanyToCompany",
      { ParentCompanyID, CompanyID }
    );
  } catch (error) {
    return false;
  }
};

const getCompaniesAssignedToPrefix = async (PrefixID) => {
  try {
    return await getAuthRequest(getToken()).get(
      "barcodescan/getCompaniesAssignedToPrefix/" + PrefixID
    );
  } catch (error) {
    return false;
  }
};

export {
  scanProduct,
  searchParentCompanyByName,
  createParentCompany,
  assignParentCompanyToPrefix,
  assignParentCompanyToCompany,
  getCompaniesAssignedToPrefix,
};
