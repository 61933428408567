import React, { useContext, useEffect, useState } from 'react';
import SectionContainer from '../../components/SectionContainer';
import TreeItemComp from './TreeItemComp';
import FunctionalGroupVarContainer from './FuncGroupVarContainer';
import { getGroups } from '../../../api/GroupVariantDetail';
import { SelectedBrickContext } from './SelectedBrickContext';

const FunctionalGroupContainer = () => {

  const { selectedBrick } = useContext(SelectedBrickContext);

  const [groups, setGroups] = useState([]);

  const fetchAssignedWords = async () => {
    const reqGroups = await getGroups(selectedBrick);
    setGroups(reqGroups !== false ? reqGroups.data : []);
  };

  useEffect(() => {
    selectedBrick?.trim().length && fetchAssignedWords();
  }, [selectedBrick]);

  const rowEven = {
    backgroundColor: '#d0dcd1'
  };

  const rowOdd = {
    backgroundColor: '#f2f2f2'
  };

  return (
    <SectionContainer header="Functional Group">
      {groups.length === 0 && (
        <TreeItemComp
          style={{ backgroundColor: '#d0dcd1', fontWeight: 'bold' }} itemName={'No records'} />
      )}
      {groups.map((item, key) => (
        <TreeItemComp
          key={key}
          style={(key % 2 === 0 ? rowEven : rowOdd)}
          itemName={item.Group}>

          <FunctionalGroupVarContainer key={key} funcGroup={item} />

        </TreeItemComp>
      ))}
    </SectionContainer>
  );
};

export default FunctionalGroupContainer;