import React from 'react';

export const BRICKFiltersContext = React.createContext({
  BRICKFilters: {
    SEGMENT: '',
    FAMILY: '',
    CLASS: '',
    BRICK: ''
  },
  setFilters: (brickFilter) => { },
  resetFilters: () => { },
  areFiltersEmpty: () => { },
  showLoading: () => { },
  hideLoading: () => { }
});