import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './AccountTransactions.css';

const AccountTransactions = () => {
  return (
    <PageSkeletonComponent>

     <h1>ACCOUNT - Transactions Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      The user can see invoices and other transactions associated with their account.<br /><br />
      The transactions shown would include:<br />
      <li>Current Open Transactions</li>
      <li>Bring Back Invoices</li>
      <li>Loyalty Program Invoices</li>
      <li>Payments</li><br />
      The user should be able to filter by Invoices, Payments, etc... to assist them with administering their account.<br /><br />

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default AccountTransactions;