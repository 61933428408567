import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1Home.css';

const GS1Home = () => {
  return (
    <PageSkeletonComponent>

      <h1>INSTRUCTIONS - GS1 - Home Page</h1>

      <hr />

      <a href="http://www.gs1.org" target="_blank">
        <img border="0" alt="GS1 - The Global Language of Business" src="/GS1-logo.jpg" height="100" />
      </a>

     <h2>Purpose of Page</h2>
      About GS1 Organisation and contact details, website, email... etc...

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default GS1Home;
