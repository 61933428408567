import React from 'react';
import './SectionContainer.css';

const SectionContainer = (props) => {

  return (
    <div className="SectionContainer">
      <div className="SectionContainerScroll">
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <h3>{props.header}</h3>
          <div style={{ flexGrow: 1, position: 'relative', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContainer;