import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../../store/store";
import Button from "@mui/material/Button";
import { orderSelector, setIndex } from "../../store/slices/getStartedSlice";

const GetStartedTab = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { wizardIndex } = useAppSelector(orderSelector);

    const routeChange = (index) => {
        //dispatch(setIndex(index));
        switch (index) {
            case 0:
                navigate("/");
                break;
            case 1:
                navigate("/company");
                break;
            case 2:
                navigate("/payment");
                break;
        }
    };

    return (
        <>
            <ul className="text-sm  font-medium text-center text-gray-500 sm:flex">
                <li className="w-full px-1 py-1 h-[50px]">
                    <button
                        disabled={wizardIndex !== 0}
                        className={
                            wizardIndex === 0
                                ? "bg-primary ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-gray-400 hover:bg-gray-500 ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Details
                    </button>
                </li>
                <li className="w-full px-1 py-1 h-[50px]">
                    <button
                        disabled={wizardIndex !== 1}
                        className={
                            wizardIndex === 1
                                ? "bg-primary ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-gray-400 hover:bg-gray-500 ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Prefix
                    </button>
                </li>
                <li className="w-full px-1 py-1 h-[50px]">
                    <button
                        disabled={wizardIndex !== 2}
                        className={
                            wizardIndex === 2
                                ? "bg-primary ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-gray-400 hover:bg-gray-500 ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Review
                    </button>
                </li>
                <li className="w-full px-1 py-1 h-[50px]">
                    <button
                        disabled={wizardIndex !== 3}
                        className={
                            wizardIndex === 3
                                ? "bg-primary ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-gray-400 hover:bg-gray-500 ripple uppercase w-full h-full text-white rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Payment
                    </button>
                </li>
            </ul>
        </>
    );
};

export default GetStartedTab;
