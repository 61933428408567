import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import AuthPrompt from "../../components/AuthPrompt";

const EditParentCompany = () => {
    const people = [
        { name: "Wade Cooper" },
        { name: "Arlene Mccoy" },
        { name: "Devon Webb" },
        { name: "Tom Cook" },
        { name: "Tanya Fox" },
        { name: "Hellen Schmidt" },
    ];

    const [selected, setSelected] = useState(people[0]);
    return (
        <div className="p-5">
            <h1 className="text-black font-semibold text-lg">Edit Parent Company Info:</h1>
            <div className="w-full mx-auto p-5 h-[calc(100vh-100px)] overflow-y-auto rounded-xl shadow-md bg-white mt-2">
                {/* <div className="grid grid-cols-12 space-y-5">
                    <div className="space-y-4 lg:col-span-8 col-span-12">
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Company Name:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                AH Test First Name
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Trading Name:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                AH Test First Name
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                ABN:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                AH Test
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Number of Employees:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">AH</span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Account Type:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                Supplier
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Annual Turnover
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                $1 to $456
                            </span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Industry
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">Health</span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Industry - Other
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">-</span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Company Phone:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                0987654321
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Website:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                http://www.avc.xyz
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Address 1:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                abc street
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Address 2:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                abc street
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                City:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">abc</span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                State:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                Northern Territory
                            </span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Postcode:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                234567
                            </span>
                            <input
                                type="text"
                                className="rounded-md w-full border py-1.5 px-2"
                            />
                        </div>
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Country:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                Northern
                            </span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                    <div className="lg:col-span-8 col-span-12 h-px bg-black" />
                    <div className="lg:col-span-8 col-span-12">
                        <div className="text-sm flex items-center gap-3">
                            <p className="text-end capitalize w-full max-w-[200px]">
                                Country of ownership:
                            </p>
                            <span className="font-bold w-full max-w-[200px] capitalize">
                                Australia
                            </span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected.name}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={true}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-900"
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected
                                                                    ? "font-medium"
                                                                    : "font-normal"
                                                                    }`}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                    <div className="lg:col-span-8 col-span-12 flex justify-center items-center gap-4">
                        <button className="bg-gray-400 rounded-lg py-2 px-5 text-white">Cancel</button>
                        <button className="bg-primary rounded-lg  py-2 px-5 text-white">Save</button>
                    </div>
                </div> */}
            </div>
            <AuthPrompt />
        </div>
    );
};

export default EditParentCompany;
