import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './ProductImages.css';

const ProductImages = () => {
  return (
    <PageSkeletonComponent>
     <h1>PRODUCTS - Images</h1>
      <br />
      <hr />

      <h2>Purpose of Page</h2>
      This page is the page for Product Images.<br /><br />
      From here the user will be able to view the status of images for products.
      <br /><br />A summary page showing products and status...
      <li><b>Summary of Image Status</b></li>
      <li>Products with images ordered list</li>
      <li>Products without images ordered list</li>

      The user will also be presented with instructions on how to update images, this will be through the GS1 system using the ImageURL field... but we need to provide instructions for the user.
      
      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      What can Employees do in relation to products?  What should Employees be able to do in relation to products?

      <h3>Supplier Subscribers</h3>
      What can Suppliers do in relation to products?  What should Suppliers be able to do in relation to products?
      
      <h3>Retailer Subscribers</h3>
      What can Retailers do in relation to products?  What should Retailers be able to do in relation to products?

      <h3>Service Subscribers</h3>
      What can Service Providers do in relation to products?  What should Service Providers be able to do in relation to products?

    </PageSkeletonComponent>
  );
};

export default ProductImages;