import React from "react";

const ActionMenuA1Home = () => {

    return (
        <div className='flex justify-center items-center h-screen'>
            <h1 className='text-3xl font-bold'>{`Page A`}</h1>
        </div>
    );
};

export default ActionMenuA1Home;
