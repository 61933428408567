import React from "react";
import { useEffect } from "react";
import DashboardContainer from "../../dashboard-components/DashboardContainer";
import RowItem from "./RowItem";
import { useAppSelector, useAppDispatch } from "../../store/store";

import {
    signupApproval,
    fetchAccsToApprove,
    fetchAccsApproved,
    fetchAccsMoreInfo
} from "../../store/slices/signupApprovalSlice";

const SignupApproval = () => {
    const dispatch = useAppDispatch();
    const { accsToApprove, accsApproved, accsMoreInfo, isAccsToApprove, isAccsApproved, isAccsMoreInfo } = useAppSelector(signupApproval);

    useEffect(() => {
        dispatch(fetchAccsToApprove());
        dispatch(fetchAccsApproved());
        dispatch(fetchAccsMoreInfo());
    }, [dispatch]);

    const refreshAccsToApprove = () => {
        dispatch(fetchAccsToApprove());
    };

    const refreshAccsApproved = () => {
        dispatch(fetchAccsApproved());
    };

    const refreshAccsMoreInfo = () => {
        dispatch(fetchAccsMoreInfo());
    };

    useEffect(() => {
        // Trigger refresh callbacks when the corresponding state is updated
        if (isAccsToApprove) {
            refreshAccsToApprove();
        }
    }, [isAccsToApprove]);

    useEffect(() => {
        // Trigger refresh callbacks when the corresponding state is updated
        if (isAccsMoreInfo) {
            refreshAccsMoreInfo();
        }
    }, [isAccsMoreInfo]);

    useEffect(() => {
        // Trigger refresh callbacks when the corresponding state is updated
        if (isAccsApproved) {
            refreshAccsApproved();
        }
    }, [isAccsApproved]);

    return (
        <DashboardContainer title={"Signup Accounts Approval"}>
            <div className="flex flex-grow h-0 flex-row">
                <div className="flex-col flex flex-grow basis-0 p-3 rounded-xl shadow-xl overflow-y-auto mr-2 bg-white">
                    <div className="bg-white p-2 border-b border-b-black">
                        <span className="font-bold">New</span>
                    </div>
                    <div className="flex-col flex flex-grow basis-0">
                        {isAccsToApprove ? (
                            <div className="h-full flex justify-center items-center">
                                <span className="font-bold text-black">
                                    Processing...
                                </span>
                            </div>
                        ) : (
                            <div className="h-full overflow-y-auto">
                                <div className="flex flex-col flex-grow h-full overflow-y-auto">
                                    {(accsToApprove || []).map?.((item, index) => (
                                        <RowItem
                                            refreshCallback={() => {
                                                refreshAccsToApprove();
                                                refreshAccsApproved();
                                            }}
                                            index={index}
                                            key={index}
                                            account={item}
                                        />
                                    ))}
                                    {accsToApprove?.length === 0 && (
                                        <div className="h-full flex justify-center items-center">
                                            <span className="font-bold text-black">
                                                No records
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col basis-0 overflow-y-auto flex-grow border-0">
                    <div className="flex flex-col basis-0 overflow-y-auto flex-grow  p-3 rounded-xl shadow-lg bg-white mb-2">
                        <div className="bg-white p-2 border-b border-b-black">
                            <span className="font-bold">More Info</span>
                        </div>
                        <div className="flex flex-col flex-grow basis-0 overflow-y-auto border-0">
                            {isAccsMoreInfo ? (
                                <div className="h-full flex justify-center items-center">
                                    <span className="font-bold text-black">
                                        Processing...
                                    </span>
                                </div>
                            ) : (
                                <div className="h-full overflow-y-auto">
                                    <div className="flex flex-col overflow-y-auto h-full flex-grow">
                                        {(accsMoreInfo || []).map?.((item, index) => (
                                            <RowItem
                                                refreshCallback={
                                                    refreshAccsMoreInfo
                                                }
                                                key={index}
                                                index={index}
                                                account={item}
                                            />
                                        ))}
                                        {accsMoreInfo?.length === 0 && (
                                            <div className="h-full flex justify-center items-center">
                                                <span className="font-bold text-black">
                                                    No records
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col basis-0 overflow-y-auto flex-grow  p-3 rounded-xl shadow-lg bg-white">
                        <div className="bg-white p-2 border-b border-b-black">
                            <span className="font-bold">
                                Recent Applications
                            </span>
                        </div>
                        <div className="flex flex-col flex-grow basis-0 overflow-y-auto border-0">
                            {isAccsApproved ? (
                                <div className="h-full flex justify-center items-center">
                                    <span className="font-bold text-black">
                                        Processing...
                                    </span>
                                </div>
                            ) : (
                                <div className="h-full overflow-y-auto">
                                    <div className="flex flex-col overflow-y-auto h-full flex-grow">
                                        {(accsApproved || []).map?.((item, index) => (
                                            <RowItem
                                                refreshCallback={
                                                    refreshAccsApproved
                                                }
                                                key={index}
                                                account={item}
                                                index={index}
                                            />
                                        ))}
                                        {accsApproved?.length === 0 && (
                                            <div className="h-full flex justify-center items-center">
                                                <span className="font-bold text-black">
                                                    No records
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContainer>
    );
};

export default SignupApproval;
