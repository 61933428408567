import { Dialog, Listbox, Tab, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationDialog from "../../components/ApplicationDialog";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
    signupApproval,
    fethchParentCompanyDetailsByID,
    fetchCountryOrigin,
    fetchHistoryDataByID,
    fetchChecksDropdown,
    fetchsaveHistoryData,
    fetchSaveSubmitData,
    saveSubscriptionData,
    fetchNumberOfEmployees,
    fetchAnnualTurnover,
    fetchIndustries,
    sendEmailToUser,
    setErrorMsg,
    setRecordType,
} from "../../store/slices/signupApprovalSlice";
import AssignParentCompanyDialog from "../../components/Dialogs/AssignParentCompanyDialog";
import { useAuthContext } from "../../context/AuthContext";
import Loader from "../../components/loader/Loader";
import Alert from '@mui/material/Alert';
import {fetchAccountDetails} from "../../store/slices/getStartedSlice";

const Dropdown = ({ selected, setSelected, options, disabled }) => (
    <>
        {
            options.length > 0 ?
                <Listbox value={selected} onChange={setSelected} disabled={disabled}>
                    <div className="relative w-full">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                            <span className="block truncate font-bold">{selected?.SelectionName || selected?.CountryName || 'Select an option'}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </span>

                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm custom_scroll">
                                {options.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                            }`
                                        }
                                        value={option}
                                    >
                                        {({ selected }) => (
                                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                {option?.SelectionName ?? option?.CountryName}
                                            </span>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox> :
                <Loader />
        }
    </>

);

const Textarea = ({ value, onChange, disabled }) => (
    <textarea
        name=""
        id=""
        disabled={disabled}
        required
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        className="rounded-md w-full border py-1.5 px-2"
        cols="30"
        rows="5"
    ></textarea>
);

const RadioButton = ({ label, value }) => (
    <label className="flex py-3 items-center gap-2">
        <input type="radio" className="w-4 h-4" name="option" value={value} />
        <span>{label}</span>
    </label>
);

const SaveCancelButton = ({ type, handleSave, disabled, setCancelModal, selectedTabIndex, cancelModal }) => {
    return (
        <div className="flex justify-center mt-5 items-center gap-4">
            <button
                onClick={() => setCancelModal(true)}
                className="bg-gray-400 text-white px-5 py-2 rounded-lg"
            >
                Cancel
            </button>
            <button
                disabled={disabled}
                onClick={() => handleSave(type)}
                className={`${disabled || cancelModal ? "opacity-70 bg-primary" : "bg-primary"
                    } text-white px-5 py-2 rounded-lg`}
            >
                {cancelModal ? <div className="flex"><Loader /><span className="ml-2">Saving</span></div> : selectedTabIndex === 4 ? 'Proceed' : 'Save'}
            </button>
        </div>
    );
}


const ViewApplication = () => {
    const { SignupID } = useParams();
    const userAuth = new useAuthContext();
    const navigate = useNavigate();
    const user = userAuth.user;
    const { singleDetails, CountryOrigin, history, options, numberOfEmployees, annualTurnover, industries, state, parentCompanyId } = useAppSelector(signupApproval);
    const dispatch = useAppDispatch();

    const [Checks, setChecks] = useState({
        type: 'Checks',
        FK_SignupID: SignupID,
        FK_UserID: singleDetails?.ApplicantUserID,
        AuditUserID: parseInt(user?.UserID),
        Check: null,
        ClearMoreInfo: false,
        FK_SignupCheck: null,
        ResearchLink: "",
        SignupCheckTitle: "",
        SignupCheckText: ""
    })
    const [RequestInfo, setRequestInfo] = useState({
        type: 'RequestInfo',
        FK_SignupID: SignupID,
        FK_UserID: parseInt(singleDetails?.ApplicantUserID),
        AuditUserID: parseInt(user?.UserID),
        SignupCheckTitle: "",
        SignupCheckText: "",
        Text: null,
        email: singleDetails?.Email
    })
    const [Ownership, setOwnership] = useState({
        type: 'Ownership',
        FK_SignupID: SignupID,
        FK_UserID: parseInt(singleDetails?.ApplicantUserID),
        AuditUserID: parseInt(user?.UserID),
        CountryOfOwnership: null,
        ApplicationSuggestedCountryOfOrigin: null,
        SignupCheckTitle: "",
        SignupCheckText: ""
    })
    const [Submit, setSubmit] = useState({
        type: 'Submit',
        SignupID: SignupID,
        FK_SignupID: SignupID,
        AuditUserID: parseInt(user?.UserID),
        ApplicationSubmittedRecommendation: null,
        SignupCheckTitle: "",
        SignupCheckText: ""
    })
    const [Decision, setDecision] = useState({
        type: 'Decision',
        FK_SignupID: SignupID,
        SignupID: SignupID,
        FK_UserID: parseInt(singleDetails?.ApplicantUserID),
        AuditUserID: parseInt(user?.UserID),
        ApplicationOutcome: singleDetails?.ApplicationOutcome ?? null,
        SignupCheckTitle: singleDetails?.ApplicationOutcome === "A" ? 'Approved' : 'Rejected',
        SignupCheckText: ""
    })
    const [ParentCompanyDialog, setParentCompanyDialog] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [validationModal, setValidationModal] = useState(true);
    const [applicationDialog, setApplicationDialog] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isRecordUpdated, setIsRecordUpdated] = useState({
        type: 'success',
        message: ""
    });
    const [parentCompanyIdState, setParentCompanyIdState] = useState(0);

    useEffect(() => {
        if (cancelModal && !validationModal) {
            navigate("/SignUpApprovalTab/Starting");
        }
    }, [cancelModal]);

    useEffect(() => {
        setParentCompanyIdState(parentCompanyId)
    }, [parentCompanyId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
               await Promise.all([
                    dispatch(fethchParentCompanyDetailsByID(SignupID)),
                    dispatch(fetchHistoryDataByID(parseInt(SignupID))),
                    dispatch(fetchCountryOrigin()),
                    dispatch(fetchChecksDropdown({ SystemCode: 'SIGNUP', SelectionCode: 'CHECK' })),
                    dispatch(fetchNumberOfEmployees()),
                    dispatch(fetchAnnualTurnover()),
                    dispatch(fetchIndustries())
                ]);
            } catch (error) {
                // Handle any errors here
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch, SignupID]);

    useEffect(() => {
        setOwnership((previous) => ({
            ...previous,
            CountryOfOwnership: CountryOrigin.find(o => o.CountryCodeN3 === singleDetails?.ApplicationSuggestedCountryOfOrigin) || {
                "CountryCodeN3": "000",
                "FK_ContinentCodeAN2": "",
                "CountryCodeAN2": "--",
                "CountryCodeAN3": "---",
                "CountryName": "-- Select from List --",
                "CountryNameDisplay": "-- Select from List --"
            },
            ApplicationSuggestedCountryOfOrigin: singleDetails?.ApplicationSuggestedCountryOfOrigin
        }));

        setSubmit((previous) => ({
            ...previous,
            ApplicationSubmittedRecommendation: singleDetails?.ApplicationSubmittedRecommendation || null,
            SignupCheckTitle: singleDetails?.ApplicationSubmittedRecommendation === "R" ? "Submitted for Rejection" : "Submitted for Approval"
        }));
    }, [CountryOrigin, singleDetails]);


    useEffect(() => {
        if (!applicationDialog) {
            resetStatesOf('Decision')
        }
    }, [applicationDialog])

    const convertUnixTimestampToDateString = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        return date.toLocaleDateString(undefined, options);
    };


    const tabPanelData = [
        {
            label: "Details",
            fields: [
                // Contact Details
                { header: "Contact Details", label: "First Name:", value: singleDetails?.NameFirst },
                { label: "Last Name:", value: singleDetails?.NameSurname },
                { label: "Preferred Name:", value: singleDetails?.NamePreferred },
                { label: "Position:", value: singleDetails?.Position },
                { label: "Direct Landline Number:", value: singleDetails?.PhoneDirect },
                { label: "Mobile Number:", value: singleDetails?.PhoneMobile },
                { label: "Email:", value: singleDetails?.Email },
                // Company Details
                { header: "Company Details", label: "Company Name:", value: singleDetails?.ApplicationCompanyName },
                { label: "Trading Name:", value: singleDetails?.ApplicationTradingName },
                { label: "ABN:", value: singleDetails?.ApplicationCompanyABN },
                // { label: "Number of Employees:", value: numberOfEmployees && numberOfEmployees.find((o) => o.SystemSelectID === singleDetails?.EmployeeCount).SelectionName },
                {
                    label: "Number of Employees:", value: singleDetails?.EmployeeCount && numberOfEmployees
                        ? numberOfEmployees.find((o) => o.SystemSelectID === singleDetails?.EmployeeCount)?.SelectionName || ""
                        : ""
                },
                { label: "Account Type:", value: "Supplier" },
                {
                    label: "Annual Turnover:", value: singleDetails?.CompanyTurnover && annualTurnover
                        ? annualTurnover.find((o) => o.SystemSelectID === singleDetails?.CompanyTurnover)?.SelectionName || ""
                        : ""
                },
                {
                    label: "Industry:", value: singleDetails?.FK_MainIndustry && industries
                        ? industries.find((o) => o.SystemSelectID === singleDetails?.FK_MainIndustry)?.SelectionName || ""
                        : ""
                },
                { label: "Phone:", value: singleDetails?.ApplicationPhoneCompany },
                { label: "Website:", value: singleDetails?.ApplicationWebsite },
                // Company Address Details
                { header: "Company Address Details", label: "Address 1:", value: singleDetails?.ApplicationAddress1 },
                { label: "Address 2:", value: singleDetails?.ApplicationAddress2 },
                { label: "City:", value: singleDetails?.ApplicationCity },
                {
                    label: "State:",
                    value: Array.isArray(state) && state.length > 0 && singleDetails?.ApplicationState
                        ? (state.find((o) => o.StateCode === singleDetails?.ApplicationState) || {}).StateName || ""
                        : singleDetails?.ApplicationState || ""

                },
                { label: "PostCode:", value: singleDetails?.ApplicationPostcode },
                {
                    label: "Country:",
                    value: singleDetails?.ApplicationCountryCodeN3 && CountryOrigin?.length > 5
                        ? ((CountryOrigin.find((o) => o.CountryCodeN3 === singleDetails?.ApplicationCountryCodeN3) || {}).CountryNameDisplay || "")
                        : ""
                }


            ],
        },
        {
            label: "Prefix",
            fields: [
                { label: "GS1 Entity GLN:", value: singleDetails?.EntityGLN },
                { label: "GS1 Company Name:", value: singleDetails?.CompanyName },
                { label: "Claimed Prefix:", value: singleDetails?.Prefix },
                { label: "Claimed GTIN (Barcode):", value: singleDetails?.ClaimedGTIN },
            ],
        },
        {
            label: "Payment",
            fields: [
                { label: "Coupon ID:", value: singleDetails?.CouponID },
                { label: "Coupon Code:", value: singleDetails?.CouponCode },
                { label: "Coupon Name:", value: singleDetails?.CouponName },
                { label: "Stripe Transaction Id:", value: singleDetails?.StripePaymentID },
                {
                    label: "Stripe Transaction Date:", value: singleDetails?.stripeTransactionDate && convertUnixTimestampToDateString(singleDetails?.stripeTransactionDate)
                },
                {
                    label: "Stripe Transaction Amount:",
                    value: `${singleDetails?.stripeTransactionAmount ? `$${(singleDetails?.stripeTransactionAmount / 100.00).toFixed(2)} (${singleDetails?.stripeCurrency.toUpperCase()}) Inc GST` : ''}`
                },
                { label: "Stripe URL:", value: `${singleDetails?.StripePaymentID ? `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}${singleDetails?.StripePaymentID}` : ''}` },
                { label: "User Email:", value: singleDetails?.stripeEmail || "" },
                { label: "Full Name:", value: singleDetails?.stripeName || "" },
                {
                    label: "Country or region:",
                    value: CountryOrigin.length > 0
                        ? CountryOrigin
                            .filter((o) => o.CountryCodeAN2 === singleDetails?.stripeCountry)
                            .map((filteredCountry) => filteredCountry.CountryName)
                        : ""
                },
                { label: "Address:", value: singleDetails?.stripeAddress1 ? `${singleDetails.stripeAddress1} ${singleDetails.stripeAddress2}` : " " || "" },
            ],
        },
    ];

    const tabPanelAction = [
        {
            label: "Checks",
        },
        {
            label: "Request Info",
        },
        {
            label: "Ownership",
        },
        {
            label: "Submit",
        },
        {
            label: "Decision",
        }
    ];

    const resetStatesOf = (data) => {
        const resetState = (state, updates) => {
            state((prev) => ({
                ...prev,
                ...updates
            }));
        };

        switch (data) {
            case 'Check':
                resetState(setChecks, {
                    Check: null,
                    FK_SignupCheck: "",
                    ResearchLink: "",
                    SignupCheckTitle: "",
                    SignupCheckText: ""
                });
                break;
            case 'RequestInfo':
                resetState(setRequestInfo, {
                    SignupCheckTitle: "",
                    SignupCheckText: "",
                    Text: "",
                });
                break;
            case 'Ownership':
                resetState(setOwnership, {
                    ApplicationSuggestedCountryOfOrigin: singleDetails?.ApplicationSuggestedCountryOfOrigin,
                    SignupCheckTitle: "",
                    SignupCheckText: ""
                });
                break;
            case 'Submit':
                resetState(setSubmit, {
                    ApplicationSubmittedRecommendation: singleDetails?.ApplicationSubmittedRecommendation || null,
                    SignupCheckTitle: "",
                    SignupCheckText: ""
                });
                break;
            case 'Decision':
                resetState(setDecision, {
                    ApplicationOutcome: singleDetails?.ApplicationOutcome || null,
                    SignupCheckTitle: "",
                    SignupCheckText: ""
                });
                break;
            default:
                break;
        }
    }

    const handleSave = (data) => {

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const day = ('0' + currentDate.getDate()).slice(-2);
        const hours = ('0' + currentDate.getHours()).slice(-2);
        const minutes = ('0' + currentDate.getMinutes()).slice(-2);
        const seconds = ('0' + currentDate.getSeconds()).slice(-2);

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


        setIsRecordUpdated({
            type: 'success',
            message: 'The update has been successfully recoded!'
        });

        if (data === 'Check') {
            if (Checks?.ClearMoreInfo) {
                const SaveSubmitDataPaylaod = {
                    SignupID: RequestInfo.FK_SignupID,
                    MoreInfoCount: 0,
                    DateApplicationMoreInfo: null,
                    AuditUserID: RequestInfo.AuditUserID
                }
                dispatch(fetchSaveSubmitData(SaveSubmitDataPaylaod))
            }

            const payload = {
                FK_SignupCheck: Checks.FK_SignupCheck,
                FK_SignupID: parseInt(Checks.FK_SignupID),
                FK_UserID: Checks.AuditUserID,
                ResearchLink: Checks.ResearchLink,
                SignupCheckText: Checks.SignupCheckText,
                SignupCheckTitle: Checks.SignupCheckTitle
            }
            dispatch(fetchsaveHistoryData(payload));
        }

        if (data === 'RequestInfo') {

            if (singleDetails?.Email) {
                const sendEmail = {
                    To: singleDetails?.Email,
                    Subject: RequestInfo?.Text,
                    EmailBody: RequestInfo?.SignupCheckText
                }

                dispatch(sendEmailToUser(sendEmail));
            }

            const SaveSubmitDataPaylaod = {
                SignupID: RequestInfo.FK_SignupID,
                MoreInfoCount: Number(singleDetails?.MoreInfoCount) + 1,
                DateApplicationMoreInfo: formattedDate,
                AuditUserID: RequestInfo.AuditUserID
            }

            dispatch(fetchSaveSubmitData(SaveSubmitDataPaylaod))

            const saveHistoryData = {
                FK_SignupID: RequestInfo?.FK_SignupID,
                FK_UserID: RequestInfo?.AuditUserID,
                SignupCheckTitle: RequestInfo?.SignupCheckTitle,
                SignupCheckText: RequestInfo?.SignupCheckText,
            }
            dispatch(fetchsaveHistoryData(saveHistoryData))
        };

        if (data === 'Ownership') {
            const ApplicationSuggestedCountry = {
                FK_ApplicationSuggestedCountryOfOrigin: Ownership?.ApplicationSuggestedCountryOfOrigin || singleDetails?.ApplicationSuggestedCountryOfOrigin,
                AuditUserID: parseInt(Ownership.AuditUserID),
                SignupID: parseInt(Ownership?.FK_SignupID)
            }
            const saveHistoryData = {
                FK_SignupID: Ownership?.FK_SignupID,
                FK_UserID: Ownership?.AuditUserID,
                SignupCheckTitle: Ownership?.SignupCheckTitle || `Ownership: ${CountryOrigin
                    .find((o) => o.CountryCodeN3 === singleDetails?.ApplicationSuggestedCountryOfOrigin).CountryName}`,
                SignupCheckText: Ownership?.SignupCheckText,
            }
            dispatch(fetchSaveSubmitData(ApplicationSuggestedCountry))
            dispatch(fetchsaveHistoryData(saveHistoryData));
        }
        if (data === 'Submit') {
            const saveSubmitDataPayload = {
                SignupID: parseInt(Submit.FK_SignupID),
                AuditUserID: Submit.AuditUserID,
                ApplicationSubmittedRecommendation: Submit?.ApplicationSubmittedRecommendation || singleDetails?.ApplicationSubmittedRecommendation,
                DateApplicationSubmittedDecision: formattedDate
            }
            const saveHistoryData = {
                FK_SignupID: Submit?.FK_SignupID,
                FK_UserID: Submit?.AuditUserID,
                SignupCheckTitle: Submit?.ApplicationSubmittedRecommendation === "A" ? 'Submitted for Approval' : 'Submitted for Rejection',
                SignupCheckText: Submit?.SignupCheckText,
            }

            dispatch(fetchSaveSubmitData(saveSubmitDataPayload))
            dispatch(fetchsaveHistoryData(saveHistoryData));
        }
        if (data === 'Decision') {
            let saveSubmitDataPayload = null
            if (Decision?.ApplicationOutcome === null) {
                saveSubmitDataPayload = {
                    SignupID: Decision?.FK_SignupID,
                    AuditUserID: Decision?.AuditUserID,
                    ApplicationSubmittedRecommendation: null,
                    DateApplicationSubmittedDecision: null,
                    // DateApplicationMoreInfo: formattedDate,
                    // MoreInfoCount: Number(singleDetails?.MoreInfoCount) + 1,
                }
            } else {
                saveSubmitDataPayload = {
                    SignupID: Decision?.FK_SignupID,
                    AuditUserID: Decision?.AuditUserID,
                    ApplicationOutcome: Decision?.ApplicationOutcome,
                    DateApplicationActioned: formattedDate
                }
            }
            const arrayData = {
                '1000129': '1000000003',
                '1000130': '1000000004',
                '1000131': '1000000005',
                '1000132': '1000000006',
                '1000133': '1000000007',
                '1000134': '1000000008'
            };
            const subscriptionId = arrayData[singleDetails.CompanyTurnover];
            if(subscriptionId === undefined && singleDetails.Trial === 0){
                setIsRecordUpdated({
                    type: 'error',
                    message: 'Invalid company turnover Id'
                });
                return;
            }
            setTimeout(() => {
                dispatch(fetchSaveSubmitData(saveSubmitDataPayload))
                const saveHistoryData = {
                    FK_SignupID: Decision?.FK_SignupID,
                    FK_UserID: Decision?.AuditUserID,
                    SignupCheckTitle: Decision?.SignupCheckTitle,
                    SignupCheckText: Decision?.SignupCheckText,
                }
                dispatch(fetchsaveHistoryData(saveHistoryData));
            });
        }

        setTimeout(() => {
            if (data !== 'Decision') {
                setCancelModal(true);
                resetStatesOf(data)
            }
            dispatch(fethchParentCompanyDetailsByID(SignupID));
            dispatch(fetchHistoryDataByID(parseInt(SignupID)));

        }, 2000);
    }

    const renderDataField = (label, value) => (
        value && (
            <p className="text-sm">
                {label !== 'date' && label + ":"} {<span className="font-bold">{value}</span>}
            </p>
        )
    );

    const isDecisionSubmitted =
        singleDetails?.ApplicationOutcome !== null ? true :
            singleDetails?.ApplicationSuggestedCountryOfOrigin === null && (selectedTabIndex === 3 || selectedTabIndex === 4) ? true :
                singleDetails?.ApplicationSubmittedRecommendation === null && selectedTabIndex === 4 ? true : false;

    const handleSaveAction = (value) => {
        if (singleDetails?.ParentCompanyID !== null) {
            setIsRecordUpdated({
                type: 'error',
                message: `Parent Company already assigned ${singleDetails?.ParentCompanyID}, Please contact support team.`
            });
        } else if (Decision?.SignupCheckTitle === '') {
            setIsRecordUpdated({
                type: 'error',
                message: 'Select a decision (Approved or Rejected)'
            });
        }
        else if (singleDetails.FK_Company === null) {
            setIsRecordUpdated({
                type: 'error',
                message: 'Applicant Company ID is not set. Link can not be created.'
            });
        } else if (Decision?.SignupCheckText?.length === 0) {
            setIsRecordUpdated({
                type: 'error',
                message: 'Comment Is Required'
            });
        }
        else if (Decision?.SignupCheckTitle === 'Decision: Requiring Clarification') {
            handleSave('Decision');
        }
        else {
            setParentCompanyDialog(value);
            return;

            // return;
        }
        setValidationModal(true)
        setCancelModal(true);
    };

    return (
        <>
            <div className="w-full mx-auto">
                <div className="space-y-4 grid grid-cols-12 gap-5">
                    <div className="flex flex-col lg:col-span-8 col-span-12 pt-2">
                        {
                            singleDetails?.Trial === 1 ?
                                <div
                                    className="px-4 py-3 mb-4 text-sm rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                                    role="alert">
                                    <p>
                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                    </svg>
                                    <strong>Info :</strong> The User is requesting a <strong>Trial</strong> Account.</p>
                                </div> : null
                        }
                        <div>
                            <Tab.Group >
                                <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
                                    <Tab.List className="space-x-1">
                                        {tabPanelData.map((data, index) => (
                                            <Tab key={index} as={Fragment}>
                                                {({ selected }) => (
                                                    <button
                                                        className={`${selected ? "bg-white border-transparent pb-2 pt-4 text-black" : " py-2 text-white border-white border-t border-x"
                                                            } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                                                    >
                                                        {data.label}
                                                    </button>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels className="rounded-b-xl shadow-md p-5 bg-white h-[calc(100vh-450px)] overflow-y-auto">

                                    {tabPanelData.map((panel, panelIndex) => (
                                        <Tab.Panel key={panelIndex}>
                                            <div className="space-y-1">
                                                {panel.fields.map((field, fieldIndex) => (
                                                    <div key={fieldIndex}>
                                                        <span className="font-bold text-sm">{field?.header}</span>
                                                        <div className={`${field.label === "Coupon Name:" && "border-b border-black pb-3"} text-sm flex gap-3`}>
                                                            <p className="text-end w-full max-w-[200px] mr-1">
                                                                {field.label}
                                                            </p>
                                                            {field.label === "Stripe URL:" ?
                                                                <a className="font-bold underline" target="_blank" href={field.value}>{field.value}</a> :
                                                                <span className="font-bold">{field.value}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                        <div>
                            <Tab.Group selectedIndex={selectedTabIndex} onChange={(index) => setSelectedTabIndex(index)}>
                                <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
                                    <Tab.List className="space-x-1">
                                        {tabPanelAction.map((data, index) => (
                                            <Tab key={index} as={Fragment}>
                                                {({ selected }) => (
                                                    <button
                                                        className={`${selected ? "bg-white border-transparent pb-2 pt-4 text-black" : " py-2 text-white border-white border-t border-x"
                                                            } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                                                    >
                                                        {data.label}
                                                    </button>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels
                                    className={
                                        "rounded-b-xl shadow-md p-5 bg-white"
                                    }
                                >
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Checks:
                                                </p>
                                                <Dropdown
                                                    selected={Checks?.Check}
                                                    disabled={isDecisionSubmitted}
                                                    setSelected={(newValue) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            Check: newValue,
                                                            FK_SignupCheck: newValue?.SystemSelectID,
                                                            SignupCheckTitle: 'Checks: ' + newValue?.SelectionName
                                                        }))
                                                    }
                                                    options={options}
                                                />
                                            </div>
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Research Link:
                                                </p>
                                                <input
                                                    type="text"
                                                    value={Checks.ResearchLink || ""}
                                                    className="rounded-md w-full border py-1.5 px-2"
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(event) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            ResearchLink: event.target.value
                                                        }))}
                                                />
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <Textarea
                                                    value={Checks.SignupCheckText || ""}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                            {
                                                singleDetails.MoreInfoCount > 0 &&
                                                <div className="text-sm items-start flex gap-3">
                                                    <p className="text-end  w-full max-w-[200px]">
                                                        Clear more info:
                                                    </p>
                                                    <input
                                                        type="checkbox"
                                                        checked={Checks.ClearMoreInfo}
                                                        disabled={isDecisionSubmitted}
                                                        className="rounded-md  border py-1.5 mt-1"
                                                        onChange={(e) =>
                                                            setChecks((prevChecks) => ({
                                                                ...prevChecks,
                                                                ClearMoreInfo: e.target.checked
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <SaveCancelButton
                                            cancelModal={cancelModal}
                                            setCancelModal={(val) => {
                                                setIsRecordUpdated({
                                                    type: 'error',
                                                    message: 'Your information has not been stored.'
                                                });
                                                resetStatesOf('Check');
                                                setValidationModal(false)
                                                setCancelModal(val);
                                            }}
                                            type={'Check'}
                                            handleSave={(value) => {
                                                if (Checks?.Check === null || Checks?.Check?.SystemSelectID === 1000092) {
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Please select a check from the dropdown list.'
                                                    });
                                                    setCancelModal(true);
                                                    return;
                                                } else if (Checks?.SignupCheckText?.length === 0) {
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Comment is required.'
                                                    });
                                                    setCancelModal(true);
                                                    return;
                                                } else {
                                                    handleSave(value);
                                                }
                                            }}
                                            disabled={isDecisionSubmitted}
                                        />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    To:
                                                </p>
                                                <span className="font-bold">
                                                    {singleDetails?.Email}
                                                </span>
                                            </div>
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Subject:
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="Requesting more info about your application"
                                                    value={RequestInfo.Text || ""}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setRequestInfo((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckTitle: 'Email:' + newValue.target.value,
                                                            Text: newValue.target.value
                                                        }))
                                                    }
                                                    className="rounded-md w-full border py-1.5 px-2"
                                                />
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Message:
                                                </p>
                                                <Textarea
                                                    value={RequestInfo.SignupCheckText || ""}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setRequestInfo((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <SaveCancelButton
                                            cancelModal={cancelModal}
                                            setCancelModal={(val) => {
                                                setIsRecordUpdated({
                                                    type: 'error',
                                                    message: 'Your information has not been stored.'
                                                });
                                                resetStatesOf('RequestInfo')
                                                setValidationModal(false)
                                                setCancelModal(val)
                                            }} type={'RequestInfo'} handleSave={(value) => {
                                                if (RequestInfo?.SignupCheckText?.length === 0) {
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Subject and Message are required.'
                                                    });
                                                    setCancelModal(true);
                                                    return;
                                                } else {
                                                    handleSave(value);
                                                }
                                            }} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Country of Ownership:
                                                </p>
                                                <Dropdown selected={Ownership?.CountryOfOwnership || CountryOrigin.find(o => o.CountryCodeN3 === singleDetails?.ApplicationSuggestedCountryOfOrigin)} disabled={isDecisionSubmitted}
                                                    setSelected={(newValue) =>
                                                        setOwnership((prevChecks) => ({
                                                            ...prevChecks,
                                                            CountryOfOwnership: newValue,
                                                            SignupCheckTitle: 'Ownership: ' + newValue.CountryName,
                                                            ApplicationSuggestedCountryOfOrigin: newValue.CountryCodeN3
                                                        }))
                                                    } options={CountryOrigin} />

                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end  w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <Textarea
                                                    value={Ownership.SignupCheckText}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setOwnership((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <SaveCancelButton
                                            cancelModal={cancelModal}
                                            type={'Ownership'} setCancelModal={(val) => {
                                                setIsRecordUpdated({
                                                    type: 'error',
                                                    message: 'Your information has not been stored.'
                                                });
                                                resetStatesOf('Ownership')
                                                setValidationModal(false)
                                                setCancelModal(val)
                                            }} handleSave={(value) => {
                                                if(Ownership.ApplicationSuggestedCountryOfOrigin === null || Ownership.ApplicationSuggestedCountryOfOrigin === "000"){
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Please select a country from the provided list.'
                                                    });
                                                    setValidationModal(true)
                                                    setCancelModal(true);
                                                    return;
                                                }
                                                if (Ownership?.SignupCheckText?.length === 0) {
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Comment is required.'
                                                    });
                                                    setValidationModal(true)
                                                    setCancelModal(true);
                                                    return;
                                                } else {
                                                    handleSave(value);
                                                }
                                            }} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-2">
                                            <p className="text-sm">
                                                I am recommending this application for:
                                            </p>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end mt-11  w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <div className="w-full">
                                                    <div className="flex items-center gap-4">
                                                        <label className="flex py-3 items-center gap-2">
                                                            <input
                                                                type="radio"
                                                                className="w-4 h-4"
                                                                disabled={isDecisionSubmitted}
                                                                name="SubmitOption"
                                                                checked={Submit.ApplicationSubmittedRecommendation === "A"}
                                                                onChange={(event) =>
                                                                    setSubmit((prevChecks) => ({
                                                                        ...prevChecks,
                                                                        ApplicationSubmittedRecommendation: event.target.value,
                                                                        SignupCheckTitle: 'Submitted for Approval'
                                                                    }))
                                                                }
                                                                value={"A"}
                                                            />
                                                            <span>Approval</span>
                                                        </label>
                                                        <label className="flex py-3 items-center gap-2">
                                                            <input
                                                                type="radio"
                                                                className="w-4 h-4"
                                                                disabled={isDecisionSubmitted}
                                                                name="SubmitOption"
                                                                checked={Submit.ApplicationSubmittedRecommendation === "R"}
                                                                onChange={(event) =>
                                                                    setSubmit((prevChecks) => ({
                                                                        ...prevChecks,
                                                                        ApplicationSubmittedRecommendation: event.target.value,
                                                                        SignupCheckTitle: 'Submitted for Rejection'
                                                                    }))
                                                                }
                                                                value={"R"}
                                                            />
                                                            <span>Rejection</span>
                                                        </label>
                                                    </div>

                                                    <Textarea
                                                        value={Submit.SignupCheckText}
                                                        disabled={isDecisionSubmitted}
                                                        onChange={(newValue) =>
                                                            setSubmit((prevChecks) => ({
                                                                ...prevChecks,
                                                                SignupCheckText: newValue
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <SaveCancelButton
                                            cancelModal={cancelModal}
                                            type={'Submit'}
                                            setCancelModal={(val) => {
                                                setIsRecordUpdated({
                                                    type: 'error',
                                                    message: 'Your information has not been stored.'
                                                });
                                                setValidationModal(false)
                                                resetStatesOf('Submit');
                                                setCancelModal(val);
                                            }}
                                            handleSave={(value) => {
                                                if(Submit.ApplicationSubmittedRecommendation === null){
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Select a decision (Approved or Rejected)'
                                                    });
                                                    setValidationModal(true)
                                                    setCancelModal(true);
                                                    return;
                                                }
                                                if (Submit?.SignupCheckText?.length === 0) {
                                                    setIsRecordUpdated({
                                                        type: 'error',
                                                        message: 'Comment is required.'
                                                    });
                                                    setValidationModal(true)
                                                    setCancelModal(true);
                                                    return;
                                                } else {
                                                    handleSave(value);
                                                }
                                            }}
                                            disabled={isDecisionSubmitted}
                                        />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-2">
                                            <div className="flex flex-col">
                                                <p className="text-sm w-full">
                                                    This application is:
                                                </p>
                                                <div className="flex ml-32 items-center gap-4">
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="approveOption"
                                                            value="A"
                                                            name='Decision'
                                                            disabled={isDecisionSubmitted}
                                                            checked={Decision?.ApplicationOutcome === "A" || singleDetails?.ApplicationOutcome === "A"}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    ApplicationOutcome: event.target.value,
                                                                    SignupCheckTitle: 'Decision: Approved'
                                                                }))
                                                            }
                                                        />
                                                        <span>Approved</span>
                                                    </label>
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="rejectOption"
                                                            value={"R"}
                                                            name='Decision'
                                                            disabled={isDecisionSubmitted}
                                                            checked={Decision?.ApplicationOutcome === "R" || singleDetails?.ApplicationOutcome === "R"}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    ApplicationOutcome: event.target.value,
                                                                    SignupCheckTitle: 'Decision: Rejected'
                                                                }))
                                                            }
                                                        />
                                                        <span>Rejected</span>
                                                    </label>
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="requestInfoOption"
                                                            name='Decision'
                                                            checked={Decision?.SignupCheckTitle === "Decision: Requiring Clarification"}
                                                            value={'Decision: Requiring Clarification'}
                                                            disabled={isDecisionSubmitted}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    SignupCheckTitle: event.target.value,
                                                                    ApplicationOutcome: null,
                                                                }))
                                                            }
                                                        />
                                                        <span>Requiring Clarification</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end  w-full max-w-[115px]">Comments:</p>
                                                <div className="flex flex-col grow">
                                                    <Textarea
                                                        value={Decision.SignupCheckText || ""}
                                                        disabled={isDecisionSubmitted}
                                                        onChange={(newValue) =>
                                                            setDecision((prevChecks) => ({
                                                                ...prevChecks,
                                                                SignupCheckText: newValue,
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <SaveCancelButton
                                            cancelModal={cancelModal}
                                            type={true}
                                            setCancelModal={(val) => {
                                                setIsRecordUpdated({
                                                    type: 'error',
                                                    message: 'Your information has not been stored.'
                                                });
                                                resetStatesOf('Decision')
                                                setValidationModal(false)
                                                setCancelModal(val)
                                            }}
                                            selectedTabIndex={selectedTabIndex}
                                            handleSave={(val) => handleSaveAction(val)}
                                            disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                    <div className="lg:col-span-4 col-span-12">
                        <h1 className="text-xl font-semibold">History</h1>
                        <div className="border h-[calc(100vh-82px)] overflow-y-auto flex-col bg-white mt-3 flex rounded border-black p-3">
                            {history.length > 0 ? (
                                history.slice().reverse().map((data, index) => (
                                    <div key={index} className="flex flex-col border-b border-dashed py-2 border-black">
                                        {renderDataField('date', new Intl.DateTimeFormat('en-US', {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }).format(new Date(data?.DateCreated)))}
                                        {renderDataField('Action', data?.SignupCheckTitle)}
                                        {renderDataField('Actioned By', data?.ActionedBy)}
                                        {renderDataField('Research URL', data?.ResearchLink)}
                                        {renderDataField('Comments', data?.SignupCheckText)}
                                        <div className="divide-y"></div>
                                    </div>
                                ))
                            ) : (
                                //change <Loader /> to No Signup History Records
                                <div className="flex justify-center items-center">
                                    No Signup History Records
                                </div>
                            )}
                        </div>
                        <Transition show={cancelModal} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed inset-0 z-[9999] flex items-center justify-center"
                                onClose={() => setCancelModal(false)}
                            >
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="flex items-center justify-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-3 text-left align-middle shadow-xl transition-all">
                                            <div
                                                onClick={() => {
                                                    setCancelModal(false)
                                                }}
                                                className="cursor-pointer ml-4 flex justify-end mb-3"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <h6 className="font-semibold text-lg">
                                                    <Alert severity={isRecordUpdated.type}>{isRecordUpdated.message}</Alert>
                                                </h6>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition>
                    </div>

                </div>
            </div>
            <ApplicationDialog applicationDialog={applicationDialog} singleDetails={singleDetails} setapplicationDialog={setApplicationDialog} Decision={Decision} sethandleSave={() => handleSave('Decision')} />
            <AssignParentCompanyDialog CountryOrigin={CountryOrigin} setCancelModal={(val) => {
                setIsRecordUpdated({
                    type: 'error',
                    message: 'Your information has not been stored.'
                });
                setValidationModal(true)
                setCancelModal(val);
            }} parentCompany={singleDetails} assignParentCompanyDialog={ParentCompanyDialog} Decision={Decision} setAssignParentCompanyDialog={setParentCompanyDialog} handleSave={() => handleSave('Decision')}
                sethandleSave={() => {
                    handleSave('Decision')
                    setParentCompanyDialog(false)
                    setApplicationDialog(true)
                    setErrorMsg({})
                }} callUpdateSubcription={true}/>

        </>
    );
};

export default ViewApplication;