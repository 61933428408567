import React, { useEffect, useState, useRef } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Auth } from "../../api/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";

const ResetPassword = () => {
    const regExpUpper = /[A-Z]/;
	const regExpNumber = /\d/;
	const regExLength = /^.{8,25}$/;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [succeeded, setSucceeded] = useState(false);
    const [failed, setFailed] = useState(null);
    const [pwDontMatch, setPwDontMatch] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
    const [passwordInput, setPasswordInput, onPasswordChange] =useFormFieldOnChange("");
    const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] =useFormFieldOnChange("");
    const [numbermet, setNumbermet] = useState(false);
	const [charmet, setCharmet] = useState(false);
	const [uppermet, setUppermet] = useState(false);
	const [confirmPasswordMatch, setConfirmPasswordMatch] = useState('');
	const [resetEnabled, setResetEnabled] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const firstNameRef = useRef(null); 

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailBlur = () => {
        if (!validateEmail(emailInput)) {
            setFailed('Invalid email address entered');
        } else {
            setFailed('');
        }
    };

	const togglePasswordVisibility = () => {
		setShowPassword(prev => !prev);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(prev => !prev);
	};

    const conditionCheck = (Password, ConfirmPassword) => {
		setPasswordInput(Password);
		setConfirmPwInput(ConfirmPassword);

		if (!Password && !ConfirmPassword) {
            setConfirmPasswordMatch(false);
            setUppermet(false);
            setNumbermet(false);
            setCharmet(false);
            return; // Exit early to avoid further checks
		}

		if (Password.match(regExpUpper)) {
		    setUppermet(true);
		} else {
		    setUppermet(false);
		}

		if (Password.match(regExpNumber)) {
		    setNumbermet(true);
		} else {
		    setNumbermet(false);
		}

		if (Password.match(regExLength)) {
		    setCharmet(true);
		} else {
		    setCharmet(false);
		}

		if (Password === ConfirmPassword) {
		    setConfirmPasswordMatch(true);
		} else {
		    setConfirmPasswordMatch(false);
		}
	};

	useEffect(() => {
		conditionCheck(passwordInput, confirmPwInput);

		if (emailInput && confirmPasswordMatch && numbermet && charmet && uppermet) {
			setResetEnabled(true);
		} else {
			setResetEnabled(false);
		}
	}, [emailInput, passwordInput, confirmPwInput, confirmPasswordMatch, numbermet, charmet, uppermet]);

	useEffect(() => {
		// Focus the input when the component mounts
		if (firstNameRef.current) {
			firstNameRef.current.focus();
		}
	}, []);


    const onEmailInputChange = (e) => {
        onEmailChange(e);
        hideStatusMessages();
    };

    // const onPasswordInputChange = (e) => {
    //     onPasswordChange(e);
    //     hideStatusMessages();
    // };

    // const onConfirmPwInputChange = (e) => {
    //     onConfirmPwChange(e);
    //     hideStatusMessages();
    // };

    const onInputChange = (e, inputHandler) => {
		inputHandler(e);
		hideStatusMessages();
	};



    // useEffect(() => {
    //     if (
    //         passwordInput.trim().length === "" ||
    //         confirmPwInput.trim().length === ""
    //     ) {
    //         setPwDontMatch(false);
    //         return;
    //     }
    //     setPwDontMatch(passwordInput !== confirmPwInput);
    // }, [passwordInput, confirmPwInput]);

    const submitForm = async (e) => {
        e.preventDefault();

        if (processing) {
            return;
        }
        if (
            emailInput.trim().length === "" ||
            passwordInput.trim().length === "" ||
            confirmPwInput.trim().length === ""
        ) {
            return;
        }

        if (passwordInput !== confirmPwInput) {
            setPwDontMatch(true);
            return;
        }

        hideStatusMessages();

        setProcessing(true);
        const reqRes = await Auth().ResetPassword(
            emailInput,
            passwordInput,
            confirmPwInput,
            searchParams.get("token")
        );

        setProcessing(false);

        if (reqRes === false || reqRes.status !== HTTP_STATUS_CODES.OK) {
            if (reqRes.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                setFailed(
                    <span>
                         {reqRes?.data?.message}
                    </span>
                );
            } else if (reqRes.status === HTTP_STATUS_CODES.BAD_REQUEST) {
                setFailed(
                    "Please, make sure you have entered your email address correctly."
                );
            } else {
                setFailed(
                    "An error has occurred during the registration process."
                );
            }
            return;
        }

        resetForm();
        setSucceeded(true);
    };

    const hideStatusMessages = () => {
        setProcessing(false);
        setFailed(null);
        setSucceeded(false);
        setPwDontMatch(false);
    };

    const resetForm = () => {
        setEmailInput("");
        setPasswordInput("");
        setConfirmPwInput("");
        hideStatusMessages();
    };

    const passwordHasBeenChanged = () => {
        return (
            <>
                <p className="text-black lg:text-left md:text-left text-center" style={{ fontWeight: "normal" }}>
                    Please close this window and login to app.
                </p>
                {/* <div className="FormButtonContainer">
          <button
            onClick={() => {
              navigate("/SignIn");
            }}
          >
            Continue to login
          </button>
        </div> */}
            </>
        );
    };

    const formContent = () => {
        return (
            <>
                <div className="flex flex-col mb-3">
                    <label>Email</label>
                    <input
                        type="text"
                        placeholder="Enter your email..."
                        value={emailInput}
                        onChange={onEmailInputChange}
                        className="rounded-full border py-1.5 px-3 "
                        ref={firstNameRef}
                        onBlur={handleEmailBlur}
                    />
                </div>
                <div className="flex flex-col mb-3">
                    <label>Password</label>
                    <div className="relative rounded-full border">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter your password..."
                            value={passwordInput}
                            onChange={(e) => {
                                onInputChange(e, onPasswordChange);
                                conditionCheck(e.target.value, confirmPwInput);
                            }}
                            className="rounded-full border py-1.5 px-3 w-full"
                        />
                        <span
                            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                            onClick={togglePasswordVisibility}
                        >
                            {/* {showPassword ? 'Hide' : 'Show'} */}
                            <img 
                                src={showPassword ? require('../../assets/images/Eye_Show.png') : require('../../assets/images/Eye_Hide.png')}
                                alt={showPassword ? "Hide Password" : "Show Password"}
                                style={{ width: '20px', height: '20px' }} // Adjust size as needed
                            />
					    </span>
                    </div>
                    
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' , marginBottom: 3, marginLeft: 4}}>
                        {!charmet ? (
                            <img
                            src={require('../../assets/images/Cross_Red_Circle.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Cross"
                            />
                        ) : (
                            <img
                            src={require('../../assets/images/White_Heavy_Check_Mark.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Check"
                            />
                        )}
                        <span>Eight or more characters (Max 25)</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 3, marginLeft: 4 }}>
                        {!uppermet ? (
                            <img
                            src={require('../../assets/images/Cross_Red_Circle.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Cross"
                            />
                        ) : (
                            <img
                            src={require('../../assets/images/White_Heavy_Check_Mark.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Check"
                            />
                        )}
                        <span>At least one (1) Uppercase character</span>
                    </div>

                        {/* Condition for at least one numeric character */}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 3, marginLeft: 4 }}>
                        {!numbermet ? (
                            <img
                            src={require('../../assets/images/Cross_Red_Circle.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Cross"
                            />
                        ) : (
                            <img
                            src={require('../../assets/images/White_Heavy_Check_Mark.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Check"
                            />
                        )}
                        <span>At least one (1) Numeric character</span>
                    </div>

                        {/* Condition for confirming password match */}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginLeft: 4}}>
                        {!confirmPasswordMatch ? (
                            <img
                            src={require('../../assets/images/Cross_Red_Circle.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Cross"
                            />
                        ) : (
                            <img
                            src={require('../../assets/images/White_Heavy_Check_Mark.png')}
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            alt="Check"
                            />
                        )}
                        <span>Confirm Password Match</span>
                    </div>
			    </div>

                <div className="flex flex-col mb-3">
                    <label>Confirm Password</label>
                    <div className="relative rounded-full border">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm your password..."
                            value={confirmPwInput}
                            onChange={(e) => onInputChange(e, onConfirmPwChange)}
                            className="rounded-full border py-1.5 px-3 w-full"
                        />
                        <span
                            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                            >
                            <img 
                                src={showConfirmPassword ? require('../../assets/images/Eye_Show.png') : require('../../assets/images/Eye_Hide.png')}
                                alt={showConfirmPassword ? "Show Password" : "Hide Password"}
                                style={{ width: '20px', height: '20px' }} // Adjust size as needed
                            />
					    </span>
                    </div>
                    
                </div>
                {/* {failed !== null && (
                    <p className="text-red-500 font-bold">{failed}</p>
                )} */}
                {/* {pwDontMatch && (
                    <p className="text-red-500 font-bold">
                        You've entered different passwords!
                    </p>
                )} */}
                {failed ? (
                    <div className="text-red-500 mb-2 font-bold text-center md:text-left ml-2">
                        {failed}
                    </div>
                ) : (
                    <div className="text-500 mb-11 font-bold bg-white text-center md:text-left"/>
                )}
                <div className="flex align-center gap-2 mt-3">
                    <button
                        className={`rounded-full text-white py-1.5 w-full bg-gray-500`}
                        disabled={processing}
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                        }}
                    >
                        Reset Screen
                    </button>
                    <button 
                        //disabled={processing} 
                        onClick={submitForm} 
                        disabled={!resetEnabled}
					    style={{opacity: !resetEnabled ? 0.4 : 1 }}
                        className={'bg-primary rounded-full text-white py-1.5 w-full'}>
                        Confirm
                    </button>
                    
                </div>
            </>
        );
    };

    return (
        <div className="w-full flex justify-center h-full items-center overflow-hidden">
            <div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
                <form>
                    <div className="flex flex-col justify-center items-center">
                        <img
                            className="w-[300px]"
                            src="BBA-Logo-TBl-S.png"
                            alt=""
                        />
                        <p className="font-bold text-center mb-3 text-lg">
                            {!succeeded
                                ? "Reset your password"
                                : "Your password has been successfully changed"}
                        </p>
                    </div>
                    {!succeeded ? formContent() : passwordHasBeenChanged()}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
