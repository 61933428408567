import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

export const Brands = () => {
  const getAll = async (gln) => {
    try {
      //when the logged user is not an employee of Bring Back, the GLN filter should pick up automatically the GLN the user is related to
      gln = gln === null ? '' : gln;
      return await getAuthRequest(getToken()).get('brands/getAll/' + gln);
    } catch (error) {
      return false;
    }
  };

  const getSubstitutions = async (brandId) => {
    try {
      //when the logged user is not an employee of Bring Back, the GLN filter should pick up automatically the GLN the user is related to
      brandId = brandId === null ? '' : brandId;
      return await getAuthRequest(getToken()).get('brands/getSubstitutions/' + brandId);
    } catch (error) {
      return false;
    }
  };

  const validateBrand = async (brandID) => {
    try {
      return await getAuthRequest(getToken()).post('brands/validateBrand', {
        'BrandID': brandID
      });
    } catch (error) {
      return false;
    }
  };

  const addSubstitute = async (brandID, subtituteID) => {
    try {
      return await getAuthRequest(getToken()).post('brands/addBrandSubstitute', {
        'BrandID': brandID, 'SubstituteID': subtituteID
      });
    } catch (error) {
      return false;
    }
  }

  const checkBrandAlreadyExists = async (GLN, brand, brandSub) => {
    try {
      return await getAuthRequest(getToken()).post('brands/checkBrandAlreadyExists', {
        'GLN': GLN, 'Brand': brand, 'BrandSub': brandSub
      });
    } catch (error) {
      return error.response;
    }
  }

  const createBrandSubstitute = async (GLN, brandID, brand, brandSub) => {
    try {
      return await getAuthRequest(getToken()).post('brands/createBrandSubstitute', {
        'GLN': GLN, 'BrandID': brandID, 'Brand': brand, 'BrandSub': brandSub
      });
    } catch (error) {
      return error.response;
    }
  }

  const brandActions = async (Payload) => {
    return getAuthRequest(getToken()).post('tab/BRAG', Payload);
  };

  const brandCreateOrUpdate = async (Payload) => {
    return getAuthRequest(getToken()).post('tab/BRAP', Payload);
  };

  const brandProductCount = async (CompanyID, filter, page, limit) => {
    let queryString = 'members/BrandProductCount?page=' + page;
    return await getAuthRequest(getToken()).post(queryString, { CompanyID, filter, limit });
  };

  const brandCompanyID = async (ParentCompanyID) => {
    return await getAuthRequest(getToken()).post('/tab/LPCG', { FK_ParentID: ParentCompanyID })
    // const FK_CompanyID = payload?.ParentCompaniesInfo[0]?.FK_CompanyID
    // console.log('dataaaaaa', payload?.ParentCompaniesInfo[0]?.FK_CompanyID)
  };

  const getUserCompanyData = async (CompanyId) => {
    try {
      return await getAuthRequest(getToken()).post('tab/COMG',{ CompanyID: CompanyId });
    } catch (error) {
      return error;
    }
  };

  const reAssignBrand = async (Payload) => {
    return getAuthRequest(getToken()).post('members/reassignBrand', Payload);
  };




  return { getAll, getSubstitutions, validateBrand, addSubstitute, checkBrandAlreadyExists, createBrandSubstitute, brandActions, brandProductCount, brandCreateOrUpdate, brandCompanyID, reAssignBrand, getUserCompanyData };
};