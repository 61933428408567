import React, { useState } from 'react'
import { useContext } from 'react';
import { useFormFieldOnChange } from '../../../../utils/FormHooks';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	brickSearch
} from "../../../../store/slices/gvdValidateSlice";

const BrickSearchContainer = () => {
  const dispatch = useAppDispatch();
  const { brickSelected, loading } = useAppSelector(gvdValidate);

  const [brickInput, setBrickInput, onChangeBrickInput] = useFormFieldOnChange('10000025');

  const { setSelectedBrick } = useContext(ContextSelectedBrick);

  const searchBrick = () => {
    if (brickInput.trim().length === 0) {
      return false;
    }
    searchBrickDetails();
  }

  const searchBrickDetails = async () => {
    dispatch(brickSearch(brickInput))
    setBrickInput('');
    setSelectedBrick(new String(brickInput));
  };

  const onPressEnterSearch = (e) => {
    if (e.key === 'Enter') {
      searchBrick()
    }
  };

  return (
    <>
      <span className="block flex-grow text-base font-bold self-center">
        {loading && <>Loading...</>}
        {!loading && brickSelected === null && brickInput.trim().length > 0 && <>Brick not found</>}
        {!loading && brickSelected !== null && <>Brick: {brickSelected.BRICK ? brickSelected.BRICK + ' - ' + brickSelected.BrickDesc : ''}</>}
      </span>

      <div className="flex gap-2 items-center">
        <input onKeyDown={onPressEnterSearch} onChange={onChangeBrickInput} value={brickInput} className="bg-white py-2 px-2 rounded border-gray border" type="text" />
        <button onClick={searchBrick} className="bg-primary rounded text-white px-3 whitespace-nowrap py-2">Search Brick</button>
      </div>
    </>
  );
};

export default BrickSearchContainer;