import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './SupplierOverrides.css';

const SupplierOverrides = () => {
  return (
    <PageSkeletonComponent>

      <h1>SUPPLIER - Overrides Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      This page is used to override the ownership of a company.<br /><br />
      The page is Read Only for the Supplier.<br /><br />
      They will be able to lodge an appeal to have the ownership reviewed, but only Bring Back staff can change the record.<br /><br />

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>
      
    </PageSkeletonComponent>
  );
};

export default SupplierOverrides;