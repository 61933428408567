import React, { useContext, useEffect, useState } from 'react'
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItemGroup from './GVDItemGroup';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	fetchGroups
} from "../../../../store/slices/gvdValidateSlice";

const GVDContainer = () => {
  const dispatch = useAppDispatch();
  const { groups } = useAppSelector(gvdValidate);

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail } = useContext(SelectedGVDDetailContext);

  useEffect(() => {
    fetchGroupVariantDetail();
  }, [BRICK]);

  useEffect(() => {
    Object.entries(selectedDetail).length === 0 && fetchGroupVariantDetail();
  }, [selectedDetail]);

  const fetchGroupVariantDetail = async () => {
    if ((BRICK?.trim().length ?? 0) === 0) return;
dispatch(fetchGroups(BRICK));
  };

  return (
    <>
      <div className="flex-grow mb-3 bg-white border flex flex-col">
        <span className="p-3 border-b border-b-[#707070] flex ">
          <span className="font-semibold">Group Variant Detail</span>
        </span>
        <div className="p-2 flex-grow flex flex-col h-0 overflow-y-auto relative">
          {groups.length === 0 ? (
            <div className='h-full flex justify-center items-center'>
              <h4>No records</h4>
            </div>
          ) : null}

          {groups.map((group, key) => (
            <GVDItemGroup key={key} group={group} />
          ))}
        </div>
      </div>
    </>
  );
};

export default GVDContainer;