import React, { useEffect } from 'react';
import './ModalDialog.css';

const ModalDialog = (props) => {

  // useEffect(() => {
  //   if (props.showModal) {
  //     document.body.style.overflow = 'hidden';
  //     //document.body.style.paddingRight = '17px';
  //   } else {
  //     document.body.style.overflow = 'auto';
  //   }
  // });

  const stopClosePropagation = (e) => {
    e.stopPropagation();
  };

  if (!props.showModal) return null;

  return (
    <div className="ModalDialog" onClick={props.onClose}>
      <div className="ModalDialogContainer" onClick={stopClosePropagation}>
        {props.children}
      </div>
    </div>
  );
};
export default ModalDialog;