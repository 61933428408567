import React, { useContext } from 'react';
import { assignParentCompanyToPrefix } from '../../../api/BarcodeScan';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import ParentCompanyModal from './ParentCompanyModal';
import { BarcodeSearchButton } from './ProductScanNewStyle';

const ButtonShowCompromisedModal = ({ GTIN, refreshCallback,showCompromisedModal,closeCompromisedModal,openCompromisedModal }) => {

  return (
    <>  
      <button className='bg-primary rounded px-3 py-1.5 text-white' onClick={openCompromisedModal}>Compromised</button>
      <ParentCompanyModal refreshCallback={refreshCallback} assignToCallbackParam={GTIN} assingToCallBack={assignParentCompanyToPrefix} closeCompromisedModal={closeCompromisedModal} showCompromisedModal={showCompromisedModal} />
    </>
  );
};

export default ButtonShowCompromisedModal;