import React from "react";

const CompaniesHome = () => {

    return (
        <div className='flex justify-center items-center h-screen'>
            <h1 className='text-3xl font-bold'>{`Coming Soon`}</h1>
        </div>
    );
};

export default CompaniesHome;
