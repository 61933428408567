import React from 'react';
import ReactCountryFlag from "react-country-flag"
import { Link } from "react-router-dom";

const defaultColumns = [
    { name: 'GTIN', minWidth: 100, header: 'Column 1', defaultFlex: 1, type: 'string', render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'FamilyID', minWidth: 80, header: 'Column 2', defaultFlex: 2, type: 'string', render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'BrickID', minWidth: 80, header: 'Column 3', defaultFlex: 3, type: 'string', render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'SegmentID', minWidth: 80, header: 'Column 4', type: 'string', defaultFlex: 4, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },

];

export default defaultColumns;