import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";

import "./RawWordsProductList.scss";

const RawWordsProductList = ({
    prods,
    wordMatch,
    proModalShow,
    handleProClose,
}) => {
    return (
        // <DashboardPopUp header={"Products"} description={wordMatch.Words.join(' - ')}>
        //   <div className="gvd-words-list products-list">
        //     <span className="brand">Brand</span>
        //     <span className="description">Description</span>
        // {prods.map((prod, key) => (
        //   <React.Fragment key={key}>
        //     <span>{prod.Brand} - {prod.BrandSub}</span>
        //     <span>{prod.TradeItemDesc}</span>
        //   </React.Fragment>
        // ))}
        //   </div>
        // </DashboardPopUp>
        <Transition show={proModalShow} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={handleProClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between border-b border-b-black items-start">
                                    <div>
                                        <h6 className="font-semibold text-sm">
                                            Products
                                        </h6>
                                        <p>{wordMatch?.Words?.join(" - ")}</p>
                                    </div>
                                    <div
                                        onClick={handleProClose}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="mt-3 grid grid-cols-12">
                                    <p className="col-span-2 font-bold">Brand</p>
                                    <p className="col-span-10 font-bold">
                                        Description
                                    </p>
                                    {prods?.map((prod, key) => (
                                        <React.Fragment key={key}>
                                            <p className="col-span-2">
                                                {prod?.Brand} - {prod?.BrandSub}
                                            </p>
                                            <p className="col-span-10">{prod?.TradeItemDesc}</p>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
export default RawWordsProductList;
