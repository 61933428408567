import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useFormFieldOnChange} from "../../utils/FormHooks";
import {useAppSelector, useAppDispatch} from "../../store/store";
import {
    auth,
    setSucceeded,
    setFailed,
    setProcessing,
    setError,
    forgotPassword,
    resetState
} from "../../store/slices/authSlice";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {failed, succeeded, processing, error} = useAppSelector(auth);
    const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");

    useEffect(() => {
        dispatch(resetState())
    }, []);

    const onEmailInputChange = (e) => {
        onEmailChange(e);
        dispatch(setError(""))
        dispatch(setFailed(false));
        hideStatusMessages();
    };
    const submitForm = async (e) => {
        e.preventDefault();
        if (processing) {
            return;
        } else if (emailInput.trim().length === "") {
            dispatch(setError("Please enter an email"))
            dispatch(setFailed(true))
            dispatch(setSucceeded(false));
            return;
        } else {
            dispatch(forgotPassword(emailInput)).then(res => {
                hideStatusMessages();
            });
        }
    };

    const hideStatusMessages = () => {
        dispatch(setProcessing(false));
    };

    const passwordResetLinkSent = () => {
        return (
            <p className="text-black lg:text-left md:text-left text-center" style={{ fontWeight: "normal" }}>
                Check your email and follow the instructions to reset your
                password.
            </p>
        );
    };
    const formContent = () => {
        return (
            <>
                <div className="flex flex-col mb-4">
                    <label>Enter your email below</label>
                    <input
                        type="email"
                        placeholder="Enter your email..."
                        className="rounded-full border py-1.5 px-2 border-gray"
                        value={emailInput}
                        onChange={onEmailInputChange}
                    />
                </div>
                {failed && (
                    <div className="text-red-500 mb-3">
                        {error.length > 0 ? error : "An error has occurred while processing the password reset request."}
                    </div>
                )}
                <div className="flex items-center mt-3 gap-2">
                    <button
                        disabled={processing}
                        className={"bg-gray-500 rounded-full text-white py-1.5 w-full"}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/SignIn");
                        }}
                    >
                        Go to Login
                    </button>
                    <button
                        disabled={processing}
                        className={`${processing ? 'bg-gray-400' : 'bg-primary'} rounded-full text-white py-1.5 w-full`}
                        onClick={submitForm}
                    >
                        Reset Password
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="w-full flex justify-center h-full items-center overflow-hidden">
            <div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
                <form>
                    <div className="flex flex-col justify-center items-center">
                        <img
                            className="w-[300px]"
                            src="BBA-Logo-TBl-S.png"
                            alt="BBA-Logo-TBl-S"
                        />
                        <p className="font-bold text-center mb-3 text-lg">
                            {!succeeded
                                ? "Forgotten Your Password?"
                                : "We've sent you a password reset link"}
                        </p>
                    </div>
                    {!succeeded ? formContent() : passwordResetLinkSent()}
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
