import React, { useEffect, useState } from "react";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import CompanyDetailTabs from "./CompanyDetailTabs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addForm,
  fetchAccountDetailsAfterLogin,
  fetchAnnualTurnover,
  fetchCountry,
  fetchIndustries,
  fetchNumberOfEmployees,
  fetchStateForAccountDetails,
  orderSelector,
  submitAccountDetailsForLoginUser,
  uploadCompanyImage,
} from "../../store/slices/getStartedSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/Loader";
import { useAuthContext } from "../../context/AuthContext";
import * as yup from "yup";

const CompanyDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = new useAuthContext();
  const user = userAuth.user;
  const userparentCompanies = userAuth.parentCompanies;
  const { t } = useTranslation(["common"]);
  const {
    form,
    country,
    state,
    numberOfEmployees,
    annualTurnover,
    industries,
    loader,
  } = useAppSelector(orderSelector);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [message, setMessage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    fetchCountryData();
  }, []);

  const fetchCountryData = async () => {
    await dispatch(fetchCountry());
    let data = Object.assign({}, form);
    data = {
      ...data,
      account_type: "supplier",
    };
    dispatch(addForm(data));

    await Promise.all([
      dispatch(fetchNumberOfEmployees()),
      dispatch(fetchAnnualTurnover()),
      dispatch(fetchIndustries()),
      dispatch(
        fetchAccountDetailsAfterLogin(userparentCompanies?.ParentCompanyID)
      ),
    ]);
  };

  useEffect(() => {
    fetchUpdatedForm();
  }, [form]);

  const schema = yup.object().shape({
    FK_CompanyTurnover: yup.string().nullable(),
    // .required("Annual turnover is required"),

    FK_EmployeeCount: yup.string(),
    // .required("Number of employees is required")
    // .min(3, "Number of employees is required")
    CompanyName: yup
      .string()
      .required("Company Name is required")
      .min(2, "Company name should be at least 2 characters")
      .max(50, "Company name should be at most 50 characters"),

    TradingName: yup
      .string()
      .nullable()
      .max(50, "Trading name should be at most 50 characters"),

    TaxNumber: yup
      .string()
      .nullable() // Make the field nullable (optional)
      .test(
        "is-valid-abn",
        "Invalid ABN format. Must be 11 digits",
        function (value) {
          if (!value) {
            // If value is not provided, skip further validation
            return true;
          }

          // Remove spaces
          const cleanedValue = (value || "").replace(/\s+/g, "");

          // Check if the value has exactly 11 digits
          if (!/^\d{11}$/.test(cleanedValue)) {
            return false;
          }

          return true;
        }
      ),

    Website: yup.string().nullable(),

    FK_MainIndustry: yup.string(),
    // .required("Industry is required, select OTHER if not in list"),

    IndustryOther: yup
      .string()
      .nullable()
      .when("FK_MainIndustry", {
        is: "1000153",
        then: () =>
          yup
            .string()
            .required("Other is required")
            .max(50, "other should be at most 50 characters"),
      }),

    AddressStreet1: yup
      .string()
      .required("Address is required")
      .min(2, "Address should be at least 2 characters")
      .max(100, "Address should be at most 50 characters"),

    AddressStreet2: yup
      .string()
      .nullable()
      .max(100, "Address 2 should be at most 50 characters"),

    AddressPostCode: yup
      .string()
      .required("Postcode is required")
      .min(4, "Postcode should be at least 4 characters")
      .max(7, "Postcode should be at most 7 characters"),

    AddressCity: yup
      .string()
      .required("City is required")
      .min(2, "City should be at least 2 characters")
      .max(50, "City should be at most 50 characters"),

    AddressCountry: yup.string().required("Country is required"),

    PhoneCompany: yup.string().nullable(),
    /* .test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
         if (!value) return true;  // Allow empty or null

         return /^[01]\d{9}$/.test(value);
     }),*/
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // Validate on blur in addition to on submit,
    resolver: yupResolver(schema),
  });

  const fetchUpdatedForm = async () => {
    let data = Object.assign({}, form);
    if (!isFormLoaded) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "AddressCountry") {
          await dispatch(
            fetchStateForAccountDetails({ FK_CountryCodeN3: value })
          );
          setValue(key, value);
        } else {
          setValue(key, value);
        }
      }
      if (Object.keys(data).length > 24) {
        setIsFormLoaded(true);
      }
    }
  };

  const onSubmit = async (data) => {
    // Handle form submission logic here

    if (uploadedFile) {
      let json = await handleUpload();
      if (json.status === 200) {
        await setFormField("ParentCompanyImageURL", json.data?.ImageURL);
      }
    }

    const submitResult = await dispatch(
      submitAccountDetailsForLoginUser({
        ParentCompanyID: form.ParentCompanyID,
        CompanyName: form.CompanyName,
        TradingName: form.TradingName,
        FK_MainIndustry: form.FK_MainIndustry,
        IndustryOther: form.IndustryOther,
        FK_CompanyTurnover: form.FK_CompanyTurnover,
        FK_EmployeeCount: form.FK_EmployeeCount,
        TaxCountry: form.TaxCountry,
        TaxNumber: form.TaxNumber,
        PhoneCompany: form.PhoneCompany,
        Website: form.Website,
        AddressStreet1: form.AddressStreet1,
        AddressStreet2: form.AddressStreet2,
        AddressCity: form.AddressCity,
        AddressState: form.AddressState,
        AddressPostCode: form.AddressPostCode,
        AddressCountry: form.AddressCountry,
        AuditUserID: user.UserID,
      })
    );

    if (submitResult.status === 204) {
      // Set the message to true
      setMessage(true);

      // After 4 seconds, set the message to false
      setTimeout(() => {
        setMessage(false);
      }, 5000);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const data_arrays = [
    {
      id: 1,
      label: "Company Name",
      value: "CompanyName",
      placeholder: "Company Name",
    },
    {
      id: 2,
      label: "Trading Name",
      value: "TradingName",
      placeholder: "Trading Name",
    },
    { id: 3, label: "Website", value: "Website", placeholder: "Website" },
    { id: 4, label: "ABN", value: "TaxNumber", placeholder: "ABN" },
  ];

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
    if (key == "AddressCountry") {
      dispatch(fetchStateForAccountDetails({ FK_CountryCodeN3: value }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handleUpload = async () => {
    if (!uploadedFile) {
      // Handle error: No file selected
      return;
    }

    const formData = new FormData();
    formData.append("type", "parent");
    formData.append("id", userparentCompanies?.ParentCompanyID);
    formData.append("image", uploadedFile);
    return await dispatch(uploadCompanyImage(formData));
  };

  const extractErrorMessages = (errors) => {
    return Object.values(errors).flatMap((error) => {
      if (error && typeof error === "object") {
        return error.message;
      }
      return [];
    });
  };

  const errorMessages = extractErrorMessages(errors);
  return (
    <>
      {!userAuth.isSupplier ? (
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl font-bold">{`Coming Soon 
                ${
                  userAuth.isEmployee
                    ? "(Admin  View)"
                    : userAuth.isRetailer
                    ? "(Retailer  View)"
                    : userAuth.isServiceProvider
                    ? "(ServiceProvider View)"
                    : ""
                } `}</h1>
        </div>
      ) : (
        <div className="w-full mx-auto">
          <DashboardHeader title={""} nav={[]} />
          {!isFormLoaded ? (
            <div className={"flex justify-center items-center my-2 h-screen"}>
              <Loader width={"w-24"} heigth={"h-24"} />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-4 rounded-xl shadow-md p-4 bg-white">
                {data_arrays.map((data) => (
                  <div
                    className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-start gap-3"
                    key={data.id}
                  >
                    <h6 className="w-full max-w-[200px] text-right mt-2">
                      {data.label} :
                    </h6>
                    <div className="flex flex-col w-full gap-2">
                      <input
                        {...register(data.value)}
                        type="text"
                        onChange={(e) =>
                          setFormField(data.value, e.target.value)
                        }
                        value={form ? form[data.value] : ""}
                        className="rounded-md font-bold w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                        placeholder={data.placeholder}
                      />
                      {errors[data.value] && (
                        <p className="text-red-500 text-sm">
                          {errors[data.value].message}
                        </p>
                      )}
                    </div>
                  </div>
                ))}

                <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-start gap-3">
                  <h6 className="w-full max-w-[200px] text-right">
                    Your Logo :
                  </h6>
                  <div className="flex items-center gap-3 flex-col justify-center w-full">
                    <div
                      className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg bg-gray-100 hover:bg-gray-200`}
                    >
                      <div className="flex flex-col items-center py-3 justify-center">
                        {uploadedFile ? (
                          // Check if the uploaded file has a valid extension
                          /\.(jpg|jpeg|png)$/i.test(uploadedFile.name) ? (
                            <img
                              src={URL.createObjectURL(uploadedFile)}
                              alt="Uploaded"
                              className="w-[150px] h-[150px] rounded-[10px] object-contain"
                            />
                          ) : (
                            // Render a placeholder or error message for invalid extensions
                            <div style={{ color: "red" }}>
                              Invalid file format. Please upload a JPG, JPEG, or
                              PNG file.
                            </div>
                          )
                        ) : form?.ParentCompanyImageURL ? (
                          <img
                            src={form?.ParentCompanyImageURL}
                            alt="Uploaded"
                            className="w-[150px] h-[150px] rounded-[10px] object-contain"
                          />
                        ) : (
                          <svg
                            className="w-8 h-8 mb-4 text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div>
                      <label htmlFor={"fileInput"}>
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          onChange={handleFileChange}
                          className="hidden"
                        />
                        <div
                          className={
                            "bg-gray-500 text-white px-4 py-2 rounded-md"
                          }
                        >
                          + Image
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <CompanyDetailTabs
                register={register}
                errors={errors}
                form={form}
                setValue={setValue}
                setFormField={setFormField}
                getValues={getValues}
                countryOptions={country}
                stateOptions={state}
                numberOfEmployeesOptions={numberOfEmployees}
                annualTurnoverOptions={annualTurnover}
                industriesOptions={industries}
                setSelectedTab={setSelectedTab}
              />
              <div className={`${errorMessages?.length > 0 && "mb-3"}`}>
                {errorMessages?.length > 0 && (
                  <ul
                    className={
                      "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3"
                    }
                  >
                    {errorMessages.map((message, index) => (
                      <li key={index}>
                        <Alert severity="error">{message}</Alert>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {isFormLoaded && selectedTab !== "Users" && (
                <div className="flex justify-center items-center space-x-4">
                  <button
                    onClick={(e) => handleCancel(e)}
                    type="button"
                    className="bg-gray-500 text-white px-4 py-2 rounded-md w-[fit-content]"
                  >
                    Cancel
                  </button>
                  {loader ? (
                    <button
                      type="submit"
                      className="bg-gray-500 flex items-center gap-2 text-white px-4 py-2 rounded-md w-[fit-content]"
                    >
                      <Loader /> Loading...
                    </button>
                  ) : (
                    <button
                      onClick={(e) => handleSubmit(e)}
                      type="submit"
                      className="bg-primary text-white px-4 py-2 rounded-md w-[fit-content]"
                    >
                      Save
                    </button>
                  )}
                </div>
              )}

              <div
                className={
                  "justify-center items-center space-x-4 w-max m-auto flex mt-3"
                }
              >
                {message && (
                  <Alert severity="success">
                    {t("common:submit_details_successful").toString()}
                  </Alert>
                )}
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyDetail;
