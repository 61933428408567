import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { useAuthContext } from "../../context/AuthContext";
import {
  SihSignupHistory,
  addForm,
  fetchAccountDetails,
  fetchCompanyByGtin,
  orderSelector,
  setCompany,
  setIndex,
  submitClaimCompany,
  updateSignupInformation,
} from "../../store/slices/getStartedSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import GetStartedTab from "./GetStartedTab";
import { isValid } from "gtin";
import { set } from "date-fns";

const GetStartedAccountPrefix = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { form, wizardIndex } = useAppSelector(orderSelector);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getValue, setValue] = useState(form.company?.EntityGLN ?? null);
  const [disableBtn, setDisableBtn] = useState(false);
  const { t } = useTranslation(["common"]);
  const userAuth = useAuthContext();
  const userSignupInfo = userAuth.signupInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const validateForm = () => {
    setMessage(null);
    if (!getValue) {
      setMessage("Please enter a barcode");
      return false;
    } else if (!isValidGTIN(getValue)) {
      setMessage("Please enter a valid barcode");
      return false;
    }
    return true;
  };

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
  };

  const search = async () => {
    setMessage(null);
    if (!getValue) {
      setMessage("please enter a barcode"); // Ensure message is set when the box is empty
      return;
    } else {
      setMessage(null); // Reset message
    }
    if (validateForm()) {
      try {
        setLoading(true);
        const { data, status } = await dispatch(fetchCompanyByGtin(getValue));

        if (status === 200) {
          const { Company, isAlreadyClaimed, Prefix, GTIN, error } = data;
          if (isAlreadyClaimed) {
            setMessage(`Error: ${error}`);
            dispatch(setCompany({}));
            setDisableBtn(true);
          } else {
            setDisableBtn(false);
            const updatedCompany = {
              ...Company,
              isAlreadyClaimed:
                form?.signup?.FK_PrefixClaimed === form.company?.EntityGLN
                  ? false
                  : isAlreadyClaimed,
              PrefixID: Prefix.PrefixID,
              GTIN: GTIN,
            };
            dispatch(setCompany(updatedCompany));
          }
        } else {
          dispatch(setCompany([]));
          setMessage("Error: Please contact our Support Team");
        }
      } catch (error) {
        setMessage("Error: Please contact our Support Team");
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    if (!form?.signup?.FK_PrefixClaimed || !form?.acceptTerms) {
      if (!form?.company?.EntityGLN) {
        setMessage("Enter barcode to select product");
        return;
      } else if (!form?.acceptTerms) {
        setMessage("Please accept the term of service");
        return;
      }
      if (form?.company?.EntityGLN) {
        const historyRecord = {
          FK_SignupID: userSignupInfo.SignupID,
          FK_UserID: userSignupInfo.FK_UserID,
          SignupCheckTitle: "User completed Sign-up Wizard Step: PREFIX",
          SignupCheckText: `${userAuth.user.NameFirst} has completed Sign-up Wizard Step: PREFIX`,
        };

        const json = await dispatch(submitClaimCompany(form?.company));
        if (json.status === 204) {
          //SIH Signup History Record
          await Promise.all([dispatch(SihSignupHistory(historyRecord))]);
          dispatch(fetchAccountDetails());
          dispatch(setIndex(2));
          navigate("/GetStarted/AccountReview");
        }
      }
    } else {
      dispatch(setIndex(2));
      navigate("/GetStarted/AccountReview");
    }
  };

  useEffect(() => {
    if (wizardIndex < 1) {
      navigate("/");
    }
    if (form?.signup?.FK_PrefixClaimed) {
      setFormField("acceptTerms", true);
    }
  }, [wizardIndex]);

  const back = () => {
    dispatch(setIndex(0));
    navigate("/GetStarted/AccountDetails");
  };

  return (
    <div className="w-full relative max-w-7xl mx-auto py-10">
      <div className="bg-[#dcebfe] rounded flex items-center px-1 mb-3 gap-2 p-3">
        <div className="w-10 h-10">
          <img
            src="../icon.png"
            alt="icon"
            className="min-w-[40px] w-full h-full object-contain"
          />
        </div>
        <p>
          Need assistance or have questions? Click the chat support icon in the
          bottom right corner for help during signup.
        </p>
      </div>
      <GetStartedTab />
      {loading && (
        <div className={"absolute inset-x-0 mx-auto bottom-0 w-16 h-16"}>
          <Loader width={"w-full"} heigth={"h-full"} />
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setMessage(null);
          if (validateForm()) {
            handleSubmit(onSubmit)(e);
          }
        }}
      >
        <div className="px-4 py-3">
          <h3>{t("common:company_heading").toString()}</h3>
          <div className="flex items-center gap-2 mt-3">
            <input
              {...register("company_code")}
              onChange={(e) => {
                setMessage(null); // Reset message on input change
                setValue(e.target.value);
                if (form?.acceptTerms) {
                  setMessage(
                    "Error: Unselect confirmation but to select again"
                  );
                } else {
                  setMessage("");
                  setValue(e.target.value);
                }
              }}
              disabled={form?.acceptTerms || loading}
              type="text"
              value={getValue || ""}
              placeholder="Enter GTIN"
              className="rounded-md w-full max-w-xs relative px-3 py-2 shadow-sm"
            />

            <button
              disabled={loading || form?.acceptTerms}
              onClick={search}
              className="rounded text-white bg-primary hover:bg-lightPrimary relative px-3 py-2 focus:outline-none"
            >
              Search
            </button>
          </div>
          {errors.company_code && (
            <span className="text-red-500">{errors.company_code?.message}</span>
          )}
          {!!message && (
            <span className="text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
              {message}
            </span>
          )}
          {form?.company?.EntityGLN && (
            <>
              <div className="mt-3">
                <h5>
                  {form?.company?.CompanyName} - {form?.company?.EntityGLN}{" "}
                </h5>
                {form?.company?.isAlreadyClaimed && (
                  <p className="text-red-500">
                    {
                      "Error: This company has already been claimed, please contact our Support Team"
                    }
                  </p>
                )}
              </div>
              {!form?.company?.isAlreadyClaimed && (
                <div className="flex items-center mt-3">
                  <label
                    htmlFor="acceptTerms"
                    className="cursor-pointer inline-flex lg:items-center md:items-center text-[15px] font-normal"
                  >
                    <input
                      disabled={form?.company?.isAlreadyClaimed || loading}
                      value={form?.acceptTerms}
                      onChange={(e) => {
                        setLoading(true);
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          setMessage(null);
                          setFormField("acceptTerms", true);
                          setLoading(false);
                        }
                        if (!isChecked) {
                          setValue(null);
                          setFormField("acceptTerms", false);
                        }
                        if (
                          !isChecked &&
                          form?.company?.EntityGLN &&
                          !form?.company?.isAlreadyClaimed
                        ) {
                          setMessage(null);

                          const updateSignupParams = {
                            SignupID: form?.signup.SignupID,
                            FK_Progress: 1000145,
                            FK_PrefixClaimed: "",
                          };

                          const { status: status_2 } = dispatch(
                            updateSignupInformation(updateSignupParams)
                          );
                          setTimeout(() => {
                            const { status: status_1 } = dispatch(
                              submitClaimCompany(form?.company)
                            );
                          }, 2000);
                          setTimeout(() => {
                            const { status: status_3 } = dispatch(
                              fetchAccountDetails()
                            );
                          }, 3000);
                          setTimeout(() => {
                            setLoading(false);
                          }, 3100);
                        }
                      }}
                      checked={form?.acceptTerms}
                      id="acceptTerms"
                      type="checkbox"
                      className={`hidden peer form-check-input ${
                        errors.acceptTerms ? "is-invalid" : ""
                      }`}
                    />
                    <span className="mr-3 mt-1 peer-checked:bg-gray-800 bg-white h-[16px] min-w-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.7px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-gray-800 rounded-sm"></span>
                    {t("common:company_agreed_text").toString()}
                  </label>
                </div>
              )}
            </>
          )}

          {form?.acceptTerms && (
            <div
              className="mt-2 flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium"></span> To change the selected
                company, uncheck the checkbox to search again.
              </div>
            </div>
          )}

          <div className="mt-6 flex gap-2">
            <button
              disabled={loading}
              onClick={back}
              className="px-3 py-2 uppercase bg-gray-500 hover:bg-lightPrimary rounded text-white"
            >
              {t("common:back_button_text").toString()}
            </button>

            <button
              disabled={loading || disableBtn}
              type="submit"
              className="px-3 py-2 uppercase bg-primary hover:bg-lightPrimary rounded text-white"
            >
              {t("common:next").toString()}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GetStartedAccountPrefix;
