import React, { useContext } from 'react';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItem from './GVDItem';

const GVDItemDetail = ({ group, variant, detail }) => {

  const { selectedDetail, setSelectedDetail } = useContext(SelectedGVDDetailContext);

  const onClick = () => {
    setSelectedDetail({
      GroupingID: group.GroupingID, GroupingDesc: group.GroupingDesc,
      VariantID: variant.VariantID, VariantDesc: variant.VariantDesc,
      DetailID: detail.DetailID, DetailDesc: detail.DetailDesc
    })
  };

  const isSelected = () =>
    selectedDetail.GroupingID === group.GroupingID && selectedDetail.VariantID === variant.VariantID && selectedDetail.DetailID === detail.DetailID;

  return (
    <GVDItem
      description={detail.DetailDesc}
      isSelected={isSelected}
      onClick={onClick} />
  );
};

export default GVDItemDetail;