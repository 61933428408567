import React, {
    Fragment,
    useState,
} from "react";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
    signupApproval,
    fetchSignupHistory,
    approveAccount,
    rejectAccount,
    addAccount,
    setErrorMsg
} from "../../store/slices/signupApprovalSlice";

import styled from "styled-components";
import { format } from "date-fns";
import { Transition, Dialog } from "@headlessui/react";
import Loader from "../../components/loader/Loader";

export const InputText = styled.input`
    padding: 5px;
    outline: none;
    border: none;
    border: 1px solid #707070;
    width: 100%;
`;

export const ChecksHistory = styled.div`
    font-size: 14px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
`;

const ApprovalModal = ({ refreshCallback, modalOpen, closeModal }) => {
    const dispatch = useAppDispatch();
    const { signupDetails, errorMsg, loading, loader } = useAppSelector(signupApproval);

    const [selectedCheck, setSelectedCheck] = useFormFieldOnChange(null);
    const [checkTitle, setCheckTitle, onChangeCheckTitle] =
        useFormFieldOnChange("");
    const [checkText, setCheckText, onChangeCheckText] =
        useFormFieldOnChange("");

    const onChangeSelectedCheck = (e) => {
        const value = e.target.value;
        setSelectedCheck(value);

        const checkOption = signupDetails?.checks?.find(
            (check) => check.ID === value
        );

        setSelectedCheck(checkOption);

        if (checkOption.Order === 9) {
            setCheckTitle("");
        }
    };

    const Actions = () => {
        return (
            <div className="flex items-center gap-2">
                <label className="whitespace-nowrap">Assigned to:</label>
                <select
                    className="rounded border border-gray py-2 mr-2"
                    value={signupDetails?.userassignedto?.ID}
                >
                    {signupDetails?.userslist?.map((signup, index) => (
                        <option key={index} value={signup.ID}>
                            {signup.NameFirst} {signup.NameSurname}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    const addNewCheck = async () => {
        if (selectedCheck === null) {
            setErrorMsg("Please, select an option from the list of checks.");
            return;
        }
        if (selectedCheck.Order === 9 && !checkTitle.trim().length) {
            setErrorMsg("Please, inform a title for the check.");
            return;
        }
        if (!checkText.trim().length) {
            setErrorMsg("Please, inform the details of the check.");
            return;
        }
        dispatch(addAccount(signupDetails?.ID, selectedCheck.ID, checkTitle, checkText)).then(r => {
             dispatch(fetchSignupHistory(signupDetails.ID));
                setCheckTitle("");
                setCheckText("");
        })

    };

    const approveClick = async () => {
        await dispatch(approveAccount(signupDetails.ID))
        refreshCallback();
        closeModal();
    };

    const rejectClick = async () => {
        await dispatch(rejectAccount(signupDetails.ID))
        refreshCallback();
        closeModal();
    };

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-3 text-left align-middle shadow-xl transition-all">
                        {loader ? 
                        <div className="flex justify-center">
                        <Loader /> 
                        </div>:
                        <>
                                <div className="flex justify-between border-b pb-3 border-b-black items-center">
                                    <h5 className="font-semibold text-sm">
                                        {signupDetails?.company?.CompanyName}
                                    </h5>
                                    {signupDetails?.userslist !== undefined &&  signupDetails?.userslist?.length !== 0 && <Actions />}
                                    <div
                                        onClick={closeModal}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="h-[400px] overflow-y-auto px-1">
                                    <div>
                                        <p className="text-sm font-bold border-b border-b-black pb-2">
                                            Details
                                        </p>
                                        <div className="flex flex-col gap-2">
                                            <div>
                                                Tax Number:{" "}
                                                {signupDetails?.company
                                                    ?.TaxNumber ?? "NOT DEFINED"}
                                            </div>
                                            <div>
                                                {signupDetails?.company
                                                    ?.AddressStreet1 ??
                                                    "NOT DEFINED"}
                                            </div>
                                            <div>
                                                {signupDetails?.company
                                                    ?.AddressCity ?? "NOT DEFINED"}
                                            </div>
                                            <div>
                                                {signupDetails?.company
                                                    ?.AddressState ?? "NOT DEFINED"}
                                            </div>
                                            <div>
                                                {signupDetails?.company
                                                    ?.AddressPostCode ??
                                                    "NOT DEFINED"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p className="text-sm font-bold border-b border-b-black pb-2">
                                            User Details
                                        </p>
                                        <div className="flex flex-col gap-2">
                                            <div>
                                                {signupDetails?.user?.NameFirst}{" "}
                                                {signupDetails?.user?.NameSurname}
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    {
                                                        signupDetails?.user
                                                            ?.PhoneMobile
                                                    }
                                                </div>
                                                <div>
                                                    {signupDetails?.user?.Email}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-sm font-bold border-b border-b-black pb-2">
                                                Checks
                                            </p>
                                            <div className="flex flex-col gap-2 mt-2">
                                                <select
                                                    onChange={onChangeSelectedCheck}
                                                    className="rounded border border-gray py-2 px-2"
                                                    value={selectedCheck?.ID}
                                                >
                                                    {signupDetails?.checks?.map(
                                                        (check, key) => (
                                                            <option
                                                                key={key}
                                                                value={check.ID}
                                                            >
                                                                {
                                                                    check.SelectionName
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <input
                                                    className="rounded border border-gray py-2 px-2"
                                                    placeholder="Title"
                                                    style={{
                                                        display:
                                                            selectedCheck?.Order ==
                                                            9
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                    onChange={onChangeCheckTitle}
                                                    value={checkTitle}
                                                    type="text"
                                                />
                                                <textarea
                                                    placeholder="Description"
                                                    className="rounded border border-gray py-2 px-2"
                                                    onChange={onChangeCheckText}
                                                    value={checkText}
                                                    rows={5}
                                                    cols={13}
                                                />
                                                <button
                                                    className="bg-primary text-white w-full py-1.5"
                                                    onClick={addNewCheck}
                                                >
                                                    Save
                                                </button>
                                                {errorMsg !== null && (
                                                    <small>
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {errorMsg}
                                                        </span>
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-sm font-bold border-b border-b-bottom pb-2">
                                                History
                                            </p>
                                            <div className="flex flex-col gap-2">
                                                {signupDetails?.signuphistory?.map(
                                                    (history, index) => (
                                                        <>
                                                            <b>ID:</b> {history?.ID}
                                                            <span>
                                                                {" "}
                                                                -{" "}
                                                                {format(
                                                                    new Date(
                                                                        history?.DateCreated
                                                                    ),
                                                                    "dd/MM/yyyy HH:mm:ss"
                                                                )}
                                                            </span>
                                                            <br />
                                                            <b>Check:</b>{" "}
                                                            {
                                                                history?.signupcheck
                                                                    ?.SelectionName
                                                            }
                                                            <br />
                                                            <b>Title:</b>{" "}
                                                            {history?.SignupCheckTitle ??
                                                                "NOT DEFINED"}
                                                            <br />
                                                            <b>User: </b>
                                                            {
                                                                history?.user
                                                                    ?.NameFirst
                                                            }{" "}
                                                            {
                                                                history?.user
                                                                    ?.NameSurname
                                                            }
                                                            <br />
                                                            <b>Description: </b>
                                                            {
                                                                history?.SignupCheckText
                                                            }
                                                        </>
                                                    )
                                                )}
                                                {!signupDetails?.signuphistory
                                                    ?.length && (
                                                    <i>
                                                        <b>No records</b>
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-end items-center mt-3">
                                            {signupDetails?.readonly === false && (
                                                <div className="flex gap-2">
                                                    <button
                                                        className="bg-gray-500 text-white px-3 py-1.5 rounded"
                                                        onClick={closeModal}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        className="bg-red-500 text-white px-3 py-1.5 rounded"
                                                        onClick={rejectClick}
                                                    >
                                                        {loading ? <Loader /> : 'Reject'}
                                                    </button>
                                                    <button
                                                        className="bg-primary text-white px-3 py-1.5 rounded"
                                                        onClick={approveClick}
                                                    >
                                                        {loading ? <Loader /> : 'Approve'}
                                                    </button>
                                                </div>
                                            )}
                                            {signupDetails?.readonly === true && (
                                                <small>
                                                    <i>
                                                        <b style={{ color: "red" }}>
                                                            This record has been assigned to&nbsp;
                                                            {
                                                                signupDetails?.userassignedto?.NameFirst
                                                            }
                                                            &nbsp;
                                                            {
                                                                signupDetails?.userassignedto?.NameSurname
                                                            }
                                                        </b>
                                                    </i>
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                </>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ApprovalModal;
