import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './EntitySubscription.css';

const EntitySubscription = () => {
  return (
    <PageSkeletonComponent>

      <h1>ENTITY - Bring Back Subscription Page</h1>
      <hr />

      <h2>Purpose of Page</h2>
      From here the use can:
      <li>Check their Bring Back Subscription</li> 
      <li>Update their Bring Back Subscription</li><br />
      The user can set... don't know how we are going to start the process yet... chicken/egg...

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default EntitySubscription;