import React from 'react';
import { useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { assignParentCompanyToPrefix, createParentCompany, searchParentCompanyByName } from '../../../api/BarcodeScan';
import ModalActions from '../../../components/PopupDialog/ModalActions';
import ModalAction from '../../../components/PopupDialog/ModalAction';
import ModalDialog from '../../../components/PopupDialog/ModalDialog';
import ModalHeader from '../../../components/PopupDialog/ModalDialogHeader';
import { useFormFieldOnChange } from '../../../utils/FormHooks';

const CompromisedModal = ({
  GTIN,
  showModal, onClose, ...props
}) => {

  const _onClose = () => {
    onClose();
    resetState();
  };

  const resetState = () => {
    setLoading(false);
    setCompanies(null)
    setErrorMsg(null);
    setSelectedCompany(null);
    setShowAddNewCompanyForm(false);
    setNewCompanyName('');
  };

  const [companyName, setCompanyName, onChangeCompanyName] = useFormFieldOnChange('');
  const [newCompanyName, setNewCompanyName, onChangeNewCompanyName] = useFormFieldOnChange('');
  const [companies, setCompanies] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [loading, setLoading] = useState(false);

  const [showAddNewCompanyForm, setShowAddNewCompanyForm] = useState(false);

  const searchCompanies = async (e) => {
    e.preventDefault();

    if (companyName.trim().length === 0 || loading === true) return;

    setLoading(true);
    setCompanies(null)
    setErrorMsg(null);
    setShowAddNewCompanyForm(false);
    const res = await searchParentCompanyByName(companyName);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) return;

    if (res.data.length === 0) {
      setErrorMsg('No companies found');
    }

    setCompanies(res.data);
  }

  const assignCompanyTo = async (e) => {
    setErrorMsg(null);
    if (selectedCompany === null && !showAddNewCompanyForm) {
      setErrorMsg('A company has not been selected');
      return;
    };

    if (showAddNewCompanyForm && newCompanyName.trim().length <= 0) return;

    let companyID = selectedCompany?.CompanyID;

    setLoading(true);
    if (showAddNewCompanyForm) {
      const createCompany = await createParentCompany(newCompanyName);
      if (createCompany.status !== HTTP_STATUS_CODES.OK) {
        setErrorMsg('An occurred while creating the parent company');
        setLoading(false);
        return;
      }

      companyID = createCompany.data.CompanyID;
    }

    const res = await assignParentCompanyToPrefix(companyID, GTIN);
    setLoading(false);

    if (res.status === HTTP_STATUS_CODES.OK) {
      _onClose();
    } else {
      setErrorMsg('An occurred while assigning prefix to the parent company')
    }

  };

  const selectCompany = (company) => {
    setErrorMsg(null)
    setSelectedCompany(company);
  }

  return (
    <ModalDialog showModal={showModal} onClose={_onClose}>
      <ModalHeader header="Search for compromised companies" />
      <div className="ProductScanCompromisedForm">
        <form className="" onSubmit={searchCompanies}>
          <input
            autoFocus
            onChange={onChangeCompanyName}
            value={companyName}
            className="GTIN" type="text"
            placeholder="Enter the company name" />
          &nbsp;
          <input disabled={loading === true} onClick={searchCompanies} className="Search" type="button" value="Search" />
        </form>
        {companies?.map((comp, index) => (
          <div className="ParentCompanyContainer" key={index}>
            <input onChange={() => { selectCompany(comp) }} checked={selectedCompany?.CompanyID === comp.CompanyID} type="checkbox" /> <span>{comp.CompanyName}</span>{comp.EntityGLN !== null ? ' - ' + comp.EntityGLN : ''}
          </div>
        ))}
        {showAddNewCompanyForm === true && (
          <div>
            <input
              autoFocus
              style={{ marginTop: '10px' }}
              onChange={onChangeNewCompanyName}
              value={newCompanyName}
              type="text"
              className="GTIN"
              placeholder="Company name" />
          </div>
        )}
        {loading === true && (
          <span className="Loading">Loading...</span>
        )}
        {errorMsg !== null && (
          <span className="ErrorMsg">
            {errorMsg}
          </span>
        )}
      </div>
      <ModalActions>
        <ModalAction disabled={companies === null} onClick={() => setShowAddNewCompanyForm(true)} description="Add New" />
        <ModalAction disabled={loading === true} onClick={assignCompanyTo} description="Confirm" />
        <ModalAction onClick={_onClose} description="Close" />
      </ModalActions>
    </ModalDialog>
  );
};

export default CompromisedModal;