import { getAuthRequest } from "../../api/API";
import { getToken } from "../../provider/AuthProvider";

/*const listProducts = async (skip, limit, sortInfo, active, filterValue) => {
  try {
    let queryString = 'product/listProduct?page=' + skip + '&limit=' + limit + "&active="+active;
    if (sortInfo) {
      queryString += '&sort=' + JSON.stringify(sortInfo);
    }
    if(filterValue){
      queryString += '&filter=' + JSON.stringify(filterValue);
    }

    return await getAuthRequest(getToken()).post(queryString);
  } catch (error) {
    return error;
  }
};*/

const listProducts = async (ParentCompanyID,filter,page,limit) => {
  try {
    let queryString = 'members/listProductDetail?page=' + page;
    return await getAuthRequest(getToken()).post(queryString, {ParentCompanyID,filter,limit});
  } catch (error) {
    return error;
  }
};

const reclassifyProduct = async (GTINs, BRICK) => {
  try {
    return await getAuthRequest(getToken()).post('productgrid/reclassify', {
      GTIN: GTINs, BRICK
    });
  } catch (error) {
    return error.response;
  }
};

const listSegments = async () => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/segments');
  } catch (error) {
    return error.response;
  }
};

const listFamilies = async (segmentId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/families/' + segmentId);
  } catch (error) {
    return error.response;
  }
};

const listClasses = async (familyId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/classes/' + familyId);
  } catch (error) {
    return error.response;
  }
};

const listBricks = async (classId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/bricks/' + classId);
  } catch (error) {
    return error.response;
  }
};

const listBrands = async (companyID) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/brands/' + companyID);
  } catch (error) {
    return error.response;
  }
};

const assignBrand = async (GTINs, brandID) => {
  try {
    return await getAuthRequest(getToken()).post('productgrid/assignBrand', {
      GTIN: GTINs, BrandID: brandID
    });
  } catch (error) {
    return error.response;
  }
};

export { listProducts, reclassifyProduct, listSegments, listFamilies, listClasses, listBricks, listBrands, assignBrand };