import React, { useContext, useEffect, useState } from 'react';
import { getGroupVarDetails } from '../../../api/GroupVariantDetail';
import { FunctionalGroupWordsContext } from './FuncGroupContext';
import { SelectedBrickContext } from './SelectedBrickContext';

const FuncGroupVarDetailContainer = ({ group, variant }) => {

  const [details, setDetails] = useState([]);

  const [funcGroupState] = useState(group);

  const [funcVariantState] = useState(variant);

  const { selectedBrick } = useContext(SelectedBrickContext);

  const { selectedVariant, setSelectedVariant } = useContext(FunctionalGroupWordsContext);

  useEffect(function () {
    fetchVariants();
  }, [funcGroupState]);

  const fetchVariants = async () => {
    const reqDetails = await getGroupVarDetails(selectedBrick, funcGroupState.Group, funcVariantState.Variant);
    setDetails(reqDetails !== false ? reqDetails.data : []);
  };

  const onClickVar = (funcDetail) => (e) => setSelectedVariant({
    BrickID: selectedBrick, Group: funcGroupState.Group, Variant: funcVariantState.Variant, Detail: funcDetail.Detail
  });

  const getDetailStyle = (item) => {
    return selectedVariant?.Group === funcGroupState.Group &&
      selectedVariant?.Variant === funcVariantState.Variant &&
      selectedVariant?.Detail === item.Detail ? { backgroundColor: '#337d63', color: 'white' } : {};
  };

  return details.map((item, key) => (
    <div style={getDetailStyle(item)} key={key} onClick={onClickVar(item)} className="FunctionalGroupVarComp">
      <div style={{ flexGrow: '1' }}>{item.Detail}</div>
      <button style={{ marginLeft: '10px' }}>Edit</button>
    </div>
  ));
};

export default FuncGroupVarDetailContainer;