import React, {useEffect, useState} from "react";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import {useParams} from "react-router-dom";
import {fetchGS1ProductDetails} from "../../store/slices/productSlice";
import {useAppDispatch} from "../../store/store";
import defaultColumns from "../SupplierProductsPage/productGridColumns";
import ReactDataGrid, {filterTypes} from "@inovua/reactdatagrid-enterprise";
import {setLimit, setPage} from "../../store/slices/productsPageSlice";


const AuditProductGS1Data = () => {
    const { GTIN } = useParams();
    const dispatch = useAppDispatch();
    const [productData, setProductData] = useState([]);

    const defaultColumns = [
        { name: 'key', header: 'ProductHub Fields', minWidth: 50, defaultFlex: 2 },
        { name: 'value', header: 'GS1 AUS', minWidth: 50, defaultFlex: 2 },
        { name: 'valueUs', header: 'GS1 US', minWidth: 50, defaultFlex: 2 },
        { name: 'valueAll', header: 'GS1 All', minWidth: 50, defaultFlex: 2 },
    ];

    useEffect(() => {
        fetchData();
    }, [GTIN]);

    const fetchData = async () => {
        const productDetails =  await dispatch(fetchGS1ProductDetails(GTIN));
        if (Object.keys(productDetails).length > 0) {
            const arrayOfObjects = Object.entries(productDetails).map(([key, value]) => ({
                key: `${key} : ${value}`,
                val: null,
                valueUs: null,
                valueAll: null
            }));
            setProductData(arrayOfObjects)
            console.log('arrayOfObjects', arrayOfObjects)
        }

    };

    return (

        <div className="w-full mx-auto">
            <DashboardHeader
                title={""}
                nav={[]}
            />
            <div className="relative overflow-x-auto">
                <ReactDataGrid
                    licenseKey={'AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988'}
                    idProperty="ProductID"
                    style={{marginTop: 10, minHeight: '90vh'}}
                    columns={defaultColumns}
                    dataSource={productData}
                />
            </div>

        </div>
    );
};

export default AuditProductGS1Data;
