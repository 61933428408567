import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import PrivateRoute from "./PrivateRoute";

export default function SupplierRoute({ element }) {
  const auth = useAuthContext();

  return auth.isSupplier ? (
    <PrivateRoute element={element} />
  ) : (
    <Navigate to={"/DashboardHome"} />
  );
}
