export const SideMenuHome = () => {
  return [
    {
      // Logout sub-item under My Profile
      title: "Login",
      name: "Login",
      parent: true,
      link: "/SignIn",
    },
  ];
};
