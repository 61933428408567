import { getAuthRequest } from "../../api/API";
import { getToken } from "../../provider/AuthProvider";

export type GroupModel = {
  GroupingDesc: string,
  GroupingID: number,
  Total: number
};

export type VariantModel = {
  VariantDesc: string,
  VariantID: number,
  Total: number
};

export type DetailModel = {
  DetailDesc: string,
  DetailID: number,
  Total: number
};

export const GVDEditorAPI = {
  getGroups: async (BrickID: string) => {
    try {
      return await getAuthRequest(getToken()).post<GroupModel[]>('pwd/getGroups', {
        BrickID: parseInt(BrickID.toString())
      });
    } catch (error) {
      return error;
    }
  },

  getGroupVars: async (BrickID: string, Group: number) => {
    try {
      return await getAuthRequest(getToken()).post<VariantModel[]>('pwd/getGroupVars', {
        BrickID: parseInt(BrickID.toString()),
        Group: Group
      });
    } catch (error) {
      return error;
    }
  },

  getGroupVarDetails: async (BrickID: string, Group: number, Variant: number) => {
    try {
      return await getAuthRequest(getToken()).post<DetailModel[]>('pwd/getGroupVarDetails', {
        BrickID: parseInt(BrickID.toString()),
        Group: Group,
        Variant: Variant
      });
    } catch (error) {
      return error;
    }
  },

  newGroup: async (description: string, brick: string) => {
    try {
      return await getAuthRequest(getToken()).put('gvdeditor/group', {
        BrickID: brick,
        description: description
      });
    } catch (error) {
      return error;
    }
  },

  renameGroup: async (groupId: number, description: string) => {
    try {
      return await getAuthRequest(getToken()).post('gvdeditor/group/' + groupId, {
        description
      });
    } catch (error) {
      return error;
    }
  },

  deleteGroup: async (groupId: number) => {
    try {
      return await getAuthRequest(getToken()).delete('gvdeditor/group/' + groupId);
    } catch (error) {
      return error;
    }
  },

  newVariant: async (description: string, groupId: number) => {
    try {
      return await getAuthRequest(getToken()).put('gvdeditor/variant/' + groupId, {
        description
      });
    } catch (error) {
      return error;
    }
  },

  renameVariant: async (variantId: number, description: string) => {
    try {
      return await getAuthRequest(getToken()).post('gvdeditor/variant/' + variantId, {
        description
      });
    } catch (error) {
      return error;
    }
  },

  deleteVariant: async (variantId: number) => {
    try {
      return await getAuthRequest(getToken()).delete('gvdeditor/variant/' + variantId);
    } catch (error) {
      return error;
    }
  },

  newDetail: async (description: string, variantId: number) => {
    try {
      return await getAuthRequest(getToken()).put('gvdeditor/detail/' + variantId, {
        description
      });
    } catch (error) {
      return error;
    }
  },

  renameDetail: async (detailId: number, description: string) => {
    try {
      return await getAuthRequest(getToken()).post('gvdeditor/detail/' + detailId, {
        description
      });
    } catch (error) {
      return error;
    }
  },

  deleteDetail: async (groupId: number) => {
    try {
      return await getAuthRequest(getToken()).delete('gvdeditor/detail/' + groupId);
    } catch (error) {
      return error;
    }
  }
};