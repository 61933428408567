import { Dialog, Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

const AssignBrandDialog = ({assignBrandDialog,setAssignBrandDialog}) => {
    const people = [
        { name: "Wade Cooper" },
        { name: "Arlene Mccoy" },
        { name: "Devon Webb" },
        { name: "Tom Cook" },
        { name: "Tanya Fox" },
        { name: "Hellen Schmidt" },
    ];

    const [selected, setSelected] = useState(people[0]);
    return (
        <Transition show={assignBrandDialog} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={() => setAssignBrandDialog(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full w-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl p-6 bg-white text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-end items-center">
                                    <div
                                        onClick={() => setAssignBrandDialog(false)}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex w-full h-[312px] flex-col">
                                    <div>
                                        <h1 className="text-lg pb-3 font-bold text-center">
                                            Assign Brand
                                        </h1>
                                        <div className="w-full max-w-md mx-auto">
                                            <div className="flex items-center gap-5">
                                                <p className="text-sm">Brand:</p>
                                                <Listbox
                                                    value={selected}
                                                    onChange={setSelected}
                                                >
                                                    <div className="relative w-full">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                                            <span className="block truncate font-bold">
                                                                {selected.name}
                                                            </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-50 mt-1 max-h-48 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                                                {people.map(
                                                                    (
                                                                        person,
                                                                        personIdx
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                personIdx
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-900"
                                                                                }`
                                                                            }
                                                                            value={
                                                                                person
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate ${
                                                                                            selected
                                                                                                ? "font-medium"
                                                                                                : "font-normal"
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            person.name
                                                                                        }
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex grow justify-center items-end gap-4">
                                        <button onClick={() => setAssignBrandDialog(false)} className="bg-gray-400 rounded-lg py-2 px-5 text-white">
                                            Cancel
                                        </button>
                                        <button className="bg-primary rounded-lg  py-2 px-5 text-white">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AssignBrandDialog;
