import React, { useContext, useEffect, useState } from 'react';
import { getUnassignedWords } from '../../../api/GroupVariantDetail';
import DataTable, { Column } from '../../components/DataTable';
import SectionContainer from '../../components/SectionContainer';
import { SelectedBrickContext } from './SelectedBrickContext';
import { DataTableSelectedRowsContext, DataTableSelectedRowsContextDefault } from '../../components/DataTableSelectedRowsContext';
import UnassignedWordsGVDForm from './UnassignedWordsGVDForm';

const UnassignedWordsContainer = () => {

  const { selectedBrick } = useContext(SelectedBrickContext);

  const [words, setWords] = useState([]);

  const [selectedWords, setSelectWords] = useState([]);

  const fetchAssignedWords = async () => {
    const assignedWords = await getUnassignedWords(selectedBrick);
    setWords(assignedWords !== false ? assignedWords.data : []);
  };

  useEffect(() => {
    selectedBrick?.trim().length && fetchAssignedWords();
  }, [selectedBrick]);

  const onClickRow = () => { };

  const containerColumns = [
    Column({ title: 'Words', rowProperty: 'Words', beforePrint: (value) => value.join(' - ') })
  ];

  return (
    <SectionContainer header="Unassigned Words">
      <DataTableSelectedRowsContext.Provider value={{
        selectedRows: selectedWords, setSelectedRows: setSelectWords, resetSelectedRows: () => fetchAssignedWords()
      }}>
        <DataTable
          onClickRow={onClickRow}
          columns={containerColumns}
          actionFooter={<UnassignedWordsGVDForm />}
          rowIdentifier={'WordMatchID'}
          showCheckboxSelection={true}
          multipleSelection={true}
          rowData={words} />
      </DataTableSelectedRowsContext.Provider>

    </SectionContainer>
  );
};

export default UnassignedWordsContainer;