import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import {
    SihSignupHistory,
    addForm,
    fetchAccountDetails,
    fetchCountry,
    orderSelector,
    setIndex,
    submitAccountReview
} from "../../store/slices/getStartedSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import GetStartedTab from "./GetStartedTab";

const capitalize = (str) => {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
};

const GetStartedAccountReview = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userAuth = useAuthContext();
    const userSignupInfo = userAuth.signupInfo;

    const { form, country, industries, annualTurnover, numberOfEmployees } =
        useAppSelector(orderSelector);

    useEffect(() => {
        if (form?.company === null) {
            dispatch(setIndex(1));
            navigate("/GetStarted/AccountPrefix");
        }
        fetchCountryData()
    }, [])

    const fetchCountryData = async () => {
        await dispatch(fetchCountry());
        let data = Object.assign({}, form);
        data = {
            ...data,
            account_type: "supplier"
        }
        dispatch(addForm(data));
        await Promise.all([
            dispatch(fetchAccountDetails()),
        ]);
    }

    const { t } = useTranslation(["common"]);

    const back = () => {
        dispatch(setIndex(1));
        navigate("/GetStarted/AccountPrefix");
    };

    const navigateTo = async () => {
        const historyRecord = {
            FK_SignupID: userSignupInfo.SignupID,
            FK_UserID: userSignupInfo.FK_UserID,
            SignupCheckTitle: "User completed Sign-up Wizard Step: REVIEW",
            SignupCheckText: `${userAuth.user.NameFirst} has completed Sign-up Wizard Step: REVIEW`
        }

        const json = await dispatch(submitAccountReview());
        if (json.status === 204) {
            //SIH Signup History Record
            await Promise.all([
                dispatch(SihSignupHistory(historyRecord))
            ])
            dispatch(setIndex(3));
            navigate("/GetStarted/AccountPayment");
        }
    };

    const turnover =
        (annualTurnover || []).filter(
            (i) => i.SystemSelectID === form?.annual_turnover
        )?.[0] || {};
    const industry =
        (industries || []).filter(
            (i) => i.SystemSelectID === form?.industry
        )?.[0] || {};
    const number_of_employees =
        (numberOfEmployees || []).filter(
            (i) => i.SystemSelectID === form?.number_of_employees
        )?.[0] || {};

    const selectedCountry =
        (country || []).find((i) => i.CountryCode === form?.country_id) ||
        {};

    return (
        <div className="w-full max-w-7xl mx-auto py-10">
            <div className="bg-[#dcebfe] rounded flex items-center px-1 mb-3 gap-2 p-3">
                <div className="w-10 h-10">
                    <img src="../icon.png" alt="icon" className="min-w-[40px] w-full h-full object-contain" />
                </div>
                <p>
                    Need assistance or have questions?
                    Click the chat support icon in the bottom
                    right corner for help during signup.
                </p>
            </div>
            <GetStartedTab />
            <div className="py-3 px-3 bg-white mt-3 rounded-xl">
                <div>
                    <h1 className="font-semibold text-lg">
                        {t("common:details").toString()}
                    </h1>
                    <div className="ml-5 mt-3">
                        <h1 className="font-semibold text-base">
                            Contact Details
                        </h1>
                        <div className="lg:w-[460px] md:w-[460px]">
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Name:</p>
                                <span className="font-bold">
                                    {form?.first_name} {form?.last_name}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Preferred Name:</p>
                                <span className="font-bold">
                                    {form?.prefered_name}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Position:</p>{" "}
                                <span className="font-bold">
                                    {form?.position}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">Direct:</p>{" "}
                                <span className="font-bold">
                                    {form?.direct_landline_number}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Mobile:</p>{" "}
                                <span className="font-bold">
                                    {form?.mobile_number}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5 mt-3">
                        <h1 className="font-semibold text-base">
                            Company Details
                        </h1>
                        <div className="lg:w-[460px] md:w-[460px]">
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Company Name:</p>
                                <span className="font-bold">
                                    {form?.company_name}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Trading Name:</p>
                                <span className="font-bold">
                                    {form?.trading_name}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">ABN:</p>
                                <span className="font-bold">
                                    {form?.company_abn}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Number of Employees:</p>
                                <span className="font-bold">
                                    {number_of_employees?.SelectionName}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Account Type:</p>
                                <span className="font-bold">
                                    {capitalize(form?.account_type)}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Annual Turnover:</p>
                                <span className="font-bold">
                                    {turnover?.SelectionName}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">Industry:</p>{" "}
                                <span className="font-bold">
                                    {industry?.SelectionName}{" "}
                                </span>
                            </div>
                            {form?.industry === "1000153" && (
                                <div className="text-sm grid grid-cols-2 gap-3">
                                    {" "}
                                    <p className="text-end">Other:</p>{" "}
                                    <span className="font-bold">
                                        {form.OtherIndustry}
                                    </span>
                                </div>
                            )}
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Phone:</p>
                                <span className="font-bold">
                                    {form?.company_phone_number}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                <p className="text-end">Website:</p>
                                <span className="break-all font-bold">
                                    {form?.website}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5 mt-3">
                        <h1 className="font-semibold text-base">
                            Company Address Details
                        </h1>
                        <div className="lg:w-[460px] md:w-[460px]">
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">Address 1:</p>{" "}
                                <span className="font-bold">
                                    {form?.company_address_1}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">Address 2:</p>{" "}
                                <span className="font-bold">
                                    {form?.company_address_2}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">City:</p>{" "}
                                <span className="font-bold">{form?.city}</span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">State:</p>{" "}
                                <span className="font-bold">
                                    {form?.state || ""}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">PostCode:</p>{" "}
                                <span className="font-bold">
                                    {form?.postcode}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">Country:</p>{" "}
                                <span className="font-bold">
                                    {selectedCountry?.CountryName || ""}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5 mt-3">
                        <h1 className="font-semibold text-base">Prefix</h1>
                        <div className="lg:w-[460px] md:w-[460px]">
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">
                                    Claimed GTIN (Barcode):
                                </p>{" "}
                                <span className="font-bold">
                                    {form?.signup?.ClaimedGTIN}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">
                                    Claimed Prefix:
                                </p>{" "}
                                <span className="font-bold">
                                    {form?.prefix?.Prefix}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">
                                    GS1 Company Name:
                                </p>{" "}
                                <span className="font-bold">
                                    {form?.company?.CompanyName}
                                </span>
                            </div>
                            <div className="text-sm grid grid-cols-2 gap-3">
                                {" "}
                                <p className="text-end">GS1 Entity GLN:</p>{" "}
                                <span className="font-bold">
                                    {form?.company?.EntityGLN}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 flex justify-center gap-2">
                    <button
                        onClick={back}
                        className="px-3 py-2 uppercase bg-gray-500 hover:bg-lightPrimary rounded text-white"
                    >
                        {t("common:back_button_text").toString()}
                    </button>

                    <button
                        onClick={navigateTo}
                        className="px-3 py-2 uppercase bg-primary hover:bg-lightPrimary rounded text-white"
                    >
                        {t("common:next").toString()}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default GetStartedAccountReview;
