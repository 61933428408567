import React, { useContext } from 'react';
import { assignParentCompanyToCompany } from '../../../api/BarcodeScan';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import ParentCompanyModal from './ParentCompanyModal';
import { BarcodeSearchButton } from './ProductScanNewStyle';

const ButtonShowParentModal = ({ CompanyID, refreshCallback,openCompromisedModal,closeCompromisedModal,showCompromisedModal }) => {

  return (
    <>
      <button className='rounded bg-primary px-3 py-1.5 text-white' onClick={openCompromisedModal}>Parent</button>
      <ParentCompanyModal refreshCallback={refreshCallback} assignToCallbackParam={CompanyID} closeCompromisedModal={closeCompromisedModal} showCompromisedModal={showCompromisedModal} assingToCallBack={assignParentCompanyToCompany} />
    </>
  );
};

export default ButtonShowParentModal;