import React from 'react';

const DataTableSelectedRowsContextDefault = {
  selectedRows: [],
  setSelectedRows: (newRows) => { },
  resetSelectedRows: () => { }
};

const DataTableSelectedRowsContext = React.createContext(DataTableSelectedRowsContextDefault);

export { DataTableSelectedRowsContext, DataTableSelectedRowsContextDefault };