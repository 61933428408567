import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";
import {fetchCountryOrigin, fetchFamily, fetchGrouping} from "../store/slices/productSlice";

const listSegments = async () => {
  try {
    return await getAuthRequest(getToken()).get('supplier/products/listSegments');
  } catch (error) {
    return false;
  }
};

const listFamilies = async (segment) => {
  try {
    return await getAuthRequest(getToken()).get('supplier/products/listFamilies/' + segment);
  } catch (error) {
    return false;
  }
};

const listClasses = async (segment, family) => {
  try {
    return await getAuthRequest(getToken()).get(`supplier/products/listClasses/${segment}/${family}`);
  } catch (error) {
    return false;
  }
};

const listBricks = async (segment, family, brickClass) => {
  try {
    return await getAuthRequest(getToken()).get(`supplier/products/listBricks/${segment}/${family}/${brickClass}`);
  } catch (error) {
    return false;
  }
};

const listGroups = async (Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroups`, {
      Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listGroupVars = async (Group, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroupVars`, {
      Group, Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listGroupVarDetails = async (Group, Variant, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroupVarDetails`, {
      Group, Variant, Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listProducts = async (GVD, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listProducts`, { GVD, Segment, Family, Class, Brick });
  } catch (error) {
    return false;
  }
};

const listProductsUnassigned = async (Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listProductsUnassigned`, { Segment, Family, Class, Brick });
  } catch (error) {
    return false;
  }
};

const getProductDetails = async (GTIN) => {
  try {
    return await getAuthRequest(getToken()).get(`members/productdetail/${GTIN}`);
  } catch (error) {
    return error;
  }
};

const getProductBrand = async (FK_CompanyID,ValidBrand) => {
  try {
    return await getAuthRequest(getToken()).post(`tab/BRAG`, {FK_CompanyID,ValidBrand });
  } catch (error) {
    return error;
  }
};

const getProductCountryOrigin = async () => {
  try {
    return await getAuthRequest(getToken()).post(`tab/COUG`);
  } catch (error) {
    return error;
  }
};

const getProductSegmentInfo = async () => {
  try {
    return await getAuthRequest(getToken()).get(`classification/segment`);
  } catch (error) {
    return error;
  }
};

const getProductFamily = async (FK_CompanyID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/family/${FK_CompanyID}`);
  } catch (error) {
    return error;
  }
};

const getProductClass = async (FamilyID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/class/${FamilyID}`);
  } catch (error) {
    return error;
  }
};


const getProductBrickInfo = async (FK_ClassID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/brick/${FK_ClassID}`);
  } catch (error) {
    return error;
  }
};

const getProductGroupingInfo = async (FK_BrickID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/grouping/${FK_BrickID}`);
  } catch (error) {
    return error;
  }
};

const getProductVariantInfo = async (FK_GroupingID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/variant/${FK_GroupingID}`);
  } catch (error) {
    return error;
  }
};

const getProductDetailInfo = async (FK_VariantID) => {
  try {
    return await getAuthRequest(getToken()).get(`classification/detail/${FK_VariantID}`);
  } catch (error) {
    return error;
  }
};


const submitProductDetails = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(`tab/PROP`, data);
  } catch (error) {
    return error;
  }
};

const submitProductDetailID = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(`classification/getClassification`, data);
  } catch (error) {
    return error;
  }
};

const getAttributeTypesByBrick = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('members/attributeTypesByBrick', data);
  } catch (error) {
    return error;
  }
};

const checkCompanyClaimed = async (GTIN) => {
  try {
    return await getAuthRequest(getToken()).get(`members/Signup/checkCompanyClaimed/${GTIN}`);
  } catch (error) {
    return error;
  }
};

const checkBarCode = async (barCode) => {
  try {
    return await getAuthRequest(getToken()).get(`members/scanProduct/${barCode}`);
  } catch (error) {
    return error;
  }
};

const getUserCompanyData = async (CompanyId) => {
  try {
    return await getAuthRequest(getToken()).post('tab/COMG',{ CompanyID: CompanyId });
  } catch (error) {
    return error;
  }
};

const getAttributeValuesByTypeBrick = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('members/attributeValuesByTypeBrick', data);
  } catch (error) {
    return error;
  }
};

const getProductAttributes = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('members/attributesProduct', data);
  } catch (error) {
    return error;
  }
};

const submitProductAttributesValues = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('tab/LBAPP', data);
  } catch (error) {
    return error;
  }
};

const getProductPROG = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('tab/PROG', data);
  } catch (error) {
    return error;
  }
};

const getunitOfMeasures = async () => {
  try {
    return await getAuthRequest(getToken()).post('members/unitOfMeasure');
  } catch (error) {
    return error;
  }
};




export {
  listSegments,
  listFamilies,
  listClasses,
  listBricks,
  listGroups,
  listGroupVars,
  listGroupVarDetails,
  listProducts,
  listProductsUnassigned,
  getProductDetails,
  getProductBrand,
  getProductSegmentInfo,
  getProductCountryOrigin,
  getProductFamily,
  getProductClass,
  getProductBrickInfo,
  getProductGroupingInfo,
  getProductVariantInfo,
  getProductDetailInfo,
  submitProductDetails,
  submitProductDetailID,
  getAttributeTypesByBrick,
  getAttributeValuesByTypeBrick,
  getProductAttributes,
  submitProductAttributesValues,
  getProductPROG,
  getunitOfMeasures,
  checkCompanyClaimed,
  checkBarCode,
  getUserCompanyData,
};