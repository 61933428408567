import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { Brands } from '../../api/Brands';
import { getAccountContactDetailsForLoginUser,updateCompanyImageDetail } from '../../api/SignUp';

export interface brandState {
  processing: boolean;
  succeeded: boolean;
  failed: boolean;
  error: string;
  brands:any;
  parentCompany:any;
  modified:boolean;
  isSelectedRow:number;
  filterValues:any;
  substituteFilterValues:any;
  limit: any;
  perPage: any;
  active: any;
  allBrands: any;
}

export const initialState: brandState = {
  processing: false,
  succeeded: false,
  failed: false,
  active: 0,
  error: "",
  brands:null,
  allBrands:null,
  parentCompany:null,
  modified:false,
  isSelectedRow:null,
  limit: 10,
  perPage:1,
  filterValues: [
    {name: "BrandID",type: "number",operator: "contains", value:null},
    {name: "Brand", type: "string",operator: "contains",value: ""},
    {name: "BrandSub", type: "string",operator: "contains",value: ""},
    {name: "RecordType", type: "string",operator: "eq",value: "VER"}
  ],
  substituteFilterValues: [
    {name: "BrandID",type: "number",operator: "eq", value:null},
    {name: "Brand", type: "string",operator: "startsWith",value: ""},
    {name: "BrandSub", type: "string",operator: "startsWith",value: ""},
    {name: "RecordType", type: "string",operator: "eq",value: ""}
  ],
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setError: (state: brandState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setSucceeded: (state: brandState, { payload }: PayloadAction<any>) => {
      state.succeeded = payload;
    },
    setFailed: (state: brandState, { payload }: PayloadAction<any>) => {
      state.failed = payload;
    },
    setProcessing: (state: brandState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setModified: (state: brandState, { payload }: PayloadAction<any>) => {
      state.modified = payload;
    },
     setIsSelectedRow: (state: brandState, { payload }: PayloadAction<any>) => {
      state.isSelectedRow = payload;
    },
    setFilterValues: (state: brandState, { payload }: PayloadAction<any>) => {
      state.filterValues = payload;
    },
    resetState: (state: brandState) => {
      return initialState;
    },
    setBrands: (state: brandState, { payload }: PayloadAction<any>) => {
     state.brands = payload;
    },
     setallBrands: (state: brandState, { payload }: PayloadAction<any>) => {
     state.allBrands = payload;
    },
    setParentCompany: (state: brandState, { payload }: PayloadAction<any>) => {
      state.parentCompany = payload;
    },
     setPage: (state: brandState, { payload }: PayloadAction<any>) => {
      state.perPage = payload;
    },
    setLimit: (state: brandState, { payload }: PayloadAction<any>) => {
      state.limit = payload;
    },
    setActive: (state: brandState, { payload }: PayloadAction<any>) => {
      state.active = payload;
      const recordTypeIndex = state.filterValues.findIndex((filter) => filter.name === "RecordType");
      if (payload === 0) {
        state.filterValues[recordTypeIndex].value = "VER";
      } else {
        state.filterValues[recordTypeIndex].value = "NON";
      }
    },
  },
});

export const fetchBrandActions = (Payload) => async (dispatch: any) => {
  const {data,status} = await Brands().brandCreateOrUpdate(Payload);
  if(status === 200){
    dispatch(setSucceeded(true))
    return data;
  }else{
    dispatch(setError(`fetchBrandList return's ${status}`))
  }
}

export const fetchBrandProductCount = (ParentCompanyID,filter,page,limit) => async (dispatch: any) => {
  try {
    const {data, status} = await Brands().brandCompanyID(ParentCompanyID)
    const CompanyID = data?.ParentCompanyCompanyInfo[0]?.FK_CompanyID
    return await Brands().brandProductCount(CompanyID,filter,page,limit);
  } catch (error) {
    console.error('Error fetching brand product count:', error);
    dispatch(setError(error.message));
  }
};

export const fetchUserCompany = (ParentCompanyID) => async (dispatch: any) => {
  try {
    const {data, status} = await Brands().brandCompanyID(ParentCompanyID);
    if(data?.ParentCompaniesInfo[0]?.FK_CompanyID) {
      // fetch User EntityGLN Data
      const record = await userCompanyData(data?.ParentCompaniesInfo[0].FK_CompanyID)
      if(record){
        return record
      }else{
        return "";
      }
    }else {
      return "";
    }

  } catch (error) {
    console.error('Error fetching brand product count:', error);
    dispatch(setError(error.message));
  }
};

export const fetchParentCompanyInformation = (payload) => async (dispatch) => {
  try {
    const { data, status } = await getAccountContactDetailsForLoginUser(payload);
    if (status === 200 && data?.ParentCompanyInfo[0]?.EntityGLN !== null) {
      dispatch(setParentCompany(data?.ParentCompanyInfo[0]));
      // dispatch(fetchBrandProductCount({ EntityGLN: data?.ParentCompanyInfo[0]?.EntityGLN }));
    } else {
      dispatch(setError("No EntityGLN Found"));
    }
  } catch (error) {
    console.error('Error fetching parent company information:', error);
    dispatch(setError(error.message));
  }
};

export const uploadBrandImage = (payload) => async (dispatch) => {
  try {
    const {data,status} = await updateCompanyImageDetail(payload);
    dispatch(setSucceeded(true));
  } catch (error) {
    console.error('Error Upload Brand Image:', error);
    dispatch(setError(error.message));
  }
};

export const brandValidationSubstitute = (payload) => async (dispatch) => {
  try {
    const {data,status} = await Brands().brandActions((payload));
    if(status === 200){
    dispatch(setallBrands(data.BrandInfo));
    }
  } catch (error) {
    console.error('Error Upload Brand Image:', error);
    dispatch(setError(error.message));
  }
};

export const updateAssignBrand = (Payload) => async (dispatch: any) => {
  const {data,status} = await Brands().reAssignBrand(Payload);
  if(status === 200){
    if (typeof data.error !== "undefined") {
      dispatch(setSucceeded(false))
    }else {
      dispatch(setSucceeded(true))
    }
    return data;
  }else{
    dispatch(setError(`updateAssignBrand return's ${status}`))
  }
}

export const userCompanyData = async (companyId) => {
  // fetch User EntityGLN Data
  const record = await Brands().getUserCompanyData(companyId)
  if(record){
    return record?.data?.CompanyInfo[0]?.EntityGLN;
  }else{
    return "";
  }
}

export const {
  setFailed,
  setError,
  resetState,
  setIsSelectedRow,
  setParentCompany,
  setProcessing,
  setSucceeded,
  setModified,
  setBrands,
  setFilterValues,
  setPage,
  setLimit,
  setActive,
  setallBrands
} = brandSlice.actions;

export const brand = (state: RootState) => state.brandSlice;

export default brandSlice.reducer;
