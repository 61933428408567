import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const getProduct = async (GTIN) => {
  try {
    GTIN = GTIN === null ? '' : GTIN;
    return await getAuthRequest(getToken()).get('search/getProduct/' + GTIN);
  } catch (error) {
    return false;
  }
};

const getSimilarProducts = async (BRICK, FunctionalGroup, Variant) => {
  try {
    return await getAuthRequest(getToken()).post('search/getSimilarProducts', {
      BRICK, FunctionalGroup, Variant
    });
  } catch (error) {
    return false;
  }
};

export { getProduct, getSimilarProducts };