import { getToken } from "../provider/AuthProvider";
import { getAuthRequest, getStripeRequest } from "./API";

const listAccountsToBeApproved = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsToBeApproved');
  } catch (error) {
    return error;
  }
};

const listAccountsApproved = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsApproved');
  } catch (error) {
    return error;
  }
};

const listAccountsWaitingMoreInfo = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsWaitingMoreInfo');
  } catch (error) {
    return error;
  }
};

const getSignUpDetail = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/getSignUpDetail/' + SignupID);
  } catch (error) {
    return error;
  }
};

const listChecks = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listChecks/' + SignupID);
  } catch (error) {
    return error;
  }
};

const addCheck = async (SignupID, SignupCheck, Title, Text) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/addCheck/' + SignupID, {
      SignupID, SignupCheck, Title, Text
    });
  } catch (error) {
    return false;
  }
};

const approve = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/approve/' + SignupID);
  } catch (error) {
    return error;
  }
};

const reject = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/reject/' + SignupID);
  } catch (error) {
    return error;
  }
};

const getParentCompanyDetails = async (page, filterValues) => {
  try {
    return await getAuthRequest(getToken()).post(`/members/SignupApplications?page=${page}`, filterValues);
  } catch (error) {
    return error;
  }
};

const getCalculateWorkdays = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('/system/CalculateWorkdays', data);
  } catch (error) {
    return error;
  }
};

const getParentCompanyDetailsByID = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('members/SignupApplications', { SignupID });
  } catch (error) {
    return error;
  }
};

const getParentCompaniesDetails = async (payload) => {
  try {
    return await getAuthRequest(getToken()).post('/tab/PARG', payload);
  } catch (error) {
    return error;
  }
};

const saveSubscriptionDetails = async (payload) => {
  try {
    return await getAuthRequest(getToken()).post('/members/subscription', payload);
  } catch (error) {
    return error;
  }
};

const getStripePaymentDetails = async (id) => {
  try {
    return await getStripeRequest(process.env.REACT_APP_STRIPE_SECRET_KEY).get('payment_intents/' + id);
  } catch (error) {
    return error;
  }
};

const getCalculateDueDate = async (payload) => {
  try {
    return await getAuthRequest(getToken()).post('/system/CalculateDueDate', payload);
  } catch (error) {
    return error;
  }
};


const getHistoryData = async (payload) => {
  try {
    return await getAuthRequest(getToken()).post('/tab/SIHG', payload);
  } catch (error) {
    return error;
  }
};

const getHistoryDataByID = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('/members/SignupHistory', { SignupID });
  } catch (error) {
    return error;
  }
};

const getChecksDropdown = async (paylaod) => {
  try {
    return await getAuthRequest(getToken()).post('/tab/SYSG', paylaod);
  } catch (error) {
    return error;
  }
};

const saveHistoryData = async (paylaod) => {
  try {
    return await getAuthRequest(getToken()).post('/tab/SIHP', paylaod);
  } catch (error) {
    return error;
  }
};


const saveSubmitData = async (paylaod) => {
  try {
    return await getAuthRequest(getToken()).post('/tab/SIGP', paylaod);
  } catch (error) {
    return error;
  }
};

const sendemail = async (paylaod) => {
  try {
    return await getAuthRequest(getToken()).post('/SendGrid/sendemail', paylaod);
  } catch (error) {
    return error;
  }
};

export {
  listAccountsToBeApproved,
  listAccountsApproved,
  listAccountsWaitingMoreInfo,
  listChecks,
  addCheck,
  getSignUpDetail,
  approve,
  reject,
  getParentCompanyDetails,
  getParentCompanyDetailsByID,
  getParentCompaniesDetails,
  saveSubscriptionDetails,
  getStripePaymentDetails,
  getCalculateWorkdays,
  getCalculateDueDate,
  getHistoryData,
  getChecksDropdown,
  saveHistoryData,
  saveSubmitData,
  getHistoryDataByID,
  sendemail
};