import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './UserHome.css';

const UserHome = () => {
  return (
    <PageSkeletonComponent>

     <h1>USER - Home Page (Dashboard)</h1>
      <br />
      <hr />
      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>
      <h2>Purpose of Page</h2>
      <b>OUTLINE OF WHAT THIS PAGE IS USED FOR AND ANY FUNCTIONALITY THAT IS KNOWN</b>

    </PageSkeletonComponent>
  );
};

export default UserHome;