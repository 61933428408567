import React, {useEffect, useState} from 'react';
import './UserProfile.css';
import DashboardHeader from "../../../dashboard-components/DashboardHeader";
import {Alert} from "@mui/material";

const UserProfile = () => {

    const [showModal, setShowModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [formData, setFormData] = useState({
        newEmail: ""
    });
    const closeModel = (params) => {
        if (params === 'save') {
            if (formData.newEmail !== "") {
                setShowModal(false)
                setShowSuccess(true)
                setShowError(false)
            }else {
                setShowError(true)
                setShowSuccess(false)
            }
        }else {
            setShowModal(false)
        }
    }

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false);
            }, 5000);
        }
    }, [showSuccess]);


    const handleChange = async (event) => {
        const {name, value} = event.target
        setFormData((prevState) => ({...prevState, [name]: value}));
    }

  return (
      <>
      <div className="w-full mx-auto">
          <DashboardHeader
              title={""}
              nav={[]}
          />

          <div className="my-5 rounded-xl shadow-md p-2 bg-white">
              <div className="grid grid-cols-12 gap-10 place-items-center">
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12 w-full space-y-4 mt-5 -mb-5">
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="text-xl w-full max-w-[200px] text-right font-bold">
                              Contact Details
                          </h6>
                      </div>

                      <div className={'justify-center flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3'}>
                          {showSuccess && <Alert severity="info">You have requested to change your Email Address to {formData.newEmail}. Check your email and follow the instructions to verify your account.</Alert>}
                      </div>
                  </div>

                  <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-6 w-full space-y-4">
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] flex justify-end">
                              First Name:
                          </h6>
                          <input value="" placeholder="First Name" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                                 />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">
                              Preferred Name:
                          </h6>
                          <input
                              value="" placeholder="Preferred Name" type="text"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">
                              Direct Landline No:
                          </h6>
                          <input
                              value="" placeholder="Preferred Name" type="text"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">
                              Email Address:
                          </h6>
                          <input
                              value="user@bba.com" placeholder="Email Address" readOnly={true} type="text"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>
                  </div>

                  <div className="lg:col-span-5 md:col-span-5 sm:col-span-5 col-span-5 w-full items-start space-y-4">

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">Last Name:</h6>
                          <input value="" placeholder="Last Name" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">Position:</h6>
                          <input value="" placeholder="Position" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">Mobile No:</h6>
                          <input value="" placeholder="Mobile No" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">&nbsp;</h6>
                          <button type="button"
                                  className="bg-gray-400 py-2 rounded-lg text-white w-30 px-4"
                                  onClick={() => setShowModal(true)}>Change Email</button>
                      </div>
                  </div>

                  <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-6 w-full space-y-4" style={{marginTop: '-25px'}}>
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">Your Photo:</h6>
                          <div className="flex items-center justify-start w-full">
                              <label htmlFor="file"
                                  className="flex flex-col items-center justify-center w-[150px] h-[150px] border-2 border-gray-300 border-dashed rounded-lg bg-gray-100 hover:bg-gray-200"
                              >
                                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                      <img src={'/temp.jpg'} alt={'temp'} className="w-[150px] h-[145px] object-fill rounded-1" />
                                  </div>
                              </label>
                          </div>
                      </div>
                  </div>

              </div>

              <div className="grid grid-cols-12 gap-10 place-items-center mt-5">
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-6 w-full space-y-4 -mb-5">
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="text-xl w-full max-w-[200px] text-right font-bold">
                              Location Details
                          </h6>
                      </div>
                  </div>

                  <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-6 w-full space-y-4">
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] flex justify-end">
                              Address 1:
                          </h6>
                          <input value="" placeholder="Address 1" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">
                              City:
                          </h6>
                          <input
                              value="" placeholder="City" type="text"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[200px] text-right">
                              Postcode:
                          </h6>
                          <input
                              value="" placeholder="Postcode" type="text"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                  </div>

                  <div className="lg:col-span-5 md:col-span-5 sm:col-span-5 col-span-5 w-full items-start space-y-4">

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">Address 2:</h6>
                          <input value="" placeholder="Address 2" type="text"
                                 className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">State:</h6>
                          <select
                              name="TargetMarket"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          >
                              <option className="font-bold">---Select---</option>
                          </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                          <h6 className="w-full max-w-[80px] flex justify-end">Country:</h6>
                          <select
                              name="TargetMarket"
                              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          >
                              <option className="font-bold">---Select---</option>
                          </select>
                      </div>

                  </div>
              </div>

              <div className="grid grid-cols-12 gap-10 place-items-center mt-5">
                  <div className="lg:col-span-11 md:col-span-11 sm:col-span-11 col-span-11 w-full space-y-4">
                      <div className="mt-3 flex justify-end items-center gap-5">
                          <button className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4">Cancel</button>

                          <button className={` bg-primary py-2 w-24 rounded-lg text-white`}>Save</button>
                      </div>
                  </div>
              </div>

          </div>
      </div>

          {showModal ? (
              <>
                  <div
                      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  >
                      <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{minWidth: '48rem'}}>
                          {/*content*/}
                          <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                              {/*header*/}
                              <div className="flex items-start justify-between p-5 rounded-t">
                                  <h3 className="text-xl font-semibold">
                                      Change Email Address
                                  </h3>
                                  <button
                                      className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                      onClick={() =>closeModel('cancel')}
                                  >
                                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                          ×
                                      </span>
                                  </button>
                              </div>
                              {/*body*/}
                              <div className="relative p-6 flex-auto" style={{maxHeight: '490px'}}>

                                  <div className={'justify-center flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3'}>
                                      {showError && <Alert severity="error">New Email Address Field is Required</Alert>}
                                  </div>
                                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                                      <h6 className="w-full max-w-[120px] flex justify-end">
                                          Current Email
                                      </h6>
                                      <input placeholder="Current Email"
                                             className="rounded-md w-full max-w-3xl font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                                             type="text"
                                             name="currentEmail"
                                             value="user@bba.com"
                                      />
                                  </div>

                                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                                      <h6 className="w-full max-w-[120px] flex justify-end">
                                          New Email <span className="pl-1 text-red-700">*</span>
                                      </h6>
                                      <input placeholder="New Email Address"
                                             className="rounded-md w-full max-w-3xl font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                                             type="text"
                                             name="newEmail"
                                             value={formData.newEmail}
                                             onChange={handleChange}
                                      />
                                  </div>
                              </div>
                              {/*footer*/}
                              <div className="flex items-center justify-end p-6 rounded-b">
                                  <button
                                      className="bg-gray-400 text-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() =>closeModel('cancel')}
                                  >
                                      Cancel
                                  </button>

                                  <button
                                      className="bg-primary text-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() =>closeModel('save')}
                                  >
                                      Save
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              </>
          ) : null}
      </>
  );
};

export default UserProfile;