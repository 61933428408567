import React from 'react';
import PageSkeletonComponent from '../../../components/PageSkeletonComponent/PageSkeletonComponent';
import './LoyaltyImplementations.css';

const LoyaltyImplementations = () => {
  return (
    <PageSkeletonComponent>

      <h1>LOYALTY - TECHNICAL - Implemenations</h1>
      <br />
      <hr />
      

      <h2>Purpose of Page</h2>
      This page is for listing the implementation partners of Bring Back Loyalty Program.<br /><br />
      This page will guide subscribers to how to implement this using their Point of Sale software.<br /><br />
      If their Point of Sale system is not listed, they will be able to request their software to be included.  We will create a form for the user to request this.  At this point we will assess the viability depending upon the userbase if we inititate that and contribute (Partial or Full) as a Business Decision.<br /><br />
      
      A page showing:
      <li>Current POS Systems Supporting our Loyalty Platform</li>
      <li>Form to Apply for Integration</li>
      <li>Link for Implementation Guide</li>


      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" rel="noopener noreferrer" href="#">Loyalty Implementations</a><br />
      <br />
      <hr />

      <h2>Third Party POS Providers</h2>
      Below are the knows POS Providers and our status with them.<br />
      <h3>GAP Solutions</h3>
      <table className="tg">
        <thead>
          <tr>
            <th style={{width: "25%"}}className="tg-0pky"><b>Item</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Detail</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Notes</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Other</b></th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td className="tg-0pky">Company Name</td>
            <td className="tg-0pky">GAP Solutions</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Website</td>
            <td className="tg-0pky"><a target="_blank" rel="noopener noreferrer" href="http://www.gapsolutions.com.au">www.gapsolutions.com.au</a></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Industries</td>
            <td className="tg-0pky">
              Supermarkets<br />
              Service Stations<br />
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Location</td>
            <td className="tg-0pky">
              26 Woodlands Terrace<br />
              Edwardstown SA 5039
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Contact Person</td>
            <td className="tg-0pky">John Goodacre</td>
            <td className="tg-0pky">0418 816 629</td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Discussions</td>
            <td className="tg-0pky">Met with John Goodacre 15th December 2020 at his office.</td>
            <td className="tg-0pky">From initial discussion with GAP Solutions, the integration of the Bring Back Loyalty Program into their POS system will be a $10,000 to $30,000 charge depending on final complexity.</td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Confluence</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
        </tbody>
        </table>

      <h3>WorldSmart - SmartRetail</h3>
      <table className="tg">
        <thead>
          <tr>
            <th style={{width: "25%"}}className="tg-0pky"><b>Item</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Detail</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Notes</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Other</b></th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td className="tg-0pky">Company Name</td>
            <td className="tg-0pky">WorldSmart</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Website</td>
            <td className="tg-0pky"><a target="_blank" rel="noopener noreferrer" href="https://www.worldsmart.com.au/products/smart-retail">www.worldmart.com.au</a></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Industries</td>
            <td className="tg-0pky">
              Supermarkets<br />
              Service Stations<br />
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Location</td>
            <td className="tg-0pky">
              Level 2/50 Greenhill Road<br />
              Wayville SA 5034
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Contact Person</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Discussions</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Confluence</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
        </tbody>
        </table>



      
      <h3>Independent Solutions</h3>
      <table className="tg">
        <thead>
          <tr>
            <th style={{width: "25%"}}className="tg-0pky"><b>Item</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Detail</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Notes</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Other</b></th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td className="tg-0pky">Company Name</td>
            <td className="tg-0pky">Independent Solutions</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Website</td>
            <td className="tg-0pky"><a target="_blank" rel="noopener noreferrer" href="https://www.independentsolutions.com.au/">www.independentsoluitions.com.au</a></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Industries</td>
            <td className="tg-0pky">
              Supermarkets<br />
              Convenience<br />
              Service Stations<br />
              Bottle Shops<br />
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Location</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Contact Person</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Discussions</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Confluence</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
        </tbody>
        </table>


      <h3>FutureNet</h3>

      <table className="tg">
        <thead>
          <tr>
            <th style={{width: "25%"}}className="tg-0pky"><b>Item</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Detail</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Notes</b></th>
            <th style={{width: "25%"}}className="tg-0pky"><b>Other</b></th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td className="tg-0pky">Company Name</td>
            <td className="tg-0pky">FutureNet</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Website</td>
            <td className="tg-0pky"><a target="_blank" rel="noopener noreferrer" href="http://futurenet.com.au/">www.futurenet.com.au</a></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Industries</td>
            <td className="tg-0pky">
              Supermarkets<br />
              Service Stations<br />
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Location</td>
            <td className="tg-0pky">
              Level 2/50 Greenhill Road<br />
              Wayville SA 5034
            </td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Contact Person</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Discussions</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
          <tr>
            <td className="tg-0pky">Confluence</td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
            <td className="tg-0pky"></td>
          </tr>
        </tbody>
        </table>


      <br />
      <hr />
      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      Employees can view promotions with any of the Suppliers/Retailers/Service Providers to assist with issues.<br />
      Employees should not be able to edit anything, they are just there as support staff to advise of any issues.
      
      <h3>Supplier Subscribers</h3>
      A Supplier can instigate a Bonus Points offer, this is entered by the Supplier with the parameters, what points are offered and who is paying for the points, for approval by the Retailer or Service Provider.

      <h3>Retailer Subscribers</h3>
      A Retailer can instigate a Bonus Points offer, this is entered by the Supplier with the parameters, what points are offered and who is paying for the points, for approval by the Supplier.
      
      <h3>Service Subscribers</h3>
      A Retailer can instigate a Bonus Points offer, this is entered by the Service Provider with the parameters, what points are offered and who is paying for the points, for approval by the Supplier.

    </PageSkeletonComponent>
  );
};

export default LoyaltyImplementations;