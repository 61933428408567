import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { getSubscription } from "../../provider/AuthProvider";
import {
  orderSelector,
  addForm,
  fetchClientSecret,
  submitAccountReview,
  setIndex,
  fetchNumberOfEmployees,
  fetchAnnualTurnover,
  fetchIndustries,
  fetchAccountDetails,
  SihSignupHistory,
} from "../../store/slices/getStartedSlice";
import GetStartedAccountRenewPaymentComponent from "./GetStartedAccountRenewPaymentComponent";
import { getCouponCode, updateCouponCode } from "../../api/SignUp";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/AuthContext";
import Loader from "../../components/loader/Loader";
import useRenewPayment from "../../hooks/UseRenewPayment";
import moment from "moment";
import TermsAndCondition from "./TermsAndCondition";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const RenewSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userAuth = useAuthContext();
  const user = userAuth.user;
  const subscription = getSubscription();
  const { t } = useTranslation(["common"]);
  const settingsLastOpenDays = 7;
  const RenewReminderDays = 14;
  const logout = async () => userAuth.logoutUser();
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Standard");
  const [showNextStep, setShowNextStep] = useState(true);
  const [showWarning, setShowWarning] = useState(true);
  const [isTrial, setIsTrial] = useState(true);
  const [isPaymentLoad, setIsPaymentLoad] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [byPassPayment, setbyPassPayment] = useState(true);
  const [message, setMessage] = useState(null);
  const [isFreeCoupon, setIsFreeCoupon] = useState(false);
  const [subscriptionDaysRemaining, setSubscriptionDaysRemaining] = useState(0);
  const [subscriptionNotification, setSubscriptionNotification] =
    useState(false);
  const { form, annualTurnover, clientSecret, industries, numberOfEmployees } =
    useAppSelector(orderSelector);
  const { handlePaymentSubmit, isProcessing, setIsProcessing } =
    useRenewPayment({ isFreeCoupon });

  // State to manage payment object details
  const [paymantObj, setPaymantObj] = useState({
    payload: null,
    annualSubscription: null,
    adjustment: 0,
    subTotal: 0,
    gst: 0,
    total: 0,
  });

  // GST rate constant
  const gstRate = 0.1; // 10% GST rate

  // Function to update form field and dispatch the action
  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
  };

  // Filter and retrieve the payment details based on annual turnover
  const payment =
    (annualTurnover || []).filter(
      (i) => i.SystemSelectID == form?.annual_turnover
    )?.[0] || {};

  // Function to fetch and update form data
  const fetchUpdatedForm = async () => {
    let data = Object.assign({}, form);
    setLoaded(true);
    if (!isFormLoaded) {
      if (Object.keys(data).length > 20) {
        setIsFormLoaded(true);
      }
    }
    setLoaded(false);
  };

  // Dispatch action to set index
  dispatch(setIndex(3));

  // useEffect to handle user logout and subscription notification logic
  useEffect(() => {
    const currentDate = moment();
    const lastOpenDate = moment(user.DateWebLastOpen, "YYYY-MM-DD HH:mm:ss");

    if (!lastOpenDate.isSame(currentDate, "day")) {
      const daysDifference = currentDate.diff(lastOpenDate, "days");
      if (daysDifference >= settingsLastOpenDays) {
        // Log out the user
        logout().then(() => {
          navigate("/SignIn");
        });
      }
    }

    const subscriptionDateTo = moment(
      subscription[0].DateTo,
      "YYYY-MM-DD HH:mm:ss"
    );
    fetchUpdatedForm();

    let daysRemaining = 0;

    if (subscriptionDateTo.isBefore(currentDate, "day")) {
      daysRemaining = 0;
    } else {
      daysRemaining = subscriptionDateTo.diff(currentDate, "days");
    }
    setSubscriptionDaysRemaining(daysRemaining);

    console.log("this is day remainder ", daysRemaining);

    if (daysRemaining <= RenewReminderDays) {
      setSubscriptionNotification(true);
    } else {
      setSubscriptionNotification(false);
    }
  }, [
    form,
    user.DateWebLastOpen,
    subscription[0].DateTo,
    navigate,
    settingsLastOpenDays,
    RenewReminderDays,
  ]);

  // useEffect to handle subscription notifications and actions
  useEffect(() => {
    if (subscriptionNotification) {
      if (subscriptionDaysRemaining === 0) {
        // Redirect to Renew Subscription Page
        setShowNextStep(false);
        setShowWarning(true);
        setIsTrial(true);
      } else {
        if (subscription[0].Trial === 1) {
          setShowNextStep(true);
          setShowWarning(true);
          setIsTrial(false);
          setSelectedOption("Trial Membership");
        } else {
          setShowWarning(false);
          setShowNextStep(true);
          setIsTrial(true);
          setSelectedOption(subscription[0].SubscriptionName);
        }
      }
    } else {
      setShowNextStep(true);
      setShowWarning(true);
      setIsTrial(true);
    }
  }, [subscriptionNotification, subscriptionDaysRemaining, subscription]);

  // Function to hide subscription notification
  const hideNotification = () => {
    setSubscriptionNotification(false);
  };

  // useEffect to fetch data and set payment object details
  useEffect(() => {
    fetchData().then((res) => {
      const gstAmount = payment?.SelectionPrice * gstRate;
      const selectionPrice = payment?.SelectionPrice || 0;

      setPaymantObj({
        payload: payment,
        annualSubscription: selectionPrice.toFixed(2),
        adjustment: 0.0,
        subTotal: selectionPrice.toFixed(2),
        gst: gstAmount,
        total: (gstAmount + selectionPrice).toFixed(2),
      });
    });
  }, []);

  // Function to fetch necessary data from various APIs
  const fetchData = async () => {
    await Promise.all([
      dispatch(fetchNumberOfEmployees()),
      dispatch(fetchAnnualTurnover()),
      dispatch(fetchIndustries()),
      dispatch(fetchAccountDetails()),
    ]);
  };

  // Filter and retrieve turnover details
  const turnover =
    (annualTurnover || []).filter(
      (i) => i.SystemSelectID == form?.annual_turnover
    )?.[0] || {};

  // Filter and retrieve industry details
  const industry =
    (industries || []).filter((i) => i.SystemSelectID == form?.industry)?.[0] ||
    {};

  // Filter and retrieve number of employees details
  const number_of_employees =
    (numberOfEmployees || []).filter(
      (i) => i.SystemSelectID == form?.number_of_employees
    )?.[0] || {};

  // Function to handle coupon payload and update payment object
  const getCouponPayload = async () => {
    setError("");
    setIsProcessing(true);
    const originalPrice = paymantObj?.annualSubscription || 0;
    try {
      const json = await getCouponCode(coupon || "~");

      if (json.status === 200) {
        const { data } = json;

        if (data?.errors?.error2 || data?.errors?.[0]) {
          setError(data?.errors?.error2 || data?.errors?.[0]);
          return;
        }

        const rCoupon = data?.record;

        if (
          rCoupon?.CouponID &&
          rCoupon?.QuantityAvailable > rCoupon?.QuantityUsed
        ) {
          const updatedCoupon = {
            CouponID: parseInt(rCoupon?.CouponID),
            QuantityUsed: parseInt(rCoupon?.QuantityUsed) + 1,
          };

          updateCouponCode(updatedCoupon);
          setFormField("coupon", rCoupon);

          if (
            rCoupon.CouponType === "Fixed" ||
            rCoupon.CouponType === "Percent"
          ) {
            const discountAmount =
              rCoupon.CouponType === "Fixed"
                ? originalPrice - rCoupon.CouponValue
                : (originalPrice * rCoupon.CouponValue) / 100;

            const payablePrice = (originalPrice - discountAmount).toFixed(2);
            const gstAmount = payablePrice * 0.1;
            const gstTotal = Number(payablePrice) * 0.1 + Number(payablePrice);
            setPaymantObj((prev) => ({
              ...prev,
              adjustment: discountAmount,
              subTotal: payablePrice,
              gst: gstAmount,
              total: gstTotal.toFixed(2),
            }));

            if (gstTotal < 1) {
              setbyPassPayment(true);
              setIsPaymentLoad(false);
              setIsProcessing(false);
              setFormField("termCondition", false);
            }
          } else {
            setError("Invalid Coupon Type. Please try a different one.");
          }
        } else {
          setError("Coupon Expired.");
        }
      } else {
        setError("Coupon Code is invalid. Please try a different one.");
      }
    } catch (error) {
      console.error("Error fetching coupon code:", error);
      setError(
        "An error occurred while fetching the coupon code. Please try again."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  // Function to navigate back to dashboard and reset term condition
  const back = () => {
    setFormField("termCondition", false);
    navigate("/DashboardHome");
  };

  // Function to handle form submission and update payment object details
  const handleSubmit = async () => {
    setMessage("");
    await dispatch(submitAccountReview());
    const gstAmount = payment?.SelectionPrice * gstRate;
    // setFormField("termCondition", false)
    const selectionPrice = payment?.SelectionPrice || 0;

    setPaymantObj({
      payload: payment,
      annualSubscription: selectionPrice.toFixed(2),
      adjustment: 0.0,
      subTotal: selectionPrice.toFixed(2),
      gst: gstAmount,
      total: (gstAmount + selectionPrice).toFixed(2),
    });
    setShowNextStep(true);
  };

  // Function to handle payment process and update payment object
  const handlePayment = (value) => {
    const totalAmount = Number(paymantObj?.total) * 100;
    if (
      totalAmount === 0 &&
      parseFloat(paymantObj.annualSubscription) > 0 &&
      parseFloat(paymantObj.total) === 0 &&
      value
    ) {
      setIsFreeCoupon(true);
      setIsPaymentLoad(true);
      setIsProcessing(false);
    } else if (totalAmount > 1 && value) {
      setbyPassPayment(false);
      setIsPaymentLoad(true);
      setIsProcessing(false);
      dispatch(fetchClientSecret({ amount: totalAmount }));
    } else {
      setbyPassPayment(true);
      setIsPaymentLoad(false);
      setIsProcessing(false);
    }
    setError("");
    setFormField("termCondition", value);
    setMessage(null);
  };

  // Constants for displaying adjusted amount
  const adjustedAmount = paymantObj?.adjustment.toFixed(2);
  const displayAdjustment =
    paymantObj?.adjustment === 0 ? `$${adjustedAmount}` : `-$${adjustedAmount}`;

  return (
    <div className={`w-full h-full flex-grow overflow-auto py-2`}>
      {loaded ? (
        <div className={"absolute inset-0 m-auto w-16 h-16"}>
          <Loader width={"w-full"} heigth={"h-full"} />
        </div>
      ) : (
        <>
          {subscriptionNotification ? (
            <>
              {!showWarning && (
                <>
                  <div className="flex justify-center">
                    <div
                      className="px-4 py-3 mb-4 w-full max-w-[50%] text-sm text-center rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                      role="alert"
                    >
                      <p>
                        <svg
                          className="flex-shrink-0 inline w-4 h-4 me-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        Your existing plan will expire soon. Please renew your
                        subscription.
                      </p>
                      <button onClick={hideNotification}>
                        Hide Notification
                      </button>
                    </div>
                  </div>
                  <div
                    className={`w-full max-w-7xl px-3 mx-auto py-1 bg-white mt-2 rounded-xl`}
                  ></div>
                </>
              )}
              {!isTrial && (
                <>
                  <div className="flex justify-center">
                    <div
                      className="px-4 py-3 mb-4 w-full max-w-[50%] text-sm text-center rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                      role="alert"
                    >
                      <p>
                        <svg
                          className="flex-shrink-0 inline w-4 h-4 me-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        Your existing plan is Trial user, please renew your
                        subscription.
                      </p>
                      <button onClick={hideNotification}>
                        Hide Notification
                      </button>
                    </div>
                  </div>
                  <div
                    className={`w-full max-w-7xl px-3 mx-auto py-1 bg-white mt-2 rounded-xl`}
                  ></div>
                </>
              )}
              {!showNextStep && (
                <>
                  <div className="flex justify-center">
                    <div
                      className="px-4 py-3 mb-4 w-full max-w-[50%] text-sm text-center rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                      role="alert"
                    >
                      <p>
                        <svg
                          className="flex-shrink-0 inline w-4 h-4 me-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        Your existing plan has expired. Please renew your
                        subscription.
                      </p>
                    </div>
                  </div>
                  <div
                    className={`w-full max-w-7xl px-3 mx-auto py-1 bg-white mt-2 rounded-xl`}
                  ></div>
                </>
              )}
              <div className="mt-1 mx-12 space-y-1">
                <span className="font-bold">
                  Which option do you want to proceed with?
                </span>
                <div className="overflow-x-auto pt-2">
                  <table className=" table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                      <tr className="text-left">
                        <th
                          scope="col"
                          className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2"
                        >
                          <h1 className="text-sm shrink-0">Features</h1>
                        </th>
                        <th
                          scope="col"
                          className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center"
                        >
                          <h1 className="text-sm shrink-0">
                            $199 <br />
                            Standard
                          </h1>
                        </th>
                        <th
                          scope="col"
                          className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center"
                        >
                          <h1 className="text-sm shrink-0">
                            $299 <br />
                            Professional
                          </h1>
                        </th>
                        <th
                          scope="col"
                          className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center"
                        >
                          <h1 className="text-sm shrink-0">
                            Enterprise <br />
                            Edition
                          </h1>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          Feature 1
                        </th>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          Feature 2
                        </th>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          Feature 3
                        </th>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          Feature 4
                        </th>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            disabled={true}
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          Feature 5
                        </th>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            disabled={true}
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                        <td className="border-b border-gray-200 px-6 py-4 text-center">
                          <input
                            checked
                            id="green-checkbox"
                            type="checkbox"
                            className="w-4 h-4 accent-black-500"
                          />
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        ></th>
                        <td className="px-6 py-4 text-center">
                          <button
                            onClick={() => handleSubmit()}
                            disabled={isProcessing}
                            className="px-8 py-2 bg-primary text-white uppercase rounded-md border"
                          >
                            Select
                          </button>
                        </td>
                        <td className="px-6 py-4 text-center blackText">
                          <span className="pt-1 pb-1">Coming Soon</span>
                        </td>
                        <td className="px-6 py-4 text-center blackText">
                          <span className="pt-1 pb-1">Coming Soon</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-center">
                <div
                  className="px-4 py-3 mb-4 w-full max-w-[50%] text-sm text-center rounded-lg bg-green-100 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  <p>
                    <svg
                      className="flex-shrink-0 inline w-4 h-4 me-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    Your selected Plan is: <strong> {selectedOption}</strong>
                  </p>
                </div>
              </div>
              <div
                className={`w-full max-w-7xl px-3 mx-auto py-1 bg-white mt-2 rounded-xl`}
              >
                <div className="mt-5 space-y-2">
                  <div className="flex gap-5">
                    <p className="w-[150px] text-end text-sm shrink-0">
                      Company Name:
                    </p>
                    <p className="text-sm">
                      <span className="font-bold">{form?.trading_name}</span>{" "}
                      trading as{" "}
                      <span className="font-bold">
                        {form?.company_name} ({form?.company_abn})
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-5">
                    <div className="mt-3">
                      <h1 className="w-[150px] text-end text-sm shrink-0 mb-5">
                        Contact Details:
                      </h1>
                      <div className="ml-12 md:w-[460px]">
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Name:</p>
                          <span className="font-bold">
                            {form?.first_name} {form?.last_name}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Preferred Name:</p>
                          <span className="font-bold">
                            {form?.prefered_name}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Position:</p>{" "}
                          <span className="font-bold">{form?.position}</span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          {" "}
                          <p className="text-end">Direct:</p>{" "}
                          <span className="font-bold">
                            {form?.direct_landline_number}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Mobile:</p>{" "}
                          <span className="font-bold">
                            {form?.mobile_number}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <h1 className="w-[150px] text-end text-sm shrink-0 mb-5">
                        Company Details:
                      </h1>
                      <div className="md:w-[460px]">
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Number of Employees:</p>
                          <span className="font-bold">
                            {number_of_employees?.SelectionName}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Annual Turnover:</p>
                          <span className="font-bold">
                            {turnover?.SelectionName}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          {" "}
                          <p className="text-end">Industry:</p>{" "}
                          <span className="font-bold">
                            {industry?.SelectionName}{" "}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Phone:</p>
                          <span className="font-bold">
                            {form?.company_phone_number}
                          </span>
                        </div>
                        <div className="text-sm grid grid-cols-2 gap-3">
                          <p className="text-end">Website:</p>
                          <span className="break-all font-bold">
                            {form?.website}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <p className="w-[150px] text-end text-sm shrink-0 mt-2">
                      Coupon Code:
                    </p>
                    <div>
                      <input
                        type="text"
                        disabled={form?.termCondition}
                        onChange={(e) => setCoupon(e.target.value)}
                        value={coupon}
                        placeholder="Enter Coupon Code"
                        className="rounded-md w-full px-3 max-w-xs relative py-2.5 border shadow-sm sm:text-sm"
                      />
                      {!!error && <p className="text-red-400">{error}</p>}
                    </div>
                    <button
                      disabled={form?.termCondition}
                      onClick={() => {
                        getCouponPayload();
                        // setIsCoupon(!isCoupon);
                      }}
                      className={`${
                        !form?.termCondition ? "bg-[#00205b]" : "bg-gray-500"
                      } w-44 h-max py-2.5 fs-15 shrink-0 leading-5 hover:opacity-80 text-white rounded-sm shadow-md gap-x-3 flex items-center justify-center`}
                    >
                      Apply Code
                    </button>
                  </div>
                  <div className="mt-8">
                    <div className="flex gap-5 ">
                      <p className="w-[160px] text-end text-sm shrink-0">
                        Annual Subscription Fee:
                      </p>
                      <p className="font-bold text-sm w-full text-end max-w-[70px]">
                        ${Number(paymantObj?.annualSubscription).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex gap-5">
                      <p className="w-[160px] text-end text-sm shrink-0">
                        Fee Adjustment:
                      </p>
                      <p className="font-bold text-sm w-full text-end max-w-[70px]">
                        {displayAdjustment}
                      </p>
                    </div>
                    <div className="flex gap-5">
                      <p className="w-[160px] text-end text-sm shrink-0">
                        Sub-Total:
                      </p>
                      <p className="font-bold text-sm w-full text-end max-w-[70px]">
                        ${Number(paymantObj?.subTotal).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex gap-5">
                      <p className="w-[160px] text-end text-sm shrink-0">
                        GST:
                      </p>
                      <p className="font-bold text-sm w-full text-end max-w-[70px]">
                        ${paymantObj.gst?.toFixed(2)}
                      </p>
                    </div>
                    <div className="flex gap-5">
                      <p className="w-[160px] text-end text-sm shrink-0">
                        TOTAL:
                      </p>
                      <p className="font-bold text-sm w-full text-end max-w-[70px]">
                        ${paymantObj?.total}
                      </p>
                    </div>
                    <TermsAndCondition />
                    <div className="flex items-center mt-3">
                      <label
                        htmlFor="agree"
                        className="cursor-pointer inline-flex lg:items-center md:items-center text-[15px] font-normal"
                      >
                        <input
                          onChange={(e) => handlePayment(e.target.checked)}
                          checked={form?.termCondition || false}
                          id="agree"
                          type="checkbox"
                          className="hidden peer"
                        />
                        <span className="mr-3 mt-1 peer-checked:bg-gray-800 bg-white h-[16px] min-w-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.7px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-gray-800 rounded-sm"></span>
                        {t("common:confirm_term_condition_text").toString()}
                      </label>
                    </div>
                  </div>
                </div>
                {!!message && (
                  <div
                    className="p-2  ml-[10.2rem] mb-2 mt-2 text-base text-red-500"
                    role="alert"
                  >
                    {message}
                  </div>
                )}
              </div>
            </>
          )}

          {!isFreeCoupon && stripePromise && clientSecret && isPaymentLoad && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <GetStartedAccountRenewPaymentComponent navigate={navigate} />
            </Elements>
          )}

          {byPassPayment && (
            <div className="flex justify-center items-center mt-5 gap-4">
              <button
                onClick={back}
                className="px-8 py-2 bg-gray-500 uppercase text-white rounded-md border"
              >
                {t("common:back_button_text").toString()}
              </button>
              {isFreeCoupon && (
                <button
                  disabled={isProcessing}
                  onClick={handlePaymentSubmit}
                  className="px-8 py-2 bg-primary uppercase text-white rounded-md border"
                >
                  {isProcessing
                    ? t("common:processing").toString()
                    : t("common:submit_text").toString()}
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RenewSubscription;
