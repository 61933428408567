import React from 'react';
import PageSkeletonComponent from '../../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS13.css';

const GS13 = () => {
  return (
    <PageSkeletonComponent>

<h1>INSTRUCTIONS - GS1 - Page 3</h1>

      <hr />

      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" href="">Loyalty Integration</a><br />

      <h2>Purpose of Page</h2>
      This page is for companies that don't have software with an existing integration, this will provide them with the instructions to integrate with our system.

      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      
      <h3>Supplier Subscribers</h3>

      <h3>Retailer Subscribers</h3>
      
      <h3>Service Subscribers</h3>

    </PageSkeletonComponent>
  );
};

export default GS13;
