import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
    orderSelector,
    addForm,
    fetchClientSecret,
    submitSIGP,
    fetchSIGG,
    submitOrder,
    submitAccountReview,
    setIndex,
    fetchNumberOfEmployees,
    fetchAnnualTurnover,
    fetchIndustries,
    fetchAccountDetails, SihSignupHistory
} from "../../store/slices/getStartedSlice";
import GetStartedAccountPaymentComponent from "./GetStartedAccountPaymentComponent";
import { getCouponCode, updateCouponCode } from "../../api/SignUp";
import GetStartedTab from "./GetStartedTab";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/AuthContext";
import Loader from "../../components/loader/Loader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const GetStartedAccountPayment = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userAuth = useAuthContext();
    const user = userAuth.user;
    const userSignupInfo = userAuth.signupInfo;
    const [error, setError] = useState("");
    const [coupon, setCoupon] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Trial');
    const [showNextStep, setShowNextStep] = useState(false);
    const [isPaymentLoad, setIsPaymentLoad] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [byPassPayment, setbyPassPayment] = useState(false);
    const [backButton, setBackButton] = useState(true);
    const [message, setMessage] = useState(null);
    const [paymantObj, setPaymantObj] = useState({
        payload: null,
        annualSubscription: null,
        adjustment: 0,
        subTotal: 0,
        gst: 0,
        total: 0
    })
    const { t } = useTranslation(["common"]);

    const { form, annualTurnover, clientSecret, industries, numberOfEmployees } =
        useAppSelector(orderSelector);

    const setFormField = (key, value) => {
        let data = Object.assign({}, form);
        data[key] = value;
        dispatch(addForm(data));
    };

    const handleSelectChange = (text) => {
        setSelectedOption(text);
        if (!showNextStep) {
            setShowNextStep(true);
            setIsProcessing(false);
        }
        setFormField('selectedField',text);
    };

    const logout = async () => userAuth.logoutUserAfterPayment();

    const payment =
        (annualTurnover || []).filter(
            (i) => i.SystemSelectID === form?.annual_turnover
        )?.[0] || {};

    const gstRate = 0.1; // 10% GST rate

    const fetchUpdatedForm = async () => {
        let data = Object.assign({}, form);
        setLoaded(true)
        if (!isFormLoaded) {
            if (Object.keys(data).length > 20) {
                setIsFormLoaded(true);
            }
        }

        if (isFormLoaded) {
            setLoaded(false)
        }
    }

    dispatch(setIndex(3))

    useEffect(() => {
        fetchUpdatedForm()
    }, [form])

    useEffect(() => {
        fetchData().then(res => {
            const gstAmount = payment?.SelectionPrice * gstRate;
            setFormField("termCondition", false)
            const selectionPrice = payment?.SelectionPrice || 0;

            setPaymantObj({
                payload: payment,
                annualSubscription: selectionPrice.toFixed(2),
                adjustment: 0.00,
                subTotal: selectionPrice.toFixed(2),
                gst: gstAmount,
                total: (gstAmount + selectionPrice).toFixed(2)
            });
            setFormField("coupon", {});
            setFormField('selectedField','Trial')
        });
    }, [])

    const fetchData = async () => {
        await Promise.all([
            dispatch(fetchNumberOfEmployees()),
            dispatch(fetchAnnualTurnover()),
            dispatch(fetchIndustries()),
            dispatch(fetchAccountDetails()),
        ]);
    }


    const turnover =
        (annualTurnover || []).filter(
            (i) => i.SystemSelectID === form?.annual_turnover
        )?.[0] || {};
    const industry =
        (industries || []).filter(
            (i) => i.SystemSelectID === form?.industry
        )?.[0] || {};
    const number_of_employees =
        (numberOfEmployees || []).filter(
            (i) => i.SystemSelectID === form?.number_of_employees
        )?.[0] || {};

    const getCouponPayload = async () => {
        setError("");
        setIsProcessing(true);
        const originalPrice = paymantObj?.annualSubscription || 0;
        try {
            const json = await getCouponCode(coupon || "~");

            if (json.status === 200) {
                const { data } = json;

                if (data?.errors?.error2 || data?.errors?.[0]) {
                    setError(data?.errors?.error2 || data?.errors?.[0]);
                    return;
                }

                const rCoupon = data?.record;

                if (rCoupon?.CouponID && rCoupon?.QuantityAvailable > rCoupon?.QuantityUsed) {
                    const updatedCoupon = {
                        CouponID: parseInt(rCoupon?.CouponID),
                        QuantityUsed: parseInt(rCoupon?.QuantityUsed) + 1,
                    };

                    updateCouponCode(updatedCoupon);
                    setFormField("coupon", rCoupon);

                    if (rCoupon.CouponType === "Fixed" || rCoupon.CouponType === "Percent") {
                        const discountAmount = rCoupon.CouponType === "Fixed"
                            ? originalPrice - rCoupon.CouponValue
                            : (originalPrice * rCoupon.CouponValue) / 100;

                        const payablePrice = (originalPrice - discountAmount).toFixed(2);
                        const gstAmount = payablePrice * 0.1;
                        const gstTotal = (Number(payablePrice) * 0.1) + Number(payablePrice);
                        setPaymantObj((prev) => ({
                            ...prev,
                            adjustment: discountAmount,
                            subTotal: payablePrice,
                            gst: gstAmount,
                            total: gstTotal.toFixed(2)
                        }));

                        if (gstTotal < 1) {
                            setbyPassPayment(true);
                            setIsPaymentLoad(false);
                            setIsProcessing(false)
                            setFormField("termCondition", false)
                        }
                    } else {
                        setError("Invalid Coupon Type. Please try a different one.");
                    }
                } else {
                    setError("Coupon Expired.");
                }
            } else {
                setError("Coupon Code is invalid. Please try a different one.");
            }
        } catch (error) {
            console.error("Error fetching coupon code:", error);
            setError("An error occurred while fetching the coupon code. Please try again.");
        } finally {
            setIsProcessing(false);
        }
    };


    const back = () => {
        if(showNextStep){
            setFormField("termCondition", false);
            setbyPassPayment(false);
            setShowNextStep(false);
        }else {
            setFormField("termCondition", false)
            dispatch(setIndex(2));
            navigate("/GetStarted/AccountReview");
        }
    };

    const handleSubmit = async () => {
        setMessage("");
        if (!form?.termCondition) {
            setMessage(t("common:accept_term_condition").toString());
            return;
        }
        await dispatch(submitAccountReview());

        if (paymantObj.subTotal < 1 || selectedOption === 'Trial') {

            const json = await dispatch(submitOrder(form));
            if (json.status === 204) {
                //SIH Signup History Record
                const historyRecord = {
                    FK_SignupID: userSignupInfo.SignupID,
                    FK_UserID: userSignupInfo.FK_UserID,
                    SignupCheckTitle: "User completed Sign-up Wizard Step: PAYMENT",
                    SignupCheckText:    `${userAuth.user.NameFirst} has completed Sign-up Wizard Step: PAYMENT`
                }
                await Promise.all([
                    dispatch(SihSignupHistory(historyRecord))
                ])
                const sigpData = {
                    SignupID: userSignupInfo.SignupID,
                    AuditUserID: userAuth.user.UserID,
                    Trial: selectedOption === 'Trial' ? 1 : 0
                }
                await dispatch(submitSIGP(sigpData));
                const siggData = {
                    SignupID : userSignupInfo.SignupID
                }
                await dispatch(fetchSIGG(sigpData));
                userAuth.setUser({
                    ...user,
                    hasSignedUp: true,
                    isApprovalBeingProcessed: true,
                    isSupplier: true,
                });
                logout().then(res => {
                    navigate('/DownloadOurApp');
                });
            }else{
                setMessage("Data not save Please try Again");
            }
        }
    }

    const handlePayment = (value) => {
        const totalAmount = Number(paymantObj?.total) * 100;
        if(selectedOption === 'Trial'){
            setbyPassPayment(true);
            setIsPaymentLoad(false);
            setIsProcessing(false)
        }else if (totalAmount > 1 && value) {
            setbyPassPayment(false);
            setBackButton(false);
            setIsPaymentLoad(true);
            setIsProcessing(false);
            dispatch(fetchClientSecret({ amount: totalAmount }));
        }
        setError("");
        setFormField("termCondition", value);
        setMessage(null);
    };


    const adjustedAmount = paymantObj?.adjustment.toFixed(2);
    const displayAdjustment = paymantObj?.adjustment === 0 ? `$${adjustedAmount}` : `-$${adjustedAmount}`;


    return (
        <div className={`w-full max-w-7xl mx-auto py-10`}>
            {!showNextStep ?
                <div className="bg-[#dcebfe] rounded flex items-center px-1 mb-3 gap-2 p-3">
                    <div className="w-10 h-10">
                        <img src="../icon.png" alt="icon" className="min-w-[40px] w-full h-full object-contain" />
                    </div>
                    <p>
                        Need assistance or have questions?
                        Click the chat support icon in the bottom
                        right corner for help during signup.
                    </p>
                </div> :
                <div className="flex items-center px-1 mb-3 gap-2 p-3">
                    <div
                        className="px-4 py-3 w-full max-w-[100%] text-sm rounded-lg bg-green-100 dark:bg-gray-800 dark:text-red-400"
                        role="alert">
                        <p>
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                            Your selected Plan is: <strong> {selectedOption}</strong>
                        </p>
                    </div>
                </div>
                }
            {Object.keys(form).length > 0 && <GetStartedTab />}
            {loaded ?
                <div className={'absolute inset-0 m-auto w-16 h-16'}>
                    <Loader width={'w-full'} heigth={'h-full'} />
                </div> :
                <>
                    <div
                        className={`py-3 px-3 bg-white mt-3 rounded-xl`}
                    >
                            {!showNextStep ? <div className="mt-1 mx-12 space-y-1">
                                    <span className="font-bold">Which option do you want to proceed with?</span>
                                    <div className="overflow-x-auto pt-2">
                                        <table className=" table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                            <tr className="text-left">
                                                <th scope="col" className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2">
                                                    <h1 className="text-sm shrink-0">
                                                        Features
                                                    </h1>
                                                </th>
                                                <th scope="col" className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center">
                                                    <h1 className="text-sm shrink-0">
                                                        Free <br />Trial
                                                    </h1>
                                                </th>
                                                <th scope="col" className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center">
                                                    <h1 className="text-sm shrink-0">
                                                        $199 <br />Standard
                                                    </h1>
                                                </th>
                                                <th scope="col" className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center">
                                                    <h1 className="text-sm shrink-0">
                                                        $299 <br />Professional
                                                    </h1>
                                                </th>
                                                <th scope="col" className="border-b border-gray-200 bg-gray-100 sticky top-0 px-6 py-2 text-center">
                                                    <h1 className="text-sm shrink-0">
                                                        Enterprise <br />Edition
                                                    </h1>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    Feature 1
                                                </th>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                            </tr>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    Feature 2
                                                </th>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                            </tr>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    Feature 3
                                                </th>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input disabled={true} id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                            </tr>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    Feature 4
                                                </th>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input disabled={true} id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input disabled={true} id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                            </tr>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="border-b border-gray-200 px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                    Feature 5
                                                </th>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input disabled={true} id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input disabled={true} id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                                <td className="border-b border-gray-200 px-6 py-4 text-center">
                                                    <input checked id="green-checkbox"
                                                           type="checkbox"
                                                           className="w-4 h-4 accent-black-500" />
                                                </td>
                                            </tr>
                                            <tr className="bg-white">
                                                <th scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">

                                                </th>
                                                <td className="px-6 py-4 text-center">
                                                    <button
                                                        onClick={() => handleSelectChange('Trial')}
                                                        disabled={isProcessing}
                                                        className="px-8 py-2 bg-primary text-white uppercase rounded-md border"
                                                    >
                                                        Select
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 text-center">
                                                    <button
                                                        onClick={() => handleSelectChange('Standard')}
                                                        disabled={isProcessing}
                                                        className="px-8 py-2 bg-primary text-white uppercase rounded-md border"
                                                    >
                                                        Select
                                                    </button>
                                                </td>
                                                <td className="px-6 py-4 text-center blackText">
                                                    {/*<input*/}
                                                    {/*    type="radio"*/}
                                                    {/*    value="professional"*/}
                                                    {/*    checked={selectedOption === 'professional'}*/}
                                                    {/*    onChange={handleRadioChange}*/}
                                                    {/*    className="form-radio h-5 w-5 text-black bg-white border-gray-300 rounded"*/}
                                                    {/*/>*/}
                                                    Coming Soon
                                                </td>
                                                <td className="px-6 py-4 text-center blackText">
                                                    {/*<input*/}
                                                    {/*    type="radio"*/}
                                                    {/*    value="enterprise"*/}
                                                    {/*    checked={selectedOption === 'enterprise'}*/}
                                                    {/*    onChange={handleRadioChange}*/}
                                                    {/*    className="form-radio h-5 w-5 text-black bg-white border-gray-300 rounded"*/}
                                                    {/*/>*/}
                                                    Coming Soon
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : <div className="mt-5 space-y-2">
                                    <div className="flex gap-5">
                                        <p className="w-[150px] text-end text-sm shrink-0">Company Name:</p>
                                        <p className="text-sm">
                                            <span className="font-bold">{form?.trading_name}</span> trading as <span className="font-bold">{form?.company_name} ({form?.company_abn})</span>
                                        </p>
                                    </div>
                                    <div className="flex gap-5">
                                        <div className="mt-3">
                                            <h1 className="w-[150px] text-end text-sm shrink-0 mb-5">Contact Details:</h1>
                                            <div className="ml-12 md:w-[460px]">
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Name:</p>
                                                    <span className="font-bold">
                                                {form?.first_name} {form?.last_name}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Preferred Name:</p>
                                                    <span className="font-bold">
                                                {form?.prefered_name}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Position:</p>{" "}
                                                    <span className="font-bold">
                                                {form?.position}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    {" "}
                                                    <p className="text-end">Direct:</p>{" "}
                                                    <span className="font-bold">
                                                {form?.direct_landline_number}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Mobile:</p>{" "}
                                                    <span className="font-bold">
                                                {form?.mobile_number}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h1 className="w-[150px] text-end text-sm shrink-0 mb-5">Company Details:</h1>
                                            <div className="md:w-[460px]">
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Number of Employees:</p>
                                                    <span className="font-bold">
                                                {number_of_employees?.SelectionName}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Annual Turnover:</p>
                                                    <span className="font-bold">
                                                {turnover?.SelectionName}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    {" "}
                                                    <p className="text-end">Industry:</p>{" "}
                                                    <span className="font-bold">
                                                {industry?.SelectionName}{" "}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Phone:</p>
                                                    <span className="font-bold">
                                                {form?.company_phone_number}
                                            </span>
                                                </div>
                                                <div className="text-sm grid grid-cols-2 gap-3">
                                                    <p className="text-end">Website:</p>
                                                    <span className="break-all font-bold">
                                                {form?.website}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { selectedOption !== 'Trial' ?
                                        <>
                                        <div className="flex gap-5">
                                            <p className="w-[150px] text-end text-sm shrink-0 mt-2">
                                                Coupon Code:
                                            </p>
                                            <div>
                                                <input
                                                    type="text"
                                                    disabled={form?.termCondition}
                                                    onChange={(e) => setCoupon(e.target.value)}
                                                    value={coupon}
                                                    placeholder="Enter Coupon Code"
                                                    className="rounded-md w-full px-3 max-w-xs relative py-2.5 border shadow-sm sm:text-sm"
                                                />
                                                {!!error && <p className="text-red-400">{error}</p>}
                                            </div>
                                            <button
                                                disabled={form?.termCondition}
                                                onClick={() => {
                                                    getCouponPayload();
                                                    // setIsCoupon(!isCoupon);
                                                }}
                                                className={`${!form?.termCondition ? "bg-[#00205b]" : "bg-gray-500"
                                                } w-44 h-max py-2.5 fs-15 shrink-0 leading-5 hover:opacity-80 text-white rounded-sm shadow-md gap-x-3 flex items-center justify-center`}
                                            >
                                                Apply Code
                                            </button>
                                        </div>
                                        <div className="mt-8">
                                            <div className="flex gap-5 ">
                                                <p className="w-[160px] text-end text-sm shrink-0">
                                                    Annual Subscription Fee:
                                                </p>
                                                <p className="font-bold text-sm w-full text-end max-w-[70px]">${Number(paymantObj?.annualSubscription).toFixed(2)}</p>
                                            </div>
                                            <div className="flex gap-5">
                                                <p className="w-[160px] text-end text-sm shrink-0">Fee Adjustment:</p>
                                                <p className="font-bold text-sm w-full text-end max-w-[70px]">{displayAdjustment}</p>
                                            </div>
                                            <div className="flex gap-5">
                                                <p className="w-[160px] text-end text-sm shrink-0">
                                                    Sub-Total:
                                                </p>
                                                <p className="font-bold text-sm w-full text-end max-w-[70px]">${Number(paymantObj?.subTotal).toFixed(2)}</p>
                                            </div>
                                            <div className="flex gap-5">
                                                <p className="w-[160px] text-end text-sm shrink-0">GST:</p>
                                                <p className="font-bold text-sm w-full text-end max-w-[70px]">${paymantObj.gst?.toFixed(2)}</p>
                                            </div>
                                            <div className="flex gap-5">
                                                <p className="w-[160px] text-end text-sm shrink-0">TOTAL:</p>
                                                <p className="font-bold text-sm w-full text-end max-w-[70px]">${paymantObj?.total}</p>
                                            </div>
                                        </div>
                                        </>
                                        : ''
                                    }


                                    <div className="flex gap-5">
                                        <p className="pt-3 w-[150px] text-end text-sm shrink-0">
                                            {t("common:term_and_conditions").toString()}:
                                        </p>
                                        <div>
                                            <div className="mt-3 border border-black p-2">
                                                <div className="h-[288px] overflow-y-auto">
                                                    <h3 className="uppercase font-bold">
                                                        Terms of Use
                                                    </h3>
                                                    <p className="italic">
                                                        Last updated: September 01, 2021
                                                    </p>

                                                    <section>
                                                        <h3 className="uppercase font-bold">
                                                            AGREEMENT TO TERMS
                                                        </h3>
                                                        <p>
                                                            These Terms of Use constitute a
                                                            legally binding agreement made
                                                            between you, whether personally or
                                                            on behalf of an entity (“you”) and
                                                            TrickleTreat Pty Ltd, doing business
                                                            as Bring Back Australia ("
                                                            <b>Bring Back</b>","
                                                            <b>Bring Back Australia</b>",“
                                                            <b>we</b>”, “<b>us</b>”, or “
                                                            <b>our</b>”), cconcerning your
                                                            access to and use of the
                                                            www.bringbackaustralia.app or
                                                            members.bringback.app websites as
                                                            well as any other media form, media
                                                            channel, mobile website or mobile
                                                            application related, linked, or
                                                            otherwise connected thereto
                                                            (collectively, the “Site”). You
                                                            agree that by accessing the Site,
                                                            you have read, understood, and
                                                            agreed to be bound by all of these
                                                            Terms of Use. IF YOU DO NOT AGREE
                                                            WITH ALL OF THESE TERMS OF USE, THEN
                                                            YOU ARE EXPRESSLY PROHIBITED FROM
                                                            USING THE SITE AND YOU MUST
                                                            DISCONTINUE USE IMMEDIATELY.
                                                            <br />
                                                            <br />
                                                            Supplemental terms and conditions or
                                                            documents that may be posted on the
                                                            Site from time to time are hereby
                                                            expressly incorporated herein by
                                                            reference. We reserve the right, in
                                                            our sole discretion, to make changes
                                                            or modifications to these Terms of
                                                            Use at any time and for any reason.
                                                            We will alert you about any changes
                                                            by updating the “Last updated” date
                                                            of these Terms of Use, and you waive
                                                            any right to receive specific notice
                                                            of each such change. It is your
                                                            responsibility to periodically
                                                            review these Terms of Use to stay
                                                            informed of updates. You will be
                                                            subject to, and will be deemed to
                                                            have been made aware of and to have
                                                            accepted, the changes in any revised
                                                            Terms of Use by your continued use
                                                            of the Site after the date such
                                                            revised Terms of Use are posted.
                                                            <br />
                                                            <br />
                                                            The information provided on the Site
                                                            is not intended for distribution to
                                                            or use by any person or entity in
                                                            any jurisdiction or country where
                                                            such distribution or use would be
                                                            contrary to law or regulation or
                                                            which would subject us to any
                                                            registration requirement within such
                                                            jurisdiction or country.
                                                            Accordingly, those persons who
                                                            choose to access the Site from other
                                                            locations do so on their own
                                                            initiative and are solely
                                                            responsible for compliance with
                                                            local laws, if and to the extent
                                                            local laws are applicable.
                                                            <br />
                                                            <br />
                                                            The Site is intended for users who
                                                            are at least 13 years of age. All
                                                            users who are minors in the
                                                            jurisdiction in which they reside
                                                            (generally under the age of 18) must
                                                            have the permission of, and be
                                                            directly supervised by, their parent
                                                            or guardian to use the Site. If you
                                                            are a minor, you must have your
                                                            parent or guardian read and agree to
                                                            these Terms of Use prior to you
                                                            using the Site.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            INTELLECTUAL PROPERTY RIGHTS
                                                        </h1>
                                                        <p>
                                                            Unless otherwise indicated, the Site
                                                            is our proprietary property and all
                                                            source code, databases,
                                                            functionality, software, website
                                                            designs, audio, video, text,
                                                            photographs, and graphics on the
                                                            Site (collectively, the “Content”)
                                                            and the trademarks, service marks,
                                                            and logos contained therein (the
                                                            “Marks”) are owned or controlled by
                                                            us or licensed to us, and are
                                                            protected by copyright and trademark
                                                            laws and various other intellectual
                                                            property rights and unfair
                                                            competition laws of the United
                                                            States, international copyright
                                                            laws, and international conventions.
                                                            The Content and the Marks are
                                                            provided on the Site “AS IS” for
                                                            your information and personal use
                                                            only. Except as expressly provided
                                                            in these Terms of Use, no part of
                                                            the Site and no Content or Marks may
                                                            be copied, reproduced, aggregated,
                                                            republished, uploaded, posted,
                                                            publicly displayed, encoded,
                                                            translated, transmitted,
                                                            distributed, sold, licensed, or
                                                            otherwise exploited for any
                                                            commercial purpose whatsoever,
                                                            without our express prior written
                                                            permission. <br />
                                                            <br />
                                                            Provided that you are eligible to
                                                            use the Site, you are granted a
                                                            limited license to access and use
                                                            the Site and to download or print a
                                                            copy of any portion of the Content
                                                            to which you have properly gained
                                                            access solely for your personal,
                                                            non-commercial use. We reserve all
                                                            rights not expressly granted to you
                                                            in and to the Site, the Content and
                                                            the Marks. <br />
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            USER REPRESENTATIONS
                                                        </h1>
                                                        <p>
                                                            By using the Site, you represent and
                                                            warrant that:
                                                        </p>
                                                        <div className="ml-3 mb-3">
                                                            <p>
                                                                1. all registration information
                                                                you submit will be true,
                                                                accurate, current, and complete;
                                                            </p>
                                                            <p>
                                                                2. you will maintain the
                                                                accuracy of such information and
                                                                promptly update such
                                                                registration information as
                                                                necessary;
                                                            </p>
                                                            <p>
                                                                3. you have the legal capacity
                                                                and you agree to comply with
                                                                these Terms of Use;
                                                            </p>
                                                            <p>
                                                                4. you are not under the age of
                                                                13;
                                                            </p>
                                                            <p>
                                                                5. you are not a minor in the
                                                                jurisdiction in which you
                                                                reside, or if a minor, you have
                                                                received parental permission to
                                                                use the Site;
                                                            </p>
                                                            <p>
                                                                6. you will not access the Site
                                                                through automated or non-human
                                                                means, whether through a bot,
                                                                script, or otherwise;
                                                            </p>
                                                            <p>
                                                                7. you will not use the Site for
                                                                any illegal or unauthorised
                                                                purpose; and (8) your use of the
                                                                Site will not violate any
                                                                applicable law or regulation.
                                                            </p>
                                                        </div>
                                                        <p>
                                                            If you provide any information that
                                                            is untrue, inaccurate, not current,
                                                            or incomplete, we have the right to
                                                            suspend or terminate your account
                                                            and refuse any and all current or
                                                            future use of the Site (or any
                                                            portion thereof).
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            USER REGISTRATION
                                                        </h1>
                                                        <p>
                                                            You may be required to register with
                                                            the Site. You agree to keep your
                                                            password confidential and will be
                                                            responsible for all use of your
                                                            account and password. We reserve the
                                                            right to remove, reclaim, or change
                                                            a username you select if we
                                                            determine, in our sole discretion,
                                                            that such username is inappropriate,
                                                            obscene, or otherwise objectionable.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            PROHIBITED ACTIVITIES
                                                        </h1>
                                                        <p>
                                                            You may not access or use the Site
                                                            for any purpose other than that for
                                                            which we make the Site available.
                                                            The Site may not be used in
                                                            connection with any commercial
                                                            endeavors except those that are
                                                            specifically endorsed or approved by
                                                            us.
                                                        </p>
                                                        <p>
                                                            As a user of the Site, you agree not
                                                            to:
                                                        </p>
                                                        <div className="ml-3 mb-3">
                                                            <p>
                                                                1. Systematically retrieve data
                                                                or other content from the Site
                                                                to create or compile, directly
                                                                or indirectly, a collection,
                                                                compilation, database, or
                                                                directory without written
                                                                permission from us.
                                                            </p>
                                                            <p>
                                                                2. Trick, defraud, or mislead us
                                                                and other users, especially in
                                                                any attempt to learn sensitive
                                                                account information such as user
                                                                passwords.
                                                            </p>
                                                            <p>
                                                                3. Circumvent, disable, or
                                                                otherwise interfere with
                                                                security-related features of the
                                                                Site, including features that
                                                                prevent or restrict the use or
                                                                copying of any Content or
                                                                enforce limitations on the use
                                                                of the Site and/or the Content
                                                                contained therein.
                                                            </p>
                                                            <p>
                                                                4. Disparage, tarnish, or
                                                                otherwise harm, in our opinion,
                                                                us and/or the Site.
                                                            </p>
                                                            <p>
                                                                5. Use any information obtained
                                                                from the Site in order to
                                                                harass, abuse, or harm another
                                                                person.
                                                            </p>
                                                            <p>
                                                                6. Make improper use of our
                                                                support services or submit false
                                                                reports of abuse or misconduct.
                                                            </p>
                                                            <p>
                                                                7. Use the Site in a manner
                                                                inconsistent with any applicable
                                                                laws or regulations.
                                                            </p>
                                                            <p>
                                                                8. Use the Site to advertise or
                                                                offer to sell goods and
                                                                services.
                                                            </p>
                                                            <p>
                                                                9. Engage in unauthorised
                                                                framing of or linking to the
                                                                Site.
                                                            </p>
                                                            <p>
                                                                10. Upload or transmit (or
                                                                attempt to upload or to
                                                                transmit) viruses, Trojan
                                                                horses, or other material,
                                                                including excessive use of
                                                                capital letters and spamming
                                                                (continuous posting of
                                                                repetitive text), that
                                                                interferes with any party’s
                                                                uninterrupted use and enjoyment
                                                                of the Site or modifies,
                                                                impairs, disrupts, alters, or
                                                                interferes with the use,
                                                                features, functions, operation,
                                                                or maintenance of the Site.
                                                            </p>
                                                            <p>
                                                                11. Engage in any automated use
                                                                of the system, such as using
                                                                scripts to send comments or
                                                                messages, or using any data
                                                                mining, robots, or similar data
                                                                gathering and extraction tools.
                                                            </p>
                                                            <p>
                                                                12. Delete the copyright or
                                                                other proprietary rights notice
                                                                from any Content
                                                            </p>
                                                            <p>
                                                                13. Attempt to impersonate
                                                                another user or person or use
                                                                the username of another user.
                                                            </p>
                                                            <p>
                                                                14. Sell or otherwise transfer
                                                                your profile.
                                                            </p>
                                                            <p>
                                                                15. Upload or transmit (or
                                                                attempt to upload or to
                                                                transmit) any material that acts
                                                                as a passive or active
                                                                information collection or
                                                                transmission mechanism,
                                                                including without limitation,
                                                                clear graphics interchange
                                                                formats (“gifs”), 1×1 pixels,
                                                                web bugs, cookies, or other
                                                                similar devices (sometimes
                                                                referred to as “spyware” or
                                                                “passive collection mechanisms”
                                                                or “pcms”).
                                                            </p>
                                                            <p>
                                                                16. Interfere with, disrupt, or
                                                                create an undue burden on the
                                                                Site or the networks or services
                                                                connected to the Site.
                                                            </p>
                                                            <p>
                                                                17. Harass, annoy, intimidate,
                                                                or threaten any of our employees
                                                                or agents engaged in providing
                                                                any portion of the Site to you.
                                                            </p>
                                                            <p>
                                                                18. Attempt to bypass any
                                                                measures of the Site designed to
                                                                prevent or restrict access to
                                                                the Site, or any portion of the
                                                                Site.
                                                            </p>
                                                            <p>
                                                                19. Copy or adapt the Site’s
                                                                software, including but not
                                                                limited to Flash, PHP, HTML,
                                                                JavaScript, or other code.
                                                            </p>
                                                            <p>
                                                                20. Decipher, decompile,
                                                                disassemble, or reverse engineer
                                                                any of the software comprising
                                                                or in any way making up a part
                                                                of the Site.
                                                            </p>
                                                            <p>
                                                                21. Except as may be the result
                                                                of standard search engine or
                                                                Internet browser usage, use,
                                                                launch, develop, or distribute
                                                                any automated system, including
                                                                without limitation, any spider,
                                                                robot, cheat utility, scraper,
                                                                or offline reader that accesses
                                                                the Site, or using or launching
                                                                any unauthorised script or other
                                                                software.
                                                            </p>
                                                            <p>
                                                                22. Use a buying agent or
                                                                purchasing agent to make
                                                                purchases on the Site.
                                                            </p>
                                                            <p>
                                                                23. Make any unauthorised use of
                                                                the Site, including collecting
                                                                usernames and/or email addresses
                                                                of users by electronic or other
                                                                means for the purpose of sending
                                                                unsolicited email, or creating
                                                                user accounts by automated means
                                                                or under false pretenses.
                                                            </p>
                                                            <p>
                                                                24. Use the Site as part of any
                                                                effort to compete with us or
                                                                otherwise use the Site and/or
                                                                the Content for any
                                                                revenue-generating endeavor or
                                                                commercial enterprise.
                                                            </p>
                                                        </div>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            USER GENERATED CONTRIBUTIONS
                                                        </h1>
                                                        <p>
                                                            The Site may invite you to chat,
                                                            contribute to, or participate in
                                                            blogs, message boards, online
                                                            forums, and other functionality, and
                                                            may provide you with the opportunity
                                                            to create, submit, post, display,
                                                            transmit, perform, publish,
                                                            distribute, or broadcast content and
                                                            materials to us or on the Site,
                                                            including but not limited to text,
                                                            writings, video, audio, photographs,
                                                            graphics, comments, suggestions, or
                                                            personal information or other
                                                            material (collectively,
                                                            "Contributions"). Contributions may
                                                            be viewable by other users of the
                                                            Site and through third-party
                                                            websites. As such, any Contributions
                                                            you transmit may be treated as
                                                            non-confidential and
                                                            non-proprietary. When you create or
                                                            make available any Contributions,
                                                            you thereby represent and warrant
                                                            that:
                                                        </p>
                                                        <div className="ml-3 mb-3">
                                                            <p>
                                                                1. The creation, distribution,
                                                                transmission, public display, or
                                                                performance, and the accessing,
                                                                downloading, or copying of your
                                                                Contributions do not and will
                                                                not infringe the proprietary
                                                                rights, including but not
                                                                limited to the copyright,
                                                                patent, trademark, trade secret,
                                                                or moral rights of any third
                                                                party.
                                                            </p>
                                                            <p>
                                                                2. You are the creator and owner
                                                                of or have the necessary
                                                                licenses, rights, consents,
                                                                releases, and permissions to use
                                                                and to authorise us, the Site,
                                                                and other users of the Site to
                                                                use your Contributions in any
                                                                manner contemplated by the Site
                                                                and these Terms of Use.
                                                            </p>
                                                            <p>
                                                                3. You have the written consent,
                                                                release, and/or permission of
                                                                each and every identifiable
                                                                individual person in your
                                                                Contributions to use the name or
                                                                likeness of each and every such
                                                                identifiable individual person
                                                                to enable inclusion and use of
                                                                your Contributions in any manner
                                                                contemplated by the Site and
                                                                these Terms of Use.
                                                            </p>
                                                            <p>
                                                                4. Your Contributions are not
                                                                false, inaccurate, or
                                                                misleading.
                                                            </p>
                                                            <p>
                                                                5. Your Contributions are not
                                                                unsolicited or unauthorised
                                                                advertising, promotional
                                                                materials, pyramid schemes,
                                                                chain letters, spam, mass
                                                                mailings, or other forms of
                                                                solicitation.
                                                            </p>
                                                            <p>
                                                                6. Your Contributions are not
                                                                obscene, lewd, lascivious,
                                                                filthy, violent, harassing,
                                                                libelous, slanderous, or
                                                                otherwise objectionable (as
                                                                determined by us).
                                                            </p>
                                                            <p>
                                                                7. Your Contributions do not
                                                                ridicule, mock, disparage,
                                                                intimidate, or abuse anyone.
                                                            </p>
                                                            <p>
                                                                8. Your Contributions do not
                                                                advocate the violent overthrow
                                                                of any government or incite,
                                                                encourage, or threaten physical
                                                                harm against another.
                                                            </p>
                                                            <p>
                                                                9. Your Contributions do not
                                                                violate any applicable law,
                                                                regulation, or rule.
                                                            </p>
                                                            <p>
                                                                10. Your Contributions do not
                                                                violate the privacy or publicity
                                                                rights of any third party.
                                                            </p>
                                                            <p>
                                                                11. Your Contributions do not
                                                                contain any material that
                                                                solicits personal information
                                                                from anyone under the age of 18
                                                                or exploits people under the age
                                                                of 18 in a sexual or violent
                                                                manner.
                                                            </p>
                                                            <p>
                                                                12. Your Contributions do not
                                                                violate any applicable law
                                                                concerning child pornography, or
                                                                otherwise intended to protect
                                                                the health or well-being of
                                                                minors;
                                                            </p>
                                                            <p>
                                                                13. Your Contributions do not
                                                                include any offensive comments
                                                                that are connected to race,
                                                                national origin, gender, sexual
                                                                preference, or physical
                                                                handicap.
                                                            </p>
                                                            <p>
                                                                14. Your Contributions do not
                                                                otherwise violate, or link to
                                                                material that violates, any
                                                                provision of these Terms of Use,
                                                                or any applicable law or
                                                                regulation.
                                                            </p>
                                                        </div>
                                                        <p>
                                                            Any use of the Site in violation of
                                                            the foregoing violates these Terms
                                                            of Use and may result in, among
                                                            other things, termination or
                                                            suspension of your rights to use the
                                                            Site.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            CONTRIBUTION LICENSE
                                                        </h1>
                                                        <p>
                                                            By posting your Contributions to any
                                                            part of the Site or making
                                                            Contributions accessible to the Site
                                                            by linking your account from the
                                                            Site to any of your social
                                                            networking accounts, you
                                                            automatically grant, and you
                                                            represent and warrant that you have
                                                            the right to grant, to us an
                                                            unrestricted, unlimited,
                                                            irrevocable, perpetual,
                                                            non-exclusive, transferable,
                                                            royalty-free, fully-paid, worldwide
                                                            right, and license to host, use,
                                                            copy, reproduce, disclose, sell,
                                                            resell, publish, broadcast, retitle,
                                                            archive, store, cache, publicly
                                                            perform, publicly display, reformat,
                                                            translate, transmit, excerpt (in
                                                            whole or in part), and distribute
                                                            such Contributions (including,
                                                            without limitation, your image and
                                                            voice) for any purpose, commercial,
                                                            advertising, or otherwise, and to
                                                            prepare derivative works of, or
                                                            incorporate into other works, such
                                                            Contributions, and grant and
                                                            authorise sublicenses of the
                                                            foregoing. The use and distribution
                                                            may occur in any media formats and
                                                            through any media channels.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            This license will apply to any form,
                                                            media, or technology now known or
                                                            hereafter developed, and includes
                                                            our use of your name, company name,
                                                            and franchise name, as applicable,
                                                            and any of the trademarks, service
                                                            marks, trade names, logos, and
                                                            personal and commercial images you
                                                            provide. You waive all moral rights
                                                            in your Contributions, and you
                                                            warrant that moral rights have not
                                                            otherwise been asserted in your
                                                            Contributions. We do not assert any
                                                            ownership over your Contributions.
                                                            You retain full ownership of all of
                                                            your Contributions and any
                                                            intellectual property rights or
                                                            other proprietary rights associated
                                                            with your Contributions. We are not
                                                            liable for any statements or
                                                            representations in your
                                                            Contributions provided by you in any
                                                            area on the Site. You are solely
                                                            responsible for your Contributions
                                                            to the Site and you expressly agree
                                                            to exonerate us from any and all
                                                            responsibility and to refrain from
                                                            any legal action against us
                                                            regarding your Contributions.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            We have the right, in our sole and
                                                            absolute discretion, (1) to edit,
                                                            redact, or otherwise change any
                                                            Contributions; (2) to re-categorise
                                                            any Contributions to place them in
                                                            more appropriate locations on the
                                                            Site; and (3) to pre-screen or
                                                            delete any Contributions at any time
                                                            and for any reason, without notice.
                                                            We have no obligation to monitor
                                                            your Contributions.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            GUIDELINES FOR REVIEWS
                                                        </h1>
                                                        <p>
                                                            We may provide you areas on the Site
                                                            to leave reviews or ratings. When
                                                            posting a review, you must comply
                                                            with the following criteria: (1) you
                                                            should have firsthand experience
                                                            with the person/entity being
                                                            reviewed; (2) your reviews should
                                                            not contain offensive profanity, or
                                                            abusive, racist, offensive, or hate
                                                            language; (3) your reviews should
                                                            not contain discriminatory
                                                            references based on religion, race,
                                                            gender, national origin, age,
                                                            marital status, sexual orientation,
                                                            or disability; (4) your reviews
                                                            should not contain references to
                                                            illegal activity; (5) you should not
                                                            be affiliated with competitors if
                                                            posting negative reviews; (6) you
                                                            should not make any conclusions as
                                                            to the legality of conduct; (7) you
                                                            may not post any false or misleading
                                                            statements; and (8) you may not
                                                            organise a campaign encouraging
                                                            others to post reviews, whether
                                                            positive or negative.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            We may accept, reject, or remove
                                                            reviews in our sole discretion. We
                                                            have absolutely no obligation to
                                                            screen reviews or to delete reviews,
                                                            even if anyone considers reviews
                                                            objectionable or inaccurate. Reviews
                                                            are not endorsed by us, and do not
                                                            necessarily represent our opinions
                                                            or the views of any of our
                                                            affiliates or partners. We do not
                                                            assume liability for any review or
                                                            for any claims, liabilities, or
                                                            losses resulting from any review. By
                                                            posting a review, you hereby grant
                                                            to us a perpetual, non-exclusive,
                                                            worldwide, royalty-free, fully-paid,
                                                            assignable, and sublicensable right
                                                            and license to reproduce, modify,
                                                            translate, transmit by any means,
                                                            display, perform, and/or distribute
                                                            all content relating to reviews.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            MOBILE APPLICATION LICENSE
                                                        </h1>
                                                        <br />
                                                        <h1 className="font-bold fonttext-xl">
                                                            Use License
                                                        </h1>
                                                        <p>
                                                            If you access the Site via a mobile
                                                            application, then we grant you a
                                                            revocable, non-exclusive,
                                                            non-transferable, limited right to
                                                            install and use the mobile
                                                            application on wireless electronic
                                                            devices owned or controlled by you,
                                                            and to access and use the mobile
                                                            application on such devices strictly
                                                            in accordance with the terms and
                                                            conditions of this mobile
                                                            application license contained in
                                                            these Terms of Use.
                                                        </p>
                                                        <p>You shall not:</p>
                                                        <div className="ml-3">
                                                            <p>
                                                                a. decompile, reverse engineer,
                                                                disassemble, attempt to derive
                                                                the source code of, or decrypt
                                                                the application;
                                                            </p>
                                                            <p>
                                                                b. make any modification,
                                                                adaptation, improvement,
                                                                enhancement, translation, or
                                                                derivative work from the
                                                                application;
                                                            </p>
                                                            <p>
                                                                c. violate any applicable laws,
                                                                rules, or regulations in
                                                                connection with your access or
                                                                use of the application;
                                                            </p>
                                                            <p>
                                                                d. remove, alter, or obscure any
                                                                proprietary notice (including
                                                                any notice of copyright or
                                                                trademark) posted by us or the
                                                                licensors of the application;
                                                            </p>
                                                            <p>
                                                                e. use the application for any
                                                                revenue generating endeavor,
                                                                commercial enterprise, or other
                                                                purpose for which it is not
                                                                designed or intended;
                                                            </p>
                                                            <p>
                                                                f. make the application
                                                                available over a network or
                                                                other environment permitting
                                                                access or use by multiple
                                                                devices or users at the same
                                                                time;
                                                            </p>
                                                            <p>
                                                                g. use the application for
                                                                creating a product, service, or
                                                                software that is, directly or
                                                                indirectly, competitive with or
                                                                in any way a substitute for the
                                                                application;
                                                            </p>
                                                            <p>
                                                                h. use the application to send
                                                                automated queries to any website
                                                                or to send any unsolicited
                                                                commercial e-mail; or
                                                            </p>
                                                            <p className="pl-3">
                                                                i. use any proprietary
                                                                information or any of our
                                                                interfaces or our other
                                                                intellectual property in the
                                                                design, development,
                                                                manufacture, licensing, or
                                                                distribution of any
                                                                applications, accessories, or
                                                                devices for use with the
                                                                application.
                                                            </p>
                                                        </div>
                                                        <br />
                                                        <h1 className="font-bold fonttext-xl">
                                                            Apple and Android Devices
                                                        </h1>
                                                        <p>
                                                            The following terms apply when you
                                                            use a mobile application obtained
                                                            from either the Apple Store or
                                                            Google Play (each an “App
                                                            Distributor”) to access the Site:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                a. the license granted to you
                                                                for our mobile application is
                                                                limited to a non-transferable
                                                                license to use the application
                                                                on a device that utilises the
                                                                Apple iOS or Android operating
                                                                systems, as applicable, and in
                                                                accordance with the usage rules
                                                                set forth in the applicable App
                                                                Distributor’s terms of service;
                                                            </p>
                                                            <p>
                                                                b. we are responsible for
                                                                providing any maintenance and
                                                                support services with respect to
                                                                the mobile application as
                                                                specified in the terms and
                                                                conditions of this mobile
                                                                application license contained in
                                                                these Terms of Use or as
                                                                otherwise required under
                                                                applicable law, and you
                                                                acknowledge that each App
                                                                Distributor has no obligation
                                                                whatsoever to furnish any
                                                                maintenance and support services
                                                                with respect to the mobile
                                                                application;;
                                                            </p>
                                                            <p>
                                                                c. in the event of any failure
                                                                of the mobile application to
                                                                conform to any applicable
                                                                warranty, you may notify the
                                                                applicable App Distributor, and
                                                                the App Distributor, in
                                                                accordance with its terms and
                                                                policies, may refund the
                                                                purchase price, if any, paid for
                                                                the mobile application, and to
                                                                the maximum extent permitted by
                                                                applicable law, the App
                                                                Distributor will have no other
                                                                warranty obligation whatsoever
                                                                with respect to the mobile
                                                                application;
                                                            </p>
                                                            <p>
                                                                d. you represent and warrant
                                                                that
                                                            </p>
                                                            <p className="pl-3">
                                                                <p>
                                                                    i. you are not located in a
                                                                    country that is subject to a
                                                                    U.S. government embargo, or
                                                                    that has been designated by
                                                                    the U.S. government as a
                                                                    “terrorist supporting”
                                                                    country and
                                                                </p>
                                                                <p>
                                                                    ii. you are not listed on
                                                                    any U.S. government list of
                                                                    prohibited or restricted
                                                                    parties;
                                                                </p>
                                                            </p>
                                                            <p>
                                                                e. you must comply with
                                                                applicable third-party terms of
                                                                agreement when using the mobile
                                                                application, e.g., if you have a
                                                                VoIP application, then you must
                                                                not be in violation of their
                                                                wireless data service agreement
                                                                when using the mobile
                                                                application; and
                                                            </p>
                                                            <p>
                                                                f. you acknowledge and agree
                                                                that the App Distributors are
                                                                third-party beneficiaries of the
                                                                terms and conditions in this
                                                                mobile application license
                                                                contained in these Terms of Use,
                                                                and that each App Distributor
                                                                will have the right (and will be
                                                                deemed to have accepted the
                                                                right) to enforce the terms and
                                                                conditions in this mobile
                                                                application license contained in
                                                                these Terms of Use against you
                                                                as a third-party beneficiary
                                                                thereof.
                                                            </p>
                                                        </div>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            SOCIAL MEDIA
                                                        </h1>
                                                        <p>
                                                            As part of the functionality of the
                                                            Site, you may link your account with
                                                            online accounts you have with
                                                            third-party service providers (each
                                                            such account, a “Third-Party
                                                            Account”) by either:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                1. providing your Third-Party
                                                                Account login information
                                                                through the Site; or
                                                            </p>
                                                            <p>
                                                                2. allowing us to access your
                                                                Third-Party Account, as is
                                                                permitted under the applicable
                                                                terms and conditions that govern
                                                                your use of each Third-Party
                                                                Account.
                                                            </p>
                                                        </div>
                                                        <br />
                                                        <p>
                                                            You represent and warrant that you
                                                            are entitled to disclose your
                                                            Third-Party Account login
                                                            information to us and/or grant us
                                                            access to your Third-Party Account,
                                                            without breach by you of any of the
                                                            terms and conditions that govern
                                                            your use of the applicable
                                                            Third-Party Account, and without
                                                            obligating us to pay any fees or
                                                            making us subject to any usage
                                                            limitations imposed by the
                                                            third-party service provider of the
                                                            Third-Party Account.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            By granting us access to any
                                                            Third-Party Accounts, you understand
                                                            that
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                1. we may access, make
                                                                available, and store (if
                                                                applicable) any content that you
                                                                have provided to and stored in
                                                                your Third-Party Account (the
                                                                “Social Network Content”) so
                                                                that it is available on and
                                                                through the Site via your
                                                                account, including without
                                                                limitation any friend lists and
                                                            </p>
                                                            <p>
                                                                2. we may submit to and receive
                                                                from your Third-Party Account
                                                                additional information to the
                                                                extent you are notified when you
                                                                link your account with the
                                                                Third-Party Account.
                                                            </p>
                                                        </div>
                                                        <br />
                                                        <p>
                                                            Depending on the Third-Party
                                                            Accounts you choose and subject to
                                                            the privacy settings that you have
                                                            set in such Third-Party Accounts,
                                                            personally identifiable information
                                                            that you post to your Third-Party
                                                            Accounts may be available on and
                                                            through your account on the Site.
                                                            Please note that if a Third-Party
                                                            Account or associated service
                                                            becomes unavailable or our access to
                                                            such Third Party Account is
                                                            terminated by the third-party
                                                            service provider, then Social
                                                            Network Content may no longer be
                                                            available on and through the Site.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            You will have the ability to disable
                                                            the connection between your account
                                                            on the Site and your Third-Party
                                                            Accounts at any time.
                                                        </p>
                                                        <br />
                                                        <p className="uppercase">
                                                            PLEASE NOTE THAT YOUR RELATIONSHIP
                                                            WITH THE THIRD-PARTY SERVICE
                                                            PROVIDERS ASSOCIATED WITH YOUR
                                                            THIRD-PARTY ACCOUNTS IS GOVERNED
                                                            SOLELY BY YOUR AGREEMENT(S) WITH
                                                            SUCH THIRD-PARTY SERVICE PROVIDERS.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            We make no effort to review any
                                                            Social Network Content for any
                                                            purpose, including but not limited
                                                            to, for accuracy, legality, or
                                                            non-infringement, and we are not
                                                            responsible for any Social Network
                                                            Content. You acknowledge and agree
                                                            that we may access your email
                                                            address book associated with a
                                                            Third-Party Account and your
                                                            contacts list stored on your mobile
                                                            device or tablet computer solely for
                                                            purposes of identifying and
                                                            informing you of those contacts who
                                                            have also registered to use the
                                                            Site.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            You can deactivate the connection
                                                            between the Site and your
                                                            Third-Party Account by contacting us
                                                            using the contact information below
                                                            or through your account settings (if
                                                            applicable). We will attempt to
                                                            delete any information stored on our
                                                            servers that was obtained through
                                                            such Third-Party Account, except the
                                                            username and profile picture that
                                                            become associated with your account.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            SUBMISSIONS
                                                        </h1>
                                                        <p>
                                                            You acknowledge and agree that any
                                                            questions, comments, suggestions,
                                                            ideas, feedback, or other
                                                            information regarding the Site
                                                            ("Submissions") provided by you to
                                                            us are non-confidential and shall
                                                            become our sole property. We shall
                                                            own exclusive rights, including all
                                                            intellectual property rights, and
                                                            shall be entitled to the
                                                            unrestricted use and dissemination
                                                            of these Submissions for any lawful
                                                            purpose, commercial or otherwise,
                                                            without acknowledgment or
                                                            compensation to you. You hereby
                                                            waive all moral rights to any such
                                                            Submissions, and you hereby warrant
                                                            that any such Submissions are
                                                            original with you or that you have
                                                            the right to submit such
                                                            Submissions. You agree there shall
                                                            be no recourse against us for any
                                                            alleged or actual infringement or
                                                            misappropriation of any proprietary
                                                            right in your Submissions.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            SITE MANAGEMENT
                                                        </h1>
                                                        <p>
                                                            We reserve the right, but not the
                                                            obligation, to:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                1. monitor the Site for
                                                                violations of these Terms of
                                                                Use;
                                                            </p>
                                                            <p>
                                                                2. take appropriate legal action
                                                                against anyone who, in our sole
                                                                discretion, violates the law or
                                                                these Terms of Use, including
                                                                without limitation, reporting
                                                                such user to law enforcement
                                                                authorities;
                                                            </p>
                                                            <p>
                                                                3. in our sole discretion and
                                                                without limitation, refuse,
                                                                restrict access to, limit the
                                                                availability of, or disable (to
                                                                the extent technologically
                                                                feasible) any of your
                                                                Contributions or any portion
                                                                thereof;
                                                            </p>
                                                            <p>
                                                                4. in our sole discretion and
                                                                without limitation, notice, or
                                                                liability, to remove from the
                                                                Site or otherwise disable all
                                                                files and content that are
                                                                excessive in size or are in any
                                                                way burdensome to our systems;
                                                                and
                                                            </p>
                                                            <p>
                                                                5. otherwise manage the Site in
                                                                a manner designed to protect our
                                                                rights and property and to
                                                                facilitate the proper
                                                                functioning of the Site.
                                                            </p>
                                                        </div>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            PRIVACY POLICY
                                                        </h1>
                                                        <p>
                                                            We care about data privacy and
                                                            security. By using the Site, you
                                                            agree to be bound by our Privacy
                                                            Policy posted on the Site, which is
                                                            incorporated into these Terms of
                                                            Use. Please be advised the Site is
                                                            hosted in Australia. If you access
                                                            the Site from any other region of
                                                            the world with laws or other
                                                            requirements governing personal data
                                                            collection, use, or disclosure that
                                                            differ from applicable laws in
                                                            Australia, then through your
                                                            continued use of the Site, you are
                                                            transferring your data to Australia,
                                                            and you agree to have your data
                                                            transferred to and processed in
                                                            Australia.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            COPYRIGHT INFRINGEMENTS
                                                        </h1>
                                                        <p>
                                                            We respect the intellectual property
                                                            rights of others. If you believe
                                                            that any material available on or
                                                            through the Site infringes upon any
                                                            copyright you own or control, please
                                                            immediately notify us using the
                                                            contact information provided below
                                                            (a “Notification”). A copy of your
                                                            Notification will be sent to the
                                                            person who posted or stored the
                                                            material addressed in the
                                                            Notification. Please be advised that
                                                            pursuant to applicable law you may
                                                            be held liable for damages if you
                                                            make material misrepresentations in
                                                            a Notification. Thus, if you are not
                                                            sure that material located on or
                                                            linked to by the Site infringes your
                                                            copyright, you should consider first
                                                            contacting an attorney.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            TERM AND TERMINATION
                                                        </h1>
                                                        <p>
                                                            These Terms of Use shall remain in
                                                            full force and effect while you use
                                                            the Site.
                                                        </p>
                                                        <br />
                                                        <p className="uppercase">
                                                            WITHOUT LIMITING ANY OTHER PROVISION
                                                            OF THESE TERMS OF USE, WE RESERVE
                                                            THE RIGHT TO, IN OUR SOLE DISCRETION
                                                            AND WITHOUT NOTICE OR LIABILITY,
                                                            DENY ACCESS TO AND USE OF THE SITE
                                                            (INCLUDING BLOCKING CERTAIN IP
                                                            ADDRESSES), TO ANY PERSON FOR ANY
                                                            REASON OR FOR NO REASON, INCLUDING
                                                            WITHOUT LIMITATION FOR BREACH OF ANY
                                                            REPRESENTATION, WARRANTY, OR
                                                            COVENANT CONTAINED IN THESE TERMS OF
                                                            USE OR OF ANY APPLICABLE LAW OR
                                                            REGULATION. WE MAY TERMINATE YOUR
                                                            USE OR PARTICIPATION IN THE SITE OR
                                                            DELETE YOUR ACCOUNT AND ANY CONTENT
                                                            OR INFORMATION THAT YOU POSTED AT
                                                            ANY TIME, WITHOUT WARNING, IN OUR
                                                            SOLE DISCRETION.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            If we terminate or suspend your
                                                            account for any reason, you are
                                                            prohibited from registering and
                                                            creating a new account under your
                                                            name, a fake or borrowed name, or
                                                            the name of any third party, even if
                                                            you may be acting on behalf of the
                                                            third party. In addition to
                                                            terminating or suspending your
                                                            account, we reserve the right to
                                                            take appropriate legal action,
                                                            including without limitation
                                                            pursuing civil, criminal, and
                                                            injunctive redress.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            MODIFICATIONS AND INTERRUPTIONS
                                                        </h1>
                                                        <p>
                                                            We reserve the right to change,
                                                            modify, or remove the contents of
                                                            the Site at any time or for any
                                                            reason at our sole discretion
                                                            without notice. However, we have no
                                                            obligation to update any information
                                                            on our Site. We also reserve the
                                                            right to modify or discontinue all
                                                            or part of the Site without notice
                                                            at any time. We will not be liable
                                                            to you or any third party for any
                                                            modification, price change,
                                                            suspension, or discontinuance of the
                                                            Site.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            We cannot guarantee the Site will be
                                                            available at all times. We may
                                                            experience hardware, software, or
                                                            other problems or need to perform
                                                            maintenance related to the Site,
                                                            resulting in interruptions, delays,
                                                            or errors. We reserve the right to
                                                            change, revise, update, suspend,
                                                            discontinue, or otherwise modify the
                                                            Site at any time or for any reason
                                                            without notice to you. You agree
                                                            that we have no liability whatsoever
                                                            for any loss, damage, or
                                                            inconvenience caused by your
                                                            inability to access or use the Site
                                                            during any downtime or
                                                            discontinuance of the Site. Nothing
                                                            in these Terms of Use will be
                                                            construed to obligate us to maintain
                                                            and support the Site or to supply
                                                            any corrections, updates, or
                                                            releases in connection therewith.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            GOVERNING LAW
                                                        </h1>
                                                        <p>
                                                            These Terms shall be governed by and
                                                            defined following the laws of
                                                            Australia. Bring Back Australia and
                                                            yourself irrevocably consent that
                                                            the courts of Australia shall have
                                                            exclusive jurisdiction to resolve
                                                            any dispute which may arise in
                                                            connection with these terms.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            DISPUTE RESOLUTION
                                                        </h1>
                                                        <h1 className="text-xl font-bold">
                                                            Informal Negotiations
                                                        </h1>
                                                        <p>
                                                            To expedite resolution and control
                                                            the cost of any dispute,
                                                            controversy, or claim related to
                                                            these Terms of Use (each a "Dispute"
                                                            and collectively, the “Disputes”)
                                                            brought by either you or us
                                                            (individually, a “Party” and
                                                            collectively, the “Parties”), the
                                                            Parties agree to first attempt to
                                                            negotiate any Dispute (except those
                                                            Disputes expressly provided below)
                                                            informally for at least thirty (30)
                                                            days before initiating arbitration.
                                                            Such informal negotiations commence
                                                            upon written notice from one Party
                                                            to the other Party.
                                                        </p>
                                                        <h1 className="text-xl font-bold">
                                                            Binding Arbitration
                                                        </h1>
                                                        <p>
                                                            Any dispute arising out of or in
                                                            connection with this contract,
                                                            including any question regarding its
                                                            existence, validity, or termination,
                                                            shall be referred to and finally
                                                            resolved by the International
                                                            Commercial Arbitration Court under
                                                            the European Arbitration Chamber
                                                            (Belgium, Brussels, Avenue Louise,
                                                            146) according to the Rules of this
                                                            ICAC, which, as a result of
                                                            referring to it, is considered as
                                                            the part of this clause. The number
                                                            of arbitrators shall be three (3).
                                                            The seat, or legal place, of
                                                            arbitration shall be Adelaide,
                                                            Australia. The language of the
                                                            proceedings shall be English. The
                                                            governing law of the contract shall
                                                            be the substantive law of Australia.
                                                        </p>
                                                        <h1 className="text-xl font-bold">
                                                            Restrictions
                                                        </h1>
                                                        <p>
                                                            The Parties agree that any
                                                            arbitration shall be limited to the
                                                            Dispute between the Parties
                                                            individually. To the full extent
                                                            permitted by law,
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                1. no arbitration shall be
                                                                joined with any other
                                                                proceeding;
                                                            </p>
                                                            <p>
                                                                2. there is no right or
                                                                authority for any Dispute to be
                                                                arbitrated on a class-action
                                                                basis or to utilise class action
                                                                procedures; and
                                                            </p>
                                                            <p>
                                                                3. there is no right or
                                                                authority for any Dispute to be
                                                                brought in a purported
                                                                representative capacity on
                                                                behalf of the general public or
                                                                any other persons.
                                                            </p>
                                                        </div>
                                                        <h1 className="text-xl font-bold">
                                                            Exceptions to Informal Negotiations
                                                            and Arbitration
                                                        </h1>
                                                        <p>
                                                            The Parties agree that the following
                                                            Disputes are not subject to the
                                                            above provisions concerning informal
                                                            negotiations and binding
                                                            arbitration:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>
                                                                1. any Disputes seeking to
                                                                enforce or protect, or
                                                                concerning the validity of, any
                                                                of the intellectual property
                                                                rights of a Party;
                                                            </p>
                                                            <p>
                                                                2. any Dispute related to, or
                                                                arising from, allegations of
                                                                theft, piracy, invasion of
                                                                privacy, or unauthorised use;
                                                                and
                                                            </p>
                                                            <p>
                                                                3. any claim for injunctive
                                                                relief.
                                                            </p>
                                                        </div>
                                                        <p>
                                                            If this provision is found to be
                                                            illegal or unenforceable, then
                                                            neither Party will elect to
                                                            arbitrate any Dispute falling within
                                                            that portion of this provision found
                                                            to be illegal or unenforceable and
                                                            such Dispute shall be decided by a
                                                            court of competent jurisdiction
                                                            within the courts listed for
                                                            jurisdiction above, and the Parties
                                                            agree to submit to the personal
                                                            jurisdiction of that court.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            DISCLAIMER
                                                        </h1>
                                                        <p className="uppercase">
                                                            THE SITE IS PROVIDED ON AN AS-IS AND
                                                            AS-AVAILABLE BASIS. YOU AGREE THAT
                                                            YOUR USE OF THE SITE AND OUR
                                                            SERVICES WILL BE AT YOUR SOLE RISK.
                                                            TO THE FULLEST EXTENT PERMITTED BY
                                                            LAW, WE DISCLAIM ALL WARRANTIES,
                                                            EXPRESS OR IMPLIED, IN CONNECTION
                                                            WITH THE SITE AND YOUR USE THEREOF,
                                                            INCLUDING, WITHOUT LIMITATION, THE
                                                            IMPLIED WARRANTIES OF
                                                            MERCHANTABILITY, FITNESS FOR A
                                                            PARTICULAR PURPOSE, AND
                                                            NON-INFRINGEMENT. WE MAKE NO
                                                            WARRANTIES OR REPRESENTATIONS ABOUT
                                                            THE ACCURACY OR COMPLETENESS OF THE
                                                            SITE’S CONTENT OR THE CONTENT OF ANY
                                                            WEBSITES LINKED TO THE SITE AND WE
                                                            WILL ASSUME NO LIABILITY OR
                                                            RESPONSIBILITY FOR ANY
                                                        </p>
                                                        <div className="ml-3">
                                                            <p className="uppercase">
                                                                1. ERRORS, MISTAKES, OR
                                                                INACCURACIES OF CONTENT AND
                                                                MATERIALS,
                                                            </p>
                                                            <p className="uppercase">
                                                                2. PERSONAL INJURY OR PROPERTY
                                                                DAMAGE, OF ANY NATURE
                                                                WHATSOEVER, RESULTING FROM YOUR
                                                                ACCESS TO AND USE OF THE SITE,
                                                            </p>
                                                            <p className="uppercase">
                                                                3. ANY UNAUTHORISED ACCESS TO OR
                                                                USE OF OUR SECURE SERVERS AND/OR
                                                                ANY AND ALL PERSONAL INFORMATION
                                                                AND/OR FINANCIAL INFORMATION
                                                                STORED THEREIN,
                                                            </p>
                                                            <p className="uppercase">
                                                                4. ANY INTERRUPTION OR CESSATION
                                                                OF TRANSMISSION TO OR FROM THE
                                                                SITE,
                                                            </p>
                                                            <p className="uppercase">
                                                                5. ANY BUGS, VIRUSES, TROJAN
                                                                HORSES, OR THE LIKE WHICH MAY BE
                                                                TRANSMITTED TO OR THROUGH THE
                                                                SITE BY ANY THIRD PARTY, AND/OR
                                                            </p>
                                                            <p className="uppercase">
                                                                6. ANY ERRORS OR OMISSIONS IN
                                                                ANY CONTENT AND MATERIALS OR FOR
                                                                ANY LOSS OR DAMAGE OF ANY KIND
                                                                INCURRED AS A RESULT OF THE USE
                                                                OF ANY CONTENT POSTED,
                                                                TRANSMITTED, OR OTHERWISE MADE
                                                                AVAILABLE VIA THE SITE.
                                                            </p>
                                                        </div>
                                                        <br />
                                                        <p className="uppercase">
                                                            WE DO NOT WARRANT, ENDORSE,
                                                            GUARANTEE, OR ASSUME RESPONSIBILITY
                                                            FOR ANY PRODUCT OR SERVICE
                                                            ADVERTISED OR OFFERED BY A THIRD
                                                            PARTY THROUGH THE SITE, ANY
                                                            HYPERLINKED WEBSITE, OR ANY WEBSITE
                                                            OR MOBILE APPLICATION FEATURED IN
                                                            ANY BANNER OR OTHER ADVERTISING, AND
                                                            WE WILL NOT BE A PARTY TO OR IN ANY
                                                            WAY BE RESPONSIBLE FOR MONITORING
                                                            ANY TRANSACTION BETWEEN YOU AND ANY
                                                            THIRD-PARTY PROVIDERS OF PRODUCTS OR
                                                            SERVICES. AS WITH THE PURCHASE OF A
                                                            PRODUCT OR SERVICE THROUGH ANY
                                                            MEDIUM OR IN ANY ENVIRONMENT, YOU
                                                            SHOULD USE YOUR BEST JUDGMENT AND
                                                            EXERCISE CAUTION WHERE APPROPRIATE.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            LIMITATIONS OF LIABILITY
                                                        </h1>
                                                        <p className="uppercase">
                                                            IN NO EVENT WILL WE OR OUR
                                                            DIRECTORS, EMPLOYEES, OR AGENTS BE
                                                            LIABLE TO YOU OR ANY THIRD PARTY FOR
                                                            ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                                                            EXEMPLARY, INCIDENTAL, SPECIAL, OR
                                                            PUNITIVE DAMAGES, INCLUDING LOST
                                                            PROFIT, LOST REVENUE, LOSS OF DATA,
                                                            OR OTHER DAMAGES ARISING FROM YOUR
                                                            USE OF THE SITE, EVEN IF WE HAVE
                                                            BEEN ADVISED OF THE POSSIBILITY OF
                                                            SUCH DAMAGES. NOTWITHSTANDING
                                                            ANYTHING TO THE CONTRARY CONTAINED
                                                            HEREIN, OUR LIABILITY TO YOU FOR ANY
                                                            CAUSE WHATSOEVER AND REGARDLESS OF
                                                            THE FORM OF THE ACTION, WILL AT ALL
                                                            TIMES BE LIMITED TO THE AMOUNT PAID,
                                                            IF ANY, BY YOU TO US DURING THE
                                                            THREE (3) MONTH PERIOD PRIOR TO ANY
                                                            CAUSE OF ACTION ARISING. CERTAIN US
                                                            STATE LAWS AND INTERNATIONAL LAWS DO
                                                            NOT ALLOW LIMITATIONS ON IMPLIED
                                                            WARRANTIES OR THE EXCLUSION OR
                                                            LIMITATION OF CERTAIN DAMAGES. IF
                                                            THESE LAWS APPLY TO YOU, SOME OR ALL
                                                            OF THE ABOVE DISCLAIMERS OR
                                                            LIMITATIONS MAY NOT APPLY TO YOU,
                                                            AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            INDEMNIFICATION
                                                        </h1>
                                                        <p>
                                                            You agree to defend, indemnify, and
                                                            hold us harmless, including our
                                                            subsidiaries, affiliates, and all of
                                                            our respective officers, agents,
                                                            partners, and employees, from and
                                                            against any loss, damage, liability,
                                                            claim, or demand, including
                                                            reasonable attorneys’ fees and
                                                            expenses, made by any third party
                                                            due to or arising out of:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p>1. your Contributions;</p>
                                                            <p>2. use of the Site;</p>
                                                            <p>
                                                                3. breach of these Terms of Use;
                                                            </p>
                                                            <p>
                                                                4. any breach of your
                                                                representations and warranties
                                                                set forth in these Terms of Use;
                                                            </p>
                                                            <p>
                                                                5. your violation of the rights
                                                                of a third party, including but
                                                                not limited to intellectual
                                                                property rights; or
                                                            </p>
                                                            <p>
                                                                6. any overt harmful act toward
                                                                any other user of the Site with
                                                                whom you connected via the Site.
                                                            </p>
                                                        </div>
                                                        <p>
                                                            Notwithstanding the foregoing, we
                                                            reserve the right, at your expense,
                                                            to assume the exclusive defense and
                                                            control of any matter for which you
                                                            are required to indemnify us, and
                                                            you agree to cooperate, at our
                                                            expense, with our defense of such
                                                            claims. We will use reasonable
                                                            efforts to notify you of any such
                                                            claim, action, or proceeding which
                                                            is subject to this indemnification
                                                            upon becoming aware of it.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            USER DATA
                                                        </h1>
                                                        <p>
                                                            We will maintain certain data that
                                                            you transmit to the Site for the
                                                            purpose of managing the performance
                                                            of the Site, as well as data
                                                            relating to your use of the Site.
                                                            Although we perform regular routine
                                                            backups of data, you are solely
                                                            responsible for all data that you
                                                            transmit or that relates to any
                                                            activity you have undertaken using
                                                            the Site. You agree that we shall
                                                            have no liability to you for any
                                                            loss or corruption of any such data,
                                                            and you hereby waive any right of
                                                            action against us arising from any
                                                            such loss or corruption of such
                                                            data.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            ELECTRONIC COMMUNICATIONS,
                                                            TRANSACTIONS, AND SIGNATURES
                                                        </h1>
                                                        <p>
                                                            Visiting the Site, sending us
                                                            emails, and completing online forms
                                                            constitute electronic
                                                            communications. You consent to
                                                            receive electronic communications,
                                                            and you agree that all agreements,
                                                            notices, disclosures, and other
                                                            communications we provide to you
                                                            electronically, via email and on the
                                                            Site, satisfy any legal requirement
                                                            that such communication be in
                                                            writing.
                                                        </p>
                                                        <br />
                                                        <p className="uppercase">
                                                            YOU HEREBY AGREE TO THE USE OF
                                                            ELECTRONIC SIGNATURES, CONTRACTS,
                                                            ORDERS, AND OTHER RECORDS, AND TO
                                                            ELECTRONIC DELIVERY OF NOTICES,
                                                            POLICIES, AND RECORDS OF
                                                            TRANSACTIONS INITIATED OR COMPLETED
                                                            BY US OR VIA THE SITE.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            You hereby waive any rights or
                                                            requirements under any statutes,
                                                            regulations, rules, ordinances, or
                                                            other laws in any jurisdiction which
                                                            require an original signature or
                                                            delivery or retention of
                                                            non-electronic records, or to
                                                            payments or the granting of credits
                                                            by any means other than electronic
                                                            means.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            MISCELLANEOUS
                                                        </h1>
                                                        <p>
                                                            These Terms of Use and any policies
                                                            or operating rules posted by us on
                                                            the Site or in respect to the Site
                                                            constitute the entire agreement and
                                                            understanding between you and us.
                                                            Our failure to exercise or enforce
                                                            any right or provision of these
                                                            Terms of Use shall not operate as a
                                                            waiver of such right or provision.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            These Terms of Use operate to the
                                                            fullest extent permissible by law.
                                                            We may assign any or all of our
                                                            rights and obligations to others at
                                                            any time. We shall not be
                                                            responsible or liable for any loss,
                                                            damage, delay, or failure to act
                                                            caused by any cause beyond our
                                                            reasonable control.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            If any provision or part of a
                                                            provision of these Terms of Use is
                                                            determined to be unlawful, void, or
                                                            unenforceable, that provision or
                                                            part of the provision is deemed
                                                            severable from these Terms of Use
                                                            and does not affect the validity and
                                                            enforceability of any remaining
                                                            provisions. There is no joint
                                                            venture, partnership, employment or
                                                            agency relationship created between
                                                            you and us as a result of these
                                                            Terms of Use or use of the Site.
                                                        </p>
                                                        <br />
                                                        <p>
                                                            You agree that these Terms of Use
                                                            will not be construed against us by
                                                            virtue of having drafted them. You
                                                            hereby waive any and all defenses
                                                            you may have based on the electronic
                                                            form of these Terms of Use and the
                                                            lack of signing by the parties
                                                            hereto to execute these Terms of
                                                            Use.
                                                        </p>
                                                    </section>

                                                    <section className="mt-4">
                                                        <h1 className="uppercase text-xl font-bold">
                                                            CONTACT US
                                                        </h1>
                                                        <p>
                                                            In order to resolve a complaint
                                                            regarding the Site or to receive
                                                            further information regarding use of
                                                            the Site, please contact us at:
                                                        </p>
                                                        <div className="ml-3">
                                                            <p className="font-bold">
                                                                Bring Back Australia <br /> PO
                                                                Box 299 <br /> Welland, SA 5007{" "}
                                                                <br /> Australia
                                                            </p>
                                                            <br />
                                                            <p>
                                                        <span className="font-bold">
                                                            Phone:
                                                        </span>{" "}
                                                                (+61) 1300 663 287
                                                            </p>
                                                            <p>
                                                        <span className="font-bold">
                                                            Email:
                                                        </span>{" "}
                                                                <a
                                                                    href="mailto:legal@bringbackaustralia.app"
                                                                    className="text-blue-500"
                                                                >
                                                                    legal@bringbackaustralia.app
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </section>
                                                </div>
                                                {/* <iframe src="https://members.bringback.app/termsconditions" className="w-full p-0 m-0" height={'250x'} frameborder="0"></iframe> */}
                                            </div>
                                            <div className="flex items-center mt-3">
                                                <label
                                                    htmlFor="agree"
                                                    className="cursor-pointer inline-flex lg:items-center md:items-center text-[15px] font-normal"
                                                >
                                                    <input
                                                        onChange={(e) => handlePayment(e.target.checked)}
                                                        checked={form?.termCondition || false}
                                                        id="agree"
                                                        type="checkbox"
                                                        className="hidden peer"
                                                    />
                                                    <span className="mr-3 mt-1 peer-checked:bg-gray-800 bg-white h-[16px] min-w-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.7px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-gray-800 rounded-sm"></span>
                                                    {t(
                                                        "common:confirm_term_condition_text"
                                                    ).toString()}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {!!message && (
                                        <div className="p-2  ml-[10.2rem] mb-2 mt-2 text-base text-red-500" role="alert">
                                            {message}
                                        </div>
                                    )}
                                </div>
                            }

                    </div>
                    {stripePromise && clientSecret && isPaymentLoad && selectedOption !== 'Trial' && (
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <GetStartedAccountPaymentComponent navigate={navigate} />
                        </Elements>
                    )}
                    {
                        backButton &&
                        <div className="flex justify-center items-center mt-5 gap-4">
                            <button
                                onClick={back}
                                className="px-8 py-2 bg-gray-500 uppercase text-white rounded-md border"
                            >
                                {t("common:back_button_text").toString()}
                            </button>
                            {byPassPayment &&
                            <button
                                onClick={() => handleSubmit()}
                                disabled={isProcessing}
                                className="px-8 py-2 bg-primary uppercase text-white rounded-md border"
                            >
                                {isProcessing
                                    ? t("common:processing").toString()
                                    : 'Submit'}
                            </button>
                            }
                        </div>
                    }
                </>
            }

        </div>
    );
};

export default GetStartedAccountPayment;
