import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import SectionContainerRow from '../../components/SectionContainerRow';
import UnassignedWordsContainer from './UnassignedWordsContainer';
import FunctionalGroupWordsWrapper from './FuncGroupWordsWrapper';
import { SelectedBrickContext } from './SelectedBrickContext';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import './ProductBricks.css';

const ProductBricks = () => {

  const [brickInput, setBrickInput, onBrickInputChange] = useFormFieldOnChange('10000025');

  const searchBrick = () => {
    setBrickInput(new String(brickInput));
  };

  return (
    <PageSkeletonComponent>
      <div className="BricksContainer">
        <div className="BrickSearchContainer">
          <div className="BrandsGlnSearchResult">
            <span className="Label">BRICK: {brickInput}</span>
            <span className="Company"></span>
          </div>
          <label htmlFor="brick_search">BRICK: </label>
          <input type="text" id="brick_search" onChange={onBrickInputChange} value={brickInput} />
          <button onClick={searchBrick}>Search</button>
        </div>

        <SelectedBrickContext.Provider value={{
          selectedBrick: new String(brickInput),
          refreshBrick: searchBrick
        }}>
          <SectionContainerRow>
            <FunctionalGroupWordsWrapper />
          </SectionContainerRow>
          <UnassignedWordsContainer />
        </SelectedBrickContext.Provider>
      </div>
    </PageSkeletonComponent>
  );
};

export default ProductBricks;