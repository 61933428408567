import DashboardContainer from "../../dashboard-components/DashboardContainer";
import React, { useEffect, useCallback, useState, Fragment } from "react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import ReactDataGrid, { filterTypes } from "@inovua/reactdatagrid-enterprise";
import { HTTP_STATUS_CODES } from "../../api/API";
import {
  productsPage,
  setActive,
  setLimit,
  setPage,
  fetchData,
  setDataSource,
  setFilterValues,
} from "../../store/slices/productsPageSlice";
import "@inovua/reactdatagrid-enterprise/index.css";
import defaultColumns from "./productGridColumns";

import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Tooltip from "../../components/tooltip";

import AssignBrandDialog from "../../components/Dialogs/AssignBrandDialog";
import AssignProductCategory from "../../components/Dialogs/AssignProductCategory";
import AssignAttributes from "../../components/Dialogs/AssignAttributes";

import { setError } from "../../store/slices/brandSlice";

const AuditProduct = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userAuth = new useAuthContext();
  const userparentCompanies = userAuth.parentCompanies;
  const { active, dataSource, page, limit, filterValues } =
    useAppSelector(productsPage);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const [entityGln, setEntityGln] = useState(true);
  const [brick, setBrick] = useState(true);
  const [gtinValue, setGtinValue] = useState("");
  const [gridRef, setGridRef] = useState(null);
  const [assignBrandDialog, setAssignBrandDialog] = useState(false);
  const [assignProductCategory, setAssignProductCategory] = useState(false);
  const [assignAttributes, setAssignAttributes] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleFilterValueChange = (o) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Dispatch your action after the timeout
      dispatch(setFilterValues(o));
    }, 2000); // Adjust the timeout value as needed (2 second in this example)

    // Save the new timeout ID in the state
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    loadData();
  }, [active, page, limit, filterValues]);

  const loadData = () => {
    const fetchApiData = async ({ ParentCompanyID, filter, page, limit }) => {
      setLoading(true);
      const res = await dispatch(
        fetchData(ParentCompanyID, filter, page, limit)
      );

      if (res.status !== HTTP_STATUS_CODES.OK) {
        setLoading(false);
        return Promise.resolve({ data: [], count: 0 });
      }
      setLoading(false);
      return Promise.resolve({
        data: res.data.data,
        count: res.data.total,
      });
    };

    dispatch(
      setDataSource(
        fetchApiData({
          ParentCompanyID: userparentCompanies?.ParentCompanyID,
          active: active,
          filter: filterValues,
          page: page,
          limit: limit,
        })
      )
    );
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const openBrandModal = () => {
    setIsOpenBrand(true);
  };
  const closeBrandModal = () => {
    setIsOpenBrand(false);
    setTimeout(() => {
      dispatch(setError(""));
    }, 1000);
  };

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    if (selected.length) {
    } else if (Object.keys(selected).length !== 0) {
      setEntityGln(true);
      setBrick(true);
      for (let i = 0; i < Object.keys(selected).length; i++) {
        setGtinValue(Object.values(selected)[i].GTIN);
        if (
          (i > 0 &&
            Object.values(selected)[i - 1].EntityGLN !==
              Object.values(selected)[i].EntityGLN) ||
          (i > 0 &&
            Object.values(selected)[i - 1].CompanyID !==
              Object.values(selected)[i].CompanyID)
        ) {
          setEntityGln(false);
          break;
        }
      }
      for (let i = 0; i < Object.keys(selected).length; i++) {
        if (
          i > 0 &&
          Object.values(selected)[i - 1].BrickID !==
            Object.values(selected)[i].BrickID
        ) {
          setBrick(false);
          break;
        }
      }

      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: (
            <div className="group inline-block group1 inline-block1">
              <ul className="bg-white rounded-sm origin-top min-w-32 ul border">
                <li className="rounded-sm relative px-3 py-1 primary li border">
                  <button className="w-full text-left flex items-center outline-none focus:outline-none button text-left">
                    <span className="pr-1 flex-1">Batch Updates</span>
                    <span className="mr-auto">
                      <svg
                        className="fill-current h-4 w-4 transition duration-150 ease-in-out svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </button>
                  <ul className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32 ul z-index-1">
                    {entityGln ? (
                      <li
                        className="px-3 py-1 primary li border"
                        onClick={() => {
                          openBrandModal();
                        }}
                      >
                        Brand
                      </li>
                    ) : (
                      <li className="px-3 py-1 li border text-gray-400 cursor-not-allowed">
                        Brand
                      </li>
                    )}
                  </ul>
                </li>
                <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
                  <Link
                    to={`/edit-product/` + gtinValue}
                    className="w-full text-left"
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      className="w-full text-left"
                    >
                      Edit Details
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          ),
        },
      ];
    } else {
      const { GTIN } = rowProps.data;
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: (
            <div className="group inline-block group1 inline-block1">
              <ul className="bg-white rounded-sm origin-top min-w-32 ul border">
                <li className="rounded-sm relative px-3 py-1 primary li border">
                  <button className="w-full text-left flex items-center outline-none focus:outline-none button text-left">
                    <span className="pr-1 flex-1">Batch Updates</span>
                    <span className="mr-auto">
                      <svg
                        className="fill-current h-4 w-4 transition duration-150 ease-in-out svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </button>
                  <ul className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32 ul z-index-1">
                    <li
                      className="px-3 py-1 primary li border"
                      onClick={() => {
                        openBrandModal();
                      }}
                    >
                      Brand
                    </li>
                  </ul>
                </li>
                <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
                  <Link
                    to={`/edit-product/${GTIN}`}
                    className="w-full text-left"
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      className="w-full text-left"
                    >
                      Edit Details
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          ),
        },
      ];
    }
  };
  return (
    <>
      <DashboardContainer title={""}>
        <div className="">
          <div className="bg-white rounded-t-none rounded-b">
            {[1, 2, 3].map((val) => {
              return val === active ? (
                <ReactDataGrid
                  key={val} // Ensure each component has a unique key
                  licenseKey={
                    "AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988"
                  }
                  onReady={setGridRef}
                  idProperty="ProductID"
                  style={{ marginTop: 10, minHeight: "90vh" }}
                  columns={defaultColumns}
                  onSelectionChange={onSelectionChange}
                  defaultFilterValue={filterValues}
                  filterTypes={filterTypes}
                  rowHeight={90}
                  renderRowContextMenu={renderRowContextMenu}
                  pagination="remote"
                  onFilterValueChange={(o) => handleFilterValueChange(o)}
                  dataSource={dataSource}
                  onSkipChange={(o) =>
                    dispatch(
                      setPage(Math.round(Math.max(o - 1, 0) / limit) + 1)
                    )
                  }
                  onLimitChange={(o) => dispatch(setLimit(o))}
                  defaultLimit={10}
                />
              ) : null;
            })}
          </div>
        </div>

        <AssignBrandDialog
          setAssignBrandDialog={setAssignBrandDialog}
          assignBrandDialog={assignBrandDialog}
        />
        <AssignProductCategory
          setAssignProductCategory={setAssignProductCategory}
          assignProductCategory={assignProductCategory}
        />
        <AssignAttributes
          setAssignAttributes={setAssignAttributes}
          assignAttributes={assignAttributes}
        />
      </DashboardContainer>
      {isOpenBrand ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ minWidth: "48rem" }}
            >
              {/*content*/}
              <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Select Brands Dialogue Box
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeBrandModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                    <h6 className="w-full max-w-[120px] flex justify-end">
                      Search Brands:
                    </h6>
                    <input
                      placeholder="My Brand"
                      className="rounded-md w-full max-w-3xl font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                      type="text"
                      name="brand"
                      value=""
                    />
                  </div>
                  <div className="relative pl-32 flex-auto">
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                      <label
                        htmlFor="radio1"
                        className="inline-flex items-center"
                      >
                        <input
                          type="radio"
                          id="radio1"
                          className="form-radio h-5 w-5 text-primary"
                          name="radio"
                        />
                        <span className="ml-2">My Brand - 1</span>
                      </label>
                    </div>
                  </div>
                  <div className="relative pl-32 flex-auto">
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                      <label
                        htmlFor="radio2"
                        className="inline-flex items-center"
                      >
                        <input
                          type="radio"
                          id="radio2"
                          className="form-radio h-5 w-5 text-primary"
                          name="radio"
                        />
                        <span className="ml-2">My Brand - 2</span>
                      </label>
                    </div>
                  </div>
                  <div className="relative pl-32 flex-auto">
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                      <label
                        htmlFor="radio3"
                        className="inline-flex items-center"
                      >
                        <input
                          type="radio"
                          id="radio3"
                          className="form-radio h-5 w-5 text-primary"
                          name="radio"
                        />
                        <span className="ml-2">My Brand - 3</span>
                      </label>
                    </div>
                  </div>
                  <div className="relative pl-32 flex-auto">
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                      <label
                        htmlFor="radio4"
                        className="inline-flex items-center"
                      >
                        <input
                          type="radio"
                          id="radio4"
                          className="form-radio h-5 w-5 text-primary"
                          name="radio"
                        />
                        <span className="ml-2">My Brand - 4</span>
                      </label>
                    </div>
                  </div>
                  <div className="relative pl-32 flex-auto">
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                      <label
                        htmlFor="radio5"
                        className="inline-flex items-center"
                      >
                        <input
                          type="radio"
                          id="radio5"
                          className="form-radio h-5 w-5 text-primary"
                          name="radio"
                        />
                        <span className="ml-2">My Brand - 5</span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b gap-5">
                  <button
                    className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
                    type="button"
                    onClick={closeBrandModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primary py-2 w-24 rounded-lg text-white"
                    type="button"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
};

export default AuditProduct;
