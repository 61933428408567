import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './RetailerProducts.css';

const ProductImages = () => {
  return (
    <PageSkeletonComponent>
     <h1>RETAILER - Products</h1>
      <br />
      <hr />

      <h2>Purpose of Page</h2>
      This page is the page for the retailer to view the products that they stock.<br /><br />
      The user should be able to view by category and see what they are stocking, the origin of their products and substitutes that are available that are Australian Owned and/or Australian Made
      <br /><br />A summary page showing products and status...
      
      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      What can Employees do in relation to products?  What should Employees be able to do in relation to products?

      <h3>Supplier Subscribers</h3>
      What can Suppliers do in relation to products?  What should Suppliers be able to do in relation to products?
      
      <h3>Retailer Subscribers</h3>
      What can Retailers do in relation to products?  What should Retailers be able to do in relation to products?

      <h3>Service Subscribers</h3>
      What can Service Providers do in relation to products?  What should Service Providers be able to do in relation to products?

    </PageSkeletonComponent>
  );
};

export default ProductImages;