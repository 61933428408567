import React from 'react';

export type SelectedBrick = {
  BRICK: string,
  BrickDesc: string,
  refreshBrick: () => void,
  setSelectedBrick: (brick: string | null) => void
  setSelectedBrickDesc: (brick: string | null) => void
}

export const ContextSelectedBrick = React.createContext<SelectedBrick>({
  BRICK: '',
  BrickDesc: '',
  refreshBrick: () => { },
  setSelectedBrick: (brick: string | null) => { },
  setSelectedBrickDesc: (brickDesc: string | null) => { }
});