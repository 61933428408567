import React, { useContext, useEffect, useState } from 'react';
import { getAssignedWords } from '../../../api/GroupVariantDetail';
import DataTable, { ActionRow, Column } from '../../components/DataTable';
import SectionContainer from '../../components/SectionContainer';
import { FunctionalGroupWordsContext } from './FuncGroupContext';
import { SelectedBrickContext } from './SelectedBrickContext';

const FunctionalGroupWordsContainer = () => {

  const { selectedBrick } = useContext(SelectedBrickContext);

  const { selectedVariant } = useContext(FunctionalGroupWordsContext);

  const [words, setWords] = useState([]);

  const fetchWords = async () => {
    const reqWords = await getAssignedWords(selectedBrick, selectedVariant.Group, selectedVariant.Variant, selectedVariant.Detail);
    setWords(reqWords !== false ? reqWords.data : []);
  };

  useEffect(() => {
    selectedBrick?.trim().length && selectedVariant && fetchWords();
  }, [selectedBrick, selectedVariant]);

  const onClickRow = async (e, item) => {

  };

  const containerColumns = [
    Column({ title: 'Words', rowProperty: 'Words', beforePrint: (value) => value.join(' - ') })
  ];

  const editAction = ActionRow({
    description: 'Edit',
    actionFunction: (e, brand) => {
      e.preventDefault();
    }
  });

  return (
    <SectionContainer>
      <DataTable
        onClickRow={onClickRow}
        columns={containerColumns}
        rowIdentifier={'WordMatchID'}
        rowData={words}
        actions={[editAction]} />
    </SectionContainer>
  );
};

export default FunctionalGroupWordsContainer;