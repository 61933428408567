import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './DatabaseBrowser.css';

const DatabaseBrowser = () => {
  return (
    <PageSkeletonComponent>

     <h1>DATABASE - Database Browser Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      <b>This page is used to display the structure and detail of the database.</b>
      <br /><br />
      Display table information, indexes, foreign keys and any other pertinent information relating to the table.


      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>
      <br /><br />
      Only eligible members of Bring Back are able to view these pages.<br /><br />
      This is not for suppliers, retailers or other users of the site to have access to.

    </PageSkeletonComponent>
  );
};

export default DatabaseBrowser;