import { createContext, useContext, useState, useEffect } from "react";
import {
  isLoggedIn,
  getUser,
  setUser as setUserProvider,
  getToken,
  setToken as setTokenProvider,
  logoutUser as logoutUserProvider,
  getUserSignupInfo,
  setUserSignupInfo as setUserSignupInfoProvider,
  getParentCompanies,
  setParentCompanies as setParentCompaniesProvider,
} from "../provider/AuthProvider";

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export const useAuthState = () => {
  const [isUserLoggedIn, setIsLoggedIn] = useState(isLoggedIn());

  const [user, setUserState] = useState(getUser());

  const [signupInfo, setSignupInfo] = useState(getUserSignupInfo());

  const [token, setTokenState] = useState(getToken());

  const [parentCompanies, setParentCompanies] = useState(getParentCompanies());
  const [isNewUser, setIsNewUser] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isRetailer, setIsRetailer] = useState(false);
  const [isServiceProvider, setIsServiceProvider] = useState(false);
  const [isAccreditation, setIsAccreditation] = useState(false);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    try {
      if (isUserLoggedIn) {
        if (parentCompanies !== null) {
          const subscription = parentCompanies?.Subscription || [];
          const companies = parentCompanies?.Companies || [];
          const groups = parentCompanies?.Groups || [];
          const stores = parentCompanies?.Stores || [];
          const accreditations = parentCompanies?.Accreditation || [];

          setIsSupplier(companies.length > 0);
          setIsRetailer(groups.length > 0);
          setIsServiceProvider(stores.length > 0);
          setIsAccreditation(accreditations.length > 0);
          setSubscription(subscription);
          setIsEmployee(false);
          setIsNewUser(false);
        } else {
          setIsSupplier(false);
          setIsServiceProvider(false);
          setIsRetailer(false);
          setIsAccreditation(false);
          setSubscription([]);

          if (user.isSuperAdmin || user.isAdmin || user.isUser) {
            setIsEmployee(true);
            setIsNewUser(false);
          } else {
            setIsEmployee(false);
            setIsNewUser(true);
          }
        }
      } else {
        setIsSupplier(false);
        setIsRetailer(false);
        setIsServiceProvider(false);
        setIsAccreditation(false);
        setSubscription([]);
        setIsEmployee(false);
        setIsNewUser(false);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  }, [parentCompanies, user, isUserLoggedIn]);

  const logoutUser = () => {
    logoutUserProvider();
    setIsLoggedIn(false);
    setUserState(null);
    setSignupInfo(null);
    setTokenState(null);
    setParentCompanies(null);
    setIsSupplier(false);
    setIsRetailer(false);
    setIsServiceProvider(false);
    setIsAccreditation(false);
    setSubscription([]);
    setIsEmployee(false);
    setIsNewUser(false);
    window.location.href = "/";
  };

  const logoutUserAfterPayment = () => {
    logoutUserProvider();
    setIsLoggedIn(false);
    setUserState(null);
    setSignupInfo(null);
    setTokenState(null);
    setParentCompanies(null);
    setIsSupplier(false);
    setIsRetailer(false);
    setIsServiceProvider(false);
    setIsAccreditation(false);
    setSubscription([]);
    setIsEmployee(false);
    setIsNewUser(false);
  };

  const setUser = (user) => {
    setUserProvider(user);
    setUserState(user);
  };

  const setSignupInfoInformation = (user) => {
    setUserSignupInfoProvider(user);
    setSignupInfo(user);
  };

  const setToken = (token) => {
    setTokenProvider(token);
    setTokenState(token);
    setIsLoggedIn(true);
  };

  const setParentCompaniesInformation = (user) => {
    setParentCompaniesProvider(user);
    setParentCompanies(user);
  };

  const isUserSupplier = () => {
    return (
      isUserLoggedIn &&
      user.hasSignedUp &&
      user.isSupplier &&
      user.isApprovalBeingProcessed
    );
  };

  const isUserRetailer = () => {
    return (
      isUserLoggedIn &&
      user.hasSignedUp &&
      user.isRetailer &&
      user.isApprovalBeingProcessed
    );
  };

  const isUserServiceProvider = () => {
    return (
      isUserLoggedIn &&
      user.hasSignedUp &&
      user.isServiceProvider &&
      user.isApprovalBeingProcessed
    );
  };

  const isUserEmployee = () => {
    return isUserLoggedIn && user.isEmployee;
  };

  const isAdmin = () => {
    return isUserLoggedIn && user.isAdmin;
  };

  const newUser = () => {
    return (
      isUserLoggedIn &&
      !user.isEmployee &&
      !user.isSupplier &&
      !user.isRetailer &&
      !user.isAdmin &&
      !user.isServiceProvider &&
      !user.isUserApp
    );
  };

  return {
    isUserLoggedIn,
    isNewUser,
    isSupplier,
    isServiceProvider,
    isEmployee,
    isRetailer,
    isAccreditation,
    subscription,
    isUserSupplier,
    isUserRetailer,
    isUserServiceProvider,
    isUserEmployee,
    newUser,
    isAdmin,
    user,
    setUser,
    token,
    setToken,
    logoutUser,
    setSignupInfoInformation,
    signupInfo,
    parentCompanies,
    setParentCompaniesInformation,
    logoutUserAfterPayment,
  };
};
