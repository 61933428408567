import { format } from "date-fns/esm";
import React, { useState } from "react";
import ApprovalModal from "./ApprovalModal";
import {
    fetchSignUpDetails
} from "../../store/slices/signupApprovalSlice";
import { useAppDispatch } from "../../store/store";

const APPROVAL_STATUS_NEW_ENTRY = 1000088;

const RowItem = ({ account, refreshCallback, index }) => {
    const dispatch = useAppDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    const openModal = ()=>{
        setModalOpen(true);
        const SignupID = account.ID
        {account.Status === APPROVAL_STATUS_NEW_ENTRY && (
        dispatch(fetchSignUpDetails(SignupID))
        )}
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const formattedDate = format(
        new Date(account.LastUpdate),
        "dd/MM/yyyy HH:mm:ss"
    );

    return (
        <>
            <div
                className={`flex justify-between items-center flex-row gap-4 p-2 cursor-pointer hover:bg-[#707070] hover:text-white  ${
                    index % 2 !== 0 && "bg-[#cce4dc]"
                }`}
                onClick={openModal}
            >
                <p className="truncate w-1/4">{account?.CompanyName}</p>
                <p className="truncate w-1/4">
                    {account?.user?.NameFirst} {account?.user?.NameSurname}
                </p>
                <p className="truncate w-1/4">
                    {account?.user?.Position || "-"}
                </p>
                <p className="truncate w-1/4">{formattedDate}</p>
            </div>
            {account.Status === APPROVAL_STATUS_NEW_ENTRY && (
                <ApprovalModal
                    closeModal={closeModal}
                    modalOpen={modalOpen}
                    refreshCallback={()=> {
                        refreshCallback()
                    }}
                />
            )}
        </>
    );
};

export default RowItem;
