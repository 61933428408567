import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { auth, signIn, setError, SignupInfoInformation, signInPromptEndpoint } from "../../store/slices/authSlice";

const AuthPrompt = ({ open, requestType, payload }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState(payload?.email);
    const [password, setPassword] = useState("");

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const userContext = useAuthContext();

    const renderDialogContent = () => {
        switch (requestType) {
            case "SignUp":
                return renderSignUpContent();
            default:
                return renderSignInContent();
        }
    };

    async function handleSubmit() {
        if (requestType === 'SignIn') {
            dispatch(signInPromptEndpoint({ UserID: payload?.id, isWebUser: 1 }));
        }

        const req = await dispatch(signIn(payload?.email, password));

        if (req && req?.status === 200) {
            setTimeout(async () => {
                if (req?.data?.ParentCompanies && req?.data?.ParentCompanies?.[0]) {
                    userContext.setParentCompaniesInformation(req.data.ParentCompanies[0]);
                }

                setEmail("");
                setPassword("");
                dispatch(setError(""));
                userContext.setUser(req.data.user);
                userContext.setToken({
                    access_token: req.data.access_token,
                });

                if (!(req?.data?.ParentCompanies && req?.data?.ParentCompanies?.[0])) {
                    try {
                        let SignupInfoData = await dispatch(SignupInfoInformation({ FK_UserID: req.data?.user?.UserID }));
                        userContext.setSignupInfoInformation(SignupInfoData?.data?.SignupInfo[0]);
                    } catch (error) {
                        // Handle the error from dispatch(SignupInfoInformation(...)) if needed
                        console.error("Error in SignupInfoInformation:", error);
                    }
                }
            });
        }
    }

    const renderSignInContent = () => (
        <>
            <div className="flex items-center gap-4">
                <div className="bg-gray-400 text-white w-10 h-10 rounded-full flex justify-center items-center text-xl">
                    i
                </div>
                <p className="text-sm">Hi {payload?.firstname ?? ""},</p>
            </div>
            <div className="ml-14 space-y-3 mt-3">
                <div>
                    <p className="text-sm">
                        Our system has noticed that you have previously signed up for Bring Back Australia's mobile app.
                    </p>
                    <p className="text-sm">
                        If you would like to upgrade your account to become a supplier on our web portal.
                    </p>
                </div>
                <div className="space-y-3">
                    <p className="text-sm">
                        Please enter your password:
                    </p>
                    <div className="flex items-center">
                        <p className="w-[100px] text-sm">
                            Email:
                        </p>
                        <p className="text-sm font-bold w-full text-left">
                            {payload?.email ?? "----"}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p className="w-[100px] text-sm">
                            Password:
                        </p>
                        <input
                            type="password"
                            className="rounded-md text-sm w-full border py-1.5 px-2"
                            name="password"
                            placeholder="Enter your password"
                            onChange={(e) => setPassword(e.target.value)}
                            id=""
                        />
                    </div>
                    <div className="flex justify-end">
                        <Link
                            to={"/ForgotPassword"}
                            className="text-sm"
                        >
                            Forgot password
                        </Link>
                    </div>
                </div>
                <div className="flex justify-center items-center gap-4">
                    <button onClick={() => setIsOpen(false)} className="bg-gray-400 rounded-lg py-2 px-5 text-white">
                        Cancel
                    </button>
                    <button onClick={() => handleSubmit()} className="bg-primary rounded-lg  py-2 px-5 text-white">
                        Proceed
                    </button>
                </div>
            </div>
        </>
    );

    const renderSignUpContent = () => (
        <>
            <div className="flex items-center gap-4">
                <div className="bg-gray-400 text-white w-10 h-10 rounded-full flex justify-center items-center text-xl">
                    i
                </div>
                <p className="text-sm">Hi {payload?.firstname ?? ""},</p>
            </div>
            <div className="ml-14 space-y-3 mt-3">
                <div>
                    <p className="text-sm">
                        Our system has noticed that you
                        already have an existing account with
                        Bring Back Australia.
                    </p>
                    <p className="text-sm">
                        If you would like to sign in
                        your account. Please enter your password.
                    </p>
                </div>
                <div className="space-y-3">
                    <div className="flex items-center">
                        <p className="w-[100px] text-sm">
                            Email:
                        </p>
                        <p className="text-sm font-bold w-full text-left">
                            {payload?.email ?? "----"}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p className="w-[100px] text-sm">
                            Password:
                        </p>
                        <input
                            type="password"
                            className="rounded-md text-sm w-full border py-1.5 px-2"
                            name="password"
                            placeholder="Enter your password"
                            onChange={(e) => setPassword(e.target.value)}
                            id=""
                        />
                    </div>
                    <div className="flex justify-end">
                        <Link
                            to={"/ForgotPassword"}
                            className="text-sm"
                        >
                            Forgot password
                        </Link>
                    </div>
                </div>
                <div className="flex justify-center items-center gap-4">
                    <button onClick={() => setIsOpen(false)} className="bg-gray-400 rounded-lg py-2 px-5 text-white">
                        Cancel
                    </button>
                    <button onClick={() => handleSubmit()} className="bg-primary rounded-lg  py-2 px-5 text-white">
                        Proceed
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={() => {
                    setIsOpen(false)
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full w-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-end items-center">
                                    <div
                                        onClick={() => {
                                            setIsOpen(false)
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    {renderDialogContent()}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AuthPrompt;
