import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import DashboardContainer from "../../dashboard-components/DashboardContainer";
import { getUserSignupInfo } from "../../provider/AuthProvider";
import { currentDate } from "../../store/slices/authSlice";
import {
  SihSignupHistory,
  fetchAccountDetails,
  fetchAnnualTurnover,
  fetchIndustries,
  fetchNumberOfEmployees,
  setIndex,
} from "../../store/slices/getStartedSlice";

import { useAppDispatch } from "../../store/store";
import "./DashboardHome.scss";

const DashboardHome = () => {
  const userAuth = new useAuthContext();
  const user = userAuth.user;
  const userparentCompanies = userAuth.parentCompanies;
  const userSignupInfo = userAuth.signupInfo;
  const userSubscription = userAuth.subscription;
  const targetDate = new Date(userSubscription?.DateTo);
  const FK_Progress_value = userSignupInfo?.FK_Progress ?? 1000140;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [isDropupVisible, setIsDropupVisible] = useState(false);

  useEffect(() => {
    const data = async () => {
      await dispatch(currentDate()).then((res) => {
        const timestamp = Date.parse(res?.Current_DateTime);
        if (timestamp) {
          const currentDate = new Date(timestamp);
          if (
            !userAuth.isNewUser &&
            targetDate <= currentDate &&
            userparentCompanies?.ParentCompanyID
          ) {
            return navigate("/RenewSubscription");
          }
        }
      });
    };

    const fetchData = async () => {
      if (
        !userparentCompanies?.ParentCompanyID &&
        !userAuth.isEmployee &&
        !userAuth.isSupplier
      ) {
        await dispatch(fetchAccountDetails()).then((res) => {
          if (res?.status === 200) {
            if (res.data.user?.NameSurname != null) {
              setText("To continue your application");
            } else {
              setText("To get started");
            }
          } else {
            setText("To get started");
          }
        });
      }
    };
    fetchData();
    data();
  }, [dispatch]);

  useEffect(() => {
    if (
      (FK_Progress_value === 1000149 &&
        !userparentCompanies?.ParentCompanyID) ||
      (user?.hasSignedUp && !user?.isApprovalBeingProcessed)
    ) {
      navigate("/UnderReview");
    }
  }, [FK_Progress_value, navigate, user, userparentCompanies]);

  const getStarted = async (e) => {
    e.preventDefault();
    const isFirstTime = text === "To get started";

    const historyRecord = {
      FK_SignupID: userSignupInfo.SignupID,
      FK_UserID: userSignupInfo.FK_UserID,
      SignupCheckTitle: isFirstTime
        ? "User initiated their application"
        : "User recommenced their application",
      SignupCheckText: `${userAuth.user.NameFirst} ${
        isFirstTime
          ? "has initiated the Signup Wizard"
          : "has recommenced the Signup Wizard"
      }`,
    };

    //SIH Signup History Record
    await Promise.all([dispatch(SihSignupHistory(historyRecord))]);

    const userSignupInfo_2 = getUserSignupInfo();
    const FK_Progress_value = userSignupInfo_2?.FK_Progress ?? 1000140;
    if (userparentCompanies?.ParentCompanyID) {
      navigate("/DashboardHome");
    } else {
      if (FK_Progress_value !== 1000140) {
        await Promise.all([
          dispatch(fetchNumberOfEmployees()),
          dispatch(fetchAnnualTurnover()),
          dispatch(fetchIndustries()),
          dispatch(fetchAccountDetails()),
          // dispatch(SignupInfoInformation(user?.UserID)),
        ]);
      }

      const progressMapping = {
        1000140: { index: 0, path: "/GetStarted/AccountDetails" },
        1000145: { index: 1, path: "/GetStarted/AccountPrefix" },
        1000147: { index: 2, path: "/GetStarted/AccountReview" },
        1000148: { index: 3, path: "/GetStarted/AccountPayment" },
      };

      const progressInfo = progressMapping[FK_Progress_value];
      if (progressInfo && userSignupInfo_2) {
        dispatch(setIndex(progressInfo.index));
        navigate(progressInfo.path);
      } else {
        dispatch(setIndex(0));
        navigate("/GetStarted/AccountDetails");
      }
    }
  };

  if (
    (FK_Progress_value === 1000149 && !userparentCompanies?.ParentCompanyID) ||
    (user?.hasSignedUp && !user?.isApprovalBeingProcessed)
  ) {
    return null; // Avoid rendering anything if navigating to /UnderReview
  } else if (
    userparentCompanies?.ParentCompanyID === undefined &&
    !userAuth.isEmployee
  ) {
    return (
      <DashboardContainer title={""}>
        <div className="h-[calc(100vh-200px)] flex justify-center items-center">
          <div className="max-w-sm w-full m-auto bg-white text-black shadow-lg p-3">
            <h4>Hi {user.NameFirst},</h4>
            <p className="text-lg">
              Welcome to Bring Back Australia(New User Page)
            </p>
            <div className={"flex justify-end py-1.5"}>
              <p className="text-md p-0 m-0 py-1">{text} </p>
              <button
                onClick={getStarted}
                className={"rounded-full text-white bg-primary w-24 ml-2"}
              >
                Click Here
              </button>
            </div>
          </div>
        </div>
        {isDropupVisible && (
          <div className="dropup-container">
            <div className="dropup-content">
              <h2>Drop-up Window</h2>
              <p>This is the content of the drop-up window.</p>
              <button onClick={() => setIsDropupVisible(false)}>Close</button>
            </div>
          </div>
        )}
      </DashboardContainer>
    );
  }

  const getViewMessage = () => {
    if (user) {
      return `
                View: ${
                  userAuth.isEmployee
                    ? "Employee"
                    : userAuth.isSupplier
                    ? "Supplier"
                    : userAuth.isRetailer
                    ? "Retailer"
                    : userAuth.isServiceProvider
                    ? "Service Provider"
                    : "N/A"
                }
                `;
    }
    return "";
  };

  const UserAuthDetails = () => {
    return (
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto mt-12">
        <h2 className="text-xl font-semibold mb-4">User Details</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <strong>isNewUser:</strong> {userAuth.isNewUser ? "Yes" : "No"}
          </li>
          <li>
            <strong>isEmployee:</strong> {userAuth.isEmployee ? "Yes" : "No"}
          </li>
          <li>
            <strong>isSupplier:</strong> {userAuth.isSupplier ? "Yes" : "No"}
          </li>
          <li>
            <strong>isRetailer:</strong> {userAuth.isRetailer ? "Yes" : "No"}
          </li>
          <li>
            <strong>isServiceProvider:</strong>{" "}
            {userAuth.isServiceProvider ? "Yes" : "No"}
          </li>
          <li>
            <strong>isAccreditation:</strong>{" "}
            {userAuth.isAccreditation ? "Yes" : "No"}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-6">{`Coming Soon ${getViewMessage()}`}</h1>
      <UserAuthDetails />
    </div>
  );
};

export default DashboardHome;
