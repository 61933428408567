import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './ServiceStores.css';

const ServiceStores = () => {
  return (
    <PageSkeletonComponent>

      <h1>SERVICE PROVIDER - Stores Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      Listing of the stores associated with a service provider.<br /><br />
      The stores can be grouped, filtered by Region, State, Size... etc...

      <h2>Who Can Access</h2>
      WHO IS EXPECTED TO ACCESS THIS PAGE

    </PageSkeletonComponent>
  );
};

export default ServiceStores;