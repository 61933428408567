import React, { Fragment, useEffect, useState } from "react";
import {
  fetchCountryOrigin,
  checkBarCodeNo,
  userComapnyData,
  fetchSubmit,
  fetchSegments,
  productSelector,
  fetchProductDetails,
  fetchFamily,
  fetchClass,
  fetchBrick,
} from "../../store/slices/productSlice";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAuthContext } from "../../context/AuthContext";
import { Listbox, Transition } from "@headlessui/react";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import validbarcode from "barcode-validator";
import { getCompanies } from "../../provider/AuthProvider";

export default function AddProduct() {
  const dispatch = useAppDispatch();
  const userAuth = new useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const [validateGTIN, setValidateGTIN] = useState(false);
  const [showError, setErrorMsg] = useState("");
  const [segments, setSegments] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoader, setLoader] = useState(false);
  const { product, family, countryOrigins, productClass, brick, brands } =
    useAppSelector(productSelector);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formData, setFormData] = useState({
    gtin: "",
    DescriptionShort: "",
    brand: "",
    localContent: "",
    country: "",
    segment: "",
    productFamily: "",
    class: "",
    brick: "",
    ProductID: "",
    AuditUserID: "",
  });

  //console.log("this is a getggggcompanies", getCompanies()[0].CompanyID);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === "segment") {
      setFormData((prevState) => ({ ...prevState, ["family"]: [] }));
      setFormData((prevState) => ({ ...prevState, ["class"]: [] }));
      setFormData((prevState) => ({ ...prevState, ["brick"]: [] }));

      await dispatch(fetchFamily(value));
    } else if (name === "productFamily") {
      setFormData((prevState) => ({ ...prevState, ["class"]: [] }));
      setFormData((prevState) => ({ ...prevState, ["brick"]: [] }));

      await dispatch(fetchClass(value));
    } else if (name === "class") {
      setFormData((prevState) => ({ ...prevState, ["brick"]: [] }));

      await dispatch(fetchBrick(parseInt(value)));
    }
  };

  const handleBrandChange = async (name, value) => {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validate = () => {
    setLoader(true);
    if (!formData.gtin.trim()) {
      setErrorMsg("Please enter a GTIN (Product Barcode)");
      setValidateGTIN(false);
      setLoader(false);
    } else {
      const validGTIN = validbarcode(formData.gtin.trim());
      if (validGTIN) {
        validateGtinNo(formData.gtin);
      } else {
        setErrorMsg("Please enter a valid GTIN (Product Barcode)");
        setValidateGTIN(false);
        setLoader(false);
      }
    }
  };

  // Validate GTIN Number
  const validateGtinNo = async (gtin) => {
    const barCode = await dispatch(checkBarCodeNo(gtin));
    const company = await dispatch(
      userComapnyData(getCompanies()[0].CompanyID)
    );
    if (barCode?.CompanyInfo?.EntityGLN === null || company.length === 0) {
      setErrorMsg("This product does not belong to your company");
      setValidateGTIN(false);
      setLoader(false);
    } else if (
      barCode?.CompanyInfo?.EntityGLN &&
      company.length > 0 &&
      !company.includes(barCode?.CompanyInfo?.EntityGLN)
    ) {
      setErrorMsg("This product does not belong to your company");
      setValidateGTIN(false);
      setLoader(false);
    } else if (
      barCode.ProductInfo.isNew === true &&
      company.includes(barCode?.CompanyInfo?.EntityGLN)
    ) {
      await fetchData(formData.gtin);
      setFormData((prevState) => ({
        ...prevState,
        ["ProductID"]: barCode?.ProductInfo?.ProductID,
      }));
      setErrorMsg("");
      setValidateGTIN(true);
      setLoader(false);
    } else if (
      barCode.ProductInfo.isNew === false &&
      company.includes(barCode?.CompanyInfo?.EntityGLN)
    ) {
      const editURL = `/edit-product/${formData.gtin}`;
      setErrorMsg(
        <div>
          {" "}
          Barcode is already used, if you want to review the product, please{" "}
          <a className="text-red-500 underline" href={`${editURL}`}>
            click here
          </a>
        </div>
      );
      setValidateGTIN(false);
      setLoader(false);
    }
  };

  const fetchData = async (GTIN) => {
    const segmentData = await dispatch(fetchSegments());
    setSegments(segmentData);
    await dispatch(fetchCountryOrigin());
    await dispatch(fetchProductDetails(GTIN));
  };

  const { BrandID, Brand, BrandSub } = product || {};
  const selectedBrandID = BrandID ?? "000";
  const selectedBrand = Brand ?? "-- Select From List";
  const selectedBrandSub = BrandSub ?? "--";

  const isBrandListAvailable = brands && brands?.[0];

  useEffect(() => {
    setSelected({
      BrandID: isBrandListAvailable ? selectedBrandID : "000",
      Brand: isBrandListAvailable ? selectedBrand : "-- Select From List",
      BrandSub: isBrandListAvailable ? selectedBrandSub : "--",
    });

    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
    if (showFailed) {
      setTimeout(() => {
        setShowFailed(false);
      }, 2000);
    }
  }, [product.BrandID, showSuccess, showFailed]);

  const [selected, setSelected] = useState({
    BrandID: selectedBrandID,
    Brand: selectedBrand,
    BrandSub: selectedBrandSub,
  });

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file && /\.(jpg|jpeg|png)$/i.test(file.name)) {
      setUploadedFile(file);
    } else {
      setDescriptionError(
        "Invalid file format. Please upload a JPG, JPEG, or PNG file."
      );
    }
  };

  const submitFormData = async (val) => {
    if (formData.DescriptionShort.trim() === "") {
      setDescriptionError("Please enter Description");
    } else {
      const formRecord = new FormData();
      formRecord.append("type", "product");
      formRecord.append("image", uploadedFile);
      formRecord.append("GTIN", formData.gtin);
      formRecord.append("FK_BrandID", formData.brand);
      formRecord.append("TradeItemDesc", formData.DescriptionShort);
      formRecord.append(
        "LabellingAustralianContentPercent",
        formData.localContent
      );
      formRecord.append("CountryOfOrigin", formData.country);
      formRecord.append("FK_SegmentID", formData.segment);
      formRecord.append("FK_FamilyID", formData.productFamily);
      formRecord.append("FK_ClassID", formData.class);
      formRecord.append("FK_BrickID", formData.brick);
      formRecord.append("ProductID", formData?.ProductID);
      formRecord.append("AuditUserID", userAuth?.user?.UserID);
      dispatch(fetchSubmit(formRecord)).then((res) => {
        if (res === "success") {
          setShowSuccess(true);
        } else {
          setShowFailed(true);
        }
        if (val) {
          setValidateGTIN(false);
          setFormData({
            gtin: "",
            DescriptionShort: "",
            brand: "",
            localContent: "",
            country: "",
            segment: "",
            productFamily: "",
            class: "",
            brick: "",
            ProductID: "",
            AuditUserID: "",
          });
        } else {
          closeModel();
        }
      });
    }
  };

  const closeModel = () => {
    setShowModal(false);
    setValidateGTIN(false);
    setErrorMsg("");
    setDescriptionError("");
    setFormData({
      gtin: "",
      DescriptionShort: "",
      brand: "",
      localContent: "",
      country: "",
      segment: "",
      productFamily: "",
      class: "",
      brick: "",
      ProductID: "",
      AuditUserID: "",
    });
  };

  return (
    <>
      <div
        className={
          "justify-center flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
        }
      >
        {showSuccess && <Alert severity="success">{"Product Added"}</Alert>}
        {showFailed && <Alert severity="error">{"Please Try Again"}</Alert>}
      </div>
      <Link to={"/ExportGS1"}>
        <button
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
          Export
        </button>
      </Link>
      <button
        className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Add Product
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ minWidth: "48rem" }}
            >
              {/*content*/}
              <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">Add Product</h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeModel}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div
                  className="relative p-6 flex-auto"
                  style={
                    validateGTIN
                      ? { maxHeight: "490px", overflowY: "scroll" }
                      : {}
                  }
                >
                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                    <h6 className="w-full max-w-[120px] flex justify-end">
                      GTIN (Barcode):
                    </h6>
                    <input
                      placeholder="GTIN (Barcode)"
                      className="rounded-md w-full max-w-3xl font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                      type="text"
                      name="gtin"
                      onChange={handleChange}
                      value={formData.gtin}
                    />
                  </div>

                  {showError && (
                    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 ">
                      <h6 className="w-full max-w-[120px] flex justify-end">
                        &nbsp;
                      </h6>
                      <div className="w-full max-w-3xl px-2 text-left text-red-500">
                        <strong>{showError}</strong>
                      </div>
                    </div>
                  )}

                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                    <h6 className="w-full max-w-[120px] flex justify-end" />
                    <button
                      onClick={validate}
                      disabled={isLoader}
                      className={`${
                        isLoader ? "bg-gray-500" : "bg-primary"
                      } text-white font-bold py-2 px-4 rounded`}
                    >
                      {isLoader
                        ? "Validating..."
                        : isLoader
                        ? "Validate"
                        : "Validate"}
                    </button>
                  </div>

                  {validateGTIN ? (
                    <div>
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Brand:
                        </h6>
                        <Listbox
                          value={selected}
                          onChange={(e) => {
                            setSelected(e);
                            handleBrandChange("brand", e.BrandID);
                          }}
                        >
                          <div className="relative mt-1 w-full">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                              <span className="block truncate font-bold">
                                {selected?.Brand} - {selected?.BrandSub}
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm text-center">
                                <div className="grid grid-cols-2">
                                  <div className="bg-gray-300 pl-4 py-2">
                                    <h6 className="font-bold">Brand</h6>
                                  </div>
                                  <div className="bg-gray-300 pl-4 py-2">
                                    <h6 className="font-bold">Sub Brand</h6>
                                  </div>
                                </div>
                                <div className=" overflow-y-auto h-48">
                                  {brands.map((brand, brandIdx) => (
                                    <Listbox.Option
                                      key={brandIdx}
                                      className={({ active }) =>
                                        `relative cursor-default grid border-b grid-cols-2 pl-4 py-2 select-none ${
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-900"
                                        }`
                                      }
                                      value={brand}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {brand?.Brand}
                                          </span>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {brand?.BrandSub || ""}
                                          </span>
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </div>
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Description:
                        </h6>
                        <input
                          placeholder="Description"
                          className="rounded-md w-full max-w-3xl font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          type="text"
                          name="DescriptionShort"
                          onChange={handleChange}
                          value={formData.DescriptionShort}
                        />
                      </div>
                      {descriptionError && (
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 ">
                          <h6 className="w-full max-w-[120px] flex justify-end">
                            &nbsp;
                          </h6>
                          <div className="w-full max-w-3xl px-2 text-left text-red-500">
                            <strong>{descriptionError}</strong>
                          </div>
                        </div>
                      )}
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Local Content:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="localContent"
                          onChange={handleChange}
                          value={formData.localContent}
                        >
                          <option value="">--Select Local Content--</option>
                          {[...Array(101).keys()].map((value) => (
                            <option
                              className="font-bold"
                              key={value}
                              value={value}
                              selected={
                                product?.LabellingAustralianContentPercent ===
                                value
                              }
                            >
                              {value === 0 ? "NIL" : value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Country of Origin:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="country"
                          onChange={handleChange}
                          value={formData.country}
                        >
                          {countryOrigins.map((country, index) => (
                            <option
                              className="font-bold"
                              key={index}
                              value={country.CountryCodeN3}
                              selected={
                                product?.ProductCountryCodeN3 ===
                                country.CountryCodeN3
                              }
                            >
                              {country.CountryNameDisplay}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Segment:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="segment"
                          onChange={handleChange}
                          value={formData.segment}
                        >
                          <option value="">--Select Segment--</option>
                          {segments.length
                            ? segments.map((segment, index) => (
                                <option
                                  className="font-bold"
                                  key={index}
                                  value={segment.ID}
                                >
                                  {segment.Desc}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Family:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="productFamily"
                          onChange={handleChange}
                          value={formData.productFamily}
                        >
                          <option value="">--Select Family--</option>
                          {family.length
                            ? family.map((family, index) => (
                                <option
                                  className="font-bold"
                                  key={index}
                                  value={family.ID}
                                >
                                  {family.Desc}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Class:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="class"
                          onChange={handleChange}
                          value={formData.class}
                        >
                          <option value="">--Select Class--</option>
                          {productClass.length
                            ? productClass.map((pro, index) => (
                                <option
                                  className="font-bold"
                                  key={index}
                                  value={pro.ID}
                                >
                                  {pro.Desc}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Brick:
                        </h6>
                        <select
                          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                          name="brick"
                          onChange={handleChange}
                          value={formData.brick}
                        >
                          <option value="">--Select Brick--</option>
                          {brick.length
                            ? brick.map((brk, index) => (
                                <option
                                  className="font-bold"
                                  key={index}
                                  value={brk.ID}
                                >
                                  {brk.Desc}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>

                      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                        <h6 className="w-full max-w-[120px] flex justify-end">
                          Image:
                        </h6>
                        <input
                          type="file"
                          name="productLogo"
                          className="border rounded-md py-1.5 px-2 w-full"
                          accept="image/*"
                          onChange={handleUpload}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="bg-gray-400 text-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 rounded ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeModel}
                  >
                    Cancel
                  </button>

                  <div className="dropdown inline-block relative">
                    <button className="bg-primary text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 rounded inline-flex items-center">
                      <span className="mr-1">SAVE & CLOSE&nbsp;</span>
                      <svg
                        className="fill-current h-4 w-4 rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul className="dropdown-menu absolute hidden bg-primary pt-1 w-full rounded">
                      <li className="">
                        <button
                          type="button"
                          onClick={() => submitFormData(true)}
                          className="bg-primary text-white active:bg-emerald-600 font-bold uppercase
                                        text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all
                                        duration-150 block whitespace-no-wrap"
                        >
                          SAVE & CREATE
                        </button>
                      </li>
                      <li className="">
                        <button
                          type="button"
                          onClick={() => submitFormData(false)}
                          className="bg-primary text-white active:bg-emerald-600 font-bold uppercase
                                        text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none
                                        mr-1 mb-1 ease-linear transition-all duration-150 block whitespace-no-wrap"
                        >
                          SAVE & CLOSE&nbsp;
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
