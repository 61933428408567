import styled from 'styled-components'

export const ProductScanContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
`;

export const UpperSection = styled.div`
  display: flex;
  flex-direction: row;

  & > div.images {
    display: flex;
    flex-direction: row;
  }

  & > div.search-area {
    margin-left: 10px;
    flex-grow: 1;
    text-align: center;
  }
`;

export const ImageLogo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-right: 10px;
  background-color: #b1b1b1;

  &.bg-white {
    background-color: white;
  }

  & > span.title {
    padding: 7px;
    font-weight: bold;
    border-bottom: 1px solid black;
    background-color: white;
  }

  & > img {
    width: 150px;
    height: 125px;
    object-fit: contain;
  }
`;

export const BarcodeInput = styled.input`
  margin-right: 10px;
  padding: 5px;
  outline: none;
  border: none;
  border: 1px solid #707070;
  width: 300px;
`

export const BarcodeSearchButton = styled.button`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0.75;
  height: 0;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  flex-grow: 1;
  width: 0;

  ${props => props['new-record'] && `
    background: #CCFFB0;
  `}

  ${props => props['existing-record'] && `
    background: #B0CDFF;
  `}

  &:not(:last-child) {
    margin-right: 10px;
  }

  & > span.title {
    padding: 7px;
    font-weight: bold;
    border-bottom: 1px solid black;

    &.no-border {
      border: none;
    }
  }

  & > div.body {
    background-color: transparent;
    padding: 12px;
    overflow: auto;

    &.center {
      text-align: center;
    }
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const DetailTable = styled.table`
  & > tbody td.label {
    font-weight: bold;
  }

  & > tbody td {
    text-align: left;
  }

  & > tbody tr:not(:last-child) {
    & td {
      padding-bottom: 5px;
    }
  }

  & > tbody tr td:not(:last-child) {
    padding-right: 10px;
  }
`;