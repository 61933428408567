import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const runReports = async () => {
  try {
    return await getAuthRequest(getToken()).get('eodreport/runReports');
  } catch (error) {
    return false;
  }
};

export {
  runReports,
};