import React from 'react';
import WelcomeMessageComponent from '../components/WelcomeMessageComponent/WelcomeMessageComponent';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import { useAuthContext } from '../../context/AuthContext';
import './TestingHome.css';

const TestingHome = (props) => {

  const user = useAuthContext().user;

  return (
    <PageSkeletonComponent
      footerContent={<WelcomeMessageComponent userName={user.NameFirst} />}
    >

      <h1><center>TESTING - Home Page</center></h1>

      <center>This page is...</center>

    </PageSkeletonComponent>
  );
}

export default TestingHome;
