import { getToken } from "../provider/AuthProvider";
import { getAuthRequest, uploadImageAuthRequest } from "./API";

const listCountries = async () => {
  try {
    return await getAuthRequest(getToken()).get("getstarted/listCountries");
  } catch (error) {
    return error;
  }
};

const listselection = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(
      "systemselect/listselection",
      data
    );
  } catch (error) {
    return error;
  }
};

const listStates = async (countryCode) => {
  try {
    return await getAuthRequest(getToken()).get(
      `getstarted/listStates/${countryCode}`
    );
  } catch (error) {
    return error;
  }
};

const listStatesForAccountDetails = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/STAG", data);
  } catch (error) {
    return error;
  }
};

const listClientSecret = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(
      `getstarted/stripeClientSecret`,
      data
    );
  } catch (error) {
    return error;
  }
};

const getAccountContactDetails = async () => {
  try {
    return await getAuthRequest(getToken()).get(
      "getstarted/getAccountContactDetails"
    );
  } catch (error) {
    return error;
  }
};

const getAccountContactDetailsForLoginUser = async (ParentCompanyID) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/PARG", {
      ParentCompanyID,
    });
  } catch (error) {
    return error;
  }
};

const updateAccountContactDetail = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/updateAccountContactDetail",
      data
    );
  } catch (error) {
    return error;
  }
};

const updateAccountContactDetailLoginUser = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/PARP", data);
  } catch (error) {
    return error;
  }
};

const saveCompanyParentCompany = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/LPCP", data);
  } catch (error) {
    return error;
  }
};

// const createCompany = async (data) => {
//   console.log('data', data)
//   try {
//     return await getAuthRequest(getToken()).post('/tab/SIGG', {
//       CompanyName: data?.company_name,
//       TradingName: data?.trading_name,
//       CompanyABN: data?.company_abn,
//       EmployeeCount: data?.number_of_employees,
//       AccountType: 'Supplier',
//       CompanyTurnover: data?.annual_turnover,
//       FK_MainIndustry: data?.industrysignup,
//       OtherIndustry: data?.OtherIndustrysignup,
//       PhoneCompany: data?.company_phone_number,
//       Website: data?.website,
//       Address1: data?.company_address_1,
//       Address2: data?.company_address_2,
//       City: data?.city,
//       State: data?.state,
//       Postcode: data?.postcode,
//       FK_Country: data?.country_id,
//       signup: data?.signup
//     });
//   } catch (error) {
//     return error;
//   }
// };

const createCompany = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/SIGG", data);
  } catch (error) {
    return error;
  }
};

const getSignup = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/SIGG", data);
  } catch (error) {
    return error;
  }
};

const updateCompanyImageDetail = async (data) => {
  try {
    return await uploadImageAuthRequest(getToken()).post("imageUpload", data);
  } catch (error) {
    return error;
  }
};

const updateAccountReview = async () => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/updateAccountReview"
    );
  } catch (error) {
    return error;
  }
};

const updateAccountContactDetails = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/updateAccountContactDetails",
      data
    );
  } catch (error) {
    return error;
  }
};

const confirmTermsConditions = async (query) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/processSuccessfulPayment",
      { StripePaymentID: query.transaction_id }
    );
    // return await getAuthRequest(getToken()).post('getstarted/confirmTermsConditions');
  } catch (error) {
    return error;
  }
};

const setSIGP = async (query) => {
  try {
    return await getAuthRequest(getToken()).post("tab/SIGP", query);
  } catch (error) {
    return error;
  }
};

const getCompanyToBeClaimed = async () => {
  try {
    return await getAuthRequest(getToken()).get(
      "getstarted/getCompanyToBeClaimed"
    );
  } catch (error) {
    return false;
  }
};

const setAccountAsSupplier = async () => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/setAccountAsSupplier"
    );
  } catch (error) {
    return false;
  }
};

const setAccountAsRetailer = async () => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/setAccountAsRetailer"
    );
  } catch (error) {
    return false;
  }
};

const setAccountAsServiceProvider = async () => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/setAccountAsServiceProvider"
    );
  } catch (error) {
    return false;
  }
};

const claimCompany = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/claimCompany",
      data
    );
  } catch (error) {
    return error;
  }
};

const searchCompanyByGTIN = async (GTIN) => {
  try {
    return await getAuthRequest(getToken()).get(
      "members/Signup/checkCompanyClaimed/" + GTIN
    );
  } catch (error) {
    return error;
  }
};

const removeCompanyByGTIN = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("tab/PARP", data);
  } catch (error) {
    return error;
  }
};

const listRetailerIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get(
      "getstarted/listRetailerIndustries"
    );
  } catch (error) {
    return false;
  }
};

const listServiceProviderIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get(
      "getstarted/listServiceProviderIndustries"
    );
  } catch (error) {
    return false;
  }
};

const listSupplierIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get(
      "getstarted/listSupplierIndustries"
    );
  } catch (error) {
    return false;
  }
};

const listTradingGroup = async () => {
  try {
    return await getAuthRequest(getToken()).get("getstarted/listTradingGroup");
  } catch (error) {
    return false;
  }
};

const createTradingGroup = async (GroupName, GroupType) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/createTradingGroup",
      { GroupName, GroupType }
    );
  } catch (error) {
    return false;
  }
};

const assignToTradingGroup = async (GroupID) => {
  try {
    return await getAuthRequest(getToken()).post(
      "getstarted/assignToTradingGroup",
      { GroupID }
    );
  } catch (error) {
    return false;
  }
};

const investorExpressionInterest = async (Name, Email, Amount, Questions) => {
  try {
    return await getAuthRequest(getToken()).post("investor/defaultinterest", {
      Name,
      Email,
      Amount,
      Questions,
    });
  } catch (error) {
    return false;
  }
};

const getCouponCode = async (couponCode) => {
  try {
    return await getAuthRequest(getToken()).post("coupon/couponcheck", {
      couponCode,
    });
  } catch (error) {
    return error.response;
  }
};
const updateCouponCode = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("tab/CPNP", data);
  } catch (error) {
    return error.response;
  }
};

const updateSignup = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("tab/SIGP", data);
  } catch (error) {
    return error.response;
  }
};

const setLUPP = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/LUPP", data);
  } catch (error) {
    return error.response;
  }
};

const getLPCG = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("/tab/LPCG", data);
  } catch (error) {
    return error.response;
  }
};

const signupHistory = async (data) => {
  try {
    return await getAuthRequest(getToken()).post("tab/SIHP", data);
  } catch (error) {
    return error;
  }
};

const saveSubscriptionDetails = async (payload) => {
  try {
    return await getAuthRequest(getToken()).post(
      "/members/subscription",
      payload
    );
  } catch (error) {
    return error;
  }
};

export {
  listCountries,
  listselection,
  listStates,
  listClientSecret,
  getAccountContactDetails,
  investorExpressionInterest,
  setLUPP,
  updateAccountContactDetails,
  updateAccountContactDetail,
  updateAccountReview,
  confirmTermsConditions,
  setSIGP,
  getCompanyToBeClaimed,
  setAccountAsSupplier,
  setAccountAsRetailer,
  setAccountAsServiceProvider,
  removeCompanyByGTIN,
  updateCompanyImageDetail,
  getLPCG,
  claimCompany,
  searchCompanyByGTIN,
  listRetailerIndustries,
  listServiceProviderIndustries,
  listSupplierIndustries,
  listStatesForAccountDetails,
  saveCompanyParentCompany,
  listTradingGroup,
  createTradingGroup,
  assignToTradingGroup,
  getCouponCode,
  updateCouponCode,
  updateSignup,
  getAccountContactDetailsForLoginUser,
  updateAccountContactDetailLoginUser,
  createCompany,
  signupHistory,
  saveSubscriptionDetails,
  getSignup,
};
