import React, { useEffect } from 'react';
import ModalDialog from './ModalDialog';
import './ModalDialog.css';

const ConfirmationDialog = (props) => {

  return (
    <ModalDialog
      {...props}>
      {props.children}
    </ModalDialog>
  );

};
export default ConfirmationDialog;