import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './SupplierPrefixes.css';

const SupplierPrefixes = () => {
  return (
    <PageSkeletonComponent>

     <h1>SUPPLIER - Prefixes Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      The supplier can see the Prefixes that they own...<br /><br />
      This page is to list GS1 Prefixes associated with a supplier company.

      <b>This pages lists all of the GS1 Prefixes that are associated with a Supplier</b><br /><br />
      The Prefix is the <b>Key to Ownership</b> of a Brand or Product.<br /><br />
      To list these Prefixes, there will be an Endpoint that uses the EntityGLN to return all Prefixes for the supplier.<br /><br />
      When validating a product, we use the Brand Owner to determine the information used in validation.

      <h2>Confluence</h2>
      For the Supplier Prefixes Documentation: <a href="">Supplier Prefixes</a><br /><br />
      For the Endpoint Documentation: <a href="https://trickletreat.atlassian.net/wiki/spaces/BBA/pages/734396423/API+Endpoint+-+Supplier+Prefixes">Endpoint - Supplier Prefixes</a><br /><br />
      


      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default SupplierPrefixes;