import { Tab } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Loader from "../../components/loader/Loader";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  signupApproval,
  fethchParentCompanyDetails,
  setRecordType,
  setLimit,
  setPage,
  setLoading,
  fetchCalculateWorkdays,
  setSignupDetails,
  fetchCalculateDueDate,
  setFilterValues,
} from "../../store/slices/signupApprovalSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApplicationDialog from "../../components/ApplicationDialog";
import { HTTP_STATUS_CODES } from "../../api/API";
//import "./responsive.css"; // Import the responsive CSS file

const generateColumns = (RecordType) => {
  switch (RecordType) {
    case "STARTING":
      return [
        {
          name: "NameFirst",
          header: "First Name",
          type: "string",
          textAlign: "center",
          defaultWidth: 210,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          type: "string",
          textAlign: "center",
          defaultWidth: 210,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Email",
          header: "Email",
          type: "string",
          filterable: false,
          textAlign: "center",
          defaultWidth: 215,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "calculateWorkdays",
          filterable: false,
          header: "Inactive Days",
          textAlign: "center",
          type: "string",
          defaultWidth: 300,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    case "ENTERING":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 250,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          //className: "custom-padding",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "ApplicationProgress",
          header: "Progress",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "calculateWorkdays",
          filterable: false,
          header: "Inactive Days",
          textAlign: "center",
          type: "string",
          defaultWidth: 300,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    case "OPEN":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Trial",
          header: "Trial",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-center block"
              to={`/view-application/${data.SignupID}`}
            >
              {value === 1 ? "Yes" : "No"}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "calculateWorkdays",
          header: "Open Days",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "DateApplicationLodged",
          header: "Submitted On",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "customDueDate",
          header: "Due Date",
          type: "string",
          filterable: false,
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => {
            if (value) {
              const formattedDate = formatDateOnly(value);
              const currentDate = new Date();
              const dueDate = new Date(value);

              // Check if due date is today
              if (
                dueDate.getDate() === currentDate.getDate() &&
                dueDate.getMonth() === currentDate.getMonth() &&
                dueDate.getFullYear() === currentDate.getFullYear()
              ) {
                // Due today format (Bold Font)
                return (
                  <span style={{ fontWeight: "bold" }}>
                    <Link
                      className="text-left block"
                      to={`/view-application/${data.SignupID}`}
                    >
                      {formattedDate}
                    </Link>
                  </span>
                );
              }

              if (dueDate > currentDate) {
                return (
                  <Link
                    className="text-left block"
                    to={`/view-application/${data.SignupID}`}
                  >
                    {formattedDate}
                  </Link>
                );
              }

              return (
                <span style={{ fontWeight: "bold", color: "red" }}>
                  <Link to={`/view-application/${data.SignupID}`}>
                    {formattedDate}
                  </Link>
                </span>
              );
            }

            return "";
          },
        },
      ];
    case "MOREINFO":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Trial",
          header: "Trial",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-center block"
              to={`/view-application/${data.SignupID}`}
            >
              {value === 1 ? "Yes" : "No"}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "MoreInfoCount",
          header: "No. of Requests",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-right block"
              to={`/view-application/${data.SignupID}`}
            >
              {value + " " + "Count(s)"}
            </Link>
          ),
        },
        {
          name: "calculateWorkdays",
          header: "Open Days",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "DateApplicationMoreInfo",
          header: "Requested On",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    case "DECISION":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Trial",
          header: "Trial",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-center block"
              to={`/view-application/${data.SignupID}`}
            >
              {value === 1 ? "Yes" : "No"}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "ApplicationSubmittedRecommendation",
          header: "Recommendation",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) =>
            value === "A" || value === "a" ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                Approve
              </Link>
            ) : (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                Reject
              </Link>
            ),
        },
        {
          name: "calculateWorkdays",
          header: "Open Days",
          type: "string",
          filterable: false,
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "DateApplicationSubmittedDecision",
          header: "Requested On",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 230,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    case "TRIALUSERS":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationCompanyName",
          header: "Days Remaining",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 230,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {"14"}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    case "ACTIONED":
      return [
        {
          name: "ApplicationCompanyName",
          header: "Company Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "ApplicationTradingName",
          header: "Trading Name",
          textAlign: "center",
          type: "string",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameFirst",
          header: "First Name",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "NameSurname",
          header: "Surname",
          type: "string",
          textAlign: "center",
          defaultWidth: 150,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Position",
          header: "Position",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-left block"
              to={`/view-application/${data.SignupID}`}
            >
              {value}
            </Link>
          ),
        },
        {
          name: "Trial",
          header: "Trial",
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) => (
            <Link
              className="text-center block"
              to={`/view-application/${data.SignupID}`}
            >
              {value === 1 ? "Yes" : "No"}
            </Link>
          ),
        },
        {
          name: "DateApplicationStarted",
          header: "Application Started",
          filterable: false,
          type: "string",
          textAlign: "center",
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "ApplicationOutcome",
          header: "Decision",
          type: "string",
          textAlign: "center",
          filterable: false,
          defaultWidth: 150,
          render: ({ value, data }) =>
            value === "A" || value === "a" ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                Approved
              </Link>
            ) : value === "R" || value === "r" ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                Rejected
              </Link>
            ) : (
              "Requiring Clarification"
            ),
        },
        {
          name: "calculateWorkdays",
          header: "Application Duration",
          type: "string",
          textAlign: "center",
          filterable: false,
          defaultWidth: 200,
          render: ({ value, data }) =>
            value?.length > 0 ? (
              <Link
                className="text-right block"
                to={`/view-application/${data.SignupID}`}
              >
                {value}
              </Link>
            ) : (
              ""
            ),
        },
        {
          name: "DateApplicationActioned",
          header: "Actioned On",
          type: "string",
          textAlign: "center",
          filterable: false,
          defaultWidth: 200,
          render: ({ value, data }) =>
            value ? (
              <Link
                className="text-left block"
                to={`/view-application/${data.SignupID}`}
              >
                {formatDateWithOrdinalAndTime(value)}
              </Link>
            ) : (
              ""
            ),
        },
      ];
    default:
      return [];
  }
};

const formatDateWithOrdinalAndTime = (dateString) => {
  if (dateString === "" || dateString === undefined) {
    return ""; // Handle undefined or null dateString
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ""; // Handle invalid dateString
  }

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const dayWithSuffix = date.getDate() + getOrdinalSuffix(date.getDate());
  const weekday = date.toLocaleDateString(undefined, { weekday: "short" });
  const month = date.toLocaleDateString(undefined, { month: "short" });

  // Check if time component should be included
  const timeComponent =
    date.getHours() === 0 && date.getMinutes() === 0
      ? ""
      : ` (${date.toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
        })})`;

  // Construct the desired date format
  const formattedDate = `${weekday}, ${dayWithSuffix} ${month}${timeComponent}`;
  return formattedDate;
};

const formatDateOnly = (dateString) => {
  if (dateString === "" || dateString === undefined) {
    return ""; // Handle undefined or null dateString
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ""; // Handle invalid dateString
  }

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const dayWithSuffix = date.getDate() + getOrdinalSuffix(date.getDate());
  const weekday = date.toLocaleDateString(undefined, { weekday: "short" });
  const month = date.toLocaleDateString(undefined, { month: "short" });

  // Construct the desired date format
  const formattedDate = `${weekday}, ${dayWithSuffix} ${month}`;
  return formattedDate;
};

const SignUpApproval = () => {
  const { signupDetails, loading, RecordType, limit, page, filterValues } =
    useAppSelector(signupApproval);
  const dispatch = useAppDispatch();
  const [applicationDialog, setApplicationDialog] = useState(false);
  const [Decision, setDecision] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const { TabName } = useParams();

  useEffect(() => {
    dispatch(setRecordType(TabName));
  }, [TabName]);

  useEffect(() => {
    loadData();
  }, [limit, page, filterValues]);

  useEffect(() => {
    dispatch(setPage(1));
    dispatch(setLimit(10));
    loadData();
  }, [RecordType]);

  const loadData = () => {
    const fetchData = async ({ RecordType, limit, page }) => {
      let recordType = RecordType;
      if (RecordType === "TRIALUSERS") {
        recordType = "TRIAL";
      }
      try {
        dispatch(setLoading(true));
        const { data, status } = await dispatch(
          fethchParentCompanyDetails(page, {
            RecordType: recordType,
            limit: limit,
            filter: filterValues,
          })
        );
        if (status !== HTTP_STATUS_CODES.OK) {
          dispatch(setLoading(false));
          return Promise.resolve({ data: [], count: 0 });
        }
        if (status === 200) {
          if (data?.message) {
            return Promise.resolve({
              data: [],
              count: 0,
            });
          }
          const recordsWithWorkdays = await Promise.all(
            data?.data.map(async (record) => {
              if (record?.ApplicationLastUpdate) {
                try {
                  let workdays = "0";
                  let workDuration = "0";
                  let dueDate = "";

                  if (record?.ApplicationLastUpdate) {
                    const json = await dispatch(
                      fetchCalculateWorkdays({
                        DateFrom: record?.ApplicationLastUpdate,
                        DateTo: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                      })
                    );

                    workdays = json?.data?.Workdays || "0";
                    workDuration = json?.data?.WorkDuration || "0";
                  }

                  if (RecordType === "OPEN") {
                    const result = await dispatch(
                      fetchCalculateDueDate({
                        DateFrom: record?.DateApplicationLodged,
                        SLA_Type: 10,
                      })
                    );
                    dueDate = result?.data?.DueDate;
                  }

                  return {
                    ...record,
                    calculateWorkdays: `${workdays} (${workDuration})`,
                    customDueDate: `${dueDate}`,
                  };
                } catch (error) {
                  console.error("Error in fetchCalculateWorkdays:", error);
                  // Handle error gracefully or throw it again based on your requirements
                  throw error;
                }
              } else {
                return record;
              }
            })
          );

          return Promise.resolve({
            data: recordsWithWorkdays || [],
            count: data.total || 0,
          });
        }
      } catch (error) {
        console.error("Error fetching parent company details:", error);
      } finally {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);
      }
    };

    let recordType = RecordType;
    if (RecordType === "TRIALUSERS") {
      recordType = "TRIAL";
    }
    dispatch(
      setSignupDetails(
        fetchData({ RecordType: recordType, limit: limit, page: page })
      )
    );
  };

  const data = [
    {
      id: 1,
      name: "Starting",
      pathName: "Starting",
      index: 0,
    },
    {
      id: 2,
      name: "Entering",
      pathName: "Entering",
      index: 1,
    },
    {
      id: 3,
      name: "Open",
      pathName: "Open",
      index: 2,
    },
    {
      id: 4,
      name: "More Info",
      pathName: "MoreInfo",
      index: 3,
    },
    {
      id: 5,
      name: "Decision",
      pathName: "Decision",
      index: 4,
    },
    {
      id: 6,
      name: "Trial Users",
      pathName: "TrialUsers",
      index: 5,
    },
    {
      id: 7,
      name: "Actioned",
      pathName: "Actioned",
      index: 6,
    },
  ];

  const columns = generateColumns(RecordType);

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    const { SignupID } = rowProps.data;
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: (
          <div className="group inline-block group1 inline-block1">
            <ul className="bg-white rounded-sm origin-top min-w-32 ul">
              <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li">
                <Link
                  to={`/view-application/${SignupID}`}
                  className="w-full primary"
                >
                  <button
                    style={{ cursor: "pointer" }}
                    className="w-full text-left"
                  >
                    View Application Details
                  </button>
                </Link>
              </li>
              <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li">
                <button
                  disabled={RecordType !== "ACTIONED"}
                  onClick={() => {
                    setDecision(rowProps.data);
                    setTimeout(() => {
                      setApplicationDialog(!applicationDialog);
                    });
                  }}
                  className={
                    "w-full disabled:pointer-events-none disabled:text-gray-500 primary cursor-pointer text-left"
                  }
                >
                  {" "}
                  View Decision
                </button>
              </li>
            </ul>
          </div>
        ),
      },
    ];
  };

  const handleFilterValueChange = (o) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      dispatch(setFilterValues(o));
    }, 2000);
    setTimeoutId(newTimeoutId);
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
    navigate(`/view-application/${selected}`);
  }, []);

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-col">
        <div>
          <Tab.Group
            defaultIndex={data.findIndex((o) => o.pathName === TabName)}
          >
            <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
              <Tab.List className="space-x-1">
                {data.map((panel, index) => (
                  <Tab as={Fragment} key={panel.id}>
                    {({ selected }) => (
                      <button
                        onClick={() => {
                          dispatch(setRecordType(panel?.name));
                          navigate(`/SignUpApprovalTab/${panel?.pathName}`);
                        }}
                        className={`${
                          selected
                            ? "bg-white border border-transparent pb-2 pt-4 text-black"
                            : "text-white py-2 border-white border-t border-x"
                        } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                      >
                        {panel.name}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="rounded-b-xl shadow-md p-5 bg-white">
              {data.map((panel, index) => (
                <Tab.Panel key={index}>
                  {signupDetails ? ( // Check if signupDetails is defined
                    <ReactDataGrid
                      licenseKey={
                        "AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988"
                      }
                      idProperty={"SignupID"}
                      style={{ marginTop: 5, minHeight: "85vh" }}
                      defaultFilterValue={filterValues}
                      onFilterValueChange={(o) => handleFilterValueChange(o)}
                      columns={columns}
                      renderRowContextMenu={renderRowContextMenu}
                      rowHeight={40}
                      loading={loading}
                      enableSelection={true}
                      onSelectionChange={onSelectionChange}
                      dataSource={signupDetails}
                      pagination="remote"
                      onSkipChange={(o) =>
                        dispatch(
                          setPage(Math.round(Math.max(o - 1, 0) / limit) + 1)
                        )
                      }
                      onLimitChange={(o) => dispatch(setLimit(o))}
                      defaultLimit={10}
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <ApplicationDialog
        applicationDialog={applicationDialog}
        singleDetails={Decision}
        setapplicationDialog={setApplicationDialog}
        Decision={Decision}
        sethandleSave={() => setApplicationDialog(false)}
      />
    </div>
  );
};

export default SignUpApproval;
