import React, { useContext, useEffect, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { listProductsUnassigned } from '../../../api/SupplierProducts';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';
import GVDItem from './GVDItem';
import ProductItem from './ProductItem';

const ProdutsUnassigned = () => {

  const [index, setIndex] = useState(0);
  const [products, setProducts] = useState(null);
  const [hasLoadedProducts, setHasLoadedProducts] = useState(false);

  const { BRICKFilters, areFiltersEmpty, hideLoading, showLoading } = useContext(BRICKFiltersContext);

  useEffect(() => {
    setIndex((current) => current + 1);
    setHasLoadedProducts(false);
    setProducts([]);
  }, [BRICKFilters]);

  const fetchProducts = async () => {
    if (areFiltersEmpty()) {
      return;
    }

    showLoading();
    setProducts([]);
    const res = await listProductsUnassigned(
      BRICKFilters.SEGMENT, BRICKFilters.FAMILY, BRICKFilters.CLASS, BRICKFilters.BRICK
    );
    hideLoading();
    setHasLoadedProducts(true);

    if (res.status !== HTTP_STATUS_CODES.OK) return false
    setProducts(res.data);
  };

  if (areFiltersEmpty()) {
    return null;
  };

  return (
    <GVDItem key={index} description="No groups assigned" total={!hasLoadedProducts ? null : products?.length} hasChildren={products?.length >= 0} fetchData={fetchProducts}>
      {products?.length === 0 && <span></span>}
      {products?.map((item, index) => <ProductItem key={index} product={item} />)}
    </GVDItem>
  );
};

export default ProdutsUnassigned;