import React, { Fragment, useEffect, useRef, useState } from "react";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import { Tab } from "@headlessui/react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  addProduct,
  clearProductDetail,
  fetchBrick,
  fetchClass,
  fetchCountryOrigin,
  fetchDetail,
  fetchDetailID,
  fetchFamily,
  fetchGrouping,
  fetchProductDetails,
  fetchSegmentInfo,
  fetchSubmit,
  fetchVariant,
  productSelector,
  fetchAttributes,
  fetchAttributesValues,
  submitProductAttributes,
  fetchProductPROG,
  fetchUOM,
  setBrick,
  setClass,
  setDetails,
  setErrorMsg,
  setFamily,
  setGrouping,
  setIndex,
  setLoading,
  setVariant,
  setAttributes,
} from "../../store/slices/productSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { uploadCompanyImage } from "../../store/slices/getStartedSlice";
import { Alert } from "@mui/material";
import { Listbox, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { getUser } from "../../provider/AuthProvider";
import Loader from "../../components/loader/Loader";
import Tooltip from "../../components/tooltip";
import AuditProduct from "./AuditProduct";
import AuditProductGS1Data from "./AuditProductGS1Data";
import Tooltips from "../../components/tooltips";

//
const SelectDropdown = ({
  label,
  name,
  options,
  selectedValue,
  onChange,
  isRequired,
  isDisabled,
}) => {
  const filteredOptions = options.filter((data) => data?.Desc !== null);

  return (
    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
      <h6 className="w-full max-w-[200px] flex justify-end">
        {isRequired && <span className="text-red-500">*</span>}
        {label}
      </h6>
      <select
        name={name}
        onChange={onChange}
        className={`rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm ${
          isDisabled ? "bg-gray-200 cursor-not-allowed" : ""
        }`}
        value={selectedValue}
        disabled={isDisabled}
      >
        <option value="">--select--</option>
        {filteredOptions.length
          ? filteredOptions.map((data) => (
              <option
                className="font-bold"
                value={data.ID}
                key={data.ID}
                selected={selectedValue === data.ID}
              >
                {data.Desc}
              </option>
            ))
          : null}
      </select>
    </div>
  );
};

const textStyle = {
  color: "#555",
  fontSize: "16px",
  lineHeight: "1.5",
  margin: "10px 15px",
};

const boldTextStyle = {
  fontWeight: "bold",
  fontSize: "25px",
  textAlign: "center",
};

const DescContact = ({ descriptionData, loading, handleChanged }) => {
  return (
    <>
      {loading ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
          <div
            key="DescriptionShort"
            className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          >
            <h6 className="w-full max-w-[200px] flex justify-end">
              Description Short:&nbsp;
              {/* <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-blue-500 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText={"Short Description Input of Description Tab"}
              /> */}
              <Tooltips
                tooltipText={"Short EYE Description Input of Description Tab"}
              />
            </h6>
            <input
              placeholder="Description Short"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="DescriptionShort"
              maxLength={35}
              value={descriptionData.DescriptionShort}
              onChange={handleChanged}
            />
          </div>

          <div
            key="DescriptionTradeItem"
            className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          >
            <h6 className="w-full max-w-[200px] flex justify-end">
              Description Trade Item:&nbsp;
              {/* <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              /> */}
              <Tooltips tooltipText="" />
            </h6>
            <input
              placeholder="Description Trade Item"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="DescriptionTradeItem"
              maxLength={200}
              value={descriptionData.DescriptionTradeItem}
              onChange={handleChanged}
            />
          </div>

          <div
            key="DescriptionTradeItemAdditional"
            className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          >
            <h6 className="w-full max-w-[200px] flex justify-end text-right">
              Description Trade Item Additional:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="ml-1 w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Description Trade Item Additional"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="DescriptionTradeItemAdditional"
              maxLength={500}
              value={descriptionData.DescriptionTradeItemAdditional}
              onChange={handleChanged}
            />
          </div>

          <div
            key="ShortDesc"
            className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          >
            <h6 className="w-full max-w-[200px] flex justify-end">
              Short Description:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Short Description"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="ShortDesc"
              maxLength={35}
              value={descriptionData.ShortDesc}
              onChange={handleChanged}
            />
          </div>

          <div
            key="TradeItemDesc"
            className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          >
            <h6 className="w-full max-w-[200px] flex justify-end">
              Trade Item Description:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Trade Item Description"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="TradeItemDesc"
              maxLength={200}
              value={descriptionData.TradeItemDesc}
              onChange={handleChanged}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Trade Item Description Additional:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Trade Item Description Additional"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="TradeItemDescAdditional"
              maxLength={500}
              value={descriptionData.TradeItemDescAdditional}
              onChange={handleChanged}
            />
          </div>
        </div>
      )}
    </>
  );
};

const TargetContact = ({ countryOrigins, product, handleChange }) => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
        <h6 className="w-full max-w-[200px] flex justify-end">
          Target Market:&nbsp;
          <Tooltip
            trigger={
              <span
                className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
              >
                i
              </span>
            }
            placement={"left"}
            tooltipText=""
          />
        </h6>
        <select
          onChange={handleChange}
          name="TargetMarket"
          className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
        >
          {countryOrigins.map((country, index) => (
            <option
              className="font-bold"
              key={index}
              value={country.CountryCodeN3}
              selected={product?.TargetMarket === country.CountryCodeN3}
            >
              {country.CountryNameDisplay}
            </option>
          ))}
        </select>
      </div>
      <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
        <h6 className="w-full max-w-[200px] flex justify-end">
          Sub Target Market:&nbsp;
          <Tooltip
            trigger={
              <span
                className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
              >
                i
              </span>
            }
            placement={"left"}
            tooltipText=""
          />
        </h6>
        <b>Comming Soon</b>
      </div>
    </div>
  );
};

const OriginContact = () => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
      <p style={boldTextStyle}>New Feature Coming Soon</p>

      <p style={textStyle}>
        Welcome to the "Origins" tab, a crucial space where you define the local
        essence of your products. The "Local Content" feature empowers you to
        provide transparency and authenticity to your customers by specifying
        the percentage of contents related to the target market where the
        product is sold.
      </p>

      <p style={textStyle}>
        Here, you can showcase the regional identity and origin of your
        products, instilling confidence in consumers who appreciate the local
        touch. The "Origins" tab aligns with the growing demand for
        sustainability and local sourcing. By entering accurate and detailed
        information about the local content in your products, you not only meet
        consumer expectations but also contribute to the promotion of local
        economies.
      </p>

      <p style={textStyle}>
        Your commitment to transparency and local engagement through the
        "Origins" tab not only enhances your product's appeal but also positions
        your brand as one that values authenticity and ethical practices. Thank
        you for using this tab to share the unique stories behind your products
        and strengthen the connection with your audience.
      </p>
    </div>
  );
};

const ImagesContact = () => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
      <p style={boldTextStyle}>New Feature Coming Soon</p>

      <p style={textStyle}>
        Step into the visual realm with the "Images" tab, where you have the
        opportunity to make a lasting impression on potential customers. This
        tab currently focuses on the main product image, serving as a visual
        anchor for your offerings.
      </p>

      <p style={textStyle}>
        Effortlessly upload a captivating representation of your product, as the
        main image plays a pivotal role in catching the eye of online shoppers.
        A compelling visual can convey the essence of your product and leave a
        memorable imprint on consumers.
      </p>

      <p style={textStyle}>
        Stay tuned for future updates as we enhance this feature to allow the
        upload of multiple images. Enabling a broader range of visuals will give
        consumers a more comprehensive view of your products, fostering a deeper
        connection and trust. Your dedication to showcasing your products
        through the "Images" tab contributes to a visually rich and engaging
        online shopping experience. Thank you for bringing your products to life
        with striking visuals through this essential tab.
      </p>
    </div>
  );
};

const AttributesContact = ({
  attributes,
  handleChange,
  handleClick,
  loading,
}) => {
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "10px",
        height: "0px",
      },
    }),
  };

  return (
    <>
      {loading && attributes.length === 0 ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : (
        <div className={loading ? "blur-background" : ""}>
          {loading ? (
            <div className="loader-wrapper">
              <div className={"flex justify-center items-center my-2 h-0.5"}>
                <Loader width={"w-24"} heigth={"h-7"} />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
            {attributes.length ? (
              attributes.map((attribute, index) => (
                <div
                  className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
                  key={attribute.AttributeTypeID}
                >
                  <h6 className="w-full max-w-[200px] flex justify-end text-right">
                    {attribute.AttributeTypeDesc}:
                    <Tooltip
                      trigger={
                        <span
                          className="w-4 h-4 ml-1 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                        >
                          i
                        </span>
                      }
                      placement={"left"}
                      tooltipText=""
                    />
                  </h6>
                  <Select
                    className="rounded-md w-full font-bold style shadow-sm sm:text-sm"
                    styles={styles}
                    placeholder={"-- Select From List --"}
                    name={attribute.AttributeTypeID}
                    getOptionLabel={(option) => option.AttributeValueDesc}
                    getOptionValue={(option) => option.BrickAttributeID}
                    onFocus={() => handleClick(index)}
                    value={attribute.selectedAttribute}
                    onChange={handleChange}
                    options={attribute?.attributeValues}
                  />
                </div>
              ))
            ) : (
              <p className="text-center" style={{ color: "#f44336" }}>
                <strong>
                  {attributes?.error
                    ? attributes?.error
                    : "Please Select Category Brick First"}
                </strong>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const MeasuresContact = ({
  loading,
  uomData,
  productPROG,
  handleChange,
  handleChanged,
}) => {
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "10px",
        height: "0px",
      },
    }),
  };
  return (
    <>
      {loading ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : uomData.length > 0 ? (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Depth:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.Depth}
              placeholder="Depth"
              className="rounded-md w-full max-w-[300px] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="Depth"
              onChange={handleChanged}
            />

            <Select
              className="rounded-md w-full max-w-[450px] font-bold style shadow-sm sm:text-sm"
              styles={styles}
              placeholder={"-- Select From List --"}
              name="DepthObj"
              getOptionLabel={(uomData) => uomData.UOM_Name}
              getOptionValue={(uomData) => uomData.UOM_Code}
              options={uomData}
              onChange={handleChange}
              value={productPROG?.DepthObj}
            />
          </div>
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Height:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.Height}
              placeholder="Height"
              className="rounded-md w-full max-w-[300px] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              name="Height"
              onChange={handleChanged}
            />

            <Select
              className="rounded-md w-full max-w-[450px] font-bold style shadow-sm sm:text-sm"
              styles={styles}
              placeholder={"-- Select From List --"}
              name="HeightObj"
              getOptionLabel={(uomData) => uomData.UOM_Name}
              getOptionValue={(uomData) => uomData.UOM_Code}
              options={uomData}
              onChange={handleChange}
              value={productPROG?.HeightObj}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Width:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.Width}
              placeholder="Width"
              type="text"
              name="Width"
              className="rounded-md w-full max-w-[300px] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={handleChanged}
            />

            <Select
              className="rounded-md w-full max-w-[450px] font-bold style shadow-sm sm:text-sm"
              styles={styles}
              placeholder={"-- Select From List --"}
              name="WidthObj"
              getOptionLabel={(uomData) => uomData.UOM_Name}
              getOptionValue={(uomData) => uomData.UnitOfMeasureID}
              options={uomData}
              onChange={handleChange}
              value={productPROG?.WidthObj}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end text-right">
              NetContent:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.NetContent}
              placeholder="NetContent"
              type="text"
              name="NetContent"
              className="rounded-md w-full max-w-[300px] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={handleChanged}
            />

            <Select
              className="rounded-md w-full max-w-[450px] font-bold style shadow-sm sm:text-sm"
              styles={styles}
              placeholder={"-- Select From List --"}
              name="NetContentObj"
              getOptionLabel={(uomData) => uomData.UOM_Name}
              getOptionValue={(uomData) => uomData.UnitOfMeasureID}
              options={uomData}
              onChange={handleChange}
              value={productPROG?.NetContentObj}
            />
          </div>
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Weight:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.Weight}
              placeholder="Weight"
              type="text"
              name="Weight"
              className="rounded-md w-full max-w-[300px] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={handleChanged}
            />

            <Select
              className="rounded-md w-full max-w-[450px] font-bold style shadow-sm sm:text-sm"
              styles={styles}
              placeholder={"-- Select From List --"}
              name="WeightObj"
              getOptionLabel={(uomData) => uomData.UOM_Name}
              getOptionValue={(uomData) => uomData.UnitOfMeasureID}
              options={uomData}
              onChange={handleChange}
              value={productPROG?.WeightObj}
            />
          </div>
        </div>
      ) : (
        <p className="text-center" style={{ color: "#f44336" }}>
          <strong>Something went wrong; please try reloading</strong>
        </p>
      )}
    </>
  );
};

const SustainabilityContact = ({ loading }) => {
  return (
    <>
      {loading ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Packaging Material Desc:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <select className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm">
              <option className="font-bold" value="null">
                --Select--{" "}
              </option>
            </select>
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end text-right">
              Packaging Material Returnable:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="ml-1 w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <select className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm">
              <option className="font-bold" value="null">
                --Select--{" "}
              </option>
            </select>
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Packaging Material Type:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <select className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm">
              <option className="font-bold" value="null">
                --Select--{" "}
              </option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};

const AvaliabilityContact = ({
  productPROG,
  loading,
  handleChange,
  formatDate,
  handleDateChange,
}) => {
  return (
    <>
      {loading ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : Object.keys(productPROG).length > 0 ? (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Publication Date:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/YYYY"
              selected={productPROG?.PublicationDate}
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={(date) => handleDateChange("PublicationDate", date)}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Community Visibility Date:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <DatePicker
              selected={productPROG?.CommunityVisibilityDate}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/YYYY"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={(date) =>
                handleDateChange("CommunityVisibilityDate", date)
              }
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Origin Date:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/YYYY"
              selected={productPROG?.OriginDate}
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={(date) => handleDateChange("OriginDate", date)}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Change Date:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/YYYY"
              selected={productPROG?.ChangeDate}
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              onChange={(date) => handleDateChange("ChangeDate", date)}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              End Availability Date:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/YYYY"
              selected={productPROG?.EndAvailabilityDate}
              onChange={(date) => handleDateChange("EndAvailabilityDate", date)}
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Date Created:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Date Created"
              type="text"
              name="DateCreated"
              disabled
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              value={formatDate(productPROG?.DateCreated)}
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Last Update:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              placeholder="Last Update"
              type="text"
              name="LastUpdate"
              disabled
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              value={formatDate(productPROG?.LastUpdate)}
            />
          </div>
        </div>
      ) : (
        <p className="text-center" style={{ color: "#f44336" }}>
          <strong>Something went wrong; please try reloading</strong>
        </p>
      )}
    </>
  );
};

const ParentContact = () => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(105vh-500px)] overflow-y-auto">
      <p style={boldTextStyle}>New Feature Coming Soon</p>
      <p style={textStyle}>
        Unlock the interconnected world of your products through the "Parent &
        Child" tab. Here, you can define relationships between products, such as
        a can of soft drink available individually or in various packs (4, 6,
        10, or 12). Additionally, extend this hierarchy to include shippers and
        pallets.
      </p>
      <p style={textStyle}>
        This feature provides a holistic view, crucial for warehousing,
        logistics, and retailer ordering. By specifying these relationships, you
        ensure seamless coordination, optimising the handling and transportation
        of your products. The "Parent/Child" tab acts as a strategic tool,
        offering a clear representation of your product ecosystem, from
        individual units to palletised shipments. Thank you for utilising this
        tab to streamline the logistical aspects of your product supply chain.
      </p>
    </div>
  );
};

const AuditContact = () => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
      <AuditProduct />
    </div>
  );
};

const GS1Contact = () => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
      <AuditProductGS1Data />
    </div>
  );
};

const LabellingContact = ({ productPROG, loading, handleChange }) => {
  return (
    <>
      {loading ? (
        <div className={"flex justify-center items-center my-2 h-64"}>
          <Loader width={"w-24"} heigth={"h-10"} />
        </div>
      ) : Object.keys(productPROG).length > 0 ? (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Country of Origin Statement:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.CountryOfOriginStatement}
              placeholder="Country of Origin Statement"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="255"
              onChange={handleChange}
              name="CountryOfOriginStatement"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Additional Phrase:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingAdditionalPhrase}
              placeholder="Labelling Additional Phrase"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingAdditionalPhrase"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end text-right">
              Labelling Australian Content Percent:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="ml-1 w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingAustralianContentPercent}
              placeholder="Labelling Australian Content Percent"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingAustralianContentPercent"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Country of Origin:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingCountryOfOrigin}
              placeholder="Labelling Country of Origin"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingCountryOfOrigin"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Get Details:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingGetDetails}
              placeholder="Labelling Get Details"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingGetDetails"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end text-right">
              Labelling Ingredient Statement:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="ml-1 w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingIngredientStatement}
              placeholder="Labelling Ingredient Statement"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingIngredientStatement"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Logos:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingLogos}
              placeholder="Labelling Logos"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingLogos"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Packed Statement:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingPackedStatement}
              placeholder="Labelling Packed Statement"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingPackedStatement"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Labelling Product Name:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.LabellingProductName}
              placeholder="Labelling Product Name"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="80"
              onChange={handleChange}
              name="LabellingProductName"
            />
          </div>

          <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] flex justify-end">
              Nutritional Claim:&nbsp;
              <Tooltip
                trigger={
                  <span
                    className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                  >
                    i
                  </span>
                }
                placement={"left"}
                tooltipText=""
              />
            </h6>
            <input
              value={productPROG?.NutritionalClaim}
              placeholder="Nutritional Claim"
              className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              type="text"
              maxLength="4000"
              onChange={handleChange}
              name="NutritionalClaim"
            />
          </div>
        </div>
      ) : (
        <p className="text-center" style={{ color: "#f44336" }}>
          <strong>Something went wrong; please try reloading</strong>
        </p>
      )}
    </>
  );
};
const isPropertyFilled = (property) =>
  property !== undefined && property !== null && property !== "";

const CategoryComponent = ({
  segments,
  family,
  productClass,
  brick,
  grouping,
  variant,
  details,
  product,
  handleChange,
}) => {
  return (
    <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
      <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
        <p className="font-semibold text-base">
          Global Product Classification (GPC)
        </p>
      </div>

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Segment:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_SegmentID"
        options={segments || []}
        selectedValue={product.FK_SegmentID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={false}
      />

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Family:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_FamilyID"
        options={family || []}
        selectedValue={product.FK_FamilyID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={!isPropertyFilled(product.FK_SegmentID)}
      />

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Class:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_ClassID"
        options={productClass || []}
        selectedValue={product.FK_ClassID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={
          !isPropertyFilled(product.FK_SegmentID) ||
          !isPropertyFilled(product.FK_FamilyID)
        }
      />

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Brick:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_BrickID"
        options={brick || []}
        selectedValue={product.FK_BrickID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={
          !isPropertyFilled(product.FK_SegmentID) ||
          !isPropertyFilled(product.FK_FamilyID) ||
          !isPropertyFilled(product.FK_ClassID)
        }
      />

      <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
        <p className="font-semibold text-base">Grouping Variant Detail (GVD)</p>
      </div>

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Grouping:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_GroupingID"
        options={grouping || []}
        selectedValue={product.FK_GroupingID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={
          !isPropertyFilled(product.FK_SegmentID) ||
          !isPropertyFilled(product.FK_FamilyID) ||
          !isPropertyFilled(product.FK_ClassID) ||
          !isPropertyFilled(product.FK_BrickID)
        }
      />

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Variant:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_VariantID"
        options={variant || []}
        selectedValue={product.FK_VariantID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={
          !isPropertyFilled(product.FK_SegmentID) ||
          !isPropertyFilled(product.FK_FamilyID) ||
          !isPropertyFilled(product.FK_ClassID) ||
          !isPropertyFilled(product.FK_BrickID) ||
          !isPropertyFilled(product.FK_GroupingID)
        }
      />

      <SelectDropdown
        label={
          <span className="w-full max-w-[200px] flex justify-end">
            Detail:&nbsp;
            <Tooltip
              trigger={
                <span
                  className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                >
                  i
                </span>
              }
              placement={"left"}
              tooltipText=""
            />
          </span>
        }
        name="FK_DetailID"
        options={details || []}
        selectedValue={product.FK_DetailID}
        onChange={handleChange}
        isRequired={false}
        isDisabled={
          !isPropertyFilled(product.FK_SegmentID) ||
          !isPropertyFilled(product.FK_FamilyID) ||
          !isPropertyFilled(product.FK_ClassID) ||
          !isPropertyFilled(product.FK_BrickID) ||
          !isPropertyFilled(product.FK_GroupingID) ||
          !isPropertyFilled(product.FK_VariantID)
        }
      />
    </div>
  );
};

const NoContact = () => {
  return (
    <div className="h-40 flex justify-center items-center">
      <h1 className="lg:text-4xl text-2xl">New Feature Coming Soon</h1>
    </div>
  );
};

const SupplierProduct = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const dispatch = useAppDispatch();
  const { GTIN } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const {
    product,
    wizardIndex,
    segments,
    countryOrigins,
    family,
    productClass,
    brick,
    grouping,
    variant,
    details,
    brands,
    errorMsg,
    loading,
    attributes,
  } = useAppSelector(productSelector);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isAttributeChange, setAttributeChange] = useState(true);
  const [isLabellingChange, setLabellingChange] = useState(true);
  const [isDescriptionChange, setDescriptionChange] = useState(true);
  const [isTargetChange, setTargetChange] = useState(true);
  const [isAvailabilityChange, setAvailabilityChange] = useState(true);
  const [isUOMChange, setUOMChange] = useState(true);
  const tabPanelsData = [
    {
      label: "Descriptions",
      component: () => (
        <DescContact
          descriptionData={productProg}
          loading={loading}
          handleChanged={handleInputChange}
        />
      ),
    },
    {
      label: "Labelling",
      component: () => (
        <LabellingContact
          productPROG={productProg}
          handleChange={handleInputChange}
          loading={loading}
        />
      ),
    },
    {
      label: "Target Markets",
      component: () => (
        <TargetContact
          countryOrigins={countryOrigins}
          product={productProg}
          handleChange={(e) => setFormField(e.target.name, e.target.value)}
        />
      ),
    },
    {
      label: "Origins",
      component: OriginContact,
    },
    {
      label: "Images",
      component: ImagesContact,
    },
    {
      label: "Categories",
      component: () => (
        <CategoryComponent
          segments={segments}
          family={family}
          productClass={productClass}
          brick={brick}
          grouping={grouping}
          variant={variant}
          details={details}
          product={product}
          handleChange={(e) => setFormField(e.target.name, e.target.value)}
        />
      ),
    },
    {
      label: "Attributes",
      component: () => (
        <AttributesContact
          attributes={attributes}
          handleChange={handleAttributeChange}
          handleClick={getAttributeValues}
          loading={loading}
        />
      ),
    },
    {
      label: "Measures & Sizing",
      component: () => (
        <MeasuresContact
          loading={loading}
          uomData={uomData}
          productPROG={productProg}
          handleChange={handleUOMChange}
          handleChanged={handleInputChange}
        />
      ),
    },
    {
      label: "Packaging & Sustainability",
      component: () => <SustainabilityContact loading={loading} />,
    },
    {
      label: "Avaliability (Dates)",
      component: () => (
        <AvaliabilityContact
          productPROG={productProg}
          handleChange={handleInputChange}
          formatDate={formatDateField}
          loading={loading}
          handleDateChange={handleDateChangeField}
        />
      ),
    },
    {
      label: "Parent / Child",
      component: ParentContact,
    },
    {
      label: "Related Products",
      component: NoContact,
    },
    {
      label: "Audit Data",
      component: () => <AuditContact />,
    },
    {
      label: "GS1 Data",
      component: GS1Contact,
    },
  ];

  const { BrandID, Brand, BrandSub } = product || {};
  const selectedBrandID = BrandID ?? "000";
  const selectedBrand = Brand ?? "-- Select From List";
  const selectedBrandSub = BrandSub ?? "--";

  const isBrandListAvailable = brands && brands.length > 0;

  const [selected, setSelected] = useState({
    BrandID: selectedBrandID,
    Brand: selectedBrand,
    BrandSub: selectedBrandSub,
  });

  useEffect(() => {
    setSelected({
      BrandID: isBrandListAvailable ? selectedBrandID : "000",
      Brand: isBrandListAvailable ? selectedBrand : "-- Select From List",
      BrandSub: isBrandListAvailable ? selectedBrandSub : "--",
    });
  }, [
    product.BrandID,
    isBrandListAvailable,
    selectedBrandID,
    selectedBrand,
    selectedBrandSub,
  ]);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showAttributeSuccess, setShowAttributeSuccess] = useState(false);
  const [productProg, setProductProg] = useState({});
  const [uomData, setUOMData] = useState([]);

  const fileInputRef = useRef(null);
  const fetchData = async () => {
    await dispatch(setLoading(false));
    await dispatch(setErrorMsg(""));
    await dispatch(clearProductDetail());
    await dispatch(fetchProductDetails(GTIN));
  };

  const fetchClassificationData = async () => {
    if (product?.SegmentID) {
      await dispatch(fetchFamily(product?.SegmentID));
    }
    if (product?.FamilyID) {
      await dispatch(fetchClass(product?.FamilyID));
    }
    if (product?.ClassID) {
      await dispatch(fetchBrick(parseInt(product?.ClassID)));
    }
    if (product?.BrickID) {
      await dispatch(fetchGrouping(product?.BrickID));
    }
    if (product?.GroupingID) {
      await dispatch(fetchVariant(product?.GroupingID));
    }
    if (product?.VariantID) {
      await dispatch(fetchDetail(product?.VariantID));
    }
  };

  useEffect(() => {
    const fetchDataAndClassification = async () => {
      await fetchData();
      await fetchClassificationData();
    };

    fetchDataAndClassification();
  }, [GTIN]);

  const segmentInfo = async () => {
    if (wizardIndex === 5) {
      await dispatch(fetchSegmentInfo());
      await dispatch(fetchCountryOrigin());
    } else if (wizardIndex === 6) {
      fetchAttributesData();
    } else if (
      wizardIndex === 1 ||
      wizardIndex === 0 ||
      wizardIndex === 2 ||
      wizardIndex === 9 ||
      wizardIndex === 7
    ) {
      fetchProductProgData();
    }
  };

  const setUomDefaultVal = (progData, uomRecords) => {
    if (uomRecords.length && Object.keys(progData).length > 0) {
      const DepthUOMIndex = uomRecords.findIndex(
        (x) => x.UOM_Code === progData.DepthUOM
      );
      if (DepthUOMIndex > -1) {
        progData.DepthObj = uomRecords[DepthUOMIndex];
      }

      const HeightUOMIndex = uomRecords.findIndex(
        (x) => x.UOM_Code === progData.HeightUOM
      );
      if (HeightUOMIndex > -1) {
        progData.HeightObj = uomRecords[HeightUOMIndex];
      }

      const NetContentUOMIndex = uomRecords.findIndex(
        (x) => x.UOM_Code === progData.NetContentUOM
      );
      if (NetContentUOMIndex > -1) {
        progData.NetContentObj = uomRecords[NetContentUOMIndex];
      }

      const WeightUOMIndex = uomRecords.findIndex(
        (x) => x.UOM_Code === progData.WeightUOM
      );
      if (WeightUOMIndex > -1) {
        progData.WeightObj = uomRecords[WeightUOMIndex];
      }

      const WidthUOMIndex = uomRecords.findIndex(
        (x) => x.UOM_Code === progData.WidthUOM
      );
      if (WidthUOMIndex > -1) {
        progData.WidthObj = uomRecords[WidthUOMIndex];
      }
      setProductProg(progData);
    }
  };

  // Function for the set the
  const setFormField = (key, value) => {
    let data = { ...product };
    console.log("this is category", data);

    const deleteMap = {
      FK_SegmentID: [
        "FK_BrickID",
        "FK_ClassID",
        "FK_DetailID",
        "FK_GroupingID",
        "FK_VariantID",
        "FK_FamilyID",
      ],
      FK_FamilyID: [
        "FK_BrickID",
        "FK_ClassID",
        "FK_DetailID",
        "FK_GroupingID",
        "FK_VariantID",
      ],
      FK_ClassID: [
        "FK_BrickID",
        "FK_DetailID",
        "FK_GroupingID",
        "FK_VariantID",
      ],
      FK_BrickID: ["FK_DetailID", "FK_GroupingID", "FK_VariantID"],
      FK_GroupingID: ["FK_VariantID", "FK_DetailID"],
      FK_VariantID: ["FK_DetailID"],
    };

    deleteMap[key]?.forEach((propToDelete) => {
      delete data[propToDelete];
      switch (propToDelete) {
        case "FK_SegmentID":
          dispatch(setFamily([]));
          dispatch(setClass([]));
          dispatch(setBrick([]));
          dispatch(setGrouping([]));
          dispatch(setVariant([]));
          dispatch(setDetails([]));
          break;
        case "FK_FamilyID":
          dispatch(setClass([]));
          dispatch(setBrick([]));
          dispatch(setGrouping([]));
          dispatch(setVariant([]));
          dispatch(setDetails([]));
          break;
        case "FK_ClassID":
          dispatch(setBrick([]));
          dispatch(setGrouping([]));
          dispatch(setVariant([]));
          dispatch(setDetails([]));
          break;
        case "FK_BrickID":
          dispatch(setGrouping([]));
          dispatch(setVariant([]));
          dispatch(setDetails([]));
          break;
        case "FK_GroupingID":
          dispatch(setVariant([]));
          dispatch(setDetails([]));
          break;
        case "FK_VariantID":
          dispatch(setDetails([]));
          break;
        // Add more cases if needed
        default:
          break;
      }
    });

    data[key] = value;
    console.log("this is data before start", data);
    dispatch(addProduct(data));
    console.log("this is fucking category", data);
  };

  const handleAttributeChange = (obj, value) => {
    let data = JSON.parse(JSON.stringify(attributes));
    const findIndex = data.findIndex((x) => x.AttributeTypeID === value.name);
    if (findIndex > -1) {
      data[findIndex].selectedAttribute = obj;
    }
    dispatch(setAttributes(data));
    setAttributeChange(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductProg((prevState) => ({ ...prevState, [name]: value }));
    if (wizardIndex === 0) {
      setDescriptionChange(false);
    } else if (wizardIndex === 1) {
      setLabellingChange(false);
    } else if (wizardIndex === 7) {
      setUOMChange(false);
    }
  };

  const handleUOMChange = (obj, val) => {
    const name = val?.name;

    if (name === "DepthObj") {
      productProg.HeightObj = obj;
      productProg.WidthObj = obj;
    }
    setProductProg((prevState) => ({ ...prevState, [name]: obj }));
    setUOMChange(false);
  };

  const formatDateField = (val) => {
    if (typeof val !== "undefined" && val !== null) {
      return moment(val).format("DD/MM/YYYY");
    } else {
      return null;
    }
  };

  const handleDateChangeField = (name, value) => {
    setProductProg((prevState) => ({ ...prevState, [name]: new Date(value) }));
    setAvailabilityChange(false);
  };

  const getAttributeValues = (index) => {
    if (attributes[index].preValue) {
      dispatch(fetchAttributesValues(product.FK_BrickID, attributes, index));
    }
  };

  useEffect(() => {
    segmentInfo();
  }, [wizardIndex]);

  //this is for fetch drop down list
  useEffect(() => {
    product.FK_SegmentID && dispatch(fetchFamily(product.FK_SegmentID));
    product.FK_FamilyID && dispatch(fetchClass(product.FK_FamilyID));
    product.FK_ClassID && dispatch(fetchBrick(parseInt(product.FK_ClassID)));
    product.FK_BrickID && dispatch(fetchGrouping(product.FK_BrickID));
    product.FK_GroupingID && dispatch(fetchVariant(product.FK_GroupingID));
    product.FK_VariantID && dispatch(fetchDetail(product.FK_VariantID));
  }, [
    product.FK_SegmentID,
    product.FK_FamilyID,
    product.FK_ClassID,
    product.FK_BrickID,
    product.FK_GroupingID,
    product.FK_VariantID,
    dispatch,
  ]);

  const fetchAttributesData = async () => {
    if (
      typeof product.FK_BrickID !== "undefined" &&
      product.FK_BrickID !== null
    ) {
      await dispatch(fetchAttributes(product.FK_BrickID, product.ProductID));
    }
  };

  const fetchProductProgData = async () => {
    if (
      typeof product.ProductID !== "undefined" &&
      product.ProductID !== null
    ) {
      const progData = await dispatch(
        fetchProductPROG(product.ProductID, product.ProductID)
      );
      if (progData.length) {
        setProductProg(progData[0]);
        if (wizardIndex === 7) {
          fetchUnitOfMeasure(progData[0]);
        }
      }
    }
  };
  const fetchUnitOfMeasure = async (progData) => {
    const uomData = await dispatch(
      fetchUOM(product.ProductID, product.ProductID)
    );
    if (uomData.length) {
      const sortedUOMData = uomData.sort((a, b) => a.SortOrder - b.SortOrder);
      setUOMData(sortedUOMData);
      setUomDefaultVal(progData, sortedUOMData);
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      // Handle error: No file selected
      return;
    }

    if (file && /\.(jpg|jpeg|png)$/i.test(file.name)) {
      setUploadedFile(file);
      dispatch(setErrorMsg("")); // Clear any previous error
    } else {
      dispatch(
        setErrorMsg(
          "Invalid file format. Please upload a JPG, JPEG, or PNG file."
        )
      );
    }

    setUploadedFile(file);
    const formData = new FormData();
    formData.append("type", "product");
    formData.append("id", product?.ProductID?.toString());
    formData.append("image", file);
    await dispatch(uploadCompanyImage(formData));
  };

  useEffect(() => {
    let successTimeout;

    if (showSuccess) {
      successTimeout = setTimeout(() => {
        setShowSuccess(false);
      }, 2000); // Display success message for 2 seconds
    }

    if (showAttributeSuccess) {
      successTimeout = setTimeout(() => {
        setShowAttributeSuccess(false);
      }, 5000); // Display success message for 2 seconds
    }

    return () => {
      // Clear the timeout on component unmount or when showSuccess changes
      clearTimeout(successTimeout);
    };
  }, [showSuccess, showAttributeSuccess]);

  const handleQuickUpload = async (key, value) => {
    if (key === "NetContent") {
      await dispatch(
        fetchSubmit({
          ProductID: product.ProductID,
          LabellingAustralianContentPercent: value,
        })
      );
    } else {
      await dispatch(
        fetchSubmit({
          ProductID: product.ProductID,
          [key]: value,
        })
      );
    }
    setShowSuccess(true);
  };

  const submitHandler = async () => {
    dispatch(setLoading(true));
    dispatch(setErrorMsg("")); // Reset error message

    const requiredKeys = [
      { key: "ProductID", message: "Product id" },
      { key: "FK_SegmentID", message: "Segment" },
      { key: "FK_ClassID", message: "Class" },
      { key: "FK_FamilyID", message: "Family" },
      { key: "FK_BrickID", message: "Brick" },
    ];

    const missingKey = requiredKeys.find(({ key }) => {
      const value = product?.[key];

      // Check if the key is missing or has a value of undefined or null
      return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      );
    });

    if (missingKey) {
      dispatch(setLoading(false));
      const validationText = `Validation error: ${missingKey.message} is missing`;
      dispatch(setErrorMsg(validationText));
      return; // Exit early if there's a validation error
    }

    const {
      ProductID,
      FK_SegmentID,
      FK_ClassID,
      FK_FamilyID,
      FK_BrickID,
      FK_GroupingID,
      FK_VariantID,
      FK_DetailID,
    } = product;

    //Convert values to integers
    const parsedSegmentID = parseInt(FK_SegmentID);
    const parsedFamilyID = parseInt(FK_FamilyID);
    const parsedClassID = parseInt(FK_ClassID);
    const parsedBrickID = parseInt(FK_BrickID);
    const parsedGroupingID = parseInt(FK_GroupingID);
    const parsedVariantID = parseInt(FK_VariantID);
    const parsedDetailID = parseInt(FK_DetailID);

    dispatch(
      fetchDetailID({
        SegmentID: parsedSegmentID,
        FamilyID: parsedFamilyID,
        ClassID: parsedClassID,
        BrickID: parsedBrickID,
        GroupingID: parsedGroupingID,
        VariantID: parsedVariantID,
        DetailID: parsedDetailID,
      })
    )
      .then((res) => {
        if (!errorMsg && res) {
          dispatch(
            fetchSubmit({
              ProductID,
              FK_DetailID: res,
            })
          );
          setShowSuccess(true);
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const submitTargetChange = async () => {
    dispatch(setLoading(true));
    dispatch(setErrorMsg("")); // Reset error message

    const requiredKeys = [
      { key: "ProductID", message: "Product id" },
      { key: "TargetMarket", message: "TargetMarket" },
    ];

    const missingKey = requiredKeys.find(({ key }) => {
      const value = product?.[key];

      // Check if the key is missing or has a value of undefined or null
      return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      );
    });

    if (missingKey) {
      dispatch(setTargetChange(false));
      const validationText = `Validation error: ${missingKey.message} is missing`;
      dispatch(setErrorMsg(validationText));
      return; // Exit early if there's a validation error
    }

    const { ProductID, TargetMarket } = product;

    // Dispatch fetchDetailID action
    dispatch(
      fetchSubmit({
        ProductID,
        TargetMarket,
      })
    )
      .then((res) => {
        setShowSuccess(true);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const submitAttributeChange = async () => {
    let data = JSON.parse(JSON.stringify(attributes));
    dispatch(setLoading(true));
    dispatch(setErrorMsg("")); // Reset error message

    for (let i = 0; i < data.length; ++i) {
      if (
        typeof data[i].selectedAttribute?.BrickAttributeID !== "undefined" &&
        data[i].selectedAttribute?.BrickAttributeID !==
          data[i].oldSelectedAttribute
      ) {
        const updateAttributeValues = {
          OLD_BrickAttributeID: data[i].oldSelectedAttribute,
          NEW_BrickAttributeID: data[i].selectedAttribute.BrickAttributeID,
          ProductID: product.ProductID,
          AuditUserID: getUser().UserID,
        };
        dispatch(submitProductAttributes(updateAttributeValues));
        data[i].oldSelectedAttribute =
          data[i].selectedAttribute.BrickAttributeID;
      }
    }
    dispatch(setAttributes(data));
    setShowAttributeSuccess(true);
    dispatch(setLoading(false));
    setAttributeChange(true);
  };

  const submitProductProg = async () => {
    dispatch(setErrorMsg("")); // Reset error message
    const data = {
      ProductID: product.ProductID,
      CountryOfOriginStatement: productProg?.CountryOfOriginStatement,
      LabellingAdditionalPhrase: productProg?.LabellingAdditionalPhrase,
      LabellingAustralianContentPercent:
        productProg?.LabellingAustralianContentPercent,
      LabellingCountryOfOrigin: productProg?.LabellingCountryOfOrigin,
      LabellingGetDetails: productProg?.LabellingGetDetails,
      LabellingIngredientStatement: productProg?.LabellingIngredientStatement,
      LabellingLogos: productProg?.LabellingLogos,
      LabellingPackedStatement: productProg?.LabellingPackedStatement,
      LabellingProductName: productProg?.LabellingProductName,
      NutritionalClaim: productProg?.NutritionalClaim,
    };
    setLabellingChange(true);
    // Dispatch Product Labeling
    dispatch(fetchSubmit(data))
      .then((res) => {
        setShowSuccess(true);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const submitUOM = async () => {
    dispatch(setErrorMsg("")); // Reset error message
    const data = {
      ProductID: product.ProductID,
      Depth: productProg?.Depth,
      DepthUOM: productProg?.DepthObj?.UOM_Code,
      Height: productProg?.Height,
      HeightUOM: productProg?.HeightObj?.UOM_Code,
      NetContent: productProg?.NetContent,
      NetContentUOM: productProg?.NetContentObj?.UOM_Code,
      Weight: productProg?.Weight,
      WeightUOM: productProg?.WeightObj?.UOM_Code,
      Width: productProg?.Width,
      WidthUOM: productProg?.WidthObj?.UOM_Code,
    };

    setUOMChange(true);
    // Dispatch Product UOM
    dispatch(fetchSubmit(data))
      .then((res) => {
        setShowSuccess(true);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const submitAvailabilityDates = async () => {
    dispatch(setErrorMsg("")); // Reset error message
    const data = {
      ProductID: product.ProductID,
      CommunityVisibilityDate: productProg?.CommunityVisibilityDate,
      EndAvailabilityDate: productProg?.EndAvailabilityDate,
      OriginDate: productProg?.OriginDate,
      PublicationDate: productProg?.PublicationDate,
      ChangeDate: productProg?.ChangeDate,
    };
    setAvailabilityChange(true);
    // Dispatch Product Availability Dates
    dispatch(fetchSubmit(data))
      .then((res) => {
        setShowSuccess(true);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const submitDescriptionChange = async () => {
    dispatch(setErrorMsg(""));
    const requiredKeys = [
      { key: "ProductID", message: "Product id" },
      { key: "DescriptionShort", message: "Description Short" },
      { key: "DescriptionTradeItem", message: "Description Trade Item" },
      {
        key: "DescriptionTradeItemAdditional",
        message: "Description Trade Item Additional",
      },
      { key: "ShortDesc", message: "Short Description" },
      { key: "TradeItemDesc", message: "Trade Item Description" },
      {
        key: "TradeItemDescAdditional",
        message: "Trade Item Description Additional",
      },
    ];
    const missingKey = requiredKeys.find(({ key }) => {
      const value = productProg?.[key];
      return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      );
    });
    if (missingKey) {
      dispatch(setLoading(false));
      const validationText = `Validation error: ${missingKey.message} is missing`;
      dispatch(setErrorMsg(validationText));
      return;
    }

    const data = {
      ProductID: product.ProductID,
      DescriptionShort: productProg?.DescriptionShort,
      DescriptionTradeItem: productProg?.DescriptionTradeItem,
      DescriptionTradeItemAdditional:
        productProg?.DescriptionTradeItemAdditional,
      ShortDesc: productProg?.ShortDesc,
      TradeItemDesc: productProg?.TradeItemDesc,
      TradeItemDescAdditional: productProg?.TradeItemDescAdditional,
    };
    setDescriptionChange(true);
    dispatch(fetchSubmit(data))
      .then((res) => {
        setShowSuccess(true);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const cancelHandler = () => {
    navigate(-1); // Navigate back in the browser history
  };

  const handleButtonClick = () => {
    // Trigger a click on the file input
    fileInputRef.current.click();
  };

  const isPropertyFilled = (property) =>
    property !== undefined && property !== null && property !== "";

  const is_filled =
    isPropertyFilled(product.FK_SegmentID) &&
    isPropertyFilled(product.FK_ClassID) &&
    isPropertyFilled(product.FK_FamilyID) &&
    isPropertyFilled(product.FK_BrickID);

  return (
    <div className="w-full mx-auto">
      <DashboardHeader title={""} nav={[]} />

      <div className="my-5 rounded-xl shadow-md p-2 bg-white">
        <div className="grid grid-cols-12 gap-10 place-items-center">
          <div className="lg:col-span-9 md:col-span-6 sm:col-span-9 col-span-6 w-full space-y-4">
            <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
              <h6 className="w-full max-w-[200px] text-right">
                GTIN (Barcode):
              </h6>
              <p className={"font-bold"}>{product?.GTIN}</p>
            </div>
            <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
              <h6 className="w-full max-w-[200px] text-right">Brand:</h6>
              <Listbox
                value={selected}
                onChange={(e) => {
                  setSelected(e);
                  setFormField("FK_BrandID", e.BrandID);
                  handleQuickUpload("FK_BrandID", e.BrandID);
                }}
              >
                <div className="relative mt-1 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                    <span className="block truncate font-bold">
                      {selected?.Brand} - {selected?.BrandSub}
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                      <div className="grid grid-cols-2">
                        <div className="bg-gray-300 pl-4 py-2">
                          <h6 className="font-bold">Brand</h6>
                        </div>
                        <div className="bg-gray-300 pl-4 py-2">
                          <h6 className="font-bold">Sub Brand</h6>
                        </div>
                      </div>
                      <div className=" overflow-y-auto h-48">
                        {brands.map((brand, brandIdx) => (
                          <Listbox.Option
                            key={brandIdx}
                            className={({ active }) =>
                              `relative cursor-default grid border-b grid-cols-2 pl-4 py-2 select-none ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={brand}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {brand?.Brand}
                                </span>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {brand?.BrandSub || ""}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </div>
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
            {!isBrandListAvailable && (
              <p className="w-full max-w-[570px] text-right text-black mt-2">
                For Brand Maintenance, contact our Team using Chat Support
              </p>
            )}
            <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
              <h6 className="w-full max-w-[200px] text-right">Description:</h6>
              <input
                onChange={(e) => setFormField("TradeItemDesc", e.target.value)}
                value={product?.TradeItemDesc}
                placeholder="Description"
                className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                type="text"
                onBlur={(e) => {
                  if (
                    !e.relatedTarget ||
                    !e.currentTarget.contains(e.relatedTarget)
                  ) {
                    handleQuickUpload("TradeItemDesc", e.target.value);
                  }
                }}
              />
            </div>
            <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
              <h6 className="w-full max-w-[200px] text-right">
                Local Content:
              </h6>
              <select
                //value={labellingContent}
                className="rounded-md w-[20%] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                onChange={(e) => {
                  setFormField("NetContent", e.target.value);
                  handleQuickUpload("NetContent", e.target.value);
                }}
              >
                {/* Populate options dynamically from 0 to 100 */}
                <option className="font-bold" value="null">
                  --Select--{" "}
                </option>
                {[...Array(101).keys()].map((value) => (
                  <option
                    className="font-bold"
                    key={value}
                    value={value}
                    selected={
                      product?.LabellingAustralianContentPercent === value
                    }
                  >
                    {value === 0 ? "NIL" : value}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
              <h6 className="w-full max-w-[200px] text-right">
                Country of Origin:
              </h6>
              <select
                value={product?.CountryOfOriginCodeN3}
                onChange={(e) => {
                  setFormField("CountryOfOrigin", e.target.value);
                  handleQuickUpload("CountryOfOrigin", e.target.value);
                }}
                className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
              >
                {countryOrigins.map((country, index) => (
                  <option
                    className="font-bold"
                    key={index}
                    value={country.CountryCodeN3}
                    selected={
                      product?.ProductCountryCodeN3 === country.CountryCodeN3
                    }
                  >
                    {country.CountryNameDisplay}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex w-full flex-col lg:col-span-3 md:col-span-6 col-span-12 lg:flex-nowrap md:flex-nowrap flex-wrap items-start gap-3">
            <h6 className="w-full max-w-[200px]">Product Image:</h6>
            <div className="flex items-center justify-start w-full">
              <label
                htmlFor="file" // Corrected attribute name
                className="flex flex-col items-center justify-center w-[150px] h-[150px] border-2 border-gray-300 border-dashed rounded-lg bg-gray-100 hover:bg-gray-200"
              >
                {uploadedFile ? (
                  <img
                    src={URL.createObjectURL(uploadedFile)}
                    alt="Uploaded"
                    className="w-[150px] h-[150px] object-contain"
                  />
                ) : product.ProductImageURL !== null ? (
                  <img
                    src={product?.ProductImageURL}
                    className="w-[150px] h-[145px] object-contain"
                    alt={product?.PrefixID}
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <img
                      src={"/temp.jpg"}
                      alt={"temp"}
                      className="w-[150px] h-[145px] object-fill rounded-1"
                    />
                  </div>
                )}
              </label>
            </div>
            <button
              onClick={handleButtonClick}
              className={`${
                uploadedFile ? "bg-primary text-white" : "bg-gray-300 text-base"
              } rounded flex justify-center items-center py-2 w-[150px]`}
            >
              + Image
            </button>
            <input
              type="file"
              id="file"
              className="hidden"
              accept="image/*"
              onChange={handleUpload}
              ref={fileInputRef}
            />
          </div>
        </div>
        <div
          className={
            "justify-center flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
          }
        >
          {showSuccess && (
            <Alert severity="success">
              {t("common:submit_details_successful").toString()}
            </Alert>
          )}
        </div>
      </div>
      <Tab.Group
        defaultIndex={wizardIndex}
        onChange={(index) => {
          dispatch(setIndex(index));
        }}
      >
        <Tab.List className="text-sm w-full flex flex-wrap gap-0 overflow-x-auto font-medium text-center bg-primary pt-5 text-gray-500">
          {tabPanelsData.map((panel, index) => (
            <Tab key={index} as={Fragment}>
              {({ selected }) => {
                if (selected) {
                  setSelectedTab(panel.label);
                }
                return (
                  <button
                    className={`${
                      selected
                        ? "bg-white text-black border-none"
                        : "text-white bg-primary border-none "
                    } capitalize w-max !overflow-visible relative font-medium text-sm px-2 py-2`}
                    onClick={() => {
                      document
                        .getElementById("scrollView")
                        .scrollIntoView({ behavior: "smooth" });
                      setSelectedTab(panel.label);
                    }}
                  >
                    {panel.label}
                  </button>
                );
              }}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels id={"scrollView"}>
          {tabPanelsData.map((panel, index) => (
            <Tab.Panel>{panel.component || ""}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>

      <div className="mb-3">
        {errorMsg !== "" && <Alert severity="error">{errorMsg}</Alert>}
      </div>

      {selectedTab === "Descriptions" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>
          <button
            onClick={submitDescriptionChange}
            disabled={loading || isDescriptionChange}
            className={`${
              loading || isDescriptionChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Saving..." : isDescriptionChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Labelling" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitProductProg}
            disabled={loading || isLabellingChange}
            className={`${
              loading || isLabellingChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Loading..." : isLabellingChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Target Markets" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitTargetChange}
            className={`${
              loading || !isTargetChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Saving..." : !isTargetChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Categories" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitHandler}
            disabled={loading || !is_filled}
            className={`${
              loading || !is_filled ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Saving..." : !is_filled ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Attributes" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitAttributeChange}
            disabled={loading || isAttributeChange}
            className={`${
              loading || isAttributeChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Loading..." : isAttributeChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Measures & Sizing" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitUOM}
            disabled={loading || isUOMChange}
            className={`${
              loading || isUOMChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Loading..." : isUOMChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Packaging & Sustainability" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            disabled={loading || isAvailabilityChange}
            className={`${
              loading || isAvailabilityChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Loading..." : isAvailabilityChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      {selectedTab === "Avaliability (Dates)" && (
        <div className="mt-3 flex justify-center items-center gap-5">
          <button
            onClick={cancelHandler}
            disabled={loading}
            className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
          >
            Cancel
          </button>

          <button
            onClick={submitAvailabilityDates}
            disabled={loading || isAvailabilityChange}
            className={`${
              loading || isAvailabilityChange ? "bg-gray-500" : "bg-primary"
            } py-2 w-24 rounded-lg text-white`}
          >
            {loading ? "Loading..." : isAvailabilityChange ? "Save" : "Save"}
          </button>
        </div>
      )}

      <div
        className={
          "justify-center items-center space-x-4 w-max m-auto flex mt-3"
        }
      >
        {showAttributeSuccess && (
          <Alert severity="success">
            {t("common:submit_details_successful").toString()}
          </Alert>
        )}
      </div>
      <div
        className={
          "justify-center items-center space-x-4 w-max m-auto flex mt-3"
        }
      >
        {showSuccess && loading && (
          <Alert severity="success">
            {t("common:submit_details_successful").toString()}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default SupplierProduct;
