import DashboardContainer from "../../dashboard-components/DashboardContainer";
import React, { useEffect, useCallback, useState, Fragment } from "react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import ReactDataGrid, { filterTypes } from "@inovua/reactdatagrid-enterprise";
import { HTTP_STATUS_CODES } from "../../api/API";
import {
  productsPage,
  setActive,
  setLimit,
  setPage,
  fetchData,
  setDataSource,
  setFilterValues,
} from "../../store/slices/productsPageSlice";
import "@inovua/reactdatagrid-enterprise/index.css";
import defaultColumns from "./exportGridColumns";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Tooltip from "../../components/tooltip";
import AssignBrandDialog from "../../components/Dialogs/AssignBrandDialog";
import AssignProductCategory from "../../components/Dialogs/AssignProductCategory";
import AssignAttributes from "../../components/Dialogs/AssignAttributes";
import AddProduct from "./AddProduct";

const ExportGS1 = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userAuth = new useAuthContext();
  const userparentCompanies = userAuth.parentCompanies;
  const { active, dataSource, page, limit, filterValues } =
    useAppSelector(productsPage);
  const [timeoutId, setTimeoutId] = useState(null);
  const [entityGln, setEntityGln] = useState(true);
  const [brick, setBrick] = useState(true);
  const [gtinValue, setGtinValue] = useState("");
  const [gridRef, setGridRef] = useState(null);
  const [assignBrandDialog, setAssignBrandDialog] = useState(false);
  const [assignProductCategory, setAssignProductCategory] = useState(false);
  const [assignAttributes, setAssignAttributes] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleFilterValueChange = (o) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Dispatch your action after the timeout
      dispatch(setFilterValues(o));
    }, 2000); // Adjust the timeout value as needed (2 second in this example)

    // Save the new timeout ID in the state
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    loadData();
  }, [active, page, limit, filterValues]);

  const loadData = () => {
    const fetchApiData = async ({ ParentCompanyID, filter, page, limit }) => {
      setLoading(true);
      const res = await dispatch(
        fetchData(ParentCompanyID, filter, page, limit)
      );

      if (res.status !== HTTP_STATUS_CODES.OK) {
        setLoading(false);
        return Promise.resolve({ data: [], count: 0 });
      }
      setLoading(false);
      return Promise.resolve({
        data: res.data.data,
        count: res.data.total,
      });
    };

    dispatch(
      setDataSource(
        fetchApiData({
          ParentCompanyID: userparentCompanies?.ParentCompanyID,
          active: active,
          filter: filterValues,
          page: page,
          limit: limit,
        })
      )
    );
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    if (selected.length) {
    } else if (Object.keys(selected).length !== 0) {
      setEntityGln(true);
      setBrick(true);
      for (let i = 0; i < Object.keys(selected).length; i++) {
        setGtinValue(Object.values(selected)[i].GTIN);
        if (
          (i > 0 &&
            Object.values(selected)[i - 1].EntityGLN !==
              Object.values(selected)[i].EntityGLN) ||
          (i > 0 &&
            Object.values(selected)[i - 1].CompanyID !==
              Object.values(selected)[i].CompanyID)
        ) {
          setEntityGln(false);
          break;
        }
      }
      for (let i = 0; i < Object.keys(selected).length; i++) {
        if (
          i > 0 &&
          Object.values(selected)[i - 1].BrickID !==
            Object.values(selected)[i].BrickID
        ) {
          setBrick(false);
          break;
        }
      }

      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: (
            <div className="group inline-block group1 inline-block1">
              <ul className="bg-white rounded-sm origin-top min-w-32 ul border">
                <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
                  <Link
                    to={`/edit-product/` + gtinValue}
                    className="w-full text-left"
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      className="w-full text-left"
                    >
                      Edit Details
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          ),
        },
      ];
    } else {
      const { GTIN } = rowProps.data;
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: (
            <div className="group inline-block group1 inline-block1">
              <ul className="bg-white rounded-sm origin-top min-w-32 ul border">
                <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
                  <Link
                    to={`/edit-product/${GTIN}`}
                    className="w-full text-left"
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      className="w-full text-left"
                    >
                      Edit Details
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          ),
        },
      ];
    }
  };
  const defaultUnselected = { 1: true, 3: true };

  const checkboxColumn = {
    renderCheckbox: (checkboxProps, cellProps) => {
      const { onChange, checked } = checkboxProps;

      const background = !checked ? "#313943" : "#7986cb";
      const border =
        checked === false ? "2px solid #7C8792" : "2px solid #7986CB";

      return (
        <div
          className={`inovua-react-toolkit-checkbox InovuaReactDataGrid__checkbox inovua-react-toolkit-checkbox--children-position-end inovua-react-toolkit-checkbox--ltr inovua-react-toolkit-checkbox--inline-block ${
            checked === false
              ? "inovua-react-toolkit-checkbox--unchecked"
              : "inovua-react-toolkit-checkbox--checked"
          } inovua-react-toolkit-checkbox--theme-default-light`}
          style={{ background, border }}
          onClick={(e) => {
            e.stopPropagation();
            onChange(!checked);
          }}
        >
          <div className="inovua-react-toolkit-checkbox__icon-wrapper">
            {checked === false ? (
              <svg
                width="16"
                height="16"
                className=""
                viewBox="0 0 16 16"
                style={{ height: "16px", width: "16px" }}
              >
                <rect
                  width="14"
                  height="14"
                  x="1"
                  y="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="2"
                  rx="1"
                ></rect>
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                className=""
                viewBox="0 0 16 16"
                style={{ height: "16px", width: "16px" }}
              >
                <g fillRule="evenodd">
                  <rect width="16" height="16" stroke="none" rx="1"></rect>
                  <path
                    fill="none"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M3.357 9.018L7.229 12 12.613 5"
                  ></path>
                </g>
              </svg>
            )}
          </div>
        </div>
      );
    },
  };
  return (
    <>
      <div className="w-full pt-2 flex-row justify-end space-x-1 text-right">
        <AddProduct />
      </div>
      <DashboardContainer title={""}>
        <div className="">
          <div className="flex justify-start gap-3 items-center rounded bg-primary pt-3 px-2">
            <button
              onClick={() => dispatch(setActive(1))}
              className={`${
                active === 1 ? "bg-white text-black" : "text-white"
              } uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
            >
              <Tooltip
                trigger={
                  <p className="flex items-center gap-2 ">
                    gs1
                    <p className="w-4 h-4 lowercase flex justify-center items-center bg-black text-white rounded-full">
                      i
                    </p>
                  </p>
                }
                placement={"left"}
                tooltipText={"This page shows your Product Data (GS1)"}
              />
            </button>
          </div>
          <div className="bg-white rounded-t-none rounded-b">
            {[1].map((val) => {
              return val === active ? (
                <ReactDataGrid
                  key={val} // Ensure each component has a unique key
                  licenseKey={
                    "AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988"
                  }
                  onReady={setGridRef}
                  idProperty="ProductID"
                  style={{ marginTop: 10, minHeight: "90vh" }}
                  columns={defaultColumns}
                  checkboxColumn
                  defaultSelected={true}
                  onSelectionChange={onSelectionChange}
                  defaultUnselected={defaultUnselected}
                  defaultFilterValue={filterValues}
                  filterTypes={filterTypes}
                  rowHeight={90}
                  renderRowContextMenu={renderRowContextMenu}
                  pagination="remote"
                  onFilterValueChange={(o) => handleFilterValueChange(o)}
                  dataSource={dataSource}
                  onSkipChange={(o) =>
                    dispatch(
                      setPage(Math.round(Math.max(o - 1, 0) / limit) + 1)
                    )
                  }
                  onLimitChange={(o) => dispatch(setLimit(o))}
                  defaultLimit={10}
                />
              ) : null;
            })}
          </div>
        </div>

        <AssignBrandDialog
          setAssignBrandDialog={setAssignBrandDialog}
          assignBrandDialog={assignBrandDialog}
        />
        <AssignProductCategory
          setAssignProductCategory={setAssignProductCategory}
          assignProductCategory={assignProductCategory}
        />
        <AssignAttributes
          setAssignAttributes={setAssignAttributes}
          assignAttributes={assignAttributes}
        />
      </DashboardContainer>
    </>
  );
};

export default ExportGS1;
