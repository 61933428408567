import React, { useEffect, useRef, useState } from 'react';
import NavbarComponent from '../NavbarComponent/NavbarComponent';
import LeftMenuComponent from '../LeftMenuComponent/LeftMenuComponent';
import './PageSkeletonComponent.css';

const PageSkeletonComponent = (props) => {

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < -1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);


  return (
    <div className="Container" ref={ref}>
      <NavbarComponent isSticky={isSticky} />

      <section className="Content">

        <LeftMenuComponent />

        <div className="Body">
          {props.children}
        </div>

      </section>
      {props.footerContent}
    </div>
  );
};

export default PageSkeletonComponent;