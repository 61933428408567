import React from 'react';
import PageSkeletonComponent from '../../../components/PageSkeletonComponent/PageSkeletonComponent';
import './LoyaltyEndpoints.css';

const LoyaltyEndpoints = () => {
  return (
    <PageSkeletonComponent>

    <h1>LOYALTY - TECHNICAL - Loyalty Endpoints</h1>
      <br />
      <hr />
      
      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" rel="noopener noreferrer" href="#">Loyaty Endpoints</a><br />
      <br />
      <hr />
            
      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      Employees can view promotions with any of the Suppliers/Retailers/Service Providers to assist with issues.<br />
      Employees should not be able to edit anything, they are just there as support staff to advise of any issues.
      
      <h3>Supplier Subscribers</h3>
      A Supplier can instigate a Bonus Points offer, this is entered by the Supplier with the parameters, what points are offered and who is paying for the points, for approval by the Retailer or Service Provider.

      <h3>Retailer Subscribers</h3>
      A Retailer can instigate a Bonus Points offer, this is entered by the Supplier with the parameters, what points are offered and who is paying for the points, for approval by the Supplier.
      
      <h3>Service Subscribers</h3>
      A Retailer can instigate a Bonus Points offer, this is entered by the Service Provider with the parameters, what points are offered and who is paying for the points, for approval by the Supplier.

      <h2>Purpose of Page</h2>
      This page is the main page for controlling Bonus Point offerings that are shared by Suppliers and Retailers/ServiceProviders.<br /><br />
      Further Investigation and Planning for how this will work and the approval process between Suppliers and Retailers/Service Providers will be required.<br /><br />
      An example of this would be that "Fleurieu Peninsula Milk" (Supplier) wants to do a promotion with "On the Run" (Retailer) where a user can go to their local OTR Service Station and purchase a "750ml Iced Coffee" (Product) to receive "50 Bonus Points" (Loyalty Reward Bonus).<br /><br />
      Normally, a user would receive "9 Points" (Loyalty Reward) from On The Run as a subscriber to our Loyalty Platform, but in this case the user gets "59 Points" whereby Fleurieu Milk Company has agreed to pay the 50 Loyalty Bonus Points and the normal Loyalty Reward is still picked up by On The Run.<br /><br />
      This can be setup on this page by either the Supplier or the Retailer, if one sets the Partner Bonus Reward up, then the other party has to agree to it for it to be valid and public. 
      
      A summary page showing:
      <li><b>Offers Requiring Approval</b></li>
      <li>Current Offers Running</li>
      <li>Upcoming Offers</li>
      <li>Completed Offers</li>

      <br />From this page will be a "Create a New Offer" button to allow the user to start the process of creating a New Offering.

    </PageSkeletonComponent>
  );
};

export default LoyaltyEndpoints;