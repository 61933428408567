import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from '@mui/material/Box';
import {
    addForm,
    orderSelector,
    fetchCountry,
    fetchState,
    fetchNumberOfEmployees,
    fetchAnnualTurnover,
    fetchIndustries,
    setFormStateOnly,
    setFormExtCmpyStateOnly
} from "../../store/slices/getStartedSlice";
import {
    updateCompanyDetail,
    saveCompanyWithParentCompany,
    LUPP,
    approveAccount,
    rejectAccount,
    setParentCompanyId,
    saveSubscriptionData,
    signupApproval
} from "../../store/slices/signupApprovalSlice";
import Loader from "../loader/Loader";
import schema from "./validationScemas"

const CreateOrUpdateCompany = ({ newcmp, sethandleSave, closeDialog, parentCompany, Decision, selectedCompany, callUpdateSubcription }) => {
    const dispatch = useAppDispatch();
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { form, country, state, numberOfEmployees, annualTurnover, industries } = useAppSelector(orderSelector);
    const { singleDetails } = useAppSelector(signupApproval);
    const { t } = useTranslation(["common"]);
    const [oldState, setOldState] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const dataValues = getValues();
        const validationErrors = [];

        // Custom validation for number_of_employees
        if (dataValues?.number_of_employees === "1000136") {
            validationErrors.push({
                field: 'number_of_employees',
                message: 'Number of employees is required',
            });
        }

        // Custom validation for annual_turnover
        if (dataValues?.annual_turnover === "1000128") {
            validationErrors.push({
                field: 'annual_turnover',
                message: 'Annual turnover is required',
            });
        }

        // Custom validation for Industry
        if (dataValues?.industry === "1000098") {
            validationErrors.push({
                field: 'industry',
                message: 'Industry is required, select OTHER if not in list',
            });
        }

        // Custom validation for country_id
        if (dataValues?.country_id === "000") {
            validationErrors.push({
                field: 'country_id',
                message: 'Country is required,',
            });
        }

        // const isValidState = !!state.find((o) => o.StateCode === form?.state);
        // console.log('isValidState', isValidState)
        // if (!isValidState) {
        //     validationErrors.push({
        //         field: 'state',
        //         message: 'Please select a valid state.',
        //     });
        // }

        if (dataValues?.state === "" || dataValues?.state === undefined) {
            validationErrors.push({
                field: 'state',
                message: 'State is required',
            });
        }

        if (!form?.state) {
            validationErrors.push({
                field: 'state',
                message: 'State is required',
            });
        }

        // Check if there are any errors
        if (validationErrors.length > 0) {
            // Set all collected errors
            validationErrors.forEach(error => {
                setError(error.field, {
                    type: 'manual',
                    message: error.message,
                });
            });

            return;
        }

        // Your form submission logic
        const AuditUserID = Decision?.AuditUserID
        const json = await dispatch(updateCompanyDetail(form, AuditUserID));
        const payload = {
            FK_ParentCompanyID: json?.ParentCompanyID ?? form?.ParentCompanyID,
            FK_CompanyID: parentCompany?.FK_CompanyID,
            FK_OwnershipCountry: form?.country_of_ownership || parentCompany?.ApplicationSuggestedCountryOfOrigin,
            SourceAdded: Decision.ApplicationOutcome === "A" ? 'Admin - Decision: Approved' : 'Admin - Decision: Rejected',
            FK_UserID: parentCompany?.ApplicantUserID
        }
        await dispatch(saveCompanyWithParentCompany(payload))

        /*
         Update Subscription API
        */
        if (callUpdateSubcription) {

        if (Decision.ApplicationOutcome === "A") {
            await dispatch(LUPP({
                FK_ParentCompanyID: json?.ParentCompanyID ?? form?.ParentCompanyID,
                SourceAdded: 'Admin - Decision: Approved',
                FK_UserID: parentCompany?.ApplicantUserID
            }))
            // Dispatch an action to approve the account based on the SignupID from the Decision object.
            await dispatch(approveAccount(Decision.SignupID))
        }

        // Check if the application outcome is "R", which stands for "Rejected".
        if (Decision.ApplicationOutcome === "R") {
            // Dispatch an action to reject the account based on the SignupID from the Decision object.
            await dispatch(rejectAccount(Decision.SignupID))
        }
            const arrayData = {
                '1000129': '1000000003',
                '1000130': '1000000004',
                '1000131': '1000000005',
                '1000132': '1000000006',
                '1000133': '1000000007',
                '1000134': '1000000008'
            };
            const saveSubscription = {
                Action: 'SIGNUP',
                ParentCompanyID: json?.ParentCompanyID ?? form?.ParentCompanyID,
                SubscriptionID: singleDetails.Trial === '1' ? '1000000015' : arrayData[singleDetails.CompanyTurnover]
            }
            await dispatch(saveSubscriptionData(saveSubscription));
        }
        sethandleSave(true)
    };

    const setFormField = (key, value) => {
        let data = Object.assign({}, form);
        data[key] = value;
        dispatch(addForm(data));
        if (key === "country_id") {
            dispatch(fetchState(value));
        }
    };

    const delayPromise = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 4000);
        });
    };

    const fetchUpdatedForm = async () => {
        let data = Object.assign({}, form);
        setLoaded(true)
        if (!isFormLoaded) {
            for (const [key, value] of Object.entries(data)) {
                setValue(key, value);
            }
            if (Object.keys(data).length > 15) {
                delayPromise().then((res) => {
                    setIsFormLoaded(true);
                });
            }
        }

        if (isFormLoaded) {
            setLoaded(false)
        }

        if (form?.country_id && (state || []).length === 0) {
            dispatch(fetchState(form?.country_id));
        }
    }

    useEffect(() => {
        fetchUpdatedForm()
    }, [form])


    useEffect(() => {
        delayPromise().then((result) => {
            if (isFormLoaded || result) {
                setTimeout(() => {
                    setLoaded(false)
                }, 2000);
            }
        });
        fetchCountryData()
    }, []);

    const fetchCountryData = async () => {
        await dispatch(fetchCountry());
        let data = Object.assign({}, form);
        data = {
            ...data,
            country_id: "036",
            account_type: "supplier",
            country_of_ownership: parentCompany?.ApplicationSuggestedCountryOfOrigin
        }
        dispatch(addForm(data));
        if (process.env.REACT_APP_SELECTED_COUNTRY_ID) {
            dispatch(fetchState(selectedCompany?.AddressCountry || parentCompany?.ApplicationCountryCodeN3 || process.env.REACT_APP_SELECTED_COUNTRY_ID));
        }
        await Promise.all([
            dispatch(fetchNumberOfEmployees()),
            dispatch(fetchAnnualTurnover()),
            dispatch(fetchIndustries()),
            newcmp ? dispatch(setFormStateOnly(parentCompany)) : dispatch(setFormExtCmpyStateOnly(selectedCompany, parentCompany)),
        ]).then(res => {
            setOldState(state)
        });
    }

    return (
        <div className="w-full mb-2">
            {
                loaded ?
                    <div className={'m-auto w-16 h-16 mt-5'}>
                        <Loader width={'w-10'} heigth={'h-10'} />
                    </div> :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid w-full grid-cols-12 space-y-1">
                            <div className="space-y-1 col-span-12">
                                <div className="flex justify-start items-center">
                                    <p className="w-[28px] ml-20 font-bold underline"></p>
                                    <p className="w-[250px] ml-20 font-bold underline">Application Details</p>
                                    <p className="font-bold ml-[60px] underline">Parent Company Details</p>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Account type */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="company_name" sx={{ color: 'primary.main' }}>{t("common:company_name").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationCompanyName}</span>
                                    <div className="flex flex-col grow">
                                        <input
                                            {...register("company_name", {
                                                required: "company name is required",
                                            })}
                                            onChange={(e) => setFormField("company_name", e.target.value)}
                                            type="text"
                                            value={form?.company_name || ""}
                                            id="company_name"
                                            placeholder="Enter company name"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.company_name && (
                                            <Box className="text-sm w-full peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_name?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Trading namee */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="trading_name" sx={{ color: 'primary.main' }}>{t("common:trading_name").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationTradingName}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("trading_name", {
                                                required: "trading name is required",
                                            })}
                                            onChange={(e) => setFormField("trading_name", e.target.value)}
                                            type="text"
                                            value={form?.trading_name || ""}
                                            id="trading_name"
                                            placeholder="Enter Trading Name"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.trading_name && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.trading_name?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Company abn */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="company_abn" sx={{ color: 'primary.main' }}>{t("common:company_abn").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationCompanyABN}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("company_abn", {
                                                required: "company abn is required",
                                            })}
                                            onChange={(e) => setFormField("company_abn", e.target.value.replace(/\s/g, ''))}
                                            type="text"
                                            value={form?.company_abn || ""}
                                            id="companyabn"
                                            placeholder="Enter Company ABN"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.company_abn && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_abn?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Account type */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="number_of_employees" sx={{ color: 'primary.main' }}>{t("common:number_of_employees").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{
                                        parentCompany?.EmployeeCount && numberOfEmployees
                                            ? numberOfEmployees.find((o) => o.SystemSelectID === parentCompany?.EmployeeCount)?.SelectionName || ""
                                            : ""
                                    }</span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("number_of_employees")}
                                            onChange={(e) => setFormField("number_of_employees", e.target.value)}
                                            value={form?.number_of_employees || ""}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            {(numberOfEmployees || []).map((item, index) => (
                                                <option key={index} value={item.SystemSelectID === "1000136" ? "" : item.SystemSelectID}>{item.SelectionName}</option>
                                            ))}
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.number_of_employees && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.number_of_employees?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Account type */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="account_type" sx={{ color: 'primary.main' }}>{t("common:account_type").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">Supplier</span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("account_type")}
                                            onChange={(e) => setFormField("account_type", e.target.value)}
                                            value={form?.account_type || ""}
                                            disabled
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            <option selected={"supplier" === form.account_type} value="supplier">Supplier</option>
                                            <option selected={"buyer" === form.account_type} value="buyer">Buyer</option>
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.account_type && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.account_type?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Account type */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="turnover" sx={{ color: 'primary.main' }}>{t("common:annual_turnover").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">
                                        {
                                            parentCompany?.CompanyTurnover && annualTurnover
                                                ? annualTurnover.find((o) => o.SystemSelectID === parentCompany?.CompanyTurnover)?.SelectionName || ""
                                                : ""
                                        }</span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("annual_turnover")}
                                            onChange={(e) => setFormField("annual_turnover", e.target.value)}
                                            value={form?.annual_turnover || ""}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            {(annualTurnover || []).map((item, index) => (
                                                <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                                            ))}
                                        </select>
                                        {errors.annual_turnover && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.annual_turnover?.message}</Box>
                                        )}
                                    </div>
                                </div>

                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* industry */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="industry" sx={{ color: 'primary.main' }}>{t("common:industry").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">
                                        {
                                            parentCompany?.FK_MainIndustry && industries
                                                ? industries.find((o) => o.SystemSelectID === parentCompany?.FK_MainIndustry)?.SelectionName || ""
                                                : ""
                                        }

                                    </span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("industry")}
                                            onChange={(e) => setFormField("industry", e.target.value)}
                                            value={form?.industry || ""}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            {(industries || []).map((item, index) => (
                                                <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                                            ))}
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.industry && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.industry?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* other */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="other" sx={{ color: 'primary.main' }}>{t("common:other").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.OtherIndustry}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("OtherIndustry")}
                                            onChange={(e) => setFormField("OtherIndustry", e.target.value)}
                                            type="text"
                                            value={form?.OtherIndustry || ""}
                                            disabled={((industries || []).filter(i => i.SystemSelectID === form?.industry)?.[0] || {})?.SelectionName !== "-- OTHER --"}
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.OtherIndustry && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.OtherIndustry?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Company phone number */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="company_phone_number" sx={{ color: 'primary.main' }}>{t("common:company_phone_number").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationPhoneCompany}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("company_phone_number", {
                                                required: "phone number is required",
                                            })}
                                            onChange={(e) =>
                                                setFormField("company_phone_number", e.target.value.replace(/\s/g, ''))
                                            }
                                            type="text"
                                            placeholder="Enter company phone number"
                                            value={form?.company_phone_number || ""}
                                            id="company_phone_number"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.company_phone_number && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_phone_number?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Company address 1 */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="Website" sx={{ color: 'primary.main' }}>{t("common:website").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationWebsite}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("website", {
                                                required: "Website is required",
                                            })}
                                            type="text"
                                            onChange={(e) =>
                                                setFormField("website", e.target.value)
                                            }
                                            id="Website"
                                            placeholder="Enter website"
                                            value={form?.website || ""}
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {errors.website && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.website?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Company address 1 */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="company_address_1" sx={{ color: 'primary.main' }}>{t("common:company_address_1").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationAddress1}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("company_address_1", {
                                                required: "address 1 is required",
                                            })}
                                            onChange={(e) =>
                                                setFormField("company_address_1", e.target.value)
                                            }
                                            type="text"
                                            placeholder="Enter company address"
                                            value={form?.company_address_1 || ""}
                                            id="company_address_1"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.company_address_1 && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_1?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Company address 2 */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="company_address_2" sx={{ color: 'primary.main' }}>{t("common:company_address_2").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationAddress2}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("company_address_2", {
                                                required: "address 2 is required",
                                            })}
                                            onChange={(e) =>
                                                setFormField("company_address_2", e.target.value)
                                            }
                                            type="text"
                                            placeholder="Enter company address 2"
                                            value={form?.company_address_2 || ""}
                                            id="company_address_2"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.company_address_2 && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_2?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* city */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="city" sx={{ color: 'primary.main' }}>{t("common:city").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationCity}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("city", { required: "city is required" })}
                                            onChange={(e) => setFormField("city", e.target.value)}
                                            type="text"
                                            value={form?.city || ""}
                                            id="city"
                                            placeholder="Enter the city"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.city && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.city?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* state */}
                                    <Box htmlFor="state" className="text-end  w-full max-w-[150px]" sx={{ color: 'primary.main' }}>{t("common:state").toString()}:</Box>
                                    {/* <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.AddressState}</span> */}
                                    <span className="font-bold w-full max-w-[305px]  ml-4">
                                        {
                                            parentCompany?.ApplicationState
                                            // oldState && (parentCompany?.ApplicationCountryCodeN3 || selectedCompany?.AddressState)
                                            // ? (oldState.find((o) => o.StateCode === parentCompany?.ApplicationCountryCodeN3 || selectedCompany?.AddressState) || {}).StateName || ""
                                            // : parentCompany?.AddressState || ""
                                        }
                                    </span>

                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("state")}
                                            value={form?.state || ""}
                                            onChange={(e) => setFormField("state", e.target.value)}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            <option value="">Select State</option>
                                            {(state || []).map((item, index) => (
                                                item?.StateCode === form?.state ?
                                                    <option
                                                        key={index}
                                                        value={item.StateCode}
                                                        selected={item?.StateCode === form?.state}
                                                    >
                                                        {item.StateName}
                                                    </option> :
                                                    <option
                                                        key={index}
                                                        value={item.StateName}
                                                        selected={item.StateName === form?.state}
                                                    >
                                                        {item.StateName}
                                                    </option>
                                            ))}
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.state && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.state?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center">
                                    {/* Postcode */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="postcode" sx={{ color: 'primary.main' }}>{t("common:postcode").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">{parentCompany?.ApplicationPostcode}</span>
                                    <div className="flex flex-col grow gap-2">
                                        <input
                                            {...register("postcode", { required: "postcode is required" })}
                                            onChange={(e) => setFormField("postcode", e.target.value)}
                                            type="text"
                                            value={form?.postcode || ""}
                                            id="postcode"
                                            placeholder="Enter the postcode"
                                            className="rounded-md w-full relative p-1 border shadow-sm sm:text-sm"
                                        />
                                        {/* Display validation errors */}
                                        {errors.postcode && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.postcode?.message}</Box>
                                        )}
                                    </div>
                                </div>

                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center ">
                                    {/* country */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="country" sx={{ color: 'primary.main' }}>{t("common:country").toString()}:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">
                                        {
                                            parentCompany?.ApplicationCountryCodeN3 && country
                                                ? (country.find((o) => o.CountryCode === parentCompany?.ApplicationCountryCodeN3) || {}).CountryName || ""
                                                : ""
                                        }
                                    </span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("country_id")}
                                            onChange={(e) => setFormField("country_id", e.target.value)}
                                            value={form?.country_id}
                                            //id={"country_id"}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            {(country || []).map((item, index) => (
                                                <option key={index} value={item.CountryCode} selected={item.CountryCode === form.country_id}>
                                                    {item.CountryName}
                                                </option>
                                            ))}
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.country_id && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.country_id?.message}</Box>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-12 h-px bg-black" />
                                {/* Input fields with labels */}
                                <div className="flex gap-3 items-center pt-5">
                                    {/* country */}
                                    <Box className="text-end  w-full max-w-[150px]" htmlFor="country" sx={{ color: 'primary.main' }}>Country of Ownership:</Box>
                                    <span className="font-bold w-full max-w-[305px]  ml-4">
                                        {
                                            parentCompany?.ApplicationCountryCodeN3 && country
                                                ? (country.find((o) => o.CountryCode === parentCompany?.ApplicationCountryCodeN3) || {}).CountryName || ""
                                                : ""
                                        }
                                    </span>
                                    <div className="flex flex-col grow gap-2">
                                        <select
                                            {...register("country_of_ownership")}
                                            onChange={(e) => setFormField("country_of_ownership", e.target.value)}
                                            value={form?.country_of_ownership || parentCompany?.ApplicationSuggestedCountryOfOrigin}
                                            //id={"country_id"}
                                            className='rounded-md w-full relative p-1 border shadow-sm sm:text-sm'
                                        >
                                            {(country || []).map((item, index) => (
                                                <option key={index} value={item.CountryCode} selected={item.CountryCode === form?.country_of_ownership || parentCompany?.ApplicationSuggestedCountryOfOrigin}>
                                                    {item.CountryName}
                                                </option>
                                            ))}
                                        </select>
                                        {/* Display validation errors */}
                                        {errors.country_of_ownership && (
                                            <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.country_of_ownership?.message}</Box>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Next button  */}
                        <div className="mt-3">
                            <div className="flex grow mt-3 justify-center items-end gap-4">
                                <button onClick={() => closeDialog(true)} className="bg-gray-400 rounded-lg py-2 px-5 text-white">
                                    Cancel
                                </button>
                                <button
                                    disabled={loaded}
                                    type="submit"
                                    className="bg-primary rounded-lg  py-2 px-5 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
            }
        </div>
    );
};

export default CreateOrUpdateCompany;
