import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useRenewPayment from "../../hooks/UseRenewPayment";
import {
  addForm,
  orderSelector,
  setIndex
} from "../../store/slices/getStartedSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import GetStartedStripePaymentElement from "./GetStartedStripePaymentElement";

const Payment = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    form,
    wizardIndex,
  } = useAppSelector(orderSelector);

  const stripe = useStripe();
  const elements = useElements();

  const [open, setOpen] = useState(false);

  const { t } = useTranslation(["common"]);

  const {isProcessing, handlePaymentSubmit, message} = useRenewPayment({stripe, elements});
  
  useEffect(() => {
    if (wizardIndex < 2) {
      navigate("/");
    }
    // openPopup();
    // window.scrollTo({
    //   top: 0,
    //   behavior: "instant",
    // });
    // if (!form?.termCondition) {
    //   setFormField("termCondition", true)
    // }
  }, [wizardIndex]);

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
  };

  const back = () => {
    if(form.selectedField !== ''){
      window.location.reload();
      dispatch(setIndex(3));
      navigate("/GetStarted/AccountPayment");

    }else {
      setFormField("termCondition", false)
      dispatch(setIndex(2));
      navigate("/GetStarted/AccountReview");
    }
  }

  return (
    <>
      {Object.keys(form).length === 0 ? (
        <div className={`w-full max-w-7xl px-3 mx-auto py-10 bg-white mt-5 rounded-xl`}>
          <div className="w-80 h-80 mx-auto">
            <img src="https://members.bringback.app/BBA-Logo-TBl.png" alt="logo" className="w-full h-full object-contain object-centers" />
          </div>
          <div
            className="p-4 text-sm text-center text-green-800 rounded-lg bg-green-300"
          >
            <span className="font-semibold">Success:</span>{" "}
            {t("common:order_success_meessage").toString()}

          </div>
          <div className="flex flex-col items-center gap-3 mt-10">
            <span>Thank you for signing up. Please download the app and test on your products. And then test on your competitor products</span>
            <a
              href="https://bringbackaustralia.app/download-app/"
              className="px-8 py-2 w-max bg-primary text-white rounded-md border"
            >
              Download our APP
            </a>
          </div>
        </div>
      ) : (
        <div className={`w-full max-w-7xl mx-auto py-10`}>
          {!!message &&
            <div className="p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-300">
              {message}
            </div>
          }
          {
            !open &&
          <>
              <div className="flex gap-5 bg-white">
                <p className="w-[220px] text-end text-sm">

                </p>
                <div className="px-5 py-3 rounded-xl mt-4 gap-5 grid lg:grid-cols-2 grid-cols-1">
                  <div className={`block`}>
                    <GetStartedStripePaymentElement />
                    <img src={'../stripe-badge-transparent.png'} alt={"stripeBadge"} />
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-3 gap-4">
                <button
                  onClick={back}
                  className="px-8 py-2 bg-gray-500 uppercase text-white rounded-md border"
                >
                  {t("common:back_button_text").toString()}
                </button>
                <button
                  disabled={
                    isProcessing
                    // !isContactValidate ||
                    // !isShippingValidate ||
                    // !isPaymentValidate
                  }
                  onClick={handlePaymentSubmit}
                  className="px-8 py-2 bg-primary uppercase text-white rounded-md border"
                >
                  {isProcessing
                    ? t("common:processing").toString()
                    : t("common:submit_text").toString()}
                </button>
              </div>
            </>
          }
        </div>
      )}
    </>
  );
};

export default Payment;
