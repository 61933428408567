import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { HTTP_STATUS_CODES } from '../../api/API';
import { runReports } from '../../api/EodReport';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import RowItem from './RowItem';
import { RowsContainer, RowHeaderStyle } from './RowItemStyle';
import { Container, Header, Section } from './Style';

const SignupApproval = () => {

  const [processingFullyRegistered, setProcessingFullyRegistered] = useState(false);
  const [processingPartiallyRegistered, setProcessingPartiallyRegistered] = useState(false);

  const [fullyRegistered, setFullyRegistered] = useState([]);
  const [partiallyRegistered, setPartiallyRegistered] = useState([]);

  useEffect(() => {
    fetchRunReports();

  }, []);

  const fetchRunReports = async () => {
    setProcessingFullyRegistered(true);
    const json = await runReports();

    setProcessingFullyRegistered(false);

  }

  return (
    <DashboardContainer title={"EOD Reports"}>
      <p>Email has been sent to admin</p>
      {/* <Container>
        <Section column mr bg-color>
          <Header>
            <span>Fully Registered</span>
          </Header>
          <Section className="request-processing-indicator" column no-border>
            {processingFullyRegistered && (
              <div className="request-processing">
                <span>Processing...</span>
              </div>
            )}
            <div className="body">
            <RowHeaderStyle>
            <p className='text-black'>Company Name</p>
            <p className='text-black'>Full Name</p>
            </RowHeaderStyle>
              <RowsContainer>
                {fullyRegistered?.map((item, index) => (
                  <RowItem refreshCallback={refreshCallback} key={index} account={item} />
                ))}
                {fullyRegistered?.length === 0 && (
                  <b style={{ padding: '5px' }}>No records</b>
                )}
              </RowsContainer>
              </div>
          </Section>
        </Section>

          <Section column bg-color>
            <Header>
              <span>Partially Registered</span>
            </Header>
            
            <Section className="request-processing-indicator" column no-border>
              {processingPartiallyRegistered && (
                <div className="request-processing">
                  <span>Processing...</span>
                </div>
              )}
              <RowHeaderStyle>
                <p className='text-black'>Company Name</p>
                <p className='text-black'>Full Name</p>
                <p className='text-black'>Last Completed Step</p>
                </RowHeaderStyle>
              <div className="body">
                <RowsContainer>
                  {partiallyRegistered?.map((item, index) => (
                    <RowItem refreshCallback={refreshCallback} key={index} account={item} />
                  ))}
                  {partiallyRegistered?.length === 0 && (
                    <b style={{ padding: '5px' }}>No records</b>
                  )}
                </RowsContainer>
              </div>
            </Section>
          </Section>

      </Container> */}
    </DashboardContainer>
  );

};

export default SignupApproval;