import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function UnderReview() {
    const userAuth = useAuthContext();
    const navigate = useNavigate();
    const logout = async () => userAuth.logoutUserAfterPayment();
    return (
        <div className={`w-full px-3 mt-20 rounded-xl overflow-y-auto`}>
            <div onClick={() => {
                logout().then(res => {
                    navigate('/DownloadOurApp');
                });
            }}
                className="p-4 text-sm text-center text-green-800 rounded-lg bg-[#B3CC2D]">
                <p className="text-lg leading-7 text-black">
                    <span className="font-bold">Your application is currently under review. We will notify you once it is processed.</span>
                </p>
            </div>
        </div>
    );
}