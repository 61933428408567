import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1Global.css';

const GS1Global = () => {
  return (
    <PageSkeletonComponent>

      <h1>GS1 Global Update Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      This is the home page for importing and validating the GS1 Global GPC Data.<br /><br />
      The file for updating this data is released each six months (January and July) by GS1 Global and is available to download from their website. 

      <h2>Who Can Access</h2>
      This page can only be accessed by authorised employees of Bring Back Australia.<br /><br />
      <b>Bruno</b> is the lead on this update process.

    </PageSkeletonComponent>
  );
};

export default GS1Global;