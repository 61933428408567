import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../rootReducer";
import {listProducts} from "../../pages/SupplierProductsPage/API";
import {
  getAttributeTypesByBrick, getAttributeValuesByTypeBrick, getProductAttributes,
  getProductBrand,
  getProductBrickInfo,
  getProductClass,
  getProductDetailInfo,
  getProductFamily,
  getProductGroupingInfo,
  getProductSegmentInfo,
  getProductVariantInfo,
  submitProductDetailID
} from "../../api/SupplierProducts";
import {setAttributes, setBrick, setClass, setDetails, setFamily, setGrouping, setVariant} from "./productSlice";

export interface ProductsPageState {
  active: any;
  limit: any;
  page: any;
  filterValues:any;
  dataSource: any;
  loading: boolean;
  errorMsg: object;
}

export const initialState: ProductsPageState = {
  active: 1,
  limit: 10,
  page:1,
  loading: false,
  errorMsg: null,
  filterValues: [
    {name: "CompanyName",type: "string",operator: "contains", value: ""},
    {name: "Brand", type: "string",operator: "contains",value: ""},
    {name: "BrandSub", type: "string",operator: "contains",value: ""},
    {name: "Description", type: "string",operator: "contains",value: ""},
    {name: "FamilyID",  type: "string",operator: "contains",value:""},
    {name: "BrickID",  type: "string",operator: "contains",value:""},
    {name: "SegmentID",  type: "string",operator: "contains",value:""},
    {name: "GTIN",  type: "string",operator: "eq",value:""},
    {name: "RecordType", type: "string",operator: "eq",value: "GS1"}
  ],
  dataSource: {}
};

export const productsPageSlice = createSlice({
  name: "productsPage",
  initialState,
  reducers: {
    setErrorMsg: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setLoading: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setPage: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.page = payload;
    },
    setLimit: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.limit = payload;
    },
    setDataSource: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.dataSource = payload;
    },
    setFilterValues: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      let updatedFilterValues = payload.map((filter) => {
        if (filter?.name === 'RecordType') {
          switch (state.active) {
            case 1:
              return { ...filter, value: 'GS1' };
            case 2:
              return { ...filter, value: 'CAT' };
            default:
              return { ...filter, value: 'NON' };
          }
        }
        return filter;
      });
      state.filterValues = updatedFilterValues
    },
    setActive: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      let updatedFilterValues = state.filterValues.map((filter) => {
        switch (filter?.name) {
          case 'RecordType':
            switch (payload) {
              case 1:
                return { ...filter, value: 'GS1' };
              case 2:
                return { ...filter, value: 'CAT' };
              default:
                return { ...filter, value: 'NON' };
            }
            // Reset values of other filters to their default state
     /*     case 'CompanyName':
          case 'Brand':
          case 'BrandSub':
          case 'TradeItemDesc':
          case 'GTIN':
          return { ...filter, value: "" };*/
          default:
            return filter;
        }
      });
      state.page = 1;
      state.filterValues = updatedFilterValues;
      state.active = payload;
    },
  },
});

/*export const fetchData = (skip, limit, sortInfo, active, filterValue) => async (dispatch: any) => {
  const json = await listProducts(
    skip,
    limit,
    sortInfo,
    active,
    filterValue
);
  return json;
};*/

export const fetchData = (ParentCompanyID,filter,page,limit) => async (dispatch: any) => {
  const json = await listProducts(ParentCompanyID,filter,page,limit);
  return json;
};

export const getProductBrands = async (FK_CompanyID,ValidBrand) => {
  try {
    const brand = await getProductBrand(FK_CompanyID, ValidBrand);
    if (brand?.status === 200) {
      return brand.data?.BrandInfo
    } else {
      console.error('Failed to fetch brand information:', brand?.status);
    }
  } catch (error) {
    return error;
  }
};


export const fetchSegmentInfo = () => async (dispatch: any) => {
  try {
    const response = await getProductSegmentInfo();
    if (response?.status === 200) {
      return response.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchFamily = (query) => async (dispatch: any) => {
  try {
    const response = await getProductFamily(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchClass = (query) => async (dispatch: any) => {
  try {
    const response = await getProductClass(query);
    if (response?.status === 200) {
     return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchBrick = (query) => async (dispatch: any) => {
  try {
    const response = await getProductBrickInfo(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchGrouping = (query) => async (dispatch: any) => {
  try {
    const response = await getProductGroupingInfo(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchVariant = (query) => async (dispatch: any) => {
  try {
    const response = await getProductVariantInfo(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch VariantInfo:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching VariantInfo:', error);
  }
};

export const fetchDetail = (query) => async (dispatch: any) => {
  try {
    const response = await getProductDetailInfo(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchClassification = (query) => async (dispatch: any) => {
  try {
    const response = await submitProductDetailID(query);
    if (response?.status === 200) {
      return response?.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchAttributes = (brickId) => async (dispatch: any) => {
  try {
    const query = {BrickID: brickId}
    const response = await getAttributeTypesByBrick(query);
    if (response?.status === 200 && response?.data.length) {
      const attributeLabels = response?.data

      for (const attribute of attributeLabels) {
        attribute.attributeValues = [];
        attribute.selectedAttribute = '';
        attribute.oldSelectedAttribute = ''
        attribute.preValue = true;
      }
      return attributeLabels
    } else {
      console.error('Failed to fetch attributes:', response?.status);
      return response?.data
    }
  } catch (error) {
    console.error('Error fetching attributes:', error);
  }
};

export const fetchProductAttributes = async (productId) => {
  try {
    const response = await getProductAttributes({ProductID: productId})
    if (response?.status === 200 && response?.data.length) {
      return response?.data
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching products attributes values:', error);
  }
};

export const fetchAttributesValues = (brickId, attributes, attributeIndex) => async (dispatch: any) => {
  try {
    let attributeData = JSON.parse(JSON.stringify(attributes))
    const data = {BrickID: brickId, AttributeTypeID: attributeData[attributeIndex].AttributeTypeID}
    const response = await getAttributeValuesByTypeBrick(data);

    if (response?.status === 200 && response?.data.length) {
      attributeData[attributeIndex].attributeValues = response?.data;
    }else {
      attributeData[attributeIndex].attributeValues = [];
    }
    attributeData[attributeIndex].preValue = false;

    return attributeData;

  } catch (error) {
    console.error('Error fetching attributes values:', error);
  }
};

export const {
  setPage,
  setLimit,
  setActive,
  setFilterValues,
  setLoading,
  setErrorMsg,
  setDataSource
} = productsPageSlice.actions;

export const productsPage = (state: RootState) => state.productsPage;

export default productsPageSlice.reducer;
