import React, { useContext, useEffect, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { listBricks, listClasses, listFamilies, listSegments } from '../../../api/SupplierProducts';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';

const BRICKFiltersContainer = () => {

  const GPCFilters = useContext(BRICKFiltersContext);

  const [segmentInput, , onSegmentInputChange] = useFormFieldOnChange('');
  const [segments, setSegments] = useState([]);

  const [familyInput, setFamilyInput, onFamilyInputChange] = useFormFieldOnChange('');
  const [families, setFamilies] = useState([]);

  const [classInput, setClassInput, onClassInputChange] = useFormFieldOnChange('');
  const [classes, setClasses] = useState([]);

  const [brickInput, setBrickInput, onBrickInputChange] = useFormFieldOnChange('');
  const [bricks, setBricks] = useState([]);

  function updateBRICKFilters(newState) {
    GPCFilters.setFilters((currentState) => {
      return {
        ...currentState,
        ...newState
      }
    })
  };

  useEffect(() => {
    fetchSegments();
  }, []);

  useEffect(() => {
    setFamilyInput('');
    setClassInput('');
    setBrickInput('');

    fetchFamilies()
    updateBRICKFilters({
      SEGMENT: segmentInput.toString()
    })
  }, [segmentInput]);

  useEffect(() => {
    setClassInput('');
    setBrickInput('');
    fetchClasses()
    updateBRICKFilters({
      FAMILY: familyInput.toString()
    })
  }, [familyInput]);

  useEffect(() => {
    setBrickInput('');
    fetchBricks()
    updateBRICKFilters({
      CLASS: classInput.toString()
    })
  }, [classInput]);

  useEffect(() => {
    updateBRICKFilters({
      BRICK: brickInput.toString()
    })
  }, [brickInput])

  const fetchSegments = async () => {
    const res = await listSegments();

    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setSegments(res.data);
  };

  const fetchFamilies = async () => {
    if (!segmentInput.trim().length) return;

    const res = await listFamilies(segmentInput);

    if (res.status !== HTTP_STATUS_CODES.OK) return;
    setFamilies(res.data);
  };

  const fetchClasses = async () => {
    if (!segmentInput.trim().length || !familyInput.trim().length) return;

    const res = await listClasses(segmentInput, familyInput);

    if (res.status !== HTTP_STATUS_CODES.OK) return;
    setClasses(res.data);
  };

  const fetchBricks = async () => {
    if (!segmentInput.trim().length || !familyInput.trim().length || !classInput.trim().length) return;

    const res = await listBricks(segmentInput, familyInput, classInput);
    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setBricks(res.data);
  };

  function getSelectFor(title, data, keyDesc, onChange, value, disabler = undefined) {
    const disabledSyle = { color: 'grey' };

    const isDisabled = () => disabler?.trim().length === 0

    return (
      <div className="filter">
        <span style={isDisabled() ? disabledSyle : {}}>{title}</span>
        <select disabled={isDisabled()} value={value} onChange={onChange}>
          <option value={''}>-- SELECT --</option>
          {data.map((item, key) => (
            <option key={key} value={item[title]}>{item[keyDesc]}</option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className="brick-filters-container">
      {getSelectFor('SEGMENT', segments, 'SegmentDesc', onSegmentInputChange, segmentInput)}
      {getSelectFor('FAMILY', families, 'FamilyDesc', onFamilyInputChange, familyInput, segmentInput)}
      {getSelectFor('CLASS', classes, 'ClassDesc', onClassInputChange, classInput, familyInput)}
      {getSelectFor('BRICK', bricks, 'BrickDesc', onBrickInputChange, brickInput, classInput)}
    </div>
  );
};
export default BRICKFiltersContainer;