import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './ServiceAnalytics.css';

const ServiceAnalytics = () => {
  return (
    <PageSkeletonComponent>

     <h1>SERVICE PROVIDER - Analytics Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      <b>OUTLINE OF WHAT THIS PAGE IS USED FOR AND ANY FUNCTIONALITY THAT IS KNOWN</b>

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default ServiceAnalytics;