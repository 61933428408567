import React from "react";
import {useTranslation} from "react-i18next";

export default function DownloadOurApp(){
    const { t } = useTranslation(["common"]);
    return(
        <div className={`w-full px-3 mt-5 rounded-xl overflow-y-auto`}>
            <div className={"flex justify-between items-center flex-wrap gap-3"}>
                <div className="w-44 h-auto">
                    <img src="https://members.bringback.app/BBA-Logo-TBl.png" alt="logo" className="w-full h-full object-contain object-centers" />
                </div>
                    <a
                        href="https://bringbackaustralia.app/download-app/"
                        className="px-8 py-2 w-max bg-primary text-white rounded-md border"
                    >
                        Download our APP
                    </a>
            </div>
            <div
                className="p-4 text-sm text-center text-green-800 rounded-lg bg-[#B3CC2D]"
            >
                <p className="text-lg leading-7 text-black">
                    <span className="font-bold">{t("common:order_success_meessage_line1").toString()}</span>
                    <br />
                    <span>{t("common:order_success_meessage_line2").toString()}</span>
                    <br />
                    <span className="font-bold">{t("common:order_success_meessage_line3").toString()}</span>
                </p>

            </div>
            <div className={'mt-[28px] mb-[10px] flex justify-center items-center'}>
                <a href="https://bringbackaustralia.app/download-app/">
                    <img src="/DownloadAppSupplier.png" className={"w-full h-[488px] mx-auto object-contain"} alt=""/>
                </a>
            </div>
        </div>
    );
}
