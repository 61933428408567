import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
    signupApproval,
    fetchParentCompanies,
    setParentCompanies,
    setErrorMsg
} from "../../store/slices/signupApprovalSlice";
import CreateOrUpdateCompany from "./CreateOrUpdateCompany";


const AssignParentCompanyDialog = ({ assignParentCompanyDialog, setAssignParentCompanyDialog, sethandleSave, setCancelModal, parentCompany, Decision, callUpdateSubcription = false }) => {
    const { ParentCompanies, errorMsg } = useAppSelector(signupApproval);
    const [loading, setLoading] = useState(true)
    const [showAdditionalForm, setShowAdditionalForm] = useState(false);

    const [step, setStep] = useState(1);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (ParentCompanies.length > 0 && loading) {
            setLoading(false);
        }
    }, [ParentCompanies]);

    const [query, setQuery] = useState('')
    const [selectedCompany, setSelectedCompany] = useState(null)

    const loadCompanies = async () => {
        dispatch(setErrorMsg({}))
        if (query !== '') {
            await dispatch(fetchParentCompanies({ ParentCompanyName: query }));
        }
        return [];
    };

    const resetFormAndCloseDialog = () => {
        dispatch(setParentCompanies([]));
        dispatch(setErrorMsg({}));
        setSelectedCompany(null);
        setShowAdditionalForm(false);
        setStep(1);
        setAssignParentCompanyDialog(false);
        setCancelModal(true);
        setQuery('')
    };


    return (
        <Transition show={assignParentCompanyDialog} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={() => resetFormAndCloseDialog()}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full w-full items-center justify-center p-2 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-full ${showAdditionalForm ? 'h-[calc(100vh-200px)]' : ''} max-w-4xl transform overflow-x-hidden overflow-y-auto rounded-2xl p-4 bg-white text-left align-middle shadow-xl transition-all`}>
                                <div className="flex pb-3 justify-between items-center">
                                    <div />
                                    <h1 className="text-lg font-bold text-right">
                                        {showAdditionalForm ? 'Create New Parent' : (step === 2 ? 'Edit Parent Company Detail' : 'Search Parent Company')}
                                    </h1>
                                    <div
                                        onClick={() => resetFormAndCloseDialog()}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>

                                </div>
                                <div className="flex w-full flex-col">
                                    <div>
                                        <div className="w-full mx-auto">
                                            <div className="flex flex-col items-center">
                                                {!showAdditionalForm && step === 1 && (
                                                    <>
                                                        <div className="flex items-center grow w-full gap-5">
                                                            <p className="text-sm whitespace-nowrap">Parent Company:</p>
                                                            <input type="text" onChange={(ev) => setQuery(ev.target.value)} className="mt-1 p-2 border rounded-md w-full mb-2" />
                                                            <button disabled={query.length === 0} onClick={() => loadCompanies()} className={`${query.length === 0 ? 'bg-gray-500' : 'bg-primary'} px-4 h-9  text-white rounded-lg`}>Search</button>
                                                        </div>
                                                        <div className="border border-black/50 rounded-lg p-3 w-full h-52 overflow-y-auto overflow-x-auto custom_scroll">
                                                            {errorMsg && ParentCompanies?.length === 0 && <p className="flex justify-center items-center h-full text-red-500">{errorMsg?.error}</p>}
                                                            {ParentCompanies?.map((person, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`flex items-center p-1 mb-1 ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'} ${selectedCompany === person ? 'bg-gray-300' : ''}`}
                                                                >
                                                                    <input
                                                                        id={`radio-${index}`}
                                                                        type="radio"
                                                                        value={person.ParentCompanyName}
                                                                        name="company-radio"
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                                                                        onClick={() => setSelectedCompany(person)}
                                                                    />
                                                                    <label
                                                                        htmlFor={`radio-${index}`}
                                                                        className={`ms-2 text-sm text-gray-900 ${selectedCompany?.ParentCompanyName === person?.ParentCompanyName ? 'font-bold' : ''}`}
                                                                    >
                                                                        {person?.ParentCompanyName}
                                                                    </label>
                                                                </div>
                                                            ))}

                                                        </div>

                                                    </>
                                                )}

                                                {(showAdditionalForm || step === 2) ? (
                                                    <CreateOrUpdateCompany
                                                        Decision={Decision}
                                                        newcmp={showAdditionalForm}
                                                        selectedCompany={selectedCompany}
                                                        parentCompany={parentCompany}
                                                        sethandleSave={() => sethandleSave(true)}
                                                        closeDialog={() => resetFormAndCloseDialog()}
                                                        callUpdateSubcription={callUpdateSubcription}
                                                    />
                                                ) : (
                                                    <>
                                                        {
                                                            (errorMsg?.error || ParentCompanies?.length > 0) && step === 1 &&
                                                            <label className="inline-flex py-3 items-center self-start gap-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="w-4 h-4"
                                                                    name="option"
                                                                    checked={showAdditionalForm}
                                                                    onChange={() => setShowAdditionalForm(!showAdditionalForm)}
                                                                />
                                                                <span>Create new</span>
                                                            </label>
                                                        }
                                                        <div className="flex grow mt-5 justify-center items-end gap-4">
                                                            <button onClick={() => resetFormAndCloseDialog()} className="bg-gray-400 rounded-lg py-2 px-5 text-white">
                                                                Cancel
                                                            </button>
                                                            <button
                                                                disabled={query === '' || ParentCompanies?.length === 0}
                                                                onClick={() => setStep(2)}
                                                                className={`${query === '' ? "bg-gray-500" : "bg-primary"}  rounded-lg  py-2 px-5 text-white`}>
                                                                Proceed
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </Dialog.Panel >
                        </Transition.Child >
                    </div >
                </div >
            </Dialog >
        </Transition >
    );
}

export default AssignParentCompanyDialog