import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { finish, importProds, start } from '../../api/ImportFileProducts';
import {
  getProductBrand,
  getProductDetails,
  getProductSegmentInfo,
  getProductCountryOrigin,
  getProductFamily,
  getProductClass,
  getProductBrickInfo,
  getProductGroupingInfo,
  getProductVariantInfo,
  getProductDetailInfo,
  submitProductDetails,
  submitProductDetailID,
  getAttributeTypesByBrick,
  getAttributeValuesByTypeBrick,
  getProductAttributes,
  submitProductAttributesValues,
  getProductPROG,
  getunitOfMeasures,
  checkCompanyClaimed,
  checkBarCode,
  getUserCompanyData
} from "../../api/SupplierProducts";

export interface productState {
  processing: boolean;
  loading: boolean;
  success: boolean;
  errorMsg: string;
  product: any;
  segments: any;
  countryOrigins: any;
  family: any;
  brick: any;
  grouping: any;
  productClass: any;
  variant: any;
  details: any;
  brands: any;
  wizardIndex: number;
  attributes: any;
}

export const initialState: productState = {
  processing: false,
  loading: false,
  success: false,
  errorMsg: "",
  product:{},
  segments: [],
  countryOrigins: [],
  family: [],
  productClass: [],
  brick: [],
  grouping: [],
  variant: [],
  details: [],
  brands: [],
  wizardIndex:5,
  attributes: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setErrorMsg: (state: productState, { payload }: PayloadAction<string>) => {
      state.errorMsg = payload;
    },
    setLoading: (state: productState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setProcessing: (state: productState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setIndex: (state: productState, { payload }: PayloadAction<any>) => {
      state.wizardIndex = payload;
    },
    setProductDetail: (state: productState, { payload }: PayloadAction<any>) => {
      state.product = {
        ...state.product,
        FK_SegmentID: payload?.SegmentID,
        FK_BrandID: payload?.BrandID,
        FK_FamilyID: payload.FamilyID,
        FK_ClassID: payload.ClassID,
        FK_BrickID: payload.BrickID,
        FK_GroupingID: payload.GroupingID,
        FK_VariantID: payload.VariantID,
        FK_DetailID: payload.DetailID,
        ...payload,
      };
    },
    clearProductDetail : (state: productState, { payload }: PayloadAction<any>) => {
      state.product = {};
    },
    addProduct: (state: productState, { payload }: PayloadAction<any>) => {
      state.product = payload;
    },
    setSegments: (state: productState, { payload }: PayloadAction<any>) => {
      state.segments = payload
    },
    setCountryOrigin: (state: productState, { payload }: PayloadAction<any>) => {
      state.countryOrigins = payload
    },
    setFamily: (state: productState, { payload }: PayloadAction<any>) => {
      state.family = payload
    },
    setClass: (state: productState, { payload }: PayloadAction<any>) => {
      state.productClass = payload
    },
    setBrick: (state: productState, { payload }: PayloadAction<any>) => {
      state.brick = payload
    },
    setGrouping: (state: productState, { payload }: PayloadAction<any>) => {
      state.grouping = payload
    },
    setVariant: (state: productState, { payload }: PayloadAction<any>) => {
      state.variant = payload
    },
    setDetails: (state: productState, { payload }: PayloadAction<any>) => {
      state.details = payload
    },
    setBrandInfo: (state: productState, { payload }: PayloadAction<any>) => {
      state.brands = payload
    },
    setAttributes: (state: productState, { payload }: PayloadAction<any>) => {
      state.attributes = payload
    },
  },
});

export const importStart = () => async (dispatch: any) => {
  const res = await start();
  return res;
};

export const productsImport = (UpdateID, chunck) => async (dispatch: any) => {
  const res = await importProds(UpdateID, chunck);
  return res;
};

export const importFinish = (UpdateID) => async (dispatch: any) => {
  const res = await finish(UpdateID);
  return res;
};

export const fetchProductDetails = (query) => async (dispatch) => {
  try {
    const response = await getProductDetails(query);

    if (response?.status === 200) {
      await dispatch(setProductDetail(response.data));
      const brand = await getProductBrand(response.data?.CompanyID, '1');

      /*const parentCompanies = JSON.parse(localStorage.getItem('parentCompanies'));
      const brand = await getProductBrand(parentCompanies?.ParentCompanyID, '1');*/

      if (brand?.status === 200) {
        await dispatch(setBrandInfo(brand.data?.BrandInfo));
      } else {
        console.error('Failed to fetch brand information:', brand?.status);
      }
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchSegmentInfo = () => async (dispatch: any) => {
  try {
    const response = await getProductSegmentInfo();
    if (response?.status === 200) {
      dispatch(setSegments(response.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchCountryOrigin = () => async (dispatch: any) => {
  try {
    const response = await getProductCountryOrigin();
    if (response?.status === 200) {
      dispatch(setCountryOrigin(response?.data?.CountryInfo));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};
export const fetchCountries = () => async (dispatch: any) => {
  try {
    const response = await getProductCountryOrigin();
    if (response?.status === 200) {
      return response?.data?.CountryInfo;
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchFamily = (query) => async (dispatch: any) => {
  try {
    const response = await getProductFamily(query);
    if (response?.status === 200) {
      dispatch(setFamily(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchClass = (query) => async (dispatch: any) => {
  try {
    const response = await getProductClass(query);
    if (response?.status === 200) {
      dispatch(setClass(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchBrick = (query) => async (dispatch: any) => {
  try {
    const response = await getProductBrickInfo(query);
    if (response?.status === 200) {
      dispatch(setBrick(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchGrouping = (query) => async (dispatch: any) => {
  try {
    const response = await getProductGroupingInfo(query);
    if (response?.status === 200) {
      dispatch(setGrouping(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchVariant = (query) => async (dispatch: any) => {
  try {
    const response = await getProductVariantInfo(query);
    if (response?.status === 200) {
      dispatch(setVariant(response?.data));
    } else {
      console.error('Failed to fetch VariantInfo:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching VariantInfo:', error);
  }
};

export const fetchDetail = (query) => async (dispatch: any) => {
  try {
    const response = await getProductDetailInfo(query);
    if (response?.status === 200) {
      dispatch(setDetails(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchSubmit = (data) => async (dispatch: any) => {
  try {

    const response = await submitProductDetails(data);
    if (response?.status === 200) {
      let data = 'success'
      return data;
    } else {
      console.error('Failed to fetch product details:', response?.status);
      let data = 'error'
      return data;
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
    let data = 'error'
    return data;
  }
};

export const fetchDetailID = (data) => async (dispatch: any) => {
  try {
    const response = await submitProductDetailID(data);
    if (response?.status === 200) {
      if (data.DetailID) {
        // If DetailID is provided in the data, return it
        return data.DetailID;
      } else if (response?.data?.DetailID) {
        // If DetailID exists, return it
        return response?.data?.DetailID;
      } else {
        dispatch(setErrorMsg(response?.data?.message
            ? response?.data?.message : response?.data?.errorMessage ? response?.data?.errorMessage: "An undefined error has occurred. Please attempt the action again later."));
      }
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }

  // Return null or handle the case where DetailID is not available
  return null;
};

export const fetchAttributes = (brickId, productId) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const query = {BrickID: brickId}
    const response = await getAttributeTypesByBrick(query);
    if (response?.status === 200 && response?.data.length) {
      const selectedAttributes = await fetchProductAttributes(productId)
      const attributeLabels = response?.data

      for (const attribute of attributeLabels) {
        attribute.attributeValues = [];
        attribute.selectedAttribute = '';
        attribute.oldSelectedAttribute = ''
        attribute.preValue = true;
        for (let i = 0; i < selectedAttributes.length; ++i)
        {
          if (selectedAttributes[i].AttributeTypeID === attribute.AttributeTypeID) {

            attribute.selectedAttribute = {BrickAttributeID: selectedAttributes[i].BrickAttributeID, AttributeValueDesc: selectedAttributes[i].AttributeValueDesc};
            attribute.oldSelectedAttribute = selectedAttributes[i].BrickAttributeID;
            attribute.attributeValues.push({BrickAttributeID: selectedAttributes[i].BrickAttributeID, AttributeValueDesc: selectedAttributes[i].AttributeValueDesc})
            break;
          }
        }
      }
      dispatch(setAttributes(attributeLabels));
      dispatch(setLoading(false));
    } else {
      dispatch(setAttributes(response?.data));
      dispatch(setLoading(false));
      console.error('Failed to fetch attributes:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching attributes:', error);
  }
};

export const checkGtinNo = (gtin) => async (dispatch: any) => {
  try {
    const response = await checkCompanyClaimed(gtin);
    if (response?.status === 200) {
      return response?.data
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching GTIN:', error);
  }
};

export const checkBarCodeNo = (barcode) => async (dispatch: any) => {
  try {
    const response = await checkBarCode(barcode);
    if (response?.status === 200) {
      return response?.data
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching GTIN:', error);
  }
};

export const userComapnyData = (companyId) => async (dispatch: any) => {
  try {
    const response = await getUserCompanyData(companyId);
    if (response?.status === 200) {
      const entityGLNs = response?.data.CompanyInfo.map(info => info.EntityGLN);
      return entityGLNs;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching GTIN:', error);
  }
};

export const fetchSegments = () => async (dispatch: any) => {
  try {
    const response = await getProductSegmentInfo();
    if (response?.status === 200) {
      return response?.data;
    } else {
      return [];
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching GTIN:', error);
  }
};

export const fetchAttributesValues = (brickId, attributes, attributeIndex) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    let attributeData = JSON.parse(JSON.stringify(attributes))
    const data = {BrickID: brickId, AttributeTypeID: attributeData[attributeIndex].AttributeTypeID}
    const response = await getAttributeValuesByTypeBrick(data);

    if (response?.status === 200 && response?.data.length) {
      attributeData[attributeIndex].attributeValues = response?.data;
    }else {
      attributeData[attributeIndex].attributeValues = [];
    }
    attributeData[attributeIndex].preValue = false;

    dispatch(setAttributes(attributeData));
    dispatch(setLoading(false));

  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching attributes values:', error);
  }
};


export const submitProductAttributes = (data) => async (dispatch: any) => {
  try {
    const response = await submitProductAttributesValues(data);
    if (response?.status === 200) {
    } else {
      console.error('Failed to update product Attributes:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }

  // Return null or handle the case where DetailID is not available
  return null;
};


const fetchProductAttributes = async (productId) => {
  try {
    const response = await getProductAttributes({ProductID: productId})
    if (response?.status === 200 && response?.data.length) {
      return response?.data
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching products attributes values:', error);
  }
};

export const fetchProductPROG = (productId) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const response = await getProductPROG({ProductID: productId})
    if (response?.status === 200 && response?.data?.ProductInfo.length) {
      dispatch(setLoading(false));
      return response?.data?.ProductInfo;
    } else {
      dispatch(setLoading(false));
      return []
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching product prog values:', error);
  }
};

export const fetchPROG = (gtin) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const response = await getProductPROG({GTIN: gtin})
    if (response?.status === 200 && response?.data?.ProductInfo.length) {
      dispatch(setLoading(false));
      return response?.data?.ProductInfo;
    } else {
      dispatch(setLoading(false));
      return []
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching product prog values:', error);
  }
};


export const fetchUOM = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const response = await getunitOfMeasures()
    if (response?.status === 200 && response?.data?.length) {
      dispatch(setLoading(false));
      return response?.data;
    } else {
      dispatch(setLoading(false));
      return []
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching product prog values:', error);
  }
};

export const fetchGS1ProductDetails = (query) => async (dispatch) => {
  try {
    const response = await getProductDetails(query);

    if (response?.status === 200) {
      return response.data
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};


export const {
  setLoading,
  setErrorMsg,
  setProcessing,
  setBrandInfo,
  clearProductDetail,
  setDetails,
  setVariant,
  setGrouping,
  setBrick,
  setClass,
  setFamily,
  setCountryOrigin,
  addProduct,
  setSegments,
  setProductDetail,
  setIndex,
  setAttributes
} = productSlice.actions;

export const productSelector = (state: RootState) => state.product;

export default productSlice.reducer;
