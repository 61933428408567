import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  let [counter, setCounter] = useState(5);

  const takeToLogin = (e) => {
    e.preventDefault();
    navigate("/SignIn");
  };

  useEffect(() => {

  const countdownInterval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        navigate("/SignIn")
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [counter]);

  return (
    <div className="w-full flex justify-center h-full items-center overflow-hidden">
      <div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
        <form>
          <img
            className="w-[200px] mb-4 self-center m-auto"
            src="BBA-Logo-TBl-S.png"
            alt="bba"
          />
          <h6 className="text-center text-black pb-3 font-bold">Congratulations, your account has been verified!</h6>
          <p className="text-black font-normal lg:text-left md:text-left text-center">You are being redirected to Login page in <span className="font-bold">{counter}</span> seconds.</p>
          {/* <div className="FormButtonContainer">
            <button onClick={takeToLogin}>Take me to the login</button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default CompleteRegistration;
