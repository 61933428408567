import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './DatabaseHome.css';

const DatabaseHome = () => {
  return (
    <PageSkeletonComponent>

     <h1>DATABASE - Home Page (Dashboard)</h1>

      <hr />

      <h2>Purpose of Page</h2>

      Our databases consist of the following:<br /><br />
      <li>
        <b>Replication of GS1 USA</b><br />
        This is a mirrored version of the Subscription that we have to source GS1 USA Data.<br />
        This data is kept up to date using an API Interface to get all updates since last update, thus keeping our data in sync with all updates.<br />
        The GS1 Data is a repository for multiple countries and we get multiple countries data, 23 countries as at November 2020 and growing, from this one source.<br />
      </li>
      <li>
        <b>Bring Back Australia Database</b><br />
        This data is what drives our app, the product data has been validated.<br />
      </li>
      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" href="https://trickletreat.atlassian.net/wiki/spaces/BBA/pages/226459669/Database">Database</a><br />

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default DatabaseHome;