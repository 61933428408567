import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1USA.css';

const GS1USA = () => {
  return (
    <PageSkeletonComponent>

      <h1>GS1 United States Update Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      This is the home page for GS1 United States<br /><br />


      <h3>GS1 USA Data Replication</h3>
      From this Page you can access the data replication that syncronises with the GS1 USA Master Data.<br /><br />

      The Master Data is mirrored to our database server here for use in validation of Companies, Locations and Products.<br /><br />

      The trigger for the syncronisation is the changes <b>Timestamp</b> and this is performed <b>Hourly / Twice-Daily / Daily / Weekly / Monthly</b> <b>*</b><br /><br />

      <b>*</b> Timeframe for syncronisation of changes to be determined<br />

      <h3>GS1 USA Data Validation</h3>
      From this Page you can access the data validation processes.

      <h2>Who Can Access</h2>
      This page can only be accessed by authorised employees of Bring Back Australia.


    </PageSkeletonComponent>
  );
};

export default GS1USA;