import React from "react";
import blueIcon from "../../assets/icons/informationBlue.png";
import greyIcon from "../../assets/icons/informationGrey.png";
import "./index.css";

const Tooltips = (props) => {
  const tooltipText = props.tooltipText;
  console.log("this is a tooltipstext", tooltipText);
  const hasText = !!tooltipText;

  return (
    <div className="tooltip-container">
      <img
        src={hasText ? blueIcon : greyIcon}
        alt="tooltip icon"
        className="tooltip-icon"
      />
      {hasText && (
        <div className="tooltip-text-container">
          <p className="tooltip-text">{tooltipText}</p>
        </div>
      )}
    </div>
  );
};

export default Tooltips;
