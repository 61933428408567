import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './SignupHome.css';

const SignupHome = () => {
  return (
    <PageSkeletonComponent>

     <h1>SIGNUP - Home Page (Dashboard)</h1>

      <hr />

      <h2>Purpose of Page</h2>
      <b>Intro Page for Signup</b>
      <br /><br />
      The user will be given an overview of what they are signing up for.
      <br /><br />
      Explanation of what a Supplier / Retailer / Service Provider is.
      <br /><br />
      Acknowledge and proceed or cancel.
      <br /><br />
      Cancel returns to... ??
      <br /><br />
      

      <b>Cancel Button</b> or <b>Continue Button</b>
      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default SignupHome;