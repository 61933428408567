import React, { useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AssignParentCompanyDialog from "../../components/Dialogs/AssignParentCompanyDialog";

const AssignParentCompany = () => {

    const [assignParentCompanyDialog,setAssignParentCompanyDialog] = useState(false);

    const DefaultColumns = [
        { name: 'Parent Company', minWidth: 80, header: 'Parent Company', align:'center', defaultFlex: 1, render: ({ value, data }) => <p>Parent company 1</p> },
        { name: 'Company', minWidth: 80, header: 'Company', align:'center', defaultFlex: 1, render: ({ value, data }) => <p>company 1</p> },
        { name: 'Company Address', minWidth: 80, header: 'Company Address', align:'center', defaultFlex: 1, render: ({ value, data }) => <p>address 1</p> },
        { name: 'Number of Products', minWidth: 80, header: 'Company Address', align:'center', defaultFlex: 1, render: ({ value, data }) => <p>1343</p> },
        { name: 'Actions', minWidth: 80, header: 'Company Address', align:'center', defaultFlex: 1, render: ({ value, data }) => <button onClick={()=>setAssignParentCompanyDialog(true)}>Assign</button> },
    ];

    const dataSource = [{}]

    return (
        <>   
            <div className="w-full mx-auto">
                <div className="flex flex-col">
                    <ReactDataGrid
						licenseKey={'AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988'}
                        idProperty={'id'}
                        style={{
                            marginTop: 5,
                            minHeight: "87vh",
                        }}
                        enableFiltering={false}
                        columns={DefaultColumns}
                        // defaultFilterValue={substituteFilterValues}
                        rowHeight={90}
                        dataSource={dataSource}
                        pagination="remote"
                        defaultLimit={10}
                    />
                </div>
            </div>
            <AssignParentCompanyDialog assignParentCompanyDialog={assignParentCompanyDialog} setAssignParentCompanyDialog={setAssignParentCompanyDialog} />
        </>
    );
};

export default AssignParentCompany;
